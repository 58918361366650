import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { type FC, useMemo } from 'react';
import { IconTwoColumnRow } from '~/shared/ui';
import { type Profile } from '../model/Profile.ts';

dayjs.extend(relativeTime);

type Props = {
  joinedCompanyDate: Profile['joinedCompanyDate'] | undefined;
  isLoading?: boolean;
};

export const CompanyExperienceTwoColumnRow: FC<Props> = (props) => {
  const { joinedCompanyDate, isLoading } = props;

  const timeDiff = useMemo(
    () => joinedCompanyDate?.fromNow(true),
    [joinedCompanyDate]
  );

  if (timeDiff == null || isLoading) {
    return null;
  }

  return (
    <IconTwoColumnRow
      icon="clockFilled"
      label="Tenure with the Company:"
      content={timeDiff}
      isLoading={isLoading}
    />
  );
};
