import dayjs from 'dayjs';
import { useMemo } from 'react';
import { MyProfile , useCurrentUser } from '~/entities/profile/crossExports.ts';
import { type KindOfMeeting } from '../model/KindOfMeeting.ts';
import { Meeting } from '../model/Meeting.ts';
import { useMeetingsQuery } from './useMeetingsQuery.ts';

export function useMeetingsByKind(kind?: KindOfMeeting) {
  const { data: currentProfile, isLoading: isCurrentProfileLoading } =
    useCurrentUser();

  const { data: loadedMeetings, isLoading: meetingsAreLoading } =
    useMeetingsQuery();

  const meetings = useMemo(() => {
    if (!loadedMeetings || !currentProfile) {
      return [];
    }
    return loadedMeetings;

    // switch (kind) {
    //   case 'accepted':
    //     return selectFutureUserMeetings(loadedMeetings);
    //
    //   case 'invites':
    //     const incomingMeetings = selectIncomingUserMeetings(
    //       loadedMeetings,
    //       currentProfile
    //     );
    //     return selectInvitationsForUser(incomingMeetings, currentProfile);
    //
    //   case 'completed':
    //     return selectPastMeetings(loadedMeetings);
    //
    //   case undefined:
    //     return loadedMeetings;
    //
    //   default:
    //     throw new Error(`Unsupported kind (${kind}) of meetings`);
    // }
  }, [currentProfile, kind, loadedMeetings]);

  return {
    meetings,
    meetingsAreLoading: isCurrentProfileLoading || meetingsAreLoading,
  };
}
//
// const selectFutureUserMeetings = (meetings: Meeting[]) => {
//   const now = dayjs();
//
//   return meetings.filter((meeting) => {
//     const hasNoEndOfMeeting = !meeting.howLong;
//     if (hasNoEndOfMeeting) {
//       return meeting.when.isAfter(now);
//     }
//
//     const endOfMeeting = meeting.when.add(meeting.howLong!);
//     return endOfMeeting.isAfter(now);
//   });
// };
//
// export const selectIncomingUserMeetings = (
//   allMeetings: Meeting[],
//   currentProfile: MyProfile | null
// ) => {
//   if (!currentProfile) {
//     return [];
//   }
//
//   return allMeetings.filter((meeting) =>
//     meeting.invitedMembers.some(
//       (member) => member.userId === currentProfile.userId
//     )
//   );
// };
//
// const selectInvitationsForUser = (
//   incomingUserMeetings: Meeting[],
//   currentProfile: MyProfile | null
// ) => {
//   if (!currentProfile) {
//     return [];
//   }
//
//   return incomingUserMeetings.filter(
//     (meeting) =>
//       meeting.meetingOwner.userId !== currentProfile.userId &&
//       !meeting.acceptedMembers.some(
//         (member) => member.userId === currentProfile.userId
//       )
//   );
// };
//
// const selectPastMeetings = (userMeetings: Meeting[]) => {
//   const now = dayjs();
//
//   return userMeetings.filter((meeting) => {
//     const hasNoEndOfMeeting = !meeting.howLong;
//     if (hasNoEndOfMeeting) {
//       return now.isAfter(meeting.when);
//     }
//
//     const endOfMeeting = meeting.when.add(meeting.howLong!);
//     return now.isAfter(endOfMeeting);
//   });
// };
