import { Collapse, IconButton, useTheme } from '@mui/material';
import { type FC, type Ref, useCallback, useMemo, useState } from 'react';
import { Icon } from '~/shared/atoms/icons';
import { useLinesCount } from './useLinesCount';
import classes from './ContentCollapser.module.scss';

type Props = {
  lineHeight: number;
  maxLines: number;

  children: FC<{ forwardRef: Ref<HTMLParagraphElement>; toggle: () => void }>;
};

export const ContentCollapser = (props: Props) => {
  const { maxLines, lineHeight, children: TextComponent } = props;

  const theme = useTheme();
  const { numberOfLines, ref } = useLinesCount(lineHeight);

  const canExpand =
    typeof numberOfLines === 'number' && numberOfLines > maxLines;

  const [expanded, setExpanded] = useState(false);
  const toggle = useCallback(() => {
    if (canExpand) {
      setExpanded((state) => !state);
    }
  }, [canExpand]);

  const transition = useMemo(
    () =>
      theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    []
  );

  const getParagraphHeight = useCallback(
    (expanded: boolean) => {
      if (numberOfLines === null) {
        return 'auto';
      }

      if (expanded) {
        return numberOfLines * lineHeight;
      }

      if (numberOfLines > maxLines) {
        return maxLines * lineHeight;
      }

      return numberOfLines * lineHeight;
    },
    [numberOfLines, maxLines, lineHeight]
  );

  const collapsedSize = useMemo(
    () => getParagraphHeight(false),
    [getParagraphHeight]
  );
  const expandedSize = useMemo(
    () => getParagraphHeight(true),
    [getParagraphHeight]
  );

  return (
    <div className={classes.container}>
      <Collapse
        in={expanded}
        collapsedSize={collapsedSize}
        style={{
          maxHeight: expandedSize,
        }}
      >
        <TextComponent forwardRef={ref} toggle={toggle} />
      </Collapse>

      {canExpand && (
        <IconButton
          onClick={toggle}
          className={classes.expandButton}
          size="small"
          color="primary"
        >
          <Icon
            variant="expandMore"
            style={{
              transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
              marginLeft: 'auto',
              transition: transition,
            }}
          />
        </IconButton>
      )}
    </div>
  );
};
