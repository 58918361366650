import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { type ColleagueProfile } from '~/entities/colleague/index.ts';
import {
  useDislikeColleague,
  useLikeColleague,
} from '~/features/colleague/index.ts';
import { Photo } from '~/shared/atoms/Photo/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { FilteredColleagueInfo } from '~/widgets/filtered-colleague-info/index.ts';
import { useAnimationReset } from './useAnimationReset.ts';
import { useCardTouch } from './useCardTouch.tsx';
import {
  MAX_ROTATION_ANGLE_TO_SWIPE,
  useRotationDegree,
} from './useRotationDegree.ts';
import classes from './SwipeColleagueCard.module.scss';

const MAX_SWIPE_TRANSLATE = 100;

type Props = {
  colleague: ColleagueProfile;
};

export const SwipeColleagueCard = (props: Props) => {
  const { colleague } = props;

  const navigate = useNavigate();

  const {
    translateX,
    onTouchStart,
    onTouchMove,
    reset: resetTouch,
  } = useCardTouch();
  let rotationDegree = useRotationDegree(translateX);

  const [syntheticRotation, setSyntheticRotation] = useState(0);
  if (syntheticRotation) {
    rotationDegree = syntheticRotation;
  }

  const { animateHiding, runHideAnimation } = useAnimationReset(
    colleague,
    () => {
      resetTouch();
      setSyntheticRotation(0);
    }
  );

  const { mutate: likeColleague } = useLikeColleague();
  const { mutate: dislikeColleague } = useDislikeColleague();

  const onLikeColleague = useCallback(() => {
    setSyntheticRotation(MAX_ROTATION_ANGLE_TO_SWIPE);
    runHideAnimation();
    likeColleague(colleague.userId);
  }, [colleague.userId, likeColleague, runHideAnimation]);

  const onDislikeColleague = useCallback(() => {
    setSyntheticRotation(-MAX_ROTATION_ANGLE_TO_SWIPE);
    runHideAnimation();
    dislikeColleague(colleague.userId);
  }, [colleague.userId, dislikeColleague, runHideAnimation]);

  const handleTouchEnd = () => {
    const isAccidentMove = Math.abs(translateX) <= MAX_SWIPE_TRANSLATE;
    if (isAccidentMove) {
      resetTouch();
      return;
    }

    runHideAnimation();

    const isSwipeOnRight = translateX > 0;
    if (isSwipeOnRight) {
      likeColleague(colleague.userId);
    } else {
      dislikeColleague(colleague.userId);
    }
  };

  const openColleagueProfile = useCallback(() => {
    navigate(routes.colleagues.userId(colleague.userId.toString()).url());
  }, [navigate, colleague]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.hidingAnimation]: animateHiding,
      })}
      style={{
        transform: `rotate(${rotationDegree}deg)`,
        transformOrigin: rotationDegree < 0 ? 'left bottom' : 'right bottom',
      }}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <Photo
        sx={{ borderRadius: '24px 24px 0 0' }}
        avatarUrl={colleague.avatar.url}
        onClick={openColleagueProfile}
      />

      <FilteredColleagueInfo
        colleague={colleague}
        onDislike={onDislikeColleague}
        onLike={onLikeColleague}
        handleHeaderClick={openColleagueProfile}
      />
    </div>
  );
};
