import { Chat } from './Chat.ts';
import { ChatType } from './ChatType.ts';

export class PrivateChat extends Chat {
  interlocutorId: number;

  constructor(chat: Omit<Chat, 'type'> & Pick<PrivateChat, 'interlocutorId'>) {
    super({
      ...chat,
      type: ChatType.Dialog,
    });

    this.interlocutorId = chat.interlocutorId;
  }
}
