import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { queryKeys, QuestApi, QuestDto } from '~/shared/api/index.ts';
import { time } from '~/utils/index.ts';
import { Quest } from '../model/Quest.ts';

export const loadQuestsQueryKeys = () => queryKeys('load quests');

export function useLoadQuestsQuery() {
  const questApi = useDi(QuestApi);

  return useQuery({
    queryKey: loadQuestsQueryKeys(),
    queryFn: async () => await questApi.getQuestsAsync(),
    staleTime: time(5, 'minutes'),
    select: mapResponse,
  });
}

const mapResponse = (dto: Awaited<ReturnType<QuestApi['getQuestsAsync']>>) =>
  dto.results.map((dto) => Mapper.map(QuestDto, Quest, dto));
