import { ApiBase } from '../ApiBase.ts';
import { type QuestFakeApi } from './QuestFakeApi.ts';
import { type QuestRealApi } from './QuestRealApi.ts';

export class QuestApi extends ApiBase {
  constructor(
    private realApi: QuestRealApi,
    private fakeApi: QuestFakeApi
  ) {
    super();
  }

  getQuestsAsync: QuestRealApi['getQuestsAsync'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.getQuestsAsync(...args);
    }
    return this.realApi.getQuestsAsync(...args);
  };

  getQuestByIdAsync: QuestRealApi['getQuestByIdAsync'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.getQuestByIdAsync(...args);
    }
    return this.realApi.getQuestByIdAsync(...args);
  };

  completeTaskAsync: QuestRealApi['completeTaskAsync'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.completeTaskAsync(...args);
    }
    return this.realApi.completeTaskAsync(...args);
  };

  deCompleteTaskAsync: QuestRealApi['deCompleteTaskAsync'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.deCompleteTaskAsync(...args);
    }
    return this.realApi.deCompleteTaskAsync(...args);
  };
}
