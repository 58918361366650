import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { useCallback } from 'react';
import { useCurrentUser } from '~/entities/profile/crossExports.ts';
import { ChatSignal, queryKeys } from '~/shared/api/index.ts';
import { MessageDto } from '~/shared/api/MessageDto.ts';
import type { PagedData } from '~/shared/ui/InfiniteScroll/InfiniteScrollRender.tsx';
import { Message } from '../model/Message.ts';
import { type PrivateChat } from '../model/PrivateChat.ts';
import { CHAT_MESSAGES_PAGE_SIZE } from '../ui/chatMessagesPageSize.ts';

export const infiniteChatMessagesQueryKeys = (
  chatId: undefined | PrivateChat['chatId'],
  userId: undefined | PrivateChat['interlocutorId']
) => queryKeys('load chat messages', 'infinitely', { chatId, userId });

export function useInfiniteChatMessagesRequest(
  chatId: undefined | PrivateChat['chatId'],
  userId: undefined | PrivateChat['interlocutorId']
) {
  const chatSignal = useDi(ChatSignal);

  const { data: myProfile } = useCurrentUser();

  return useCallback(
    async (lastMessage: Message | undefined): Promise<PagedData<Message>> => {
      if (!chatId) {
        throw new Error('there is no chat id');
      }
      if (!userId) {
        throw new Error('there is no interlocutor id');
      }
      if (!myProfile) {
        throw new Error('my profile was not loaded');
      }

      const { items: dtos } = await chatSignal.getDialogMessages({
        pageSize: CHAT_MESSAGES_PAGE_SIZE,
        earliestMessageId: lastMessage?.messageId ?? null,
        dialogId: chatId,
      });

      const messages = dtos.map((dto) => Mapper.map(MessageDto, Message, dto));

      for (const message of messages) {
        if (myProfile.userId === message.authorId) {
          message.inOutType = 'outcoming';
          message.interlocutorId = userId;
        } else {
          message.inOutType = 'incoming';
          message.interlocutorId = message.authorId;
        }
      }

      return {
        items: messages,
      };
    },
    [chatId, userId, myProfile, chatSignal]
  );
}
