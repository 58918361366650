import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { ChatSignal } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';
import { infiniteChatMessagesQueryKeys, type Message } from '../index.ts';

export function useMessageViewedMutation() {
  const queryClient = useQueryClient();
  const chatSignal = useDi(ChatSignal);

  return useMutation({
    mutationKey: ['message was viewed'],
    mutationFn: async (message: Message) => {
      await chatSignal.notifyThatMessageWasViewed({
        messageId: message.messageId,
        interlocutorId: message.interlocutorId,
      });
    },
    onError: (error) => {
      notify({
        message: `Cant mark message as viewed: ${error.message}`,
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: infiniteChatMessagesQueryKeys(undefined, undefined),
      });
    },
  });
}
