import { RealApiBase } from '../RealApiBase.ts';
import { apiRoutes } from '../apiRoutes.ts';
import type { BlockUserDto } from './BlockUserDto.ts';

export class BlockListRealApi extends RealApiBase {
  getBlockUsers = async () => {
    return this.get<{
      users: BlockUserDto[];
    }>(apiRoutes.blocklist.block_users_list.url());
  };

  blockUser = async (userId: number) => {
    return this.put<{}>(
      apiRoutes.blocklist.block_user.url({
        targetUserId: userId.toString(),
      })
    );
  };

  unblockUser = async (userId: number) => {
    return this.put<{}>(
      apiRoutes.blocklist.unblock_user.url({
        targetUserId: userId.toString(),
      })
    );
  };
}
