import { useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from '~/shared/api/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { setAuthorizationTokenToConfig } from '../config/useAuthConfig.ts';
import { invalidateIsTokenValidQuery } from './useIsTokenValidQuery.ts';

export function useSuccessLoginHandler() {
  const navigate = useNavigate();
  const authApi = useDi(AuthApi);
  const queryClient = useQueryClient();

  return useCallback(
    async (authorizationToken: string) => {
      authApi.setAuthorizationTokenToApiInterceptor(authorizationToken);
      setAuthorizationTokenToConfig(authorizationToken);
      await invalidateIsTokenValidQuery(queryClient);
      navigate(routes.url());
    },
    [authApi, navigate, queryClient]
  );
}
