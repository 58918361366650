import { type Container } from 'cheap-di';
import { FakeApiDatabase } from '../fakeApiDatabase/index.ts';
import { AuthApi } from './auth/AuthApi.ts';
import { BlockListApi } from './blockList/BlockListApi.ts';
import { configureChatApi } from './chat/configureChatApi.ts';
import type { ConfigurationOptions } from './ConfigurationOptions.ts';
import { createApiLogger } from './createApiLogger.ts';
import { DepartmentApi } from './department/DepartmentApi.ts';
import { DeveloperApi } from './developer/DeveloperApi.ts';
import { InterestApi } from './interest/InterestApi.ts';
import { JobPositionApi } from './jobPosition/JobPositionApi.ts';
import { configureMatcherApi } from './matcher/configureMatcherApi.ts';
import { configureMeetingApi } from './meeting/configureMeetingApi.ts';
import { OtherApi } from './other/OtherApi.ts';
import { ProfileApi } from './profile/ProfileApi.ts';
import { QuestApi } from './quest/QuestApi.ts';
import { SkillApi } from './skill/SkillApi.ts';
import { TelegramApi } from './telegram/TelegramApi.ts';

export async function configureApi(
  container: Container,
  options: ConfigurationOptions
) {
  container.registerInstance(new FakeApiDatabase());

  container
    .registerImplementation(AuthApi)
    .asSingleton()
    .enrich(createApiLogger('auth'));

  container
    .registerImplementation(TelegramApi)
    .asSingleton()
    .enrich(createApiLogger('telegram'));

  container
    .registerImplementation(BlockListApi)
    .asSingleton()
    .enrich(createApiLogger('block-list'));

  await configureChatApi(container, options);

  container
    .registerImplementation(DeveloperApi)
    .asSingleton()
    .enrich(createApiLogger('developer'));

  await configureMatcherApi(container, options);
  await configureMeetingApi(container, options);

  container
    .registerImplementation(OtherApi)
    .asSingleton()
    .enrich(createApiLogger('other'));

  container
    .registerImplementation(ProfileApi)
    .asSingleton()
    .enrich(createApiLogger('profile'));

  container
    .registerImplementation(QuestApi)
    .asSingleton()
    .enrich(createApiLogger('quest'));

  container
    .registerImplementation(DepartmentApi)
    .asSingleton()
    .enrich(createApiLogger('department'));

  container
    .registerImplementation(JobPositionApi)
    .asSingleton()
    .enrich(createApiLogger('jobPosition'));

  container
    .registerImplementation(InterestApi)
    .asSingleton()
    .enrich(createApiLogger('interest'));

  container
    .registerImplementation(SkillApi)
    .asSingleton()
    .enrich(createApiLogger('skill'));
}
