import { type FC } from 'react';
import { APP_BUILD_VERSION } from '~/config/environment/index.ts';
import classes from '~/pages/SettingsDisplay/SettingsDisplay.module.scss';

export const AppVersion: FC = () => {
  return (
    <p className={classes.version}>
      Version <span className={classes.versionNumber}>{APP_BUILD_VERSION}</span>
    </p>
  );
};
