import CircularProgress from '@mui/material/CircularProgress';
import { type ForwardedRef, forwardRef } from 'react';
import {
  InfiniteScroll,
  type InfiniteScrollProps,
  type InfiniteScrollRef,
  useVirtualRow,
} from '~/shared/ui/index.ts';
import {
  infiniteChatMessagesQueryKeys,
  useInfiniteChatMessagesRequest,
} from '../api/useInfiniteChatMessagesRequest.ts';
import { Message } from '../model/Message.ts';
import type { PrivateChat } from '../model/PrivateChat.ts';
import { CHAT_MESSAGES_PAGE_SIZE } from './chatMessagesPageSize.ts';
import { IncomingMessage } from './IncomingMessage.tsx';
import { OutgoingMessage } from './OutgoingMessage.tsx';
import classes from './ChatMessagesInfiniteList.module.scss';

type Props = Omit<
  InfiniteScrollProps<PrivateChat>,
  | 'estimatedRowHeight'
  | 'queryKeys'
  | 'apiRequest'
  | 'pageSize'
  | 'Row'
  | 'emptyListMessage'
> & {
  chatId: undefined | PrivateChat['chatId'];
  userId: undefined | PrivateChat['interlocutorId'];
};

const ChatMessagesInfiniteList = (
  props: Props,
  ref: ForwardedRef<InfiniteScrollRef<Message>>
) => {
  const { chatId, userId, ...infiniteProps } = props;

  const Row = useVirtualRow<Message>((props) => {
    const {
      isLoading,
      rowEntity: message,
      forwardedRef,
      hasNextPage,
      ...rest
    } = props;

    if (isLoading) {
      if (hasNextPage) {
        return (
          <div ref={forwardedRef} {...rest}>
            <CircularProgress size={20} />
          </div>
        );
      }

      return (
        <div ref={forwardedRef} {...rest}>
          Nothing more to load
        </div>
      );
    }

    if (message instanceof Message && message.inOutType === 'incoming') {
      return (
        <IncomingMessage
          ref={forwardedRef}
          message={message}
          loading={isLoading}
          {...rest}
        />
      );
    }

    return (
      <OutgoingMessage
        ref={forwardedRef}
        message={message}
        loading={isLoading}
        {...rest}
      />
    );
  }, []);

  // const _ref = useRef<InfiniteScrollRef<Message>>();
  //
  // const initialPageParam = {
  //   lastRow: undefined,
  // };
  //
  // const apiRequest = useInfiniteChatMessagesRequest(chatId, userId);
  //
  // const query = useInfiniteQuery({
  //   enabled: chatId != null && userId != null,
  //   queryKey: infiniteChatMessagesQueryKeys(chatId, userId),
  //   initialPageParam: initialPageParam,
  //   queryFn: ({ pageParam }) => apiRequest(pageParam.lastRow) as any,
  //   getNextPageParam: (lastGroup, paginatedResults) => {
  //     if (lastGroup == null) {
  //       return initialPageParam;
  //     }
  //     if (lastGroup.items.length < CHAT_MESSAGES_PAGE_SIZE) {
  //       console.log(
  //         `has no next page, last group items length: ${lastGroup.items.length}`
  //       );
  //       return null;
  //     }
  //     return {
  //       lastRow: lastGroup.items.at(-1),
  //     };
  //   },
  // });
  //
  // return (
  //   <InfiniteScrollRender
  //     ref={(list) => {
  //       if (typeof ref === 'function') {
  //         ref?.(list);
  //       } else if (ref) {
  //         ref.current = list;
  //       }
  //       _ref.current = list ?? undefined;
  //     }}
  //     query={query}
  //     estimatedRowHeight={DEFAULT_ROW_HEIGHT}
  //     Row={Row}
  //     classes={{
  //       container: classes.container,
  //       virtualRowBatch: classes.rowBatch,
  //     }}
  //     emptyListMessage={
  //       <>
  //         There are no messages here yet. <br />
  //         Be the first to write.
  //       </>
  //     }
  //     {...infiniteProps}
  //   />
  // );
  return (
    <InfiniteScroll
      ref={ref}
      estimatedRowHeight={DEFAULT_ROW_HEIGHT}
      queryKeys={infiniteChatMessagesQueryKeys(chatId, userId)}
      apiRequest={useInfiniteChatMessagesRequest(chatId, userId)}
      pageSize={CHAT_MESSAGES_PAGE_SIZE}
      Row={Row}
      enabled={chatId != null && userId != null}
      classes={{
        virtualRowBatch: classes.rowBatch,
      }}
      emptyListMessage={
        <>
          There are no messages here yet. <br />
          Be the first to write.
        </>
      }
      {...infiniteProps}
    />
  );
};

const component = forwardRef(ChatMessagesInfiniteList);
export { component as ChatMessagesInfiniteList };

const DEFAULT_ROW_HEIGHT = 48;
