import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { MeetingApi, MeetingDto } from '~/shared/api/index.ts';
import { useApiErrorNotification } from '~/shared/notification/index.ts';
import { time } from '../../../utils/index.ts';
import { useLoadProfileImageUrls } from '../../profile/index.ts';
import { Meeting } from '../model/Meeting.ts';

export const loadMeetingByIdQueryKey = (
  meetingId?: Meeting['meetingId'] | null
) =>
  ['load meeting', meetingId ? { meetingId } : undefined].filter(
    (key) => key != null
  );

export function useMeetingQuery(meetingId?: Meeting['meetingId'] | null) {
  const meetingApi = useDi(MeetingApi);
  const loadProfileImageUrls = useLoadProfileImageUrls();

  const query = useQuery({
    enabled: meetingId != null,
    queryKey: loadMeetingByIdQueryKey(meetingId),
    queryFn: async () => {
      if (meetingId == null) {
        return;
      }

      const response = await meetingApi.getMeetingById(meetingId);
      if (!response.meeting) {
        throw new Error(`there is no meeting with id ${meetingId}`);
      }

      const meeting = Mapper.map(MeetingDto, Meeting, response.meeting);

      await loadProfileImageUrls([
        meeting.meetingOwner,
        ...meeting.invitedMembers,
        ...meeting.acceptedMembers,
      ]);

      return meeting;
    },
    staleTime: time(5, 'minutes'),
  });

  useApiErrorNotification(query.error);

  return query;
}
