import clsx from 'clsx';
import { useKeyboardOpened } from '~/entities/device/index.ts';
import { ApiDevToolsButton } from '~/features/apiDevTools/index.ts';
import {
  LoginContextProvider,
  LoginSwitchButton,
} from '~/features/auth/index.ts';
import { StyledTitle } from '~/features/auth/ui/StyledTitle.tsx';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { LoginForm } from '~/widgets/auth/index.ts';
import commonClasses from './common.module.scss';

export const LoginDisplay = () => {
  const keyboardOpened = useKeyboardOpened();

  return (
    <ContentSlot
      className={clsx(commonClasses.authorization, {
        [commonClasses.authorizationFocused]: keyboardOpened,
      })}
    >
      <LoginContextProvider>
        <Icon className={commonClasses.logo} variant="logo_dayton" />

        <StyledTitle variant="h1" className={commonClasses.title}>
          Log In
        </StyledTitle>

        <LoginForm />

        <ApiDevToolsButton className={commonClasses.health} />

        <LoginSwitchButton className={commonClasses.appInfo} />
      </LoginContextProvider>
    </ContentSlot>
  );
};
