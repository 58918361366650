export function sortListByFilter<T extends { id: number }>(
  list: undefined | T[],
  filter: undefined | Set<number>
) {
  if (!filter || !list) {
    return list;
  }

  const filteredItems = [] as T[];
  const notFilteredItems = [] as T[];

  list.forEach((item) => {
    if (filter.has(item.id)) {
      filteredItems.push(item);
    } else {
      notFilteredItems.push(item);
    }
  });

  return filteredItems.concat(notFilteredItems);
}
