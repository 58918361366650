import { faker } from '@faker-js/faker';
import { type FakeApiDatabase } from '../../fakeApiDatabase/index.ts';
import { ApiError } from '../ApiError.ts';
import { ApiResponseStatus } from '../ApiResponseStatus.ts';
import type { TagDto } from '../index.ts';
import { SKILL_PAGE_SIZE } from './SKILL_PAGE_SIZE.ts';
import { type SkillRealApi } from './SkillRealApi.ts';

export class SkillFakeApi {
  constructor(readonly db: FakeApiDatabase) {}

  createSkill: SkillRealApi['createSkill'] = async (skillName) => {
    const skills = await this.db.skill.getAll();
    const existedSkill = skills.find(
      ({ name }) => name.toLocaleLowerCase() === skillName.toLocaleLowerCase()
    );

    if (existedSkill) {
      throw new ApiError({
        status: ApiResponseStatus.BadRequest,
        error: 'skill with such name already exist',
      });
    }

    const newSkill: TagDto = {
      id: faker.number.int(),
      name: skillName,
      userByCount: 0,
    };
    await this.db.skill.insert(newSkill.id, newSkill);
    return {
      createdTag: newSkill,
    };
  };

  searchSkills: SkillRealApi['searchSkills'] = async (search) => {
    const { skillName, page, pageSize } = search;
    const skills = await this.db.skill.getAll();

    const lowerSkillName = skillName?.toLocaleLowerCase();

    const normalizedSize = pageSize ?? SKILL_PAGE_SIZE;
    const startIndex = (page ?? 0) * normalizedSize;
    const endIndex = startIndex + normalizedSize;
    const filtered = skills.filter((skill) => {
      if (
        lowerSkillName &&
        !skill.name.toLocaleLowerCase().includes(lowerSkillName)
      ) {
        return false;
      }
      return true;
    });
    const batch = filtered.slice(startIndex, endIndex);

    return {
      totalCount: filtered.length,
      items: batch,
    };
  };

  searchSkillsById: SkillRealApi['searchSkillsById'] = async (skillIds) => {
    const skills = await this.db.skill.getAll();

    const set = new Set(skillIds);
    const filtered = skills.filter((skill) => set.has(skill.id));

    return {
      totalCount: filtered.length,
      items: filtered,
    };
  };
}
