type QuestsTag = {
  id: number;
  value: string; // name/description
};

export abstract class QuestsTaskDto {
  abstract id: number;
  abstract taskData: {
    id: number;
    content: string; // description - what to do
  };
  abstract isComplete: boolean;
  abstract priority: number;
  abstract quest: number;
  abstract task: number;
}

type QuestsUserProfileInterest = {
  id: number;
  confirmsCount: number;
  interest: number;
  interestName: string;
};

type QuestsUserProfileGallery = {
  uuid: string;
  url: string;
};

type QuestsUserProfile = {
  id: number;
  timezone: string; // "Europe/Moscow"
  user: number;
  userData: {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    middleName: string;
    email: string;
    dateJoined: string; // full date
    lastLogin: string; // full date
  };
  interests: QuestsUserProfileInterest[];
  skills: [];
  position: 2;
  positionName: 'PM';
  department: 1;
  departmentName: 'Icnetworking';
  gallery: QuestsUserProfileGallery[];
  bio: string;
  avatarLink: null;
  joinedCompanyDate: string; // date -> YYYY-MM-DD
  workExperienceSince: null;
  blacklist: {
    id: number;
    recordsData: [];
  };
};

export abstract class QuestDto {
  abstract id: number;
  abstract completeTask: number;
  abstract taskCount: number;
  abstract mainQuest: null;
  abstract tagsData: QuestsTag[];
  abstract tasksData: QuestsTaskDto[];
  // abstract userProfileData: QuestsUserProfile | null;
  abstract isActive: boolean;
  abstract isTemplate: boolean;
  abstract createdAt: string; // full date (with time)
  abstract startAt: string; // date -> YYYY-MM-DD
  abstract endAt: string; // date -> YYYY-MM-DD
  abstract title: string;
  // abstract userProfile: number | null;
  abstract tasks: number[];
  abstract tags: number[];
}

export abstract class QuestsDto {
  abstract count: number;
  abstract next: null;
  abstract previous: null;
  abstract results: QuestDto[];
}
