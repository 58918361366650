import { StatusMessagesEnum } from '~/shared/enums/StatusMessagesEnum.ts';
import { type MessageStatus } from './MessageStatus.ts';

export function mapMessageStatuses(
  messageStatusEnum: StatusMessagesEnum
): MessageStatus {
  switch (messageStatusEnum) {
    case StatusMessagesEnum.Sent:
      return 'sent';

    case StatusMessagesEnum.Received:
      return 'received';

    case StatusMessagesEnum.Viewed:
      return 'viewed';

    case StatusMessagesEnum.Unsent:
    default:
      throw new Error(`Unsupported message status (${messageStatusEnum})`);
  }
}
