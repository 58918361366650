import {
  type QueryClient,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { isAxiosError } from 'axios';
import { useDi } from 'cheap-di-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ApiError,
  ApiResponseStatus,
  MyUserDto,
  ProfileApi,
} from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { time } from '~/utils/index.ts';
import { MyProfile } from '../model/MyProfile.ts';
import { useLoadProfileImageUrls } from './useLoadProfileImageUrls.ts';

const loadCurrentUserQueryKeys = ['load current user'];

export const invalidateCurrentUserQuery = (queryClient: QueryClient) =>
  queryClient.invalidateQueries({
    queryKey: loadCurrentUserQueryKeys,
  });

export function useCurrentUser(options?: { enabled?: boolean }) {
  const { enabled = true } = options ?? {};

  // const navigate = useNavigate();
  const profileApi = useDi(ProfileApi);
  const loadProfileImageUrls = useLoadProfileImageUrls();

  const query = useQuery({
    enabled: enabled,
    queryKey: loadCurrentUserQueryKeys,
    queryFn: async () => {
      const { user } = await profileApi.getCurrentUser();

      const myProfile = Mapper.map(MyUserDto, MyProfile, user);
      await loadProfileImageUrls([myProfile]);

      return myProfile;
    },
    // staleTime: time(5, 'minutes'),
    retry: 0,
  });

  // useEffect(() => {
  //   const error = query.error;
  //
  //   if (error) {
  //     console.error(error.message);
  //
  //     let errorStatus: ApiResponseStatus | undefined;
  //     if (isAxiosError(error)) {
  //       errorStatus = error.response?.status;
  //     } else if (error instanceof ApiError) {
  //       errorStatus = error.status;
  //     }
  //
  //     // todo: this code should be somewhere at the application entry point and not here
  //     if (
  //       [ApiResponseStatus.NotAuthorized, ApiResponseStatus.Forbidden].includes(
  //         errorStatus!
  //       )
  //     ) {
  //       navigate(routes.auth.login.url());
  //     }
  //
  //     notify({
  //       message: error.message,
  //       variant: 'error',
  //     });
  //   }
  // }, [navigate, query.error]);

  return query;
}
