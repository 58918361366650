import { useMatch, useParams } from 'react-router-dom';
import { useParseInt } from '~/shared/numbers/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { useCurrentUser } from '~/entities/profile/crossExports.ts';
import { useUserById } from '../../colleague/index.ts';
import { useLoadInterestsById } from './useLoadInterestsById.ts';

export function useProfileInterests() {
  const currentProfileMatch = useMatch(routes.profile.interests.url());
  const colleagueProfileMatch = useMatch(
    routes.colleagues.userId().interests.url()
  );
  const { userId: stringUserId } = useParams<{ userId: string | undefined }>();
  const userId = useParseInt(stringUserId);

  const isCurrentProfile = currentProfileMatch != null;
  const isColleagueProfile = colleagueProfileMatch != null;

  const { data: currentProfile, isLoading: currentProfileIsLoading } =
    useCurrentUser({
      enabled: isCurrentProfile,
    });
  const {
    data: currentProfileInterests,
    isLoading: currentProfileInterestsAreLoading,
  } = useLoadInterestsById(currentProfile?.interestIds, {
    enabled: isCurrentProfile,
  });

  const { data: profile, isLoading: profileIsLoading } = useUserById(userId, {
    enabled: isColleagueProfile,
  });
  const { data: profileInterests, isLoading: profileInterestsAreLoading } =
    useLoadInterestsById(profile?.interestIds, { enabled: isColleagueProfile });

  return {
    interests: currentProfileInterests || profileInterests,
    interestsAreLoading:
      currentProfileIsLoading ||
      currentProfileInterestsAreLoading ||
      profileIsLoading ||
      profileInterestsAreLoading,
  };
}
