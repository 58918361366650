import {
  type TouchEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export const useAvatarTouch = (onEnd: (translateX: number) => void) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const startPositionRef = useRef({ x: 0 });
  const widthRef = useRef(0);
  const [translateX, setTranslateX] = useState(0);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    widthRef.current = containerRef.current.offsetWidth / 2;
  }, [containerRef.current]);

  const onTouchStart = useCallback((event: TouchEvent) => {
    const { clientX } = event.touches[0];
    startPositionRef.current.x = clientX;
  }, []);

  const onTouchMove = useCallback((event: TouchEvent) => {
    const { clientX } = event.touches[0];
    const diffX = clientX - startPositionRef.current.x;
    setTranslateX(translateX + diffX);
  }, []);

  const onTouchEnd = (event: TouchEvent) => {
    onEnd(translateX);
    startPositionRef.current.x = 0;
    setTranslateX(0);
  };

  return {
    containerRef,
    translateX,

    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};
