import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction as MuiBottomNavigationAction,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '../atoms/icons/index.ts';
import { SlotPortal } from '../organisms/slots/index.ts';
import { routes } from '../routing/routes.ts';

const tabUrls = [
  routes.colleagues.url(),
  routes.contacts.url(),
  routes.pets.url(),
  routes.quests.url(),
  routes.profile.url(),
];

function findTabUrlIndex(urlPathname: string) {
  return tabUrls.findIndex((url) => url.includes(urlPathname)) ?? 0;
}

export const FooterTabs = memo(Tabs);
function Tabs() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<number>(() =>
    findTabUrlIndex(pathname)
  );

  useEffect(() => {
    if (!pathname.includes(tabUrls[activeTab])) {
      setActiveTab(findTabUrlIndex(pathname));
    }
  }, [pathname, activeTab]);

  return (
    <SlotPortal slotName="footer">
      <BottomNavigation
        showLabels
        value={activeTab}
        onChange={(event, newValue) => {
          navigate(tabUrls[newValue]);
        }}
      >
        <BottomNavigationAction
          label="People"
          icon={<Icon variant="users" />}
        />
        <BottomNavigationAction
          label="Contacts"
          icon={<Icon variant="approve" />}
        />
        <BottomNavigationAction label="Pets" icon={<Icon variant="pets" />} />
        <BottomNavigationAction
          label="Quests"
          icon={<Icon variant="bookSource" />}
        />
        <BottomNavigationAction
          label="Profile"
          icon={<Icon variant="profileFilled" />}
        />
      </BottomNavigation>
    </SlotPortal>
  );
}

const BottomNavigation = styled(MuiBottomNavigation)(() => ({
  '&.MuiBottomNavigation-root': {
    height: 52,
  },
}));
const BottomNavigationAction = styled(MuiBottomNavigationAction)(
  ({ theme }) => ({
    '&.MuiButtonBase-root.MuiBottomNavigationAction-root': {
      color: '#C1C6CC',
      padding: 0,
      minWidth: 'unset',
    },
    '&.MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected': {
      color: theme.palette.primary.main,
    },
    '& .MuiBottomNavigationAction-label.Mui-selected': {
      fontSize: '0.75rem',
    },
  })
);
