import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { ProfileApi, UserForCarouselDto } from '~/shared/api/index.ts';
import { useApiErrorNotification } from '~/shared/notification/index.ts';
import { useLoadProfileImageUrls } from '../../profile/index.ts';
import { ContactProfile } from '../model/ContactProfile.ts';

export const loadContactsQueryKey = (
  contactIds: ContactProfile['userId'][]
) => ['load contacts', { contactIds }];

export function useContactsQuery(contactIds: ContactProfile['userId'][]) {
  const profileApi = useDi(ProfileApi);
  const loadProfileImageUrls = useLoadProfileImageUrls();

  const query = useQuery({
    queryKey: loadContactsQueryKey(contactIds),
    enabled: contactIds.length > 0,
    queryFn: async () => {
      // todo: discuss with backend getting contacts via mathApi ?
      const promises = contactIds.map((contactId) =>
        profileApi.getProfile(contactId)
      );
      const contactsResults = await Promise.allSettled(promises);
      const contactDtos = contactsResults
        .filter((promiseResult) => promiseResult.status === 'fulfilled')
        .map((promiseResult) => promiseResult.value.user);

      const contacts = contactDtos.map((dto) =>
        Mapper.map(UserForCarouselDto, ContactProfile, dto)
      );

      await loadProfileImageUrls(contacts);

      return contacts;
    },
  });

  useApiErrorNotification(query.error);

  return query;
}
