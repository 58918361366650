import { List } from '@mui/material';
import { useMemo } from 'react';
import { type Quest, type Task as TaskModel } from '~/entities/quest/index.ts';
import {
  useCompleteQuestTaskMutation,
  useDeCompleteQuestTaskMutation,
} from '~/features/quest/index.ts';
import { Task } from './Task';

type Props = {
  questId: Quest['id'];
  tasks: TaskModel[];
};

const Tasks = (props: Props) => {
  const { questId, tasks } = props;

  const { mutate: completeTask } = useCompleteQuestTaskMutation(questId);
  const { mutate: decompleteTask } = useDeCompleteQuestTaskMutation(questId);

  const makeOnTaskClickCallback = (task: TaskModel) => {
    return () => {
      if (task.isCompleted) {
        decompleteTask(task.id);
      } else {
        completeTask(task.id);
      }
    };
  };

  const sortedTasks = useMemo(
    () => tasks.sort((left, right) => left.order - right.order),
    [tasks]
  );

  return (
    <List sx={{ width: '100%' }} disablePadding>
      {sortedTasks.map((task) => (
        <Task
          key={task.id}
          task={task}
          onTaskClick={makeOnTaskClickCallback(task)}
        />
      ))}
    </List>
  );
};

export { Tasks };
export type { Props as TasksProps };
