import dayjs from 'dayjs';
import { useMemo } from 'react';
import { type CalendarEvent } from '~/shared/organisms/Calendar';
import { type Meeting } from '../model/Meeting.ts';

function useCalendarEvents(meetings: undefined | Meeting[]) {
  return useMemo<CalendarEvent[]>(() => {
    const events: CalendarEvent[] = [];

    const dateMeetingsMap = new Map<string, number>();

    meetings?.forEach((meeting) => {
      const date = meeting.when.startOf('day');
      let meetingsInTheDay = 0;

      const iso = date.toISOString();
      if (dateMeetingsMap.has(iso)) {
        meetingsInTheDay = dateMeetingsMap.get(iso)!;
      }

      dateMeetingsMap.set(iso, meetingsInTheDay + 1);
    });

    dateMeetingsMap.forEach((meetingsCount, isoDate) => {
      events.push({
        date: dayjs(isoDate),
        count: meetingsCount,
      });
    });

    return events;
  }, [meetings]);
}

export { useCalendarEvents };
