import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);

const genitiveMonthListRU = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

/**
 * Принимаю дату, возвращаю дату в локальном формате
 * @params date {Date?}
 * @params withYear {boolean}
 * @return DD month YYYY | DD month {string}
 */
export const convertDateToLocalString = (
  date?: Date,
  withYear?: boolean
): string => {
  if (!date) {
    return '';
  }
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const prepareMonth: string =
    genitiveMonthListRU.find((_, index) => index === month) ?? '';

  if (withYear) {
    return `${day} ${prepareMonth} ${year}`;
  }
  return `${day} ${prepareMonth}`;
};
