import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const WriteIcon = (props: Props) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12C20.7348 12 20.4804 12.1054 20.2929 12.2929C20.1053 12.4804 20 12.7348 20 13V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5195 19.8946 19.2652 20 19 20H4.99997C4.73475 20 4.4804 19.8946 4.29286 19.7071C4.10533 19.5196 3.99997 19.2652 3.99997 19V5C3.99997 4.73478 4.10533 4.48043 4.29286 4.29289C4.4804 4.10536 4.73475 4 4.99997 4H11C11.2652 4 11.5195 3.89464 11.7071 3.70711C11.8946 3.51957 12 3.26522 12 3C12 2.73478 11.8946 2.48043 11.7071 2.29289C11.5195 2.10536 11.2652 2 11 2H4.99997C4.20432 2 3.44126 2.31607 2.87865 2.87868C2.31604 3.44129 1.99997 4.20435 1.99997 5V19C1.99997 19.7956 2.31604 20.5587 2.87865 21.1213C3.44126 21.6839 4.20432 22 4.99997 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V13C22 12.7348 21.8946 12.4804 21.7071 12.2929C21.5195 12.1054 21.2652 12 21 12ZM5.99997 12.76V17C5.99997 17.2652 6.10533 17.5196 6.29286 17.7071C6.4804 17.8946 6.73475 18 6.99997 18H11.24C11.3716 18.0008 11.502 17.9755 11.6239 17.9258C11.7457 17.876 11.8565 17.8027 11.95 17.71L18.87 10.78L21.71 8C21.8037 7.90704 21.8781 7.79644 21.9289 7.67458C21.9796 7.55272 22.0058 7.42201 22.0058 7.29C22.0058 7.15799 21.9796 7.02728 21.9289 6.90542C21.8781 6.78356 21.8037 6.67296 21.71 6.58L17.47 2.29C17.377 2.19627 17.2664 2.12188 17.1445 2.07111C17.0227 2.02034 16.892 1.9942 16.76 1.9942C16.628 1.9942 16.4973 2.02034 16.3754 2.07111C16.2535 2.12188 16.1429 2.19627 16.05 2.29L13.23 5.12L6.28997 12.05C6.19729 12.1434 6.12396 12.2543 6.0742 12.3761C6.02443 12.4979 5.99921 12.6284 5.99997 12.76ZM16.76 4.41L19.59 7.24L18.17 8.66L15.34 5.83L16.76 4.41ZM7.99997 13.17L13.93 7.24L16.76 10.07L10.83 16H7.99997V13.17Z"
        fill={color}
      />
    </svg>
  );
};

export type { Props as WriteIconProps };
export { WriteIcon };
