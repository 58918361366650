import {
  type Action,
  controller,
  reducer,
  type WatchedController,
} from 'redux-controller-middleware';
import type { MessageStatusNotificationDto } from '~/shared/api/MessageStatusNotificationDto.ts';

@controller
class ChatMessagesController {
  @reducer
  onUpdateMessageStatus(
    action: Action<{ messageNotification: MessageStatusNotificationDto }>
  ) {
    // const {
    //   messageNotification: {
    //     messageId,
    //     dialogId: chatId,
    //     newStatus: newStatusEnum,
    //   },
    // } = action.payload;
    //
    // const { chatsMap, privateChatsMap, privateChatMessagesMap, messagesMap } =
    //   this.getState().chat;
    //
    // const message = messagesMap.get(messageId);
    // if (!message) {
    //   // nothing to update
    //   return;
    // }
    //
    // const newStatus = mapMessageStatuses(newStatusEnum);
    // const updatedMessage = new Message({
    //   ...message,
    //   status: newStatus,
    // });
    //
    // const newMessagesMap = new Map(messagesMap);
    // newMessagesMap.set(messageId, updatedMessage);
    //
    // const partialStore: Partial<ChatStore> = {
    //   messagesMap: newMessagesMap,
    // };
    //
    // const messagesWithUser = privateChatMessagesMap.get(message.interlocutorId);
    // if (messagesWithUser) {
    //   const index = messagesWithUser.findIndex(
    //     (message) =>
    //       message instanceof Message && message.messageId === messageId
    //   );
    //   if (index > -1) {
    //     const newMessages = [...messagesWithUser];
    //     newMessages.splice(index, 1, updatedMessage);
    //
    //     const newPrivateChatMessagesMap = new Map(privateChatMessagesMap);
    //     newPrivateChatMessagesMap.set(message.interlocutorId, newMessages);
    //
    //     partialStore.privateChatMessagesMap = newPrivateChatMessagesMap;
    //   }
    // }
    //
    // const privateChat = privateChatsMap.get(chatId);
    // if (
    //   privateChat?.messagePreview &&
    //   // replace sent message preview with message
    //   ((privateChat.messagePreview instanceof SentMessage &&
    //     privateChat.messagePreview.messageGuid ===
    //       updatedMessage.messageGuid) ||
    //     // update message preview
    //     (privateChat.messagePreview instanceof Message &&
    //       privateChat.messagePreview.messageId === messageId))
    // ) {
    //   const newPrivateChat = new PrivateChat({
    //     ...privateChat,
    //     messagePreview: updatedMessage,
    //   });
    //
    //   const newPrivateChatsMap = new Map(privateChatsMap);
    //   newPrivateChatsMap.set(newPrivateChat.interlocutorId, newPrivateChat);
    //
    //   partialStore.privateChatsMap = newPrivateChatsMap;
    //
    //   const newChatsMap = new Map(chatsMap);
    //   newChatsMap.set(chatId, newPrivateChat);
    //
    //   partialStore.chatsMap = newChatsMap;
    // }
    //
    // this.updateStoreSlice(partialStore);
  }
}

const typedController =
  ChatMessagesController as unknown as WatchedController<ChatMessagesController>;
export { typedController as ChatMessagesController };
