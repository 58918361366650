import { useTheme } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { type FC, useCallback, useMemo, useRef, useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { useUserById } from '~/entities/colleague/index.ts';
import { MyProfile, useCurrentUser } from '~/entities/profile/index.ts';
import { BackIconNavigationButton, useOnBack } from '~/features/navigation';
import {
  useDeletePhoto,
  useSetPhotoAsAvatar,
  useUploadPhoto,
} from '~/features/profile/index.ts';
import {
  TakePicture,
  type TakePictureRef,
} from '~/pages/profile/AvatarsDisplay/TakePicture';
import { Icon } from '~/shared/atoms/icons';
import { Photo } from '~/shared/atoms/Photo';
import { notify } from '~/shared/notification';
import { useParseInt } from '~/shared/numbers/index.ts';
import { ContentSlot } from '~/shared/organisms/slots';
import { routes } from '~/shared/routing/index.ts';
import { OptionsIconButton } from '~/shared/templates/IconButtons/OptionsIconButton';
import { Navbar } from '~/shared/ui/index.ts';
import { distinct, guid } from '~/utils/index.ts';
import { SwipeAblePhotos } from './SwipeAblePhotos';
import classes from './AvatarsDisplay.module.scss';

function useProfile() {
  const currentProfileMatch = useMatch(routes.profile.avatars.url());
  const colleagueProfileMatch = useMatch(
    routes.colleagues.userId().avatars.url()
  );
  const { userId: stringUserId } = useParams<{ userId: string | undefined }>();
  const userId = useParseInt(stringUserId);

  const isCurrentProfile = currentProfileMatch != null;
  const isColleagueProfile = colleagueProfileMatch != null;

  const { data: currentProfile, isLoading: currentProfileIsLoading } =
    useCurrentUser({ enabled: isCurrentProfile });
  const { data: profile, isLoading: profileIsLoading } = useUserById(userId, {
    enabled: isColleagueProfile,
  });

  return {
    profile: currentProfile ?? profile,
    profileIsLoading: currentProfileIsLoading || profileIsLoading,
  };
}

export const AvatarsDisplay: FC = () => {
  const onBack = useOnBack({});
  const theme = useTheme();
  const takePictureRef = useRef<TakePictureRef>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [photoIndex, setPhotoIndex] = useState(0);

  const { profile, profileIsLoading } = useProfile();

  const { mutate: uploadPhoto, isPending: photoIsUploading } = useUploadPhoto();
  const { mutate: setPhotoAsAvatar, isPending: avatarIsPending } =
    useSetPhotoAsAvatar();
  const { mutate: deletePhoto, isPending: photoIsDeleting } = useDeletePhoto();

  const photos = useMemo(() => {
    return distinct(
      [profile?.avatar].concat(profile?.photos).filter((image) => image != null)
    );
  }, [profile?.avatar, profile?.photos]);

  const onChooseNewPhoto = useCallback(async () => {
    if (!takePictureRef.current) {
      return;
    }

    try {
      const userPhoto = await takePictureRef.current.takePicture();

      uploadPhoto({
        fileName: guid(),
        photoBase64: userPhoto,
      });
    } catch (error) {
      notify({
        variant: 'error',
        message:
          'Photo could not be taken. Please allow access to your camera.',
      });
    }
  }, [uploadPhoto]);

  const onSetPhotoAsAvatar = useCallback(() => {
    const photo = photos[photoIndex];
    if (!photo.fileName) {
      return;
    }

    setPhotoAsAvatar(photo.fileName);
    setAnchorEl(null);
    onBack();
  }, [onBack, photoIndex, photos, setPhotoAsAvatar]);

  const onDeletePhoto = useCallback(() => {
    const photo = photos[photoIndex];
    if (!photo.fileName) {
      return;
    }

    deletePhoto(photo.fileName);
    setPhotoIndex((photoIndex) => {
      let newIndex = photoIndex + 1;
      if (newIndex > photos.length - 2) {
        newIndex = photos.length - 2;
      }
      if (newIndex < 0) {
        return 0;
      }
      return newIndex;
    });

    if (photos.length === 1) {
      setAnchorEl(null);
    }
  }, [deletePhoto, photoIndex, photos]);

  const onChangePhotoIndex = useCallback(
    (callback: (photoIndex: number) => number) => {
      setPhotoIndex((photoIndex) => {
        const newIndex = callback(photoIndex);
        if (newIndex < 0) {
          return 0;
        }
        if (newIndex > photos.length - 1) {
          return photos.length - 1;
        }
        return newIndex;
      });
    },
    [setPhotoIndex, photos]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!profile) {
    return null;
  }

  return (
    <>
      <Navbar
        title={
          photos.length ? `${photoIndex + 1} из ${photos.length}` : undefined
        }
        left={<BackIconNavigationButton color="inherit" />}
        right={
          profile instanceof MyProfile ? (
            <OptionsIconButton
              color="inherit"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            />
          ) : undefined
        }
        partialLoading={
          profileIsLoading ||
          avatarIsPending ||
          photoIsUploading ||
          photoIsDeleting
        }
        className={classes.navbar}
        classes={{ title: classes.title }}
        style={{ color: theme.palette.common.white }}
        hideHealthButton
      />

      <ContentSlot className={classes.root}>
        {profile instanceof MyProfile && (
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuList>
              <MenuItem onClick={onChooseNewPhoto}>
                <ListItemIcon>
                  <Icon variant="addPhoto" />
                </ListItemIcon>

                <ListItemText>Upload New Avatar</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={onSetPhotoAsAvatar}
                disabled={photos[photoIndex] === profile.avatar.url}
              >
                <ListItemIcon>
                  <Icon variant="profileOutlined" />
                </ListItemIcon>

                <ListItemText>Set as Profile Picture</ListItemText>
              </MenuItem>

              <MenuItem onClick={onDeletePhoto} disabled={!photos[photoIndex]}>
                <ListItemIcon>
                  <Icon variant="deleteOutlined" />
                </ListItemIcon>

                <ListItemText>Remove Photo</ListItemText>
              </MenuItem>
            </MenuList>
          </Menu>
        )}

        {photos.length <= 1 ? (
          <Photo avatarUrl={photos[0]?.url} scale="contain" />
        ) : (
          <SwipeAblePhotos
            activePhotoUrl={photos[photoIndex]?.url}
            setPhotoIndex={onChangePhotoIndex}
          />
        )}

        <TakePicture ref={takePictureRef} />
      </ContentSlot>
    </>
  );
};
