import { Typography } from '@mui/material';
import type { FC } from 'react';
import { useRouteError } from 'react-router-dom';
import { Icon } from '~/shared/atoms/icons';
import { CollapsedItem } from '~/shared/organisms/ColappsedItem';
import classes from './ErrorPage.module.scss';

export const ErrorPage: FC = () => {
  const error = useRouteError() as Error;

  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <Icon variant="logo_dayton" />
      </div>

      <div className={classes.container}>
        <Typography>Ой, что-то пошло не так</Typography>
        <Typography>
          Нам очень жаль, что вы столкнулись с проблемой. Мы постараемся решить
          её в самые короткие сроки. Вы можете сообщить нам о проблеме, приложив
          детали ниже к вашему обращению
        </Typography>

        <CollapsedItem
          className={classes.collapsed}
          shortInfo={<Typography>Детали ошибки</Typography>}
        >
          <div className={classes.errorContent}>
            <div className={classes.messageContainer}>
              <Typography>Ошибка:</Typography>

              <pre>
                {JSON.stringify(
                  error instanceof Error ? error.message : error,
                  null,
                  2
                )}
              </pre>
            </div>
          </div>

          <div className={classes.stackContainer}>
            <Typography>Цепочка вызовов:</Typography>

            {error.stack
              ?.split('\n')
              .map((row, index) => <Typography key={index}>{row}</Typography>)}
          </div>
        </CollapsedItem>
      </div>
    </div>
  );
};
