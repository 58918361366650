import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const LogoIcon_Dayton = (props: Props) => {
  const { height = 88, color = '#5295F0', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="88" height="88" rx="24" fill={color} />
      <path
        d="M44.013 73.6322C32.212 73.6322 25 66.8092 25 54.5722C25 42.6642 32.409 35.5782 44.013 35.5782C55.682 35.5782 63.058 42.5982 63.058 54.5722C63.058 66.8422 55.846 73.6322 44.013 73.6322ZM44.013 64.0202C47.356 64.0202 49.421 60.6742 49.421 54.6052C49.421 48.7002 47.323 45.1902 44.013 45.1902C40.735 45.1902 38.637 48.7002 38.637 54.6052C38.637 60.6742 40.636 64.0202 44.013 64.0202Z"
        fill="white"
      />
      <path
        d="M53.191 24.7182C52.212 30.2852 49.166 38.3992 47.228 38.0582C45.289 37.7162 45.192 29.0492 46.171 23.4822C47.149 17.9152 49.514 13.6782 51.452 14.0192C53.391 14.3602 54.169 19.1502 53.191 24.7182Z"
        fill="white"
      />
      <path
        d="M35.524 24.7182C36.503 30.2852 39.549 38.3992 41.487 38.0582C43.426 37.7162 43.523 29.0492 42.544 23.4822C41.566 17.9152 39.201 13.6782 37.262 14.0192C35.324 14.3602 34.546 19.1502 35.524 24.7182Z"
        fill="white"
      />
      <path
        d="M45.816 50.1442C45.816 51.0132 45.111 51.7182 44.242 51.7182C43.373 51.7182 42.669 51.0132 42.669 50.1442C42.669 49.2742 43.373 48.5692 44.242 48.5692C45.111 48.5692 45.816 49.2742 45.816 50.1442Z"
        fill="white"
      />
    </svg>
  );
};

export type { Props as LogoIcon_DaytonProps };
export { LogoIcon_Dayton };
