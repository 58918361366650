import { TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(MuiTextField)({
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    color: '#495057',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: 10,
    ' > input': {
      padding: 12,
    },
  },
  '& .MuiFormControl-root': {
    margin: 0,
  },
});
