import { useCallback, useEffect, useRef, useState } from 'react';

function useAnimationReset<Dependency>(
  dependency: Dependency,
  resetTouch: () => void
) {
  const timersRef = useRef<any[]>([]);
  const [animateHiding, setAnimateHiding] = useState(false);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      // if to reset right after card swiped, user can see,
      // how it rotates to back (initial position)
      resetTouch();

      const timer2 = setTimeout(() => {
        setAnimateHiding(false);
        timersRef.current.pop();
      }, 200);

      timersRef.current.pop();
      timersRef.current.push(timer2);
    }, 200);

    timersRef.current.push(timer1);

    return () => {
      for (let index = 0; index < timersRef.current.length; index++) {
        clearTimeout(timersRef.current.pop());
      }
    };
  }, [dependency]);

  const runHideAnimation = useCallback(() => {
    setAnimateHiding(true);
  }, []);

  return { animateHiding, runHideAnimation };
}

export { useAnimationReset };
