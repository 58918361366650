import { type ComponentProps, type FC } from 'react';
import { type CommonIconProps } from '~/shared/atoms/icons/CommonProps';
import { ChevronLeftIcon } from './ChevronLefttIcon';
import { ChevronRightIcon } from './ChevronRightIcon';
import { ChevronUpIcon } from './ChevronUpIcon';

const variants = {
  left: ChevronLeftIcon,
  right: ChevronRightIcon,
  up: ChevronUpIcon,
};

type Variants = typeof variants;
type IconVariant = keyof Variants;

type VariantProps<Variant extends IconVariant> = ComponentProps<
  Variants[Variant]
>;

type Props<Variant extends IconVariant> = CommonIconProps & {
  direction: Variant;
} & VariantProps<Variant>;

function ChevronIcon<Variant extends IconVariant>(props: Props<Variant>) {
  const { direction, ...componentProps } = props;

  if (!direction) {
    return null;
  }

  const Component = variants[direction] as
    | FC<typeof componentProps>
    | undefined;

  if (!Component) {
    throw new Error(`direction (${direction}) is not supported`);
  }

  return <Component {...componentProps} />;
}

export { ChevronIcon };
export type { Props as ChevronIconProps };
