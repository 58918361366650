import { guid } from '~/utils/index.ts';
import { useNotificationStore } from '../config/useNotificationStore.ts';
import { type Notification } from '../model/Notification.ts';

export function notify(notification: Omit<Notification, 'key'>) {
  const key = guid();

  useNotificationStore.setState((prev) => ({
    notifications: prev.notifications.concat({
      ...notification,
      key,
    }),
  }));

  return key;
}
