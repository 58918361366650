import { Switch } from '@mui/material';
import { type FC } from 'react';
import { toggleUseMirageApi, useFakeApiConfig } from '../config/useFakeApi.ts';

export const FakeApiSwitcher: FC = () => {
  const useMirageApi = useFakeApiConfig((config) => config.useFakeApi);

  return (
    <label>
      Use fake API
      <Switch checked={useMirageApi} onChange={toggleUseMirageApi} />
    </label>
  );
};
