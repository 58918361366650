import clsx from 'clsx';
import { type HTMLAttributes, memo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classes from './ModalPortal.module.scss';

type Props = HTMLAttributes<HTMLDivElement>;

const ModalPortal = (props: Props) => {
  useEffect(() => {
    document.body.setAttribute('style', 'overflow: hidden');
  }, []);

  useEffect(() => {
    document.querySelector('body')?.removeAttribute('style');
  });

  return createPortal(
    <div {...props} className={clsx(classes.root, props.className)} />,
    document.body
  );
};

const memoizedComponent = memo(ModalPortal);
export { memoizedComponent as ModalPortal };
