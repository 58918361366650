import { useDi } from 'cheap-di-react';
import { useCallback } from 'react';
import { ProfileApi } from '~/shared/api/index.ts';
import type { Chat } from '../model/Chat.ts';

type ChatWithImages = Pick<Chat, 'chatId' | 'avatar'>;

export function useLoadChatImageUrls() {
  const profileApi = useDi(ProfileApi);

  return useCallback(
    async (profiles: ChatWithImages[]) => {
      const promises: Promise<unknown>[] = [];

      for (const profile of profiles) {
        promises.push(
          (async () => {
            if (profile.avatar.fileName) {
              profile.avatar.url = await profileApi.getAvatarUrl({
                userId: profile.chatId,
                fileName: profile.avatar.fileName,
              });
            }
          })()
        );
      }

      await Promise.allSettled(promises);
    },
    [profileApi]
  );
}
