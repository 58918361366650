import { useMutation } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { DeveloperApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification';

export function useMessagesDeleteBetweenMeAndUser() {
  const developerApi = useDi(DeveloperApi);

  return useMutation({
    mutationKey: ['delete messages between me and user'],
    mutationFn: (userIdString: string) => {
      if (userIdString.length) {
        const userId = parseInt(userIdString, 10);
        return developerApi.deleteMessagesBetweenMeAndUser(userId);
      }
      throw new Error('User id is not entered');
    },
    onError: (error) => {
      notify({
        message: `Cant remove messages with user: ${error.message}`,
        variant: 'error',
      });
    },
    onSuccess: () => {
      notify({
        message: 'Removed',
        variant: 'success',
      });

      // todo: revalidate chat queries
      // const { chatsMap, privateChatsMap, privateChatMessagesMap } =
      //   this.getState().chat;
      //
      // const privateChat = privateChatsMap.get(userId);
      // if (privateChat) {
      //   const newMap = new Map(chatsMap);
      //   newMap.delete(privateChat.chatId);
      //
      //   const newPrivateChatsMap = new Map(privateChatsMap);
      //   newPrivateChatsMap.delete(privateChat.interlocutorId);
      //
      //   const newPrivateChatMessagesMap = new Map(privateChatMessagesMap);
      //   newPrivateChatMessagesMap.delete(privateChat.interlocutorId);
      //
      //   this.updateStoreSlice({
      //     chatsMap: newMap,
      //     privateChatsMap: newPrivateChatsMap,
      //     privateChatMessagesMap: newPrivateChatMessagesMap,
      //   });
      // }
    },
  });
}
