import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { RegistrationContextProvider } from '~/features/auth/index.ts';
import { StyledTitle } from '~/features/auth/ui/StyledTitle.tsx';
import { useSavePersonalData } from '~/features/profile/api/useSavePersonalData.ts';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { Button } from '~/shared/molecules/Button/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { useDisplayLoader } from '~/shared/ui/index.ts';
import { InformationStep } from '~/widgets/auth/ui/InformationStep.tsx';
import classes from './FillInformationFirstTimeDisplay.module.scss';

export const FillInformationFirstTimeDisplay: FC = () => {
  const navigate = useNavigate();
  const { data: myProfile, isLoading } = useCurrentUser();

  const { mutate: save, isPending: isSaving } = useSavePersonalData({
    onSuccessCallback: () => {
      navigate(routes.profile.url(), { replace: true });
    },
  });

  useDisplayLoader(isLoading);

  // if (isLoading) {
  //   return null;
  // }

  return (
    <ContentSlot className={classes.root}>
      <RegistrationContextProvider
        firstName={myProfile?.firstName}
        lastName={myProfile?.lastName}
      >
        <Icon variant="logo_dayton" className={classes.logo} />

        <StyledTitle variant="h1" className={classes.title}>
          Fill up your name
        </StyledTitle>

        <InformationStep
          hideBackButton
          onSubmit={(data) => {
            if (
              myProfile != null &&
              data.firstName != null &&
              data.lastName != null
            ) {
              save({
                firstName: data.firstName,
                lastName: data.lastName,
                bio: myProfile.bio,
                timezone: myProfile.timezone,
              });
            }
          }}
          SubmitButton={
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSaving}
            >
              Save
            </Button>
          }
        />
      </RegistrationContextProvider>
    </ContentSlot>
  );
};
