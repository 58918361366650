import clsx from 'clsx';
import { type CSSProperties, type FC, type ReactNode } from 'react';
import { ModalPortal } from '../ModalPortal';
import classes from './ModalView.module.scss';

type Props = {
  onClose: () => void;
  // todo: fix casing
  classname?: string;
  style?: CSSProperties;
  children: ReactNode;
};

const ModalView: FC<Props> = (props) => {
  const { children, onClose, classname, style } = props;

  return (
    <ModalPortal
      onClick={onClose}
      className={clsx(classes.root, classname)}
      style={style}
    >
      {children}
    </ModalPortal>
  );
};

export { ModalView };
