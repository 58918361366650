import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { queryKeys, QuestApi, QuestDto } from '~/shared/api/index.ts';
import { time } from '~/utils/index.ts';
import { Quest } from '../model/Quest.ts';

export const loadQuestQueryKeys = (questId: Quest['id'] | undefined) =>
  queryKeys('load quest', { questId });

export function useLoadQuestById(questId: Quest['id'] | undefined) {
  const questApi = useDi(QuestApi);

  return useQuery({
    queryKey: loadQuestQueryKeys(questId),
    queryFn: async () => {
      if (questId == null) {
        throw new Error('quest id is not provided');
      }
      return await questApi.getQuestByIdAsync(questId);
    },
    enabled: questId != null,
    staleTime: time(5, 'minutes'),
    select: mapResponse,
  });
}

const mapResponse = (dto: Awaited<ReturnType<QuestApi['getQuestByIdAsync']>>) =>
  Mapper.map(QuestDto, Quest, dto);
