import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DIProviderMemo } from 'cheap-di-react';
import { type FC, type PropsWithChildren, useMemo } from 'react';
import { ApiDevTools } from '~/features/apiDevTools/index.ts';
import { ChatReactionsProvider } from '~/features/chat/index.ts';
import { NotificationStack } from '~/shared/notification/index.ts';
import { theme } from '../config/theme.ts';
import { useAppConfiguration } from '../config/useAppConfiguration.ts';
import classes from './App.module.scss';

export const App: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const queryClient = useMemo(() => new QueryClient(), []);

  const configuredContainer = useAppConfiguration();

  // useEffect(() => {
  //   Telegram.WebApp.BackButton.onClick(() => {
  //     navigate(-1);
  //   });
  // }, []);

  if (!configuredContainer) {
    return null;
  }

  return (
    <DIProviderMemo rootContainer={configuredContainer}>
      <QueryClientProvider client={queryClient}>
        <ChatReactionsProvider>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CssBaseline />

              {children}
              {/* <TestError />*/}
            </LocalizationProvider>
          </ThemeProvider>

          <ApiDevTools />
          <NotificationStack className={classes.notifications} />
        </ChatReactionsProvider>
      </QueryClientProvider>
    </DIProviderMemo>
  );
};
