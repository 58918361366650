import { ApiBase } from '../ApiBase.ts';
import { type TelegramFakeApi } from './TelegramFakeApi.ts';
import { type TelegramRealApi } from './TelegramRealApi.ts';

export class TelegramApi extends ApiBase {
  constructor(
    private realApi: TelegramRealApi,
    private fakeApi: TelegramFakeApi
  ) {
    super();
  }

  login: TelegramRealApi['login'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.login(...args);
    }
    return this.realApi.login(...args);
  };

  connectTonWallet: TelegramRealApi['connectTonWallet'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.connectTonWallet(...args);
    }
    return this.realApi.connectTonWallet(...args);
  };

  isTonWalletConnected: TelegramRealApi['isTonWalletConnected'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.isTonWalletConnected(...args);
    }
    return this.realApi.isTonWalletConnected(...args);
  };
}
