import { type FC, type ReactNode, useMemo } from 'react';
import {
  type InfiniteScrollRenderProps,
  type RowProps,
} from './InfiniteScrollRender.tsx';

export function useVirtualRow<RowEntity extends {}>(
  Row: (props: RowProps<RowEntity>) => ReactNode,
  ...dependencies: unknown[]
): InfiniteScrollRenderProps<RowEntity>['Row'] {
  return useMemo(() => {
    (Row as FC).displayName = 'VirtualRow';
    return Row;
  }, dependencies);
}
