import { type SyntheticEvent, useCallback, useState } from 'react';
import { DepartmentInfiniteList } from '~/entities/department/index.ts';
import { useIsPublicVersion } from '~/entities/device/index.ts';
import { InterestsInfiniteList } from '~/entities/interest/index.ts';
import { JobPositionInfiniteList } from '~/entities/jobPosition/index.ts';
import { SkillsInfiniteList } from '~/entities/skill/index.ts';
import { useColleaguesFilterContext } from '~/features/colleague/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { NavbarInput } from '~/shared/molecules/NavbarInput/index.ts';
import { Tabs } from '~/shared/molecules/Tabs/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { FilterOffIconButton } from '~/shared/templates/IconButtons/FilterOffIconButton.tsx';
import { Navbar } from '~/shared/ui/index.ts';
import { useFilterTabs } from './useFilterTabs.ts';
import classes from './ColleaguesFiltersDisplay.module.scss';

export const ColleaguesFiltersDisplay = () => {
  const isPublicVersion = useIsPublicVersion();

  const {
    selectedDepartmentIds,
    addDepartment,
    deleteDepartment,
    clearSelectedDepartmentIds,

    selectedJobPositionIds,
    addJobPosition,
    deleteJobPosition,
    clearSelectedJobPositionIds,

    selectedInterestIds,
    addInterest,
    deleteInterest,
    clearSelectedInterestIds,

    selectedSkillIds,
    addSkill,
    deleteSkill,
    clearSelectedSkillIds,
  } = useColleaguesFilterContext() ?? {};

  const { tabs, tabIndexes } = useFilterTabs(isPublicVersion);
  const [activeTabIndex, setActiveTabIndex] = useState(tabIndexes.interest);
  const [searchName, setSearchName] = useState('');

  const clearFilters = useCallback(() => {
    clearSelectedDepartmentIds?.();
    clearSelectedJobPositionIds?.();
    clearSelectedInterestIds?.();
    clearSelectedSkillIds?.();
  }, [
    clearSelectedDepartmentIds,
    clearSelectedInterestIds,
    clearSelectedJobPositionIds,
    clearSelectedSkillIds,
  ]);

  const onChangeActiveTab = useCallback(
    (_: SyntheticEvent, newValue: number) => {
      setActiveTabIndex(newValue);
    },
    []
  );

  const noFilters =
    !selectedDepartmentIds?.size &&
    !selectedJobPositionIds?.size &&
    !selectedInterestIds?.size &&
    !selectedSkillIds?.size;

  return (
    <>
      <Navbar
        title="Filters"
        left={
          <div>
            <BackIconNavigationButton />
            <FilterOffIconButton onClick={clearFilters} disabled={noFilters} />
          </div>
        }
        classes={{
          children: classes.navbarChildren,
        }}
        withBorderBottom
      >
        <NavbarInput
          placeholder="Search"
          icon={<Icon variant="search" />}
          value={searchName}
          onChange={setSearchName}
        />

        <Tabs
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          onChangeActiveTab={onChangeActiveTab}
        />
      </Navbar>

      <ContentSlot>
        {activeTabIndex === tabIndexes.interest && (
          <InterestsInfiniteList
            searchedInterestName={searchName}
            selectedInterestIds={selectedInterestIds}
            onRowClick={(interest) => {
              if (selectedInterestIds?.has(interest.id)) {
                deleteInterest?.(interest.id);
              } else {
                addInterest?.(interest.id);
              }
            }}
          />
        )}

        {activeTabIndex === tabIndexes.skill && (
          <SkillsInfiniteList
            searchedSkillName={searchName}
            selectedSkillIds={selectedSkillIds}
            onRowClick={(skill) => {
              if (selectedSkillIds?.has(skill.id)) {
                deleteSkill?.(skill.id);
              } else {
                addSkill?.(skill.id);
              }
            }}
          />
        )}

        {activeTabIndex === tabIndexes.department && (
          <DepartmentInfiniteList
            selectedIcon="cross"
            searchedDepartmentName={searchName}
            onRowClick={(department) => {
              if (selectedDepartmentIds?.has(department.id)) {
                deleteDepartment?.(department.id);
              } else {
                addDepartment?.(department.id);
              }
            }}
            selectedDepartmentIds={selectedDepartmentIds}
          />
        )}

        {activeTabIndex === tabIndexes.jobPosition && (
          <JobPositionInfiniteList
            selectedIcon="cross"
            searchedJobPositionName={searchName}
            onRowClick={(jobPosition) => {
              if (selectedJobPositionIds?.has(jobPosition.id)) {
                deleteJobPosition?.(jobPosition.id);
              } else {
                addJobPosition?.(jobPosition.id);
              }
            }}
            selectedJobPositionIds={selectedJobPositionIds}
          />
        )}
      </ContentSlot>
    </>
  );
};
