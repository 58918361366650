import { useMemo } from 'react';
import { useLoadInterestsById } from '~/entities/interest/index.ts';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { useProfileInterestsEditContext } from '~/features/profile/index.ts';
import { useNavbarLoader } from '~/shared/ui/index.ts';

export function useEditedProfileInterests() {
  const { selectedInterestIds } = useProfileInterestsEditContext() ?? {};

  const { data: profile, isLoading: profileIsLoading } = useCurrentUser();
  const { data: profileInterests, isLoading: profileInterestsAreLoading } =
    useLoadInterestsById(profile?.interestIds);

  const selected = useMemo(
    () => Array.from(selectedInterestIds ?? []),
    [selectedInterestIds]
  );
  const { data: selectedInterests, isLoading: selectedInterestsAreLoading } =
    useLoadInterestsById(selected);

  useNavbarLoader(
    profileIsLoading ||
      profileInterestsAreLoading ||
      selectedInterestsAreLoading
  );

  return useMemo(() => {
    const map = new Map(
      profileInterests?.map((interest) => [interest.id, interest])
    );

    const added = new Set(selectedInterestIds).difference(
      new Set(profile?.interestIds)
    );
    const removed = new Set(profile?.interestIds).difference(
      new Set(selectedInterestIds)
    );

    selectedInterests?.forEach((interest) => {
      if (!map.has(interest.id)) {
        map.set(interest.id, interest);
      }
    });

    return {
      interests: Array.from(map.values()),
      added,
      removed,
    };
  }, [
    profileInterests,
    selectedInterests,
    selectedInterestIds,
    profile?.interestIds,
  ]);
}
