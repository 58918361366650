import { Button, Divider, Typography } from '@mui/material';
import { useMemo } from 'react';
import { type Interest } from '~/entities/interest/model/Interest.ts';
import { Section } from '~/pages/profile/Section';
import { useNavbarLoader } from '~/shared/ui/index.ts';
import { useLoadInterestsById } from '../api/useLoadInterestsById.ts';
import { InterestCard } from './InterestCard.tsx';
import classes from './ShortInterestList.module.scss';

const shortListAmount = 6;

type Props = {
  onShowAll: VoidFunction;
  interestIds: Interest['id'][] | undefined;
};

export const ShortInterestList = (props: Props) => {
  const { onShowAll, interestIds } = props;

  const { data: interests, isLoading } = useLoadInterestsById(interestIds);
  useNavbarLoader(isLoading);

  const interestSlice = useMemo(
    () => interests?.slice(0, shortListAmount) ?? [],
    [interests]
  );

  if (interestSlice.length === 0) {
    return null;
  }

  return (
    <>
      <Divider />

      <Section>
        <Typography variant="h3" className={classes.title}>
          Interests
        </Typography>

        {interests != null && interestSlice.length < interests.length && (
          <Button
            onClick={onShowAll}
            variant="text"
            color="primary"
            className={classes.link}
          >
            Show all
          </Button>
        )}

        <div className={classes.cards}>
          {interestSlice.map((interest) => (
            <InterestCard key={interest.id} interest={interest} />
          ))}
        </div>
      </Section>
    </>
  );
};
