import { createStore } from '~/shared/store/index.ts';
import type { DeviceInfo } from './DeviceInfo.ts';

type Store = {
  deviceInfo: DeviceInfo;
  keyboardOpened: boolean;
  isPublicVersion: boolean;
};

export const useDeviceStore = createStore<Store>({
  deviceInfo: {
    platform: 'web',
  },
  keyboardOpened: false,
  isPublicVersion: true,
});

export const useDeviceInfo = () => useDeviceStore((store) => store.deviceInfo);
export const useKeyboardOpened = () =>
  useDeviceStore((store) => store.keyboardOpened);
export const useIsPublicVersion = () =>
  useDeviceStore((store) => store.isPublicVersion);
