import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { Profile } from '~/entities/profile/crossExports.ts';
import { ProfileApi, UserForCarouselDto } from '~/shared/api/index.ts';
import { useApiErrorNotification } from '~/shared/notification/index.ts';
import { time } from '~/utils/index.ts';
import { useLoadProfileImageUrls } from '../../profile/index.ts';

export const loadUserByIdQueryKeys = (
  userId: Profile['userId'] | undefined
) => ['load user by id', { userId }];

export function useUserById(
  userId: Profile['userId'] | undefined,
  options?: { enabled?: boolean }
) {
  const { enabled = true } = options ?? {};

  const profileApi = useDi(ProfileApi);
  const loadProfileImageUrls = useLoadProfileImageUrls();

  const query = useQuery({
    enabled: userId != null && enabled,
    queryKey: loadUserByIdQueryKeys(userId),
    queryFn: async () => {
      if (userId == null) {
        throw new Error('there is no user id');
      }

      const { user } = await profileApi.getProfile(userId);

      const profile = Mapper.map(UserForCarouselDto, Profile, user);
      await loadProfileImageUrls([profile]);

      return profile;
    },
    staleTime: time(5, 'minutes'),
    retry: 2,
  });

  useApiErrorNotification(query.error);

  return query;
}
