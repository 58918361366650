import dayjs from 'dayjs';
import React, { memo, useMemo } from 'react';
import { useCurrentUser } from '~/entities/profile';
import { ModalView, ModalViewButton } from '~/shared/components/ModalView';
import { useTimezoneUpdate } from '../../api/useTimezoneUpdate.ts';
import { useTimezoneModalConfig } from '../../config/useTimezoneModalConfig.ts';
import { useShouldProposeTimezoneChange } from './useShouldProposeTimezoneChange.ts';

const ChooseTimezoneModal = () => {
  const { data: profile } = useCurrentUser();

  const currentDeviceTimezone = useMemo(() => {
    return dayjs.tz.guess();
  }, []);

  const [shouldProposeTimezoneChange, stopProposeTimezoneChange] =
    useShouldProposeTimezoneChange(currentDeviceTimezone);
  const { mutate: updateTimezone } = useTimezoneUpdate();

  if (!shouldProposeTimezoneChange || !profile) {
    return null;
  }

  let message: string;
  if (!profile.timezone) {
    message = `We detected your time zone as "${currentDeviceTimezone}". Remember it?`;
  } else {
    message = `You changed the time zone from "${
      profile.timezone ? profile.timezone : 'not selected'
    }" to "${currentDeviceTimezone}". Update the app's time settings?`;
  }

  return (
    <ModalView onClose={stopProposeTimezoneChange}>
      <ModalViewButton
        onClick={() => {
          updateTimezone(currentDeviceTimezone);
        }}
        message={message}
      >
        Yes
      </ModalViewButton>

      <ModalViewButton
        onClick={() =>
          useTimezoneModalConfig.setState(() => ({
            hasUserDisabledTimezoneProposalDialog: true,
          }))
        }
      >
        No, don't ask again
      </ModalViewButton>
    </ModalView>
  );
};

const memoizedComponent = memo(ChooseTimezoneModal);
export { memoizedComponent as ChooseTimezoneModal };
