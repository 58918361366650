import { type FakeApiDatabase } from '../../fakeApiDatabase/index.ts';
import { ApiError } from '../ApiError.ts';
import { ApiResponseStatus } from '../ApiResponseStatus.ts';
import { type BlockListRealApi } from './BlockListRealApi.ts';
import type { BlockUserDto } from './BlockUserDto.ts';

export class BlockListFakeApi {
  constructor(private readonly db: FakeApiDatabase) {}

  getBlockUsers: BlockListRealApi['getBlockUsers'] = async () => {
    const blockedUsers = await this.db.blockedUser.getAll();

    return {
      users: blockedUsers,
    };
  };

  blockUser: BlockListRealApi['blockUser'] = async (userId) => {
    const alreadyBlocked = await this.db.blockedUser.get(userId);
    if (alreadyBlocked) {
      return {};
    }

    const targetUser = await this.db.user.get(userId);
    if (!targetUser) {
      throw new ApiError({
        error: 'user not found',
        status: ApiResponseStatus.NotFound,
      });
    }

    const blockUserDto: BlockUserDto = {
      userId,
      firstName: targetUser.firstName,
      lastName: targetUser.lastName,
      userAvatarPhotoFileName: targetUser.userAvatarPhotoFileName,
      jobPositionId: targetUser.jobPosition,
      departmentId: targetUser.department,
    };

    await this.db.blockedUser.insert(userId, blockUserDto);
    return {};
  };

  unblockUser: BlockListRealApi['unblockUser'] = async (userId) => {
    await this.db.blockedUser.delete(userId);
    return {};
  };
}
