import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

type LoginContextType = {
  email: string;
  onEmailChange: (email: string) => void;

  password: string;
  onPasswordChange: (password: string) => void;
};

const LoginContext = createContext<LoginContextType | null>(null);

export const useLoginContext = () => useContext(LoginContext);

export const LoginContextProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const [email, setEmail] = useState<LoginContextType['email']>('');

  const [password, setPassword] = useState<LoginContextType['password']>('');

  const context = useMemo<LoginContextType>(
    () => ({
      email,
      onEmailChange: setEmail,

      password,
      onPasswordChange: setPassword,
    }),
    [email, password]
  );

  return (
    <LoginContext.Provider value={context}>{children}</LoginContext.Provider>
  );
};
