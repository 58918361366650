import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { type ForwardedRef, forwardRef, useMemo } from 'react';
import { TagDto } from '~/shared/api/index.ts';
import {
  ADDABLE_ITEM_ROW_HEIGHT,
  AddableItemInList,
  PageOffsetInfiniteScroll,
  type PageOffsetInfiniteScrollRef,
  useVirtualRow,
} from '~/shared/ui/index.ts';
import { type AddableItemInListProps } from '~/shared/ui/InfiniteScroll/AddableItemInList.tsx';
import { JobPositionApi } from '~/shared/api/jobPosition/JobPositionApi.ts';
import { loadLoadJobPositionsQueryKeys } from '../api/useLoadJobPositions.ts';
import { JobPosition } from '../model/JobPosition.ts';
import { JOB_POSITION_PAGE_SIZE } from './jobPositionPageSize.ts';

const queryKeys = (searchedJobPositionName?: JobPosition['name']) => [
  ...loadLoadJobPositionsQueryKeys(searchedJobPositionName),
  'infinitely',
];

type Props = {
  /** if used search, this name will be passed to api for searching */
  searchedJobPositionName?: JobPosition['name'];

  /** if some job positions are selected, and you want to shw check icon for them, use this prop */
  selectedJobPositionIds?: Set<JobPosition['id'] | null>;

  onRowClick?: (jobPosition: JobPosition) => void;

  /** icon displayed on a row, when the row is selected */
  selectedIcon: Extract<
    AddableItemInListProps<any>['iconVariant'],
    'cross' | 'check'
  >;
};

const JobPositionInfiniteList = (
  props: Props,
  ref: ForwardedRef<PageOffsetInfiniteScrollRef<TagDto>>
) => {
  const {
    searchedJobPositionName,
    selectedJobPositionIds,
    onRowClick,
    selectedIcon,
  } = props;

  const jobPositionApi = useDi(JobPositionApi);

  const Row = useVirtualRow<TagDto>(
    (props) => {
      const { forwardedRef, isLoading, rowEntity, hasNextPage, ...rest } =
        props;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const jobPosition = useMemo(
        () =>
          rowEntity ? Mapper.map(TagDto, JobPosition, rowEntity) : undefined,
        [rowEntity]
      );

      let selected: boolean = false;
      if (jobPosition && selectedJobPositionIds) {
        selected = selectedJobPositionIds.has(jobPosition.id);
      }

      return (
        <AddableItemInList
          model={jobPosition}
          onClick={() => {
            if (jobPosition) {
              onRowClick?.(jobPosition);
            }
          }}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          forwardedRef={forwardedRef}
          iconVariant={selected ? selectedIcon : 'add'}
          {...rest}
        />
      );
    },
    [onRowClick, selectedJobPositionIds, selectedIcon]
  );

  return (
    <PageOffsetInfiniteScroll
      ref={ref}
      estimatedRowHeight={ADDABLE_ITEM_ROW_HEIGHT}
      queryKeys={queryKeys(searchedJobPositionName)}
      apiRequest={(offset) =>
        jobPositionApi.searchJobPositions({
          pageSize: JOB_POSITION_PAGE_SIZE,
          page: offset,
          jobPositionName: searchedJobPositionName,
        })
      }
      Row={Row}
    />
  );
};

const component = forwardRef(JobPositionInfiniteList);
export { component as JobPositionInfiniteList };
