import { Typography } from '@mui/material';
import { type FC } from 'react';
import type { CryptoWallet } from '../model/CryptoWallet.ts';
import classes from './TonWallet.module.scss';

type Props = {
  tonWallet: CryptoWallet;
};

export const TonWallet: FC<Props> = (props) => {
  const { tonWallet } = props;

  return (
    <div className={classes.root}>
      <Typography>TON wallet address:</Typography>
      <Typography>{tonWallet?.address}</Typography>
    </div>
  );
};
