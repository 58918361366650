import { Outlet, type RouteObject } from 'react-router-dom';
import { MeetingDraftContextProvider } from '~/entities/meeting/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { Display } from '~/shared/ui/index.ts';
import {
  MeetingAddMembersDisplay,
  MeetingCreatedDisplay,
  MeetingDateDisplay,
  MeetingDetailsDisplay,
  MeetingReminderDisplay,
} from '../meetings/index.ts';
import { ContactProfileDisplay } from './ContactProfileDisplay.tsx';
import { ContactsDisplay } from './ContactsDisplay.tsx';

export const contactRouteObjects: RouteObject[] = [
  {
    path: routes.contacts.relativeUrl(),
    element: (
      <ContactsDisplay>
        <MeetingDraftContextProvider parentRoute={routes.contacts.new_meeting}>
          <Outlet />
        </MeetingDraftContextProvider>
      </ContactsDisplay>
    ),
    children: [
      {
        index: true,
        element: null,
      },
      {
        path: routes.contacts.userId().relativeUrl(),
        element: (
          <ContactProfileDisplay>
            <Outlet />
          </ContactProfileDisplay>
        ),
        children: [
          {
            index: true,
            element: null,
          },
          ...meetingRoutes(),
        ],
      },
      ...meetingRoutes(),
    ],
  },
];

function meetingRoutes(): RouteObject[] {
  return [
    {
      path: routes.contacts.new_meeting.relativeUrl(),
      element: (
        <Display
          documentTitle="new meeting with contacts"
          animationVariant="card-effect"
        >
          <MeetingDateDisplay />
          <Outlet />
        </Display>
      ),
      children: [
        {
          index: true,
          element: null,
        },
        {
          path: routes.contacts.new_meeting.details.relativeUrl(),
          element: (
            <MeetingDetailsDisplay>
              <Outlet />
            </MeetingDetailsDisplay>
          ),
          children: [
            {
              index: true,
              element: null,
            },
            {
              path: routes.contacts.new_meeting.details.member.relativeUrl(),
              element: (
                <Display
                  documentTitle="new meeting members"
                  animationVariant="card-effect"
                >
                  <MeetingAddMembersDisplay />
                </Display>
              ),
            },
            {
              path: routes.contacts.new_meeting.details.reminder.relativeUrl(),
              element: (
                <Display
                  documentTitle="new meeting reminder"
                  animationVariant="card-effect"
                >
                  <MeetingReminderDisplay />
                </Display>
              ),
            },
            {
              path: routes.contacts.new_meeting.details.meeting_created.relativeUrl(),
              element: (
                <Display
                  documentTitle="new meeting has created"
                  animationVariant="card-effect"
                >
                  <MeetingCreatedDisplay />
                </Display>
              ),
            },
          ],
        },
      ],
    },
  ];
}
