import React from 'react';
import { RULES_URL } from '../../../config/environment/index.ts';
import classes from './UserRules.module.scss';

// todo: check styles

export const UserRules = () => {
  return (
    <span className={classes.container}>
      By signing up for the app, you confirm that you have read and agree to the{' '}
      <a
        type="button"
        href={RULES_URL}
        target="_blank"
        className={classes.userRules}
        rel="noreferrer"
      >
        Terms of Service
      </a>
    </span>
  );
};
