import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo } from 'react';
import type { ModelImage } from '~/entities/profile/index.ts';
import { Avatar } from '~/shared/atoms/Avatar';
import { Icon } from '~/shared/atoms/icons';
import classes from './HorizontalUser.module.scss';

type UserInfo = {
  userId: number;
  firstName: string;
  lastName: string;
  avatar: ModelImage;
};

type Props<User extends UserInfo> = {
  user: User;
  onRemove?: (user: User) => void;
};

function HorizontalUser<User extends UserInfo>(props: Props<User>) {
  const { user, onRemove } = props;

  const showRemoveIcon = onRemove != null;

  return (
    <div className={classes.root}>
      <div className={classes.avatarContainer}>
        <Avatar
          className={classes.avatar}
          firstName={user.firstName}
          lastName={user.lastName}
          avatarUrl={user.avatar?.url}
        />

        {showRemoveIcon && (
          <CustomizedIconButton
            onClick={() => onRemove?.(user)}
            size="small"
            className={classes.remove}
          >
            <Icon variant="cross" />
          </CustomizedIconButton>
        )}
      </div>

      <Typography className={classes.name} variant="body1">
        {user.firstName}
      </Typography>
    </div>
  );
}

const CustomizedIconButton = styled(IconButton)({
  '&.MuiButtonBase-root.MuiIconButton-root': {
    padding: 0,
    border: '2px solid white',
    background: '#ADB5BD',
    color: 'white',
  },
  '&.MuiButtonBase-root.MuiIconButton-root:hover': {
    background: 'rgb(150, 160, 171)',
  },
});

const MemoizedHorizontalUser = memo(HorizontalUser) as typeof HorizontalUser;
export { MemoizedHorizontalUser as HorizontalUser };
export type { Props as HorizontalUserProps, UserInfo };
