import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { type ForwardedRef, forwardRef, useMemo } from 'react';
import { TagDto } from '~/shared/api/index.ts';
import { SKILL_PAGE_SIZE } from '~/shared/api/skill/SKILL_PAGE_SIZE.ts';
import { SkillApi } from '~/shared/api/skill/SkillApi.ts';
import {
  ADDABLE_ITEM_ROW_HEIGHT,
  AddableItemInList,
  PageOffsetInfiniteScroll,
  type PageOffsetInfiniteScrollRef,
  useVirtualRow,
} from '~/shared/ui/index.ts';
import { loadSkillsQueryKeys } from '../api/useLoadSkills.ts';
import { Skill } from '../model/Skill.ts';

export const infiniteSkillsQueryKeys = (searchedSkillName?: Skill['name']) => [
  ...loadSkillsQueryKeys(searchedSkillName),
  'infinitely',
];

type Props = {
  /** if used search, this name will be passed to api for searching */
  searchedSkillName?: Skill['name'];

  /** if some skills are selected, and you want to shw check icon for them, use this prop */
  selectedSkillIds?: Set<Skill['id']>;

  onRowClick?: (skill: Skill) => void;
};

const SkillsInfiniteList = (
  props: Props,
  ref: ForwardedRef<PageOffsetInfiniteScrollRef<TagDto>>
) => {
  const { searchedSkillName, selectedSkillIds, onRowClick } = props;

  const skillApi = useDi(SkillApi);

  const Row = useVirtualRow<TagDto>(
    (props) => {
      const { forwardedRef, isLoading, rowEntity, hasNextPage, ...rest } =
        props;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const skill = useMemo(
        () => (rowEntity ? Mapper.map(TagDto, Skill, rowEntity) : undefined),
        [rowEntity]
      );

      let selected: boolean = false;
      if (skill && selectedSkillIds) {
        selected = selectedSkillIds.has(skill.id);
      }

      return (
        <AddableItemInList
          model={skill}
          onClick={() => {
            if (skill) {
              onRowClick?.(skill);
            }
          }}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          forwardedRef={forwardedRef}
          iconVariant={selected ? 'cross' : 'add'}
          {...rest}
        />
      );
    },
    [selectedSkillIds, onRowClick]
  );

  return (
    <PageOffsetInfiniteScroll
      ref={ref}
      estimatedRowHeight={ADDABLE_ITEM_ROW_HEIGHT}
      queryKeys={infiniteSkillsQueryKeys(searchedSkillName)}
      apiRequest={(offset) =>
        skillApi.searchSkills({
          pageSize: SKILL_PAGE_SIZE,
          page: offset,
          skillName: searchedSkillName,
        })
      }
      Row={Row}
    />
  );
};

const component = forwardRef(SkillsInfiniteList);
export { component as SkillsInfiniteList };
