import IconButton from '@mui/material/IconButton';
import { Checkbox } from '~/shared/molecules/Checkbox/index.ts';
import { noop } from '../../../utils/index.ts';
import { ContactRow, type ContactRowProps } from './ContactRow.tsx';

type Props = Omit<ContactRowProps, 'secondaryAction'> & {
  interactible: boolean;
  checked: boolean;
};
export type { Props as ContactRowWithCheckboxProps };

export const ContactRowWithCheckbox = (props: Props) => {
  const { interactible, checked, ...contactRowProps } = props;

  return (
    <ContactRow
      secondaryAction={
        interactible && (
          <IconButton edge="end" onClick={contactRowProps.onRowClick ?? noop}>
            <Checkbox checked={checked} />
          </IconButton>
        )
      }
      {...contactRowProps}
    />
  );
};
