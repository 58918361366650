import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { type Skill } from '~/entities/skill/index.ts';
import { ProfileApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification';
import {
  invalidateCurrentUserQuery,
  useCurrentUser,
} from '../../../entities/profile';
import { useOnBack } from '../../navigation/index.ts';

export function useSaveProfileSkills() {
  const queryClient = useQueryClient();
  const profileApi = useDi(ProfileApi);
  const onBack = useOnBack({});

  const { data: profile } = useCurrentUser();

  return useMutation({
    mutationKey: ['save user skills', { profileSkillIds: profile?.skillIds }],
    mutationFn: (selectedSkillIds: Set<Skill['id']>) => {
      if (!profile) {
        throw new Error('current profile is not loaded');
      }

      const skills = new Set(profile.skillIds);

      const newIds = Array.from(selectedSkillIds.difference(skills));
      const deletedIds = Array.from(skills.difference(selectedSkillIds));

      return profileApi.changeSkillsBulk({
        addIds: newIds,
        deleteIds: deletedIds,
      });
    },
    onSuccess: () => {
      invalidateCurrentUserQuery(queryClient);
      onBack();
    },
    onError: (error) => {
      notify({
        message: `Cant save profile: ${error.message}`,
        variant: 'error',
      });
    },
  });
}
