import { useMutation } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { DeveloperApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification';

export function useDeleteUserMatches() {
  const developerApi = useDi(DeveloperApi);

  return useMutation({
    mutationKey: ['delete my matches with all users'],
    mutationFn: () => developerApi.deleteMatches(),
    onError: (error) => {
      notify({
        message: `Cant remove: ${error.message}`,
        variant: 'error',
      });
    },
    onSuccess: () => {
      notify({
        message: 'Removed all likes and dislikes',
        variant: 'success',
      });

      // todo: revalidate matches
    },
  });
}
