import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useMemo } from 'react';
import { useBlockedUsersQuery } from '~/entities/block-list/index.ts';
import { useBlockUserMutation } from '~/features/block-list/api/useBlockUserMutation.ts';
import { useUnblockUserMutation } from '~/features/block-list/index.ts';
import { Icon } from '~/shared/atoms/icons/index.ts';

type Props = {
  anchorEl: Element | null;
  onClose: () => void;
  userId: number;
};

export const ColleagueProfileOptions = (props: Props) => {
  const { anchorEl, onClose, userId } = props;

  const { mutate: blockUser, isPending: isUserBlocking } =
    useBlockUserMutation();

  const { mutate: unblockUser, isPending: isUserUnblocking } =
    useUnblockUserMutation();

  const { data: blockedUsers, isLoading } = useBlockedUsersQuery();

  const blockedUserIdsSet = useMemo(
    () => new Set(blockedUsers?.map((user) => user.userId)),
    [blockedUsers]
  );
  const isUserBlocked = blockedUserIdsSet.has(userId);

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      {isUserBlocked ? (
        <MenuItem
          onClick={() => {
            unblockUser(userId);
            onClose();
          }}
          disabled={isLoading}
        >
          <ListItemIcon>
            <Icon variant="removeFromList" />
          </ListItemIcon>

          <ListItemText>Unblock</ListItemText>
        </MenuItem>
      ) : (
        <MenuItem
          onClick={() => {
            blockUser(userId);
            onClose();
          }}
          disabled={isLoading}
        >
          <ListItemIcon>
            <Icon variant="block" />
          </ListItemIcon>

          <ListItemText>Block</ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
};
