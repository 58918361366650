import {
  InterestCard,
  useProfileInterests,
} from '~/entities/interest/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { ContentSlot } from '~/shared/organisms/slots';
import { Display, Navbar, NotFoundView } from '~/shared/ui/index.ts';
import classes from './InterestsDisplay.module.scss';

export const InterestsDisplay = () => {
  const { interests, interestsAreLoading } = useProfileInterests();

  return (
    <Display documentTitle="interests" animationVariant="card-effect">
      <Navbar
        title="Interests"
        left={<BackIconNavigationButton />}
        partialLoading={interestsAreLoading}
      />

      {interests && interests.length > 0 ? (
        <ContentSlot className={classes.root}>
          {interests.map((interest) => (
            <InterestCard key={interest.id} interest={interest} />
          ))}
        </ContentSlot>
      ) : (
        <ContentSlot>
          <NotFoundView>There is no information about interests.</NotFoundView>
        </ContentSlot>
      )}
    </Display>
  );
};
