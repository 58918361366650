import { type QueryClient, useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { BlockListApi, BlockUserDto, queryKeys } from '~/shared/api/index.ts';
import { useApiErrorNotification } from '~/shared/notification/index.ts';
import { time } from '../../../utils/index.ts';
import { useLoadProfileImageUrls } from '../../profile/index.ts';
import { BlockedUser } from '../model/BlockedUser.ts';
import { useBlockedListStore } from '../model/useBlockedListStore.ts';

const queryKey = 'load blocked users';

export function invalidateBlockedUsersQueryKey(queryClient: QueryClient) {
  return queryClient.invalidateQueries({
    queryKey: [queryKey],
  });
}

export function useBlockedUsersQuery(searchedName?: BlockedUser['name']) {
  const blockListApi = useDi(BlockListApi);
  const loadProfileImageUrls = useLoadProfileImageUrls();

  const query = useQuery({
    queryKey: [queryKey, { searchedName }],
    queryFn: async () => {
      const { users: dtos } = await blockListApi.getBlockUsers();
      const blockedUsers = dtos.map((dto) =>
        Mapper.map(BlockUserDto, BlockedUser, dto)
      );

      useBlockedListStore.setState(() => ({
        blockedUserIdsSet: new Set(blockedUsers.map((user) => user.userId)),
      }));

      let filtered: BlockedUser[];
      if (searchedName) {
        const loweredSearchName = searchedName.toLowerCase();
        filtered = blockedUsers.filter((user) =>
          user.name.toLowerCase().includes(loweredSearchName)
        );
      } else {
        filtered = blockedUsers;
      }

      await loadProfileImageUrls(filtered);

      return blockedUsers;
    },
    staleTime: time(5, 'minutes'),
  });

  useApiErrorNotification(query.error);

  return query;
}
