import type { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '~/entities/profile';
import { ChooseTimezoneModal } from '~/features/timezone';
import { routes } from '~/shared/routing/index.ts';
import { FooterTabs } from '~/shared/templates/FooterTabs.tsx';
import { OpenSettingsIconButton } from '~/shared/templates/IconButtons/OpenSettingsIconButton.tsx';
import { Display, Navbar } from '~/shared/ui/index.ts';
import { CurrentProfileInformation } from '~/widgets/profile';

export const ProfileDisplay: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const { isLoading: profileIsLoading } = useCurrentUser();

  return (
    <>
      <Display documentTitle="my profile">
        <Navbar
          title="Profile Information"
          right={
            <OpenSettingsIconButton
              onClick={() => navigate(routes.profile.settings.url())}
            />
          }
          partialLoading={profileIsLoading}
        />

        <CurrentProfileInformation />
        <ChooseTimezoneModal />
        <FooterTabs />
      </Display>

      {children}
    </>
  );
};
