import {
  Avatar as MuiAvatar,
  type AvatarProps as MuiAvatarProps,
  type SxProps,
  type Theme,
} from '@mui/material';
import Skeleton, { type SkeletonProps } from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import {
  type FC,
  type ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isNullOrUndefined, stringToColor } from '~/utils/index.ts';
import classes from './Avatar.module.scss';

type ImageState = 'initial' | 'loaded' | 'failed';

type Props = {
  className?: string;
  firstName?: string;
  lastName?: string;
  avatarUrl?: string | null;
  sx?: SxProps<Theme>;
  skeleton?: Pick<SkeletonProps, 'height' | 'width'>;
  icon?: ReactElement;
};

const Avatar = (props: Props) => {
  const {
    className,
    sx,
    firstName,
    lastName,
    avatarUrl,
    skeleton = {},
    icon = null,
  } = props;

  const [state, setState] = useState<ImageState>('initial');

  useEffect(() => {
    setState('initial');
  }, [avatarUrl]);

  if (!avatarUrl && !isNullOrUndefined(firstName)) {
    return (
      <NameAvatar
        className={className}
        sx={sx}
        firstName={firstName}
        lastName={lastName}
      />
    );
  }

  return (
    <>
      <Skeleton
        variant="circular"
        height={skeleton.height ?? '100%'}
        width={skeleton.width ?? '100%'}
        style={{ display: avatarUrl && state === 'initial' ? 'block' : 'none' }}
      />
      <StyledAvatar
        className={className}
        sx={sx}
        src={avatarUrl ?? undefined}
        data-loaded={state}
        style={{
          display: !avatarUrl || state !== 'initial' ? 'block' : 'none',
        }}
        onLoad={() => setState('loaded')}
        onError={() => setState('failed')}
      />
      {icon && <span className={classes.icon}>{icon}</span>}
    </>
  );
};

const StyledAvatar = styled(
  MuiAvatar as FC<MuiAvatarProps & { 'data-loaded': ImageState }>
)((props) => ({
  '&.MuiAvatar-root': {
    backgroundColor:
      props.src && props['data-loaded'] === 'loaded' ? '#3a3636' : '#E5EFFD',
    color: '#B1CEF9',
    '.MuiSvgIcon-root.MuiAvatar-fallback': {
      width: '100%',
      height: '100%',
    },
  },
}));

type NameAvatarProps = {
  className?: string;
  firstName: string;
  lastName?: string;
  sx?: SxProps<Theme>;
};

const NameAvatar = (props: NameAvatarProps) => {
  const { className, sx, firstName, lastName } = props;

  const nameColor = useMemo(
    () => stringToColor(`${firstName} ${lastName}`),
    [firstName, lastName]
  );

  const nameInitials = useMemo(() => {
    if (!lastName) {
      return firstName[0];
    }

    return `${firstName[0]} ${lastName[0]}`;
  }, [firstName, lastName]);

  return (
    <CustomizedAvatarForName
      className={className}
      sx={{ bgcolor: nameColor, ...sx }}
    >
      {nameInitials}
    </CustomizedAvatarForName>
  );
};

const CustomizedAvatarForName = styled(MuiAvatar)({
  '&.MuiAvatar-root': {
    letterSpacing: '-2px',
  },
});

export { Avatar };
export type { Props as AvatarProps };
