import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { loadSkillsQueryKeys } from '~/entities/skill/api/useLoadSkills.ts';
import { SkillApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';

export function useCreateSkill() {
  const queryClient = useQueryClient();
  const skillApi = useDi(SkillApi);

  return useMutation({
    mutationKey: ['create skill'],
    mutationFn: skillApi.createSkill,
    onError: (error) => {
      notify({
        message: `Cant create skill: ${error.message}`,
        variant: 'error',
      });
    },
    onSuccess: () => {
      notify({
        message: 'Skill created',
        variant: 'success',
      });

      queryClient.invalidateQueries({
        queryKey: loadSkillsQueryKeys(),
      });
    },
  });
}
