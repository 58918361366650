import Badge from '@mui/material/Badge';
import dayjs from 'dayjs';
import { useMemo, useRef } from 'react';
import { useCurrentUser } from '~/entities/profile';
import { CancelNavigationButton } from '~/features/navigation';
import { ProfilePersonalFormModel } from '~/features/profile';
import { useSavePersonalData } from '~/features/profile/api/useSavePersonalData.ts';
import { FormField } from '~/shared/form/FormField.tsx';
import {
  FormProvider,
  type FormProviderRef,
} from '~/shared/form/FormProvider.tsx';
import { SelectField, type SelectOption } from '~/shared/organisms/SelectField';
import { ContentSlot } from '~/shared/organisms/slots';
import { TextField } from '~/shared/organisms/TextField';
import {
  ConfirmButton,
  Display,
  Navbar,
  useDisplayLoader,
} from '~/shared/ui/index.ts';
import classes from './EditProfileInfoDisplay.module.scss';

export const EditProfileInfoDisplay = () => {
  const {
    data: profile,
    isLoading: profileIsLoading,
    error,
  } = useCurrentUser();

  const { mutate: saveProfile, isPending } = useSavePersonalData();
  useDisplayLoader(isPending);

  useDisplayLoader(profileIsLoading);

  const formRef = useRef<FormProviderRef>(null);

  const options = useMemo<SelectOption[]>(() => {
    const timezone = dayjs.tz.guess();
    const offset = dayjs().offsetName();
    return [
      {
        label: `${timezone} ${offset}`,
        value: timezone,
      },
      {
        label: `22323 ${timezone} ${offset}`,
        value: 'asdasd',
      },
    ];
  }, []);

  return (
    <Display documentTitle="edit profile info" animationVariant="card-effect">
      <Navbar
        title="Profile Information"
        left={<CancelNavigationButton disabled={!profile?.wasSaved} />}
        right={
          <ConfirmButton
            onClick={() => {
              formRef.current?.triggerSubmit();
            }}
          />
        }
      />

      <ContentSlot className={classes.root}>
        {error && <p>Your profile or user does not exist</p>}

        {profile && (
          <FormProvider<ProfilePersonalFormModel>
            ref={formRef}
            className={classes.root}
            initialValues={{
              firstName: profile.firstName,
              lastName: profile.lastName,
              bio: profile.bio,
              timezone: profile.timezone,
            }}
            validationSchema={ProfilePersonalFormModel.validationSchema}
            onValidSubmit={(data) => {
              saveProfile(data);
            }}
          >
            <FormField<ProfilePersonalFormModel> name="firstName">
              {({ register, errors }) => (
                <TextField
                  label="First Name"
                  clearable
                  isError={errors?.firstName != null}
                  helpText={errors?.firstName?.message}
                  {...register()}
                />
              )}
            </FormField>

            <FormField<ProfilePersonalFormModel> name="lastName">
              {({ register, errors }) => (
                <TextField
                  label="Last Name"
                  isError={errors?.lastName != null}
                  helpText={errors?.lastName?.message}
                  {...register()}
                />
              )}
            </FormField>

            <TextField label="Email" value={profile.email} disabled />

            <FormField<ProfilePersonalFormModel> name="bio">
              {({ register, errors, getValues }) => (
                <Badge
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={
                    ProfilePersonalFormModel.bioMaxLength -
                    (getValues('bio')?.length ?? 0)
                  }
                  showZero
                  color="secondary"
                >
                  <TextField
                    label="About Me"
                    multiline
                    rows={5}
                    fullWidth
                    isError={errors?.bio != null}
                    helpText={errors?.bio?.message}
                    {...register()}
                  />
                </Badge>
              )}
            </FormField>

            <FormField<ProfilePersonalFormModel> name="timezone">
              {({ register, errors, getValues }) => {
                const { onChangeValue, onChange, ...fieldProps } = register();
                const value = getValues('timezone');
                const selectedOption =
                  options.find((option) => option.value === value) ?? null;
                return (
                  <SelectField
                    label="Time Zone"
                    options={options}
                    menuPlacement="auto"
                    isError={errors?.bio != null}
                    helpText={errors?.bio?.message}
                    {...fieldProps}
                    value={selectedOption}
                    onChangeValue={onChangeValue}
                  />
                );
              }}
            </FormField>
          </FormProvider>
        )}
      </ContentSlot>
    </Display>
  );
};
