import {
  Button as MuiButton,
  type ButtonProps as MuiButtonProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { type FC } from 'react';
import { type CommonButtonProps } from './CommonButtonProps';

type Props = CommonButtonProps;

const ContainedButton: FC<Props> = (props) => {
  return <CustomizedButton variant="contained" {...props} />;
};

const CustomizedButton = styled(MuiButton)<MuiButtonProps>(
  ({ theme, color }) => ({
    '&.MuiButtonBase-root.MuiButton-root': {
      boxShadow: 'unset',
      borderRadius: 12,
      textTransform: 'unset',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
    },
    '&.MuiButton-containedSecondary': {
      backgroundColor: '#F1F3F5',
      color: '#212529',
    },
    '&.MuiButton-containedError': {
      backgroundColor: '#FFF2F2',
      color: '#FF5F61',
    },
  })
);

export { ContainedButton };
export type { Props as ContainedButtonProps };
