import { useNotificationStore } from '../config/useNotificationStore.ts';
import { type Notification } from '../model/Notification.ts';

export function dismissNotification(notificationKey: Notification['key']) {
  useNotificationStore.setState((prev) => ({
    notifications: prev.notifications.filter(
      (_notification) => _notification.key !== notificationKey
    ),
  }));
}
