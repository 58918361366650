import type { DialogTypeEnum } from '../enums/DialogTypeEnum.ts';
import { type MessageDto } from './MessageDto.ts';

export abstract class UserPreviewDto {
  abstract id: number;
  abstract name: string;
  abstract avatarName: string | null;
}

export abstract class DialogDto {
  abstract dialogId: number;
  /** UserID / GroupID */
  abstract entityId: number;
  abstract dialogName: string;
  abstract dialogAvatarName: string | null | undefined;
  abstract dialogType: DialogTypeEnum;
  abstract lastMessagePreview: MessageDto | null | undefined;
  abstract lastUserSender: UserPreviewDto | null | undefined;
  abstract unreadMessagesCount: number;
}
