import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import {
  infiniteChatMessagesQueryKeys,
  type PrivateChat,
} from '~/entities/chat/index.ts';
import { ChatSignal, queryKeys } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';
import { guid } from '../../../utils/index.ts';

export function useSendMessageMutation(options: {
  chatId: undefined | PrivateChat['chatId'];
  userId: undefined | PrivateChat['interlocutorId'];
  onSuccessCallback?: VoidFunction;
}) {
  const { chatId, userId, onSuccessCallback } = options;

  const chatSignal = useDi(ChatSignal);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: queryKeys('send message', { chatId, userId }),
    mutationFn: async (message: string) => {
      if (userId == null) {
        return;
      }

      const messageGuid = guid();

      await chatSignal.sendMessage({
        message,
        interlocutorId: userId,
        guid: messageGuid,
      });
    },
    onError: (error) => {
      notify({
        message: `Cant send message: ${error.message}`,
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: infiniteChatMessagesQueryKeys(chatId, userId),
      });
      onSuccessCallback?.();
    },
  });
}
