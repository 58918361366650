import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { type Skill } from '~/entities/skill/index.ts';
import { useStateSet } from '~/shared/useStateSet.ts';

export type ProfileSkillsEditContextType = {
  selectedSkillIds: Set<Skill['id']>;
  addSkill: (skillId: Skill['id']) => void;
  deleteSkill: (skillId: Skill['id']) => void;
};

const ProfileSkillsEditContext =
  createContext<ProfileSkillsEditContextType | null>(null);

export const ProfileSkillsEditContextProvider: FC<PropsWithChildren> = (
  props
) => {
  const { children } = props;

  const { data: profile } = useCurrentUser();

  const {
    state: selectedSkillIds,
    setState: setSelectedSkillIds,
    add: addSkill,
    remove: deleteSkill,
  } = useStateSet<Skill['id']>();

  useEffect(() => {
    if (!profile) {
      return;
    }
    setSelectedSkillIds(new Set(profile.skillIds));
  }, [profile?.skillIds]);

  const context = useMemo<ProfileSkillsEditContextType>(
    () => ({
      selectedSkillIds,
      addSkill,
      deleteSkill,
    }),
    [addSkill, deleteSkill, selectedSkillIds]
  );

  return (
    <ProfileSkillsEditContext.Provider value={context}>
      {children}
    </ProfileSkillsEditContext.Provider>
  );
};

export const useProfileSkillsEditContext = () =>
  useContext(ProfileSkillsEditContext);
