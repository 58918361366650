import { type ReactElement, useState } from 'react';
import {
  type FieldErrors,
  type Path,
  type UseFormGetValues,
  type UseFormRegisterReturn,
  type UseFormSetValue,
} from 'react-hook-form';
import { useFormContext } from './FormContext.ts';

type FormFieldState<FormValues extends object> = {
  getValues: UseFormGetValues<FormValues>;
  errors: FieldErrors<FormValues> | undefined;
  register: (anyProps?: {
    onChangeValue?: (value: any) => void;
  }) => UseFormRegisterReturn<Path<FormValues>> & {
    onChangeValue: (value: unknown) => void;
  };
  setValue: UseFormSetValue<FormValues>;
};

type Props<FormValues extends object> = {
  name: Path<FormValues>;
  children: (form: FormFieldState<FormValues>) => ReactElement;
};

export function FormField<FormValues extends object>(props: Props<FormValues>) {
  const { name, children } = props;

  const [, rerender] = useState({});

  const context = useFormContext<FormValues>();
  if (!context) {
    throw new Error(
      `[${name} FormField]: there is no FormContext, probably you forbid to add FormProvider above the FormField`
    );
  }

  const { form } = context;
  const { register, formState, setValue, getValues } = form;

  const fieldProps = register(name);

  return children({
    register: (externalProps) => ({
      ...fieldProps,
      onChangeValue: (value) => {
        setValue(name, value as any);
        if (typeof externalProps?.onChangeValue === 'function') {
          externalProps.onChangeValue(value);
        }
        // rerender component to give him chance to react on updated value if he needs
        rerender({});
      },
    }),
    errors: formState.errors,
    getValues,
    setValue: setValue,
  });
}
