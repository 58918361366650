import { ListItemButton } from '@mui/material';
import dayjs from 'dayjs';
import { type Quest } from '~/entities/quest/index.ts';
import { Icon } from '~/shared/atoms/icons';
import classes from './QuestRow.module.scss';

const borderColor = '#E9ECEF';

type Props = {
  quest: Quest;
  navigateToQuest: (quest: Quest) => void;
};

const QuestRow = (props: Props) => {
  const { quest, navigateToQuest } = props;

  const endDate = quest.endAt ? dayjs(quest.endAt).format('DD.MM.YY') : '';

  return (
    <ListItemButton
      classes={{
        root: classes.row,
      }}
      // override properties of mui component
      sx={{
        display: 'grid',
        alignItems: 'center',
        padding: '8px 16px',
        backgroundColor: '#FFFFFF',
        borderBottom: `1px solid ${borderColor}`,
        borderTop: `1px solid ${borderColor}`,
      }}
      onClick={() => navigateToQuest(quest)}
    >
      <Icon variant="network" className={classes.network} />
      <span className={classes.title}>{quest.title}</span>
      <Icon variant="chevron" direction="right" className={classes.chevron} />

      <p className={classes.shortInfo}>
        <span>
          Задач {quest.taskCounts.completed}/{quest.taskCounts.total}
        </span>
        {' | '}
        <span>Срок до {endDate}</span>
      </p>
    </ListItemButton>
  );
};

export { QuestRow };
export type { Props as QuestRowProps };
