import { Navigate, Outlet, type RouteObject } from 'react-router-dom';
import { RedirectIfAuthorized } from '~/entities/auth/ui/RedirectIfAuthorized.tsx';
import { routes } from '~/shared/routing/index.ts';
import { Display } from '~/shared/ui/index.ts';
import { LoginDisplay } from './LoginDisplay';
import { RegistrationDisplay } from './RegistrationDisplay';

export const authRouteObject: RouteObject[] = [
  {
    path: routes.auth.relativeUrl(),
    element: (
      <RedirectIfAuthorized>
        <Outlet />
      </RedirectIfAuthorized>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={routes.auth.login.relativeUrl()} replace />,
      },
      {
        path: routes.auth.login.relativeUrl(),
        element: (
          <Display documentTitle="login">
            <LoginDisplay />
          </Display>
        ),
      },
      {
        path: routes.auth.registration.relativeUrl(),
        element: (
          <Display documentTitle="register">
            <RegistrationDisplay />
          </Display>
        ),
      },
    ],
  },
];
