import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { type ForwardedRef, forwardRef, useMemo } from 'react';
import { TagDto } from '~/shared/api/index.ts';
import {
  ADDABLE_ITEM_ROW_HEIGHT,
  AddableItemInList,
  PageOffsetInfiniteScroll,
  type PageOffsetInfiniteScrollRef,
  useVirtualRow,
} from '~/shared/ui/index.ts';
import { type AddableItemInListProps } from '~/shared/ui/InfiniteScroll/AddableItemInList.tsx';
import { DepartmentApi } from '~/shared/api/department/DepartmentApi.ts';
import { loadDepartmentsQueryKeys } from '../api/useLoadDepartments.ts';
import { Department } from '../model/Department.ts';
import { DEPARTMENT_PAGE_SIZE } from './depatmentPageSize.ts';

const queryKey = (searchedDepartmentName?: Department['name']) => [
  ...loadDepartmentsQueryKeys(searchedDepartmentName),
  'infinitely',
];

type Props = {
  /** if used search, this name will be passed to api for searching */
  searchedDepartmentName?: Department['name'];

  /** if some departments are selected, and you want to shw check icon for them, use this prop */
  selectedDepartmentIds?: Set<Department['id'] | null>;

  onRowClick?: (department: Department) => void;

  /** icon displayed on a row, when the row is selected */
  selectedIcon: Extract<
    AddableItemInListProps<any>['iconVariant'],
    'cross' | 'check'
  >;
};

const DepartmentInfiniteList = (
  props: Props,
  ref: ForwardedRef<PageOffsetInfiniteScrollRef<TagDto>>
) => {
  const {
    searchedDepartmentName,
    selectedDepartmentIds,
    onRowClick,
    selectedIcon,
  } = props;

  const departmentApi = useDi(DepartmentApi);

  const Row = useVirtualRow<TagDto>(
    (props) => {
      const { forwardedRef, isLoading, rowEntity, hasNextPage, ...rest } =
        props;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const department = useMemo(
        () =>
          rowEntity ? Mapper.map(TagDto, Department, rowEntity) : undefined,
        [rowEntity]
      );

      let selected: boolean = false;
      if (department && selectedDepartmentIds) {
        selected = selectedDepartmentIds.has(department.id);
      }

      return (
        <AddableItemInList
          model={department}
          onClick={() => {
            if (department) {
              onRowClick?.(department);
            }
          }}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          forwardedRef={forwardedRef}
          iconVariant={selected ? selectedIcon : 'add'}
          {...rest}
        />
      );
    },
    [onRowClick, selectedDepartmentIds, selectedIcon]
  );

  return (
    <PageOffsetInfiniteScroll
      ref={ref}
      estimatedRowHeight={ADDABLE_ITEM_ROW_HEIGHT}
      queryKeys={queryKey(searchedDepartmentName)}
      apiRequest={(offset) =>
        departmentApi.searchDepartments({
          pageSize: DEPARTMENT_PAGE_SIZE,
          page: offset,
          departmentName: searchedDepartmentName,
        })
      }
      Row={Row}
    />
  );
};

const component = forwardRef(DepartmentInfiniteList);
export { component as DepartmentInfiniteList };
