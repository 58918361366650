import { Collapse } from '@mui/material';
import { type FC } from 'react';
import { useBoolean } from 'usehooks-ts';
import {
  EditCareerNavigationSlat,
  EditInterestsNavigationSlat,
  EditProfileInformationNavigationSlat,
  EditProfileSlat,
  EditSkillsNavigationSlat,
} from '../../../features/profile';

type Props = {};

export const EditProfileCollapsableSlats: FC<Props> = (props) => {
  const {} = props;

  const { value: isEditingExpanded, toggle: toggleIsEditingExpanded } =
    useBoolean(false);

  return (
    <>
      <EditProfileSlat
        expandMore={isEditingExpanded}
        onClick={toggleIsEditingExpanded}
      />

      <Collapse in={isEditingExpanded}>
        <EditProfileInformationNavigationSlat />
        <EditCareerNavigationSlat />
        <EditInterestsNavigationSlat />
        <EditSkillsNavigationSlat />
      </Collapse>
    </>
  );
};
