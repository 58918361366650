import AddIcon from '@mui/icons-material/Add';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BlockIcon from '@mui/icons-material/Block';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import EventIcon from '@mui/icons-material/Event';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PetsIcon from '@mui/icons-material/Pets';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ShareIcon from '@mui/icons-material/Share';
import StarIcon from '@mui/icons-material/Star';
import SwipeIcon from '@mui/icons-material/Swipe';
import TuneIcon from '@mui/icons-material/Tune';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import {
  type ComponentProps,
  type FC,
  type ForwardedRef,
  forwardRef,
} from 'react';
import { AddEventIcon } from './AddEventIcon';
import { AddPersonIcon } from './AddPersonIcon';
import { AddPersonOutlineIcon } from './AddPersonOutlineIcon';
import { ApproveIcon } from './ApproveIcon';
import { BlockListIcon } from './BlockListIcon';
import { BookIcon } from './BookIcon';
import { BookSourceIcon } from './BookSourceIcon';
import { CalendarCheckIcon } from './CalendarCheckIcon';
import { CalendarIcon } from './CalendarIcon';
import { ChatIcon } from './ChatIcon';
import { CheckIcon } from './CheckIcon';
import { ChevronIcon } from './Chevron/ChevronIcon';
import { ClockFilledIcon } from './ClockFilledIcon';
import { CoinIcon } from './CoinIcon.tsx';
import { CrossIcon } from './CrossIcon';
import { LogoIcon } from './LogoIcon';
import { LogoIcon_Dayton } from './LogoIcon_Dayton.tsx';
import { LogoutIcon } from './LogoutIcon';
import { NetworkFilledIcon } from './NetworkFilledIcon';
import { NetworkIcon } from './NetworkIcon';
import { NotFoundIcon } from './NotFoundIcon';
import { NotificationIcon } from './NotificationIcon';
import { PositionFilledIcon } from './PositionFilledIcon';
import { PositionIcon } from './PositionIcon';
import { ProfileFilledIcon } from './ProfileFilledIcon';
import { ProfileOutlinedIcon } from './ProfileOutlinedIcon';
import { RemovePersonIcon } from './RemovePersonIcon';
import { SearchIcon } from './SearchIcon';
import { SettingsIcon } from './SettingsIcon';
import { SmileIcon } from './SmileIcon';
import { StudyIcon } from './StudyIcon';
import { UsersIcon } from './UsersIcon';
import { WriteIcon } from './WriteIcon';

const variants = {
  add: AddIcon,
  addEvent: AddEventIcon,
  addPerson: AddPersonIcon,
  addPersonOutline: AddPersonOutlineIcon,
  addPhoto: AddAPhotoIcon,
  approve: ApproveIcon,
  block: BlockIcon,
  blockList: BlockListIcon,
  book: BookIcon,
  bookSource: BookSourceIcon,
  calendar: CalendarIcon,
  calendarCheck: CalendarCheckIcon,
  chat: ChatIcon,
  check: CheckIcon,
  chevron: ChevronIcon,
  clockFilled: ClockFilledIcon,
  clocks: ScheduleIcon,
  coin: CoinIcon,
  copy: ContentCopyIcon,
  cross: CrossIcon,
  delete: DeleteIcon,
  deleteOutlined: DeleteOutlinedIcon,
  done: DoneOutlinedIcon,
  doubleDone: DoneAllOutlinedIcon,
  edit: ModeEditIcon,
  event: EventIcon,
  expandLess: ExpandLessIcon,
  expandMore: ExpandMoreIcon,
  filterOff: FilterAltOffIcon,
  filters: TuneIcon,
  hiddenPassword: VisibilityOffIcon,
  keyboardDoubleArrowUp: KeyboardDoubleArrowUpIcon,
  logo: LogoIcon,
  logo_dayton: LogoIcon_Dayton,
  logout: LogoutIcon,
  more: MoreHorizIcon,
  network: NetworkIcon,
  networkFilled: NetworkFilledIcon,
  notFound: NotFoundIcon,
  notification: NotificationIcon,
  pets: PetsIcon,
  position: PositionIcon,
  positionFilled: PositionFilledIcon,
  profileData: AssignmentIndIcon,
  profileFilled: ProfileFilledIcon,
  profileOutlined: ProfileOutlinedIcon,
  removeFromList: PlaylistRemoveIcon,
  removePerson: RemovePersonIcon,
  search: SearchIcon,
  settings: SettingsIcon,
  share: ShareIcon,
  smile: SmileIcon,
  star: StarIcon,
  study: StudyIcon,
  swipe: SwipeIcon,
  users: UsersIcon,
  visiblePassword: VisibilityIcon,
  wallet: WalletOutlinedIcon,
  write: WriteIcon,
};

type Variants = typeof variants;
type IconVariant = keyof Variants;

type VariantProps<Variant extends IconVariant> = ComponentProps<
  Variants[Variant]
>;

type Props<Variant extends IconVariant> = {
  variant: Variant;
} & VariantProps<Variant>;

function Icon<Variant extends IconVariant>(
  props: Props<Variant>,
  ref: ForwardedRef<SVGSVGElement>
) {
  const { variant, ...componentProps } = props;

  if (!variant) {
    return null;
  }

  const Component = variants[variant] as FC<typeof componentProps> | undefined;

  if (!Component) {
    throw new Error(`variant (${variant}) is not supported`);
  }

  return <Component ref={ref} {...componentProps} />;
}

const componentWithRef = forwardRef(Icon) as typeof Icon;

export type {
  Props as IconProps,
  IconVariant,
  VariantProps as IconVariantProps,
};
export { componentWithRef as Icon };
