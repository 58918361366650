import dayjs from 'dayjs';
import durationPlugin, { type Duration } from 'dayjs/plugin/duration';
dayjs.extend(durationPlugin);

const props = (label: string, value: string) => ({ label, value });

export const durationValueMap = {
  no: {
    props: props('Нет', 'no'),
    duration: null,
  },
  halfHour: {
    props: props('За 30 мин', 'halfHour'),
    duration: dayjs.duration({ minutes: 30 }).asMinutes(),
  },
  oneHour: {
    props: props('За 1 час', 'oneHour'),
    duration: dayjs.duration({ hours: 1 }).asMinutes(),
  },
  twoHours: {
    props: props('За 2 часа', 'twoHours'),
    duration: dayjs.duration({ hours: 2 }).asMinutes(),
  },
  oneDay: {
    props: props('За 1 день', 'oneDay'),
    duration: dayjs.duration({ days: 1 }).asMinutes(),
  },
  twoDays: {
    props: props('За 2 дня', 'twoDays'),
    duration: dayjs.duration({ days: 2 }).asMinutes(),
  },
  oneWeek: {
    props: props('За неделю', 'oneWeek'),
    duration: dayjs.duration({ weeks: 1 }).asMinutes(),
  },
};

export function getDurationValueElement(
  remindBefore: Duration | null | undefined
) {
  if (!remindBefore) {
    return durationValueMap.no;
  }

  const currentMinutes = remindBefore.asMinutes();
  const valueMapElement = Array.from(Object.values(durationValueMap)).find(
    ({ duration }) => currentMinutes === duration
  );
  if (!valueMapElement) {
    return durationValueMap.no;
  }

  return valueMapElement;
}
