import { type FC } from 'react';
import { Button } from '~/shared/molecules/Button';
import { useDeleteUserMatches } from '../api/useDeleteUserMatches.ts';

export const ResetLikesAndDislikesButton: FC = () => {
  const { mutate: reset } = useDeleteUserMatches();

  return (
    <Button variant="text" color="primary" onClick={() => reset()}>
      Reset All Likes and Dislikes
    </Button>
  );
};
