import { SkillCard, useProfileSkills } from '~/entities/skill/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { ContentSlot } from '~/shared/organisms/slots';
import { Display, Navbar, NotFoundView } from '~/shared/ui/index.ts';
import classes from './SkillsDisplay.module.scss';

export const SkillsDisplay = () => {
  const { skills, skillsAreLoading } = useProfileSkills();

  return (
    <Display documentTitle="skills" animationVariant="card-effect">
      <Navbar
        title="Навыки"
        left={<BackIconNavigationButton />}
        partialLoading={skillsAreLoading}
      />

      {skills && skills.length > 0 ? (
        <ContentSlot className={classes.root}>
          {skills.map((skill) => (
            <SkillCard key={skill.id} skill={skill} />
          ))}
        </ContentSlot>
      ) : (
        <ContentSlot>
          <NotFoundView>Нет навыков</NotFoundView>
        </ContentSlot>
      )}
    </Display>
  );
};
