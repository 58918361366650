import { type FC } from 'react';
import {
  IconButton,
  type IconButtonProps,
} from '../../molecules/IconButton/IconButton';

type Props = Pick<IconButtonProps<'settings'>, 'onClick'>;

// what is feature domain here?
export const OpenSettingsIconButton: FC<Props> = (props) => {
  return (
    <IconButton
      {...props}
      aria-label="settings"
      size="large"
      color="primary"
      variant="settings"
    />
  );
};
