import dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs.locale('ru');

function formatErrorTime(timestamp?: Date) {
  if (!timestamp) {
    return '--:--:--:--';
  }

  const dayJs = dayjs(timestamp);
  const formatted = dayJs.format('HH:mm:ss:SSS');
  return formatted;
}

export { formatErrorTime };
