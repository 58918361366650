import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { JobPositionApi, TagDto } from '~/shared/api/index.ts';
import { defined } from '~/utils/defined.ts';
import { time } from '~/utils/index.ts';
import { JobPosition } from '../model/JobPosition.ts';

export function useLoadJobPositionById(
  jobPositionId: JobPosition['id'] | null | undefined,
  options?: {
    enabled: boolean;
  }
) {
  const { enabled = true } = options ?? {};

  const jobPositionApi = useDi(JobPositionApi);

  const queryResult = useQuery({
    queryKey: ['load job position', jobPositionId],
    queryFn: () =>
      jobPositionApi.searchJobPositionsById([jobPositionId].filter(defined)),
    enabled: jobPositionId != null && enabled,
    staleTime: time(5, 'minutes'),
    select: mapResponse,
  });

  return {
    ...queryResult,
    data: queryResult.data?.at(0),
  };
}

const mapResponse = (
  dto: Awaited<ReturnType<JobPositionApi['searchJobPositionsById']>>
) => dto.items.map((dtoItem) => Mapper.map(TagDto, JobPosition, dtoItem));
