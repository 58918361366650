import { type FC, useState } from 'react';
import { Button } from '~/shared/molecules/Button';
import { TextField } from '~/shared/organisms/TextField';
import { useMessagesDeleteBetweenMeAndUser } from '../api/useMessagesDeleteBetweenMeAndUser.ts';
import classes from './RemoveMessagesWithUser.module.scss';

export const RemoveMessagesWithUser: FC = () => {
  const [userId, setUserId] = useState('');

  const { mutate: deleteMessages } = useMessagesDeleteBetweenMeAndUser();

  return (
    <div className={classes.root}>
      <TextField
        type="number"
        label="Delete Messages with User (user ID)"
        value={userId}
        onChangeValue={setUserId}
        fullWidth
      />

      <Button
        variant="text"
        color="primary"
        onClick={() => {
          deleteMessages(userId);
        }}
      >
        Delete
      </Button>
    </div>
  );
};
