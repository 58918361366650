import { type FakeApiDatabase } from '../../fakeApiDatabase/index.ts';
import { ApiError } from '../ApiError.ts';
import { ApiResponseStatus } from '../ApiResponseStatus.ts';
import { type QuestRealApi } from './QuestRealApi.ts';

export class QuestFakeApi {
  constructor(readonly db: FakeApiDatabase) {}

  getQuestsAsync: QuestRealApi['getQuestsAsync'] = async (payload) => {
    const currentUserId = await this.db.currentUser.get('userID');
    if (currentUserId == null) {
      throw new ApiError({
        status: ApiResponseStatus.NotAuthorized,
        error: 'user not authorized',
      });
    }

    const allQuests = await this.db.quest.getAll();
    const userQuests = allQuests.filter(
      (quest) => quest.userId === currentUserId
    );

    return {
      next: null,
      previous: null,
      results: userQuests.map((quest) => ({
        id: quest.questId,
        completeTask: quest.tasks.filter((task) => task.isComplete).length,
        taskCount: quest.tasks.length,
        mainQuest: null,
        tagsData: quest.tags.map((tag) => ({
          id: tag.tagId,
          value: tag.value,
        })),
        tasksData: quest.tasks.map((task) => ({
          id: task.taskId,
          taskData: {
            id: task.taskId,
            content: task.content,
          },
          isComplete: task.isComplete,
          priority: task.priority,
          quest: quest.questId,
          task: task.taskId,
        })),
        isActive: quest.isActive,
        isTemplate: quest.isTemplate,
        createdAt: quest.createdAt,
        startAt: quest.startAt,
        endAt: quest.endAt,
        title: quest.title,
        tasks: quest.tasks.map((task) => task.taskId),
        tags: quest.tags.map((tag) => tag.tagId),
      })),
      count: userQuests.length,
    };
  };

  getQuestByIdAsync: QuestRealApi['getQuestByIdAsync'] = async (questId) => {
    const quest = await this.db.quest.get(questId);
    if (quest == null) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: 'quest is not found',
      });
    }

    return {
      id: quest.questId,
      completeTask: quest.tasks.filter((task) => task.isComplete).length,
      taskCount: quest.tasks.length,
      mainQuest: null,
      tagsData: quest.tags.map((tag) => ({
        id: tag.tagId,
        value: tag.value,
      })),
      tasksData: quest.tasks.map((task) => ({
        id: task.taskId,
        taskData: {
          id: task.taskId,
          content: task.content,
        },
        isComplete: task.isComplete,
        priority: task.priority,
        quest: quest.questId,
        task: task.taskId,
      })),
      isActive: quest.isActive,
      isTemplate: quest.isTemplate,
      createdAt: quest.createdAt,
      startAt: quest.startAt,
      endAt: quest.endAt,
      title: quest.title,
      tasks: quest.tasks.map((task) => task.taskId),
      tags: quest.tags.map((tag) => tag.tagId),
    };
  };

  completeTaskAsync: QuestRealApi['completeTaskAsync'] = async (
    questId,
    taskId
  ) => {
    const quest = await this.db.quest.get(questId);
    if (quest == null) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: 'quest is not found',
      });
    }

    const targetTask = quest.tasks.find((task) => task.taskId == taskId);
    if (!targetTask) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: 'task is not found',
      });
    }

    targetTask.isComplete = true;
    await this.db.quest.update(quest.questId, quest);
  };

  deCompleteTaskAsync: QuestRealApi['deCompleteTaskAsync'] = async (
    questId,
    taskId
  ) => {
    const quest = await this.db.quest.get(questId);
    if (quest == null) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: 'quest is not found',
      });
    }

    const targetTask = quest.tasks.find((task) => task.taskId == taskId);
    if (!targetTask) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: 'task is not found',
      });
    }

    targetTask.isComplete = false;
    await this.db.quest.update(quest.questId, quest);
  };
}
