import { ApiBase } from '../ApiBase.ts';
import { type OtherFakeApi } from './OtherFakeApi.ts';
import type { OtherRealApi } from './OtherRealApi.ts';

export class OtherApi extends ApiBase {
  constructor(
    private realApi: OtherRealApi,
    private fakeApi: OtherFakeApi
  ) {
    super();
  }

  getVersion: OtherRealApi['getVersion'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.getVersion(...args);
    }
    return this.realApi.getVersion(...args);
  };
}
