import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { type Interest } from '~/entities/interest/index.ts';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { useStateSet } from '~/shared/useStateSet.ts';

export type ProfileInterestsEditContextType = {
  selectedInterestIds: Set<Interest['id']>;
  addInterest: (interestId: Interest['id']) => void;
  deleteInterest: (interestId: Interest['id']) => void;
};

const ProfileInterestsEditContext =
  createContext<ProfileInterestsEditContextType | null>(null);

export const ProfileInterestsEditContextProvider: FC<PropsWithChildren> = (
  props
) => {
  const { children } = props;

  const { data: profile } = useCurrentUser();

  const {
    state: selectedInterestIds,
    setState: setSelectedInterestIds,
    add: addInterest,
    remove: deleteInterest,
  } = useStateSet<Interest['id']>();

  useEffect(() => {
    if (!profile) {
      return;
    }
    setSelectedInterestIds(new Set(profile.interestIds));
  }, [profile?.interestIds]);

  const context = useMemo<ProfileInterestsEditContextType>(
    () => ({
      selectedInterestIds,
      addInterest,
      deleteInterest,
    }),
    [addInterest, deleteInterest, selectedInterestIds]
  );

  return (
    <ProfileInterestsEditContext.Provider value={context}>
      {children}
    </ProfileInterestsEditContext.Provider>
  );
};

export const useProfileInterestsEditContext = () =>
  useContext(ProfileInterestsEditContext);
