import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { invalidateCurrentUserQuery } from '~/entities/profile/index.ts';
import { ProfileApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';

export function useSetPhotoAsAvatar() {
  const queryClient = useQueryClient();
  const profileApi = useDi(ProfileApi);

  return useMutation({
    mutationKey: [],
    mutationFn: profileApi.setAvatar,
    onSuccess: () => {
      invalidateCurrentUserQuery(queryClient);
    },
    onError: (error) => {
      notify({
        message: `Cant set avatar: ${error.message}`,
        variant: 'error',
      });
    },
  });
}
