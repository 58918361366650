import { type Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';
import {
  type ContactProfile,
  useContactsQuery,
} from '~/entities/contact/index.ts';
import {
  useMeetingDraftContext,
  useMeetingQuery,
} from '~/entities/meeting/index.ts';
import {
  ProceedWithMeetingDate,
  SelectDateForMeeting,
} from '~/features/meeting/index.ts';
import { useOnBack } from '~/features/navigation/index.ts';
import { HorizontalUserList } from '~/shared/components/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { CancelButton, Navbar } from '~/shared/ui/index.ts';
import classes from './MeetingDateDisplay.module.scss';

export const MeetingDateDisplay = () => {
  const [searchParams] = useSearchParams();
  const { meetingDraft, removeDraft, newClonedDraft } =
    useMeetingDraftContext() ?? {};

  const meetingId = useMemo(() => {
    const clonedMeetingStringId = searchParams.get('clone');
    const id = parseInt(clonedMeetingStringId ?? '', 10);
    if (isNaN(id)) {
      return null;
    }
    return id;
  }, [searchParams]);

  const colleagueIds = useMemo<ContactProfile['userId'][]>(() => {
    if (meetingDraft) {
      const meetingMembers = meetingDraft.invitedMembers
        .concat(meetingDraft.acceptedMembers)
        .concat(meetingDraft.meetingOwner);
      if (meetingMembers.length > 0) {
        // use draft members in case when it is existed meeting editing and
        // prevents HorizontalUserList flashing, because of search params disappear from url
        return meetingMembers.map((member) => member.userId);
      }
    }

    const parameters = searchParams.getAll('colleagueIds');
    return parameters.map((id) => parseInt(id, 10)).filter((id) => !isNaN(id));
  }, [searchParams, meetingDraft]);

  const { data: clonedMeeting, isLoading: clonedMeetingIsLoading } =
    useMeetingQuery(meetingId);

  useEffect(() => {
    if (clonedMeeting) {
      newClonedDraft?.({ meeting: clonedMeeting });
    }
  }, [clonedMeeting, newClonedDraft]);

  const { data: contacts, isLoading: contactsAreLoading } =
    useContactsQuery(colleagueIds);

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    meetingDraft?.when ?? null
  );

  useEffect(() => {
    setSelectedDate(meetingDraft?.when ?? null);
  }, [meetingDraft?.when]);

  const onBack = useOnBack({});

  const onCancel = useCallback(() => {
    onBack();
    removeDraft?.();
  }, [onBack, removeDraft]);

  const meetingEditMatch = useMatch(routes.meetings.meetingId().edit.url());

  return (
    <>
      <Navbar
        title={meetingEditMatch ? 'Edit meeting date' : 'Schedule Meeting'}
        left={<CancelButton onClick={onCancel} />}
        classes={{
          children: classes.navbar,
        }}
        withBorderBottom
        partialLoading={contactsAreLoading || clonedMeetingIsLoading}
      >
        <HorizontalUserList users={contacts} />
      </Navbar>

      <ProceedWithMeetingDate selectedDate={selectedDate} contacts={contacts} />

      <ContentSlot className={classes.root}>
        <SelectDateForMeeting
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          kind="accepted"
          disablePast
        />
      </ContentSlot>
    </>
  );
};
