export abstract class CommonUserDto {
  abstract userId: number;
  abstract firstName: string;
  abstract lastName: string;
  abstract userAvatarPhotoFileName: string | null;
  abstract department: number | null;
  abstract jobPosition: number | null;
}

export abstract class UserForCarouselDto extends CommonUserDto {
  abstract aboutMe?: string | null;
  abstract timezoneId: string;

  abstract workInCompanyStartDate: string | null; // iso
  abstract careerStartDate: string | null; // iso

  abstract skills: number[];
  abstract interests: number[];

  abstract profilePhotoNames: string[];
}
