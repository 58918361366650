import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import {
  InterestApi,
  loadInterestsQueryKeys,
} from '~/entities/interest/index.ts';
import { notify } from '~/shared/notification/index.ts';

export function useCreateInterest() {
  const queryClient = useQueryClient();
  const interestApi = useDi(InterestApi);

  return useMutation({
    mutationKey: ['create interest'],
    mutationFn: interestApi.createInterest,
    onError: (error) => {
      notify({
        message: `Cant create interest: ${error.message}`,
        variant: 'error',
      });
    },
    onSuccess: () => {
      notify({
        message: 'Interest created',
        variant: 'success',
      });

      queryClient.invalidateQueries({
        queryKey: loadInterestsQueryKeys(),
      });
    },
  });
}
