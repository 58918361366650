import clsx from 'clsx';
import { useKeyboardOpened } from '~/entities/device/index.ts';
import { ApiDevToolsButton } from '~/features/apiDevTools/index.ts';
import {
  RegistrationContextProvider,
  RegistrationSwitchButton,
} from '~/features/auth/index.ts';
import { StyledTitle } from '~/features/auth/ui/StyledTitle.tsx';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { RegistrationForm } from '~/widgets/auth/ui/RegistrationForm.tsx';
import commonClasses from './common.module.scss';

export const RegistrationDisplay = () => {
  const keyboardOpened = useKeyboardOpened();

  return (
    <ContentSlot
      className={clsx(commonClasses.authorization, {
        [commonClasses.authorizationFocused]: keyboardOpened,
      })}
    >
      <RegistrationContextProvider>
        <Icon variant="logo_dayton" className={commonClasses.logo} />

        <StyledTitle variant="h1" className={commonClasses.title}>
          Sign Up
        </StyledTitle>

        <RegistrationForm />

        <ApiDevToolsButton className={commonClasses.health} />

        <RegistrationSwitchButton className={commonClasses.appInfo} />
      </RegistrationContextProvider>
    </ContentSlot>
  );
};
