import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { memo } from 'react';
import { type Task as TaskModel } from '~/entities/quest/index.ts';

type Props = {
  task: TaskModel;
  onTaskClick: () => void;
};

const Task = (props: Props) => {
  const { task, onTaskClick } = props;

  const labelId = `task-label-${task.id}`;

  return (
    <ListItem disablePadding>
      <ListItemButton role={undefined} onClick={onTaskClick} dense>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={task.isCompleted}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={task.description} />
      </ListItemButton>
    </ListItem>
  );
};

const memoizedComponent = memo(Task, (prevProps, nextProps) => {
  return prevProps.task === nextProps.task;
});

export { memoizedComponent as Task };
export type { Props as TaskProps };
