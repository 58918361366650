import {
  type InfiniteData,
  type UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import {
  type CSSProperties,
  type FC,
  type ForwardedRef,
  forwardRef,
  type ReactElement,
  type ReactNode,
  type RefAttributes,
  type RefCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useNavbarLoader } from '../Navbar/useNavbarLoader.ts';
import { NotFoundView } from '../NotFoundView/NotFoundView.tsx';
import classes from './InfiniteScrollRender.module.scss';

export type PagedData<RowsEntity extends {}> = {
  items: RowsEntity[];
};

export type RowProps<RowEntity extends {}> = {
  rowEntity: RowEntity | undefined;
  isLoading: boolean;
  hasNextPage: boolean;
  forwardedRef: RefCallback<HTMLElement>;
  'data-index': number;
};

export type InfiniteScrollRenderRef<RowEntity extends {}> = {
  getAllRows(): RowEntity[];
  scrollToIndex: (index: number) => void;
};

type Props<RowEntity extends {}> = {
  className?: string;
  classes?: Partial<Record<'root' | 'container' | 'virtualRowBatch', string>>;
  query: UseInfiniteQueryResult<InfiniteData<PagedData<RowEntity>>>;
  estimatedRowHeight: number;
  Row: FC<RowProps<RowEntity>>;
  emptyListMessage?: ReactNode;
};

const overscan = 5;

function InfiniteScrollRender<RowEntity extends {}>(
  props: Props<RowEntity>,
  ref: ForwardedRef<InfiniteScrollRenderRef<RowEntity>>
) {
  const {
    className,
    classes: propsClasses = {},
    query,
    estimatedRowHeight,
    Row,
    emptyListMessage = 'Nothing Found',
  } = props;

  const {
    isLoading,
    isError,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = query;

  useNavbarLoader(isLoading);

  const allRows = data?.pages.flatMap((pageRows) => pageRows.items) ?? [];

  const allRowsRef = useRef(allRows);
  allRowsRef.current = allRows;

  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => estimatedRowHeight,
    overscan: overscan,
  });

  useImperativeHandle(
    ref,
    () => ({
      getAllRows: () => allRowsRef.current,
      scrollToIndex: (index) =>
        rowVirtualizer.scrollToIndex(index, { align: 'start' }),
    }),
    [rowVirtualizer]
  );

  const virtualRows = rowVirtualizer.getVirtualItems();

  useEffect(() => {
    const lastRow = virtualRows.at(-1);
    if (!lastRow) {
      return;
    }

    console.log(
      `last virtual row index: ${lastRow.index}`,
      `hasNextPage: ${hasNextPage}`,
      `isFetchingNextPage: ${isFetchingNextPage}`
    );

    if (
      lastRow.index >= allRows.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    allRows.length,
    isFetchingNextPage,
    virtualRows,
  ]);

  if (isError) {
    return <span>Error: {(error as Error).message}</span>;
  }

  return (
    <div
      ref={parentRef}
      className={clsx(classes.root, propsClasses.root, className)}
    >
      <div
        className={clsx(classes.container, propsClasses.container)}
        style={{
          ['--virtual-container-height' as keyof CSSProperties]: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        <div
          className={clsx(
            classes.virtualRowBatch,
            propsClasses.virtualRowBatch
          )}
          style={{
            ['--virtual-y-offset' as keyof CSSProperties]: `${virtualRows.at(0)?.start ?? 0}px`,
          }}
        >
          {virtualRows.map((virtualRow) => {
            const isLoading = virtualRow.index > allRows.length - 1;
            const rowEntity = allRows[virtualRow.index];

            return (
              <Row
                key={virtualRow.index}
                data-index={virtualRow.index}
                forwardedRef={rowVirtualizer.measureElement}
                rowEntity={rowEntity}
                isLoading={isLoading}
                hasNextPage={hasNextPage}
              />
            );
          })}
        </div>
      </div>

      {allRows.length === 0 && !isLoading && (
        <NotFoundView>{emptyListMessage}</NotFoundView>
      )}
    </div>
  );
}

const componentWithRef = forwardRef(InfiniteScrollRender) as <
  RowEntity extends {},
>(
  props: Props<RowEntity> & RefAttributes<InfiniteScrollRenderRef<RowEntity>>
) => ReactElement;

export {
  componentWithRef as InfiniteScrollRender,
  type Props as InfiniteScrollRenderProps,
};
