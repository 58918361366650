import MuiIconButton, {
  type IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import { type ForwardedRef, forwardRef } from 'react';
import { Icon, type IconProps, type IconVariant } from '~/shared/atoms/icons';

type Props<Variant extends IconVariant> = Pick<
  MuiIconButtonProps,
  'onClick' | 'title' | 'size' | 'className' | 'disabled' | 'color'
> &
  Omit<IconProps<Variant>, 'onClick'>;

const IconButton = <Variant extends IconVariant>(
  props: Props<Variant>,
  ref: ForwardedRef<SVGSVGElement>
) => {
  const { onClick, title, size, className, disabled, color, ...rest } = props;

  return (
    <MuiIconButton
      onClick={onClick}
      size={size}
      className={className}
      disabled={disabled}
      color={color}
    >
      <Icon ref={ref} {...(rest as IconProps<Variant>)} />
    </MuiIconButton>
  );
};

const componentWithRef = forwardRef(IconButton) as unknown as typeof IconButton;
export { type Props as IconButtonProps, componentWithRef as IconButton };
