import { Outlet, type RouteObject } from 'react-router-dom';
import { ColleaguesFilterContextProvider } from '~/features/colleague/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { Display } from '~/shared/ui/index.ts';
import { InterestsDisplay } from '../interests';
import { AvatarsDisplay } from '../profile/AvatarsDisplay';
import { SkillsDisplay } from '../skills';
import { ColleagueProfileDisplay } from './ColleagueProfileDisplay.tsx';
import { ColleaguesCarouselDisplay } from './ColleaguesCarouselDisplay.tsx';
import { ColleaguesFiltersDisplay } from './ColleaguesFiltersDisplay.tsx';

export const colleagueRouteObjects: RouteObject[] = [
  {
    path: routes.colleagues.relativeUrl(),
    element: (
      <ColleaguesFilterContextProvider>
        <Display documentTitle="people">
          <ColleaguesCarouselDisplay />
        </Display>
        <Outlet />
      </ColleaguesFilterContextProvider>
    ),
    children: [
      {
        index: true,
        element: null,
      },
      {
        path: routes.colleagues.filters.relativeUrl(),
        element: (
          <Display
            documentTitle="people filters"
            animationVariant="card-effect"
          >
            <ColleaguesFiltersDisplay />
          </Display>
        ),
      },
      {
        path: routes.colleagues.userId().relativeUrl(),
        element: (
          <ColleagueProfileDisplay>
            <Outlet />
          </ColleagueProfileDisplay>
        ),
        children: [
          {
            index: true,
            element: null,
          },
          {
            path: routes.colleagues.userId().avatars.relativeUrl(),
            element: (
              <Display documentTitle="colleague avatars">
                <AvatarsDisplay />
              </Display>
            ),
          },
          {
            path: routes.colleagues.userId().interests.relativeUrl(),
            element: <InterestsDisplay />,
          },
          {
            path: routes.colleagues.userId().skills.relativeUrl(),
            element: <SkillsDisplay />,
          },
        ],
      },
    ],
  },
];
