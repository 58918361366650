import {
  FormControlLabel,
  type FormControlLabelProps,
  Paper,
  Radio,
} from '@mui/material';
import { Icon } from '~/shared/atoms/icons/index.ts';
import classes from './SlatRadio.module.scss';

type Props = Pick<FormControlLabelProps, 'label' | 'value'>;

export const SlatRadio = (props: Props) => {
  return (
    <Paper variant="outlined" square>
      <FormControlLabel
        sx={{ display: 'grid', marginLeft: 0, marginRight: 0 }}
        className={classes.label}
        control={
          <Radio
            checkedIcon={<Icon variant="check" color="#5295F0" />}
            icon={<Icon variant="check" color="#FFF" />}
          />
        }
        {...props}
      />
    </Paper>
  );
};
