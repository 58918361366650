import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const CalendarIcon = (props: Props) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3 4H18.3V3C18.3 2.4 17.9 2 17.3 2C16.7 2 16.3 2.4 16.3 3V4H8.30005V3C8.30005 2.4 7.90005 2 7.30005 2C6.70005 2 6.30005 2.4 6.30005 3V4H5.30005C3.60005 4 2.30005 5.3 2.30005 7V8H22.3V7C22.3 5.3 21 4 19.3 4ZM2.30005 19C2.30005 20.7 3.60005 22 5.30005 22H19.3C21 22 22.3 20.7 22.3 19V10H2.30005V19ZM17.3 12C17.9 12 18.3 12.4 18.3 13C18.3 13.6 17.9 14 17.3 14C16.7 14 16.3 13.6 16.3 13C16.3 12.4 16.7 12 17.3 12ZM17.3 16C17.9 16 18.3 16.4 18.3 17C18.3 17.6 17.9 18 17.3 18C16.7 18 16.3 17.6 16.3 17C16.3 16.4 16.7 16 17.3 16ZM12.3 12C12.9 12 13.3 12.4 13.3 13C13.3 13.6 12.9 14 12.3 14C11.7 14 11.3 13.6 11.3 13C11.3 12.4 11.7 12 12.3 12ZM12.3 16C12.9 16 13.3 16.4 13.3 17C13.3 17.6 12.9 18 12.3 18C11.7 18 11.3 17.6 11.3 17C11.3 16.4 11.7 16 12.3 16ZM7.30005 12C7.90005 12 8.30005 12.4 8.30005 13C8.30005 13.6 7.90005 14 7.30005 14C6.70005 14 6.30005 13.6 6.30005 13C6.30005 12.4 6.70005 12 7.30005 12ZM7.30005 16C7.90005 16 8.30005 16.4 8.30005 17C8.30005 17.6 7.90005 18 7.30005 18C6.70005 18 6.30005 17.6 6.30005 17C6.30005 16.4 6.70005 16 7.30005 16Z"
        fill={color}
      />
    </svg>
  );
};

export type { Props as CalendarIconProps };
export { CalendarIcon };
