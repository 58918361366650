import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { invalidateCurrentUserQuery } from '~/entities/profile/index.ts';
import { ProfileApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification';
import { useOnBack } from '../../navigation/index.ts';
import { type ProfilePersonalFormModel } from '../model/ProfilePersonalFormModel.ts';

export function useSavePersonalData(options?: {
  onSuccessCallback?: VoidFunction;
}) {
  const { onSuccessCallback } = options ?? {};

  const queryClient = useQueryClient();
  const profileApi = useDi(ProfileApi);
  const onBack = useOnBack({});

  return useMutation({
    mutationKey: ['save user profile'],
    mutationFn: (data: ProfilePersonalFormModel) => {
      const { lastName, firstName, timezone, bio } = data;

      return profileApi.updateProfileInfo({
        timezoneId: timezone ?? '',
        aboutMe: bio ?? '',
        firstName: firstName ?? '',
        lastName: lastName ?? '',
      });
    },
    onSuccess: () => {
      invalidateCurrentUserQuery(queryClient);
      if (onSuccessCallback) {
        onSuccessCallback?.();
      } else {
        onBack();
      }
    },
    onError: (error) => {
      notify({
        message: `Cant save profile: ${error.message}`,
        variant: 'error',
      });
    },
  });
}
