import { IconButton, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  type ChangeEvent,
  type KeyboardEvent,
  memo,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useDeviceInfo } from '~/entities/device/index.ts';
import { Icon } from '~/shared/atoms/icons/index.ts';
import classes from './UserChatInputBlock.module.scss';

const MAX_MESSAGE_LENGTH = 5e3;

type Props = {
  onSubmit: (value: string) => void;
  disabled: boolean;
};

const component = memo(UserChatInputBlock);
export { component as UserChatInputBlock };

function UserChatInputBlock(props: Props) {
  const { onSubmit, disabled } = props;
  const deviceInfo = useDeviceInfo();

  const [message, setMessage] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const submitMessage = useCallback(() => {
    setMessage((message) => {
      inputRef.current?.focus(); // get back focus to input to prevent keyboard hiding

      if (!message.trim()) {
        return message;
      }

      onSubmit(message);
      return '';
    });
  }, []);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      if (newValue.length <= MAX_MESSAGE_LENGTH) {
        setMessage(newValue);
      }
    },
    []
  );

  const onKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (['android', 'ios'].includes(deviceInfo.platform)) {
      return;
    }

    if (
      event.key === 'Enter' &&
      !event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey
    ) {
      submitMessage();
      event.preventDefault();
    }
  }, []);

  return (
    <div className={classes.root}>
      <TextBox
        inputRef={inputRef}
        placeholder="Message"
        multiline
        value={message}
        maxRows={4}
        onChange={onChange}
        onKeyPress={onKeyDown}
        disabled={disabled}
      />

      <PrimaryIconButton
        size="large"
        color="primary"
        onClick={submitMessage}
        disabled={disabled}
      >
        <Icon variant="chevron" direction="up" color="#fff" />
      </PrimaryIconButton>
    </div>
  );
}

const TextBox = styled(TextField)({
  '.MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: 24,
  },
});

const PrimaryIconButton = styled(IconButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    backgroundColor: theme.palette.primary.main,
  },
  '&.MuiButtonBase-root.MuiIconButton-root:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));
