import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const SearchIcon = (props: Props) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7606 18.6432L16.8075 15.6902C19.2817 12.5775 18.8028 8.0282 15.6902 5.55403C12.5775 3.07986 8.0282 3.63854 5.55403 6.6714C3.07986 9.78407 3.63854 14.3333 6.6714 16.8075C9.30519 18.8826 13.0564 18.8826 15.6902 16.8075L18.6432 19.7606C18.9624 20.0798 19.4413 20.0798 19.7606 19.7606C20.0798 19.4413 20.0798 18.9624 19.7606 18.6432ZM11.2207 16.8075C8.10801 16.8075 5.63384 14.3333 5.63384 11.2207C5.63384 8.10801 8.10801 5.63384 11.2207 5.63384C14.3333 5.63384 16.8075 8.10801 16.8075 11.2207C16.8075 14.3333 14.3333 16.8075 11.2207 16.8075Z"
        fill={color}
      />
    </svg>
  );
};

export type { Props as SearchIconProps };
export { SearchIcon };
