import { createLoaderStore, type Loader } from '../../createLoaderStore.ts';

const {
  useLoaderStore: useNavbarLoaderStore,
  useActiveLoader: useActiveNavbarLoader,
  addLoader: addNavbarLoader,
  removeLoader: removeNavbarLoader,
} = createLoaderStore();

export {
  useNavbarLoaderStore,
  useActiveNavbarLoader,
  addNavbarLoader,
  removeNavbarLoader,
  type Loader,
};
