import { Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { type CSSProperties, type ForwardedRef, forwardRef } from 'react';
import { Icon } from '~/shared/atoms/icons/index.ts';
import type { Message } from '../model/Message.ts';
import type { SentMessage } from '../model/SentMessage.ts';
import classes from './OutgoingMessage.module.scss';

type Props = {
  message: SentMessage | Message | undefined;
  loading?: boolean;
  className?: string;
  style?: CSSProperties;
};

const component = forwardRef(OutgoingMessage);
export { component as OutgoingMessage };

function OutgoingMessage(props: Props, ref: ForwardedRef<HTMLDivElement>) {
  const { message, className, style, loading, ...rest } = props;

  return (
    <div className={clsx(classes.row, className)} style={style} {...rest}>
      {(loading || !message) && (
        <Skeleton
          className={clsx(classes.message, classes.skeleton)}
          variant="rectangular"
          height={40}
          width={120}
        />
      )}

      {!loading && message && (
        <div className={classes.message}>
          <StyledText variant="body1">{message.content}</StyledText>

          <div className={classes.info}>
            <Typography
              variant="caption"
              component="span"
              className={classes.time}
            >
              {message.date.format('HH:mm')}
            </Typography>

            {message.status === 'sent' ? (
              <Icon variant="clocks" fontSize="small" />
            ) : message.status === 'received' ? (
              <Icon variant="done" fontSize="small" />
            ) : (
              <Icon variant="doubleDone" fontSize="small" />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const StyledText = styled(Typography)({
  '&.MuiTypography-root': {
    lineHeight: '20px',
    whiteSpace: 'pre-wrap',
  },
});
