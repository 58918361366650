import { type FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { type Quest, useLoadQuestsQuery } from '~/entities/quest/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { FooterTabs } from '~/shared/templates/FooterTabs.tsx';
import { Display, Navbar, NotFoundView } from '~/shared/ui/index.ts';
import { QuestRow } from './QuestRow/index.ts';
import classes from './QuestsDisplay.module.scss';

export const QuestsDisplay: FC = () => {
  const { data: quests, isLoading: questsAreLoading } = useLoadQuestsQuery();
  const navigate = useNavigate();

  const navigateToQuest = useCallback(
    (quest: Quest) => {
      navigate(routes.quests.questId(quest.id.toString()).relativeUrl());
    },
    [navigate]
  );

  return (
    <Display documentTitle="Quests">
      <Navbar
        title="Квесты"
        className={classes.gridNavbar}
        partialLoading={questsAreLoading}
      />

      {!quests?.length && (
        <ContentSlot>
          <NotFoundView>You Have No Quests</NotFoundView>
        </ContentSlot>
      )}

      {quests && quests.length > 0 && (
        <ContentSlot className={classes.fullHeight}>
          <div className={classes.gridContent}>
            {quests.map((quest) => (
              <QuestRow
                key={quest.id}
                quest={quest}
                navigateToQuest={navigateToQuest}
              />
            ))}
          </div>
        </ContentSlot>
      )}

      <FooterTabs />
    </Display>
  );
};
