import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { SkillApi, TagDto } from '~/shared/api/index.ts';
import { time } from '~/utils/index.ts';
import { Skill } from '../model/Skill.ts';

export const loadSkillsQueryKeys = (skillName?: Skill['name']) => [
  'load skills',
  { skillName },
];

export function useLoadSkills(skillName?: Skill['name']) {
  const skillApi = useDi(SkillApi);

  return useQuery({
    queryKey: loadSkillsQueryKeys(skillName),
    queryFn: () => skillApi.searchSkills({ skillName }),
    staleTime: time(5, 'minutes'),
    select: mapResponse,
  });
}

const mapResponse = (dto: Awaited<ReturnType<SkillApi['searchSkills']>>) => ({
  totalCount: dto.totalCount,
  interests: dto.items.map((dtoItem) => Mapper.map(TagDto, Skill, dtoItem)),
});
