import React from 'react';
import { Icon } from '~/shared/atoms/icons';
import { ContentSlot } from '~/shared/organisms/slots';
import classes from './Sandbox.module.scss';

const Sandbox = () => {
  return (
    <ContentSlot>
      <div className={classes.icons}>
        <Icon variant="approve" />
        <Icon variant="addPerson" />
        <Icon variant="addPersonOutline" />
        <Icon variant="blockList" />
        <Icon variant="book" />
        <Icon variant="bookSource" />
        <Icon variant="calendar" />
        <Icon variant="calendarCheck" />
        <Icon variant="chat" />
        <Icon variant="check" />
        <Icon variant="chevron" direction="left" />
        <Icon variant="chevron" direction="right" />
        <Icon variant="clockFilled" />
        <Icon variant="clocks" />
        <Icon variant="cross" />
        <Icon variant="logo" />
        <Icon variant="logout" />
        <Icon variant="more" />
        <Icon variant="network" />
        <Icon variant="networkFilled" />
        <Icon variant="notification" />
        <Icon variant="position" />
        <Icon variant="positionFilled" />
        <Icon variant="profileFilled" />
        <Icon variant="profileOutlined" />
        <Icon variant="removePerson" />
        <Icon variant="search" />
        <Icon variant="notFound" />
        <Icon variant="settings" />
        <Icon variant="smile" />
        <Icon variant="study" />
        <Icon variant="users" />
      </div>
    </ContentSlot>
  );
};

export { Sandbox };
