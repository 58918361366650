import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const AddPersonIcon = (props: Props) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 11.5015C12.791 11.5015 13.5644 11.2669 14.2222 10.8273C14.88 10.3878 15.3927 9.76311 15.6954 9.0322C15.9982 8.3013 16.0774 7.49703 15.923 6.72111C15.7687 5.94518 15.3877 5.23245 14.8283 4.67304C14.2689 4.11363 13.5562 3.73267 12.7802 3.57833C12.0043 3.42398 11.2001 3.5032 10.4692 3.80595C9.73825 4.1087 9.11353 4.62139 8.67401 5.27919C8.23448 5.93698 7.99989 6.71034 7.99989 7.50147C7.99989 8.56233 8.42131 9.57975 9.17146 10.3299C9.9216 11.08 10.939 11.5015 11.9999 11.5015ZM11.9999 13.0015C10.2077 13.0016 8.47465 13.6434 7.1146 14.8107C5.75456 15.9779 4.85738 17.5936 4.58546 19.3651C4.5662 19.5071 4.57764 19.6516 4.619 19.7888C4.66036 19.9261 4.73068 20.0528 4.82521 20.1605C4.91975 20.2682 5.03629 20.3544 5.16698 20.4132C5.29767 20.472 5.43945 20.5021 5.58277 20.5015H18.4172C18.5605 20.5021 18.7023 20.472 18.833 20.4132C18.9636 20.3544 19.0802 20.2682 19.1747 20.1605C19.2692 20.0528 19.3396 19.926 19.3809 19.7888C19.4223 19.6516 19.4337 19.5071 19.4144 19.3651C19.1425 17.5936 18.2453 15.9779 16.8853 14.8107C15.5252 13.6434 13.7922 13.0016 11.9999 13.0015ZM23 10.501H22V9.50101C22 9.23579 21.8946 8.98144 21.7071 8.7939C21.5196 8.60636 21.2652 8.50101 21 8.50101C20.7348 8.50101 20.4804 8.60636 20.2929 8.7939C20.1054 8.98144 20 9.23579 20 9.50101V10.501H19C18.7348 10.501 18.4804 10.6064 18.2929 10.7939C18.1054 10.9814 18 11.2358 18 11.501C18 11.7662 18.1054 12.0206 18.2929 12.2081C18.4804 12.3956 18.7348 12.501 19 12.501H20V13.501C20 13.7662 20.1054 14.0206 20.2929 14.2081C20.4804 14.3956 20.7348 14.501 21 14.501C21.2652 14.501 21.5196 14.3956 21.7071 14.2081C21.8946 14.0206 22 13.7662 22 13.501V12.501H23C23.2652 12.501 23.5196 12.3956 23.7071 12.2081C23.8946 12.0206 24 11.7662 24 11.501C24 11.2358 23.8946 10.9814 23.7071 10.7939C23.5196 10.6064 23.2652 10.501 23 10.501Z"
        fill={color}
      />
    </svg>
  );
};

export type { Props as AddPersonIconProps };
export { AddPersonIcon };
