import { MapFunction } from '@tomas-light/mapper-js';
import { QuestDto, QuestsTaskDto } from '~/shared/api/index.ts';
import { Quest } from './Quest.ts';
import { Task } from './Task.ts';

export const questMappingProfile = [
  new MapFunction(QuestDto, Quest, mapQuestsDtoToQuest),
  new MapFunction(QuestsTaskDto, Task, mapQuestsTaskDtoToTask),
];

function mapQuestsDtoToQuest(dto: QuestDto): Quest {
  return {
    id: dto.id,
    title: dto.title,
    isActive: dto.isActive,
    isTemplate: dto.isTemplate,
    createdAt: new Date(dto.createdAt),
    startAt: new Date(dto.startAt),
    endAt: new Date(dto.endAt),
    taskCounts: {
      completed: dto.completeTask,
      total: dto.taskCount,
    },
    tasks: dto.tasksData.map((taskDto) => {
      const task = mapQuestsTaskDtoToTask(taskDto);
      const indexOfTask = dto.tasks.indexOf(taskDto.task);
      if (indexOfTask > -1) {
        task.order = indexOfTask + 1;
      } else {
        console.error(
          `Couldn't find task (${task.id}) order in quest (${
            dto.id
          } - ${JSON.stringify(dto.tasks)})`
        );
      }

      return task;
    }),
  };
}

function mapQuestsTaskDtoToTask(dto: QuestsTaskDto): Task {
  return {
    id: dto.task,
    isCompleted: dto.isComplete,
    description: dto.taskData.content,
    order: 0,
  };
}
