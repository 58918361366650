import { Typography } from '@mui/material';
import { type FC } from 'react';
import { useNavbarLoader } from '~/shared/ui/index.ts';
import { useConnectTonWalletMutation } from '../api/useConnectTonWalletMutation.ts';
import classes from './ConnectTonWalletButton.module.scss';

type Props = {};

export const ConnectTonWalletButton: FC<Props> = (props) => {
  const {} = props;

  const { mutate: connectWallet, isPending } = useConnectTonWalletMutation();
  useNavbarLoader(isPending);

  return (
    <button className={classes.root} onClick={() => connectWallet()}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <circle cx="20" cy="20" r="20" fill="#0088CC" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8505 13.2C13.5676 13.2 12.25 14 12 15C11.5754 16.6983 17.0353 24.6709 18.7857 27.2269C19.0967 27.681 19.2906 27.9641 19.3135 28.0106C19.6789 28.6399 20.5594 28.6727 20.9784 28.109C21.0104 28.0659 21.035 28.0222 21.0603 27.9773C21.0718 27.9569 21.0834 27.9362 21.0959 27.9152C21.1858 27.7649 21.385 27.4492 21.6614 27.0112C23.3973 24.2603 28.176 16.6872 28 15C27.8956 14 26.5002 13.2 26.2091 13.2H13.8505ZM20.8 15H25.2034C25.9872 15 26.4663 15.8608 26.0533 16.5269L21.0509 24.5953C20.979 24.7113 20.8 24.6603 20.8 24.5238V15ZM14.7966 15H19.2V24.5238C19.2 24.6603 19.021 24.7113 18.9491 24.5953L13.9467 16.5269C13.5337 15.8608 14.0128 15 14.7966 15Z"
          fill="white"
        />
      </svg>
      <Typography>Connect TON Wallet</Typography>
    </button>
  );
};
