import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import {
  invalidateCurrentUserQuery,
  type MyProfile,
} from '~/entities/profile/index.ts';
import { ProfileApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification';
import { useOnBack } from '../../navigation/index.ts';

export type CareerSavingData = Pick<
  MyProfile,
  'departmentId' | 'jobPositionId' | 'workExperienceSince' | 'joinedCompanyDate'
>;

export function useSaveCareer() {
  const queryClient = useQueryClient();
  const profileApi = useDi(ProfileApi);
  const onBack = useOnBack({});

  return useMutation({
    mutationKey: ['save user career'],
    mutationFn: (data: CareerSavingData) => {
      const {
        departmentId,
        jobPositionId,
        joinedCompanyDate,
        workExperienceSince,
      } = data;

      return profileApi.updateProfileCareer({
        departmentTagId: departmentId,
        jobPositionTagId: jobPositionId,
        workInCompanyStartDate: joinedCompanyDate?.toISOString() ?? null,
        careerStartDate: workExperienceSince?.toISOString() ?? null,
      });
    },
    onSuccess: () => {
      invalidateCurrentUserQuery(queryClient);
      onBack();
    },
    onError: (error) => {
      notify({
        message: `Cant save career: ${error.message}`,
        variant: 'error',
      });
    },
  });
}
