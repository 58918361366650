import { type FC, type PropsWithChildren, useState } from 'react';
import { type MyProfile, useCurrentUser } from '~/entities/profile/index.ts';
import { CancelNavigationButton } from '~/features/navigation/index.ts';
import { EditUserPositionField } from '~/features/position/ui/EditUserPositionField.tsx';
import {
  EditCareerStartDateField,
  EditJoinCompanyDateField,
  EditUserCompanyField,
  ProfileCareerEditContextProvider,
  SaveCareerButton,
} from '~/features/profile/index.ts';
import { ContentSlot } from '~/shared/organisms/slots';
import { Display, Navbar, useDisplayLoader } from '~/shared/ui/index.ts';
import classes from './EditProfileCareerDisplay.module.scss';

const EditProfileCareerDisplay: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const {
    data: profile,
    isLoading: profileIsLoading,
    error,
  } = useCurrentUser();

  useDisplayLoader(profileIsLoading);

  const [joinedCompanyDate, setJoinedCompanyDate] =
    useState<MyProfile['joinedCompanyDate']>(null);

  const [workExperienceSince, setWorkExperienceSince] =
    useState<MyProfile['workExperienceSince']>(null);

  return (
    <ProfileCareerEditContextProvider>
      <Display
        documentTitle="edit profile career"
        animationVariant="card-effect"
      >
        <Navbar
          title="Career"
          left={<CancelNavigationButton />}
          right={
            <SaveCareerButton
              joinedCompanyDate={joinedCompanyDate}
              workExperienceSince={workExperienceSince}
            />
          }
        />

        <ContentSlot className={classes.root}>
          {error && <p>Ваш профиль или пользователь не существует</p>}

          <EditUserCompanyField />
          <EditUserPositionField />

          <EditJoinCompanyDateField
            joinedCompanyDate={
              joinedCompanyDate ?? profile?.joinedCompanyDate ?? null
            }
            onChange={setJoinedCompanyDate}
          />

          <EditCareerStartDateField
            workExperienceSince={
              workExperienceSince ?? profile?.workExperienceSince ?? null
            }
            onChange={setWorkExperienceSince}
          />
        </ContentSlot>
      </Display>

      {children}
    </ProfileCareerEditContextProvider>
  );
};

export { EditProfileCareerDisplay };
