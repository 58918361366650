import { Box, Tab as MuiTab, Tabs as MuiTabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React, { memo, type SyntheticEvent } from 'react';
import classes from './Tabs.module.scss';

type Tab = {
  label: string;
  counter?: number;
};

type Props = {
  tabs: Tab[];
  activeTabIndex: number;
  onChangeActiveTab: (event: SyntheticEvent, tabIndex: number) => void;
  className?: string;
};

function TabsWithCounters(props: Props) {
  const { tabs, activeTabIndex, onChangeActiveTab, className } = props;

  return (
    <Box className={className}>
      <StyledTabs
        value={activeTabIndex}
        onChange={onChangeActiveTab}
        variant="scrollable"
        scrollButtons={false}
        aria-label="tabs"
      >
        {tabs.map((tab, index) => (
          <MuiTab
            key={tab.label}
            label={
              <Typography>
                {tab.label}

                {typeof tab.counter !== 'number' ? null : (
                  <Typography
                    component="span"
                    className={clsx(classes.counter, {
                      [classes.selected]: index === activeTabIndex,
                    })}
                  >
                    {tab.counter}
                  </Typography>
                )}
              </Typography>
            }
          />
        ))}
      </StyledTabs>
    </Box>
  );
}

const StyledTabs = styled(MuiTabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    height: '100%',
    opacity: '0.1',
    borderRadius: '32px',
  },
  '& .MuiTouchRipple-root': {
    borderRadius: '32px',
  },
  '&.MuiTabs-root': {
    minHeight: 'unset',
  },
  '& .MuiTab-root': {
    textTransform: 'none',
    color: '#868E96', // gray
    padding: '10px 16px',
    minHeight: 'unset',
  },
  '& .MuiTab-root .MuiTypography-root': {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
  },
  '& .MuiTab-root.Mui-selected': {
    color: '#000', // black
  },
}));

const memoizedComponent = memo(TabsWithCounters);
export { memoizedComponent as Tabs };
export type { Tab };
