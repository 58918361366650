import List from '@mui/material/List';
import { type Contact, ContactItem, type ContactItemProps } from './ContactItem.tsx';

type Props<TContact extends Contact> = Omit<
  ContactItemProps<TContact>,
  'contact' | 'checked'
> & {
  contacts: undefined | TContact[];
  selectedContacts: Set<TContact['userId']>;
};

export function ContactsList<TContact extends Contact>(props: Props<TContact>) {
  const { contacts, selectedContacts, ...contactItemProps } = props;

  return (
    <List>
      {contacts?.map((contact) => (
        <ContactItem
          key={contact.userId}
          contact={contact}
          checked={selectedContacts.has(contact.userId)}
          {...contactItemProps}
        />
      ))}
    </List>
  );
}
