import { type CommonIconProps } from './CommonProps';

type Props = Omit<CommonIconProps, 'color'>;

export const CoinIcon = (props: Props) => {
  const { height = 24, ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_27_278)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#FFD100"
        />
        <path
          d="M22.424 17.9263C21.6425 19.2985 20.5983 20.5033 19.3512 21.472C18.104 22.4406 16.6783 23.1541 15.1554 23.5718C13.6325 23.9895 12.0422 24.1031 10.4754 23.9061C8.90856 23.7092 7.39587 23.2056 6.02368 22.4241C4.65149 21.6425 3.44667 20.5984 2.47801 19.3512C1.50935 18.1041 0.795817 16.6783 0.378159 15.1554C-0.0394993 13.6325 -0.153108 12.0422 0.0438207 10.4754C0.240749 8.90861 0.744357 7.39592 1.52589 6.02373L11.975 11.975L22.424 17.9263Z"
          fill="#FF9500"
        />
        <path
          d="M11.9961 19.1C9.1744 19.1 7.44995 17.4695 7.44995 14.5453C7.44995 11.6997 9.2215 10.0064 11.9961 10.0064C14.7863 10.0064 16.55 11.6839 16.55 14.5453C16.55 17.4774 14.8255 19.1 11.9961 19.1ZM11.9961 16.803C12.7955 16.803 13.2892 16.0035 13.2892 14.5532C13.2892 13.1421 12.7876 12.3033 11.9961 12.3033C11.2123 12.3033 10.7107 13.1421 10.7107 14.5532C10.7107 16.0035 11.1887 16.803 11.9961 16.803Z"
          fill="white"
        />
        <path
          d="M14.1907 7.41125C13.9566 8.74157 13.2283 10.6805 12.7649 10.599C12.3013 10.5173 12.2781 8.44621 12.5121 7.11589C12.746 5.78557 13.3115 4.77308 13.7749 4.85457C14.2385 4.93606 14.4245 6.0807 14.1907 7.41125Z"
          fill="white"
        />
        <path
          d="M9.96636 7.41125C10.2004 8.74157 10.9288 10.6805 11.3922 10.599C11.8558 10.5173 11.879 8.44621 11.6449 7.11589C11.411 5.78557 10.8456 4.77308 10.3819 4.85457C9.91853 4.93606 9.73251 6.0807 9.96636 7.41125Z"
          fill="white"
        />
        <path
          d="M12.4273 13.4872C12.4273 13.6949 12.2587 13.8633 12.0509 13.8633C11.8431 13.8633 11.6748 13.6949 11.6748 13.4872C11.6748 13.2793 11.8431 13.1108 12.0509 13.1108C12.2587 13.1108 12.4273 13.2793 12.4273 13.4872Z"
          fill="white"
        />
        <mask
          id="mask0_27_278"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="-1"
          y="6"
          width="24"
          height="19"
        >
          <path
            d="M22.424 17.9763C21.6425 19.3485 20.5983 20.5533 19.3512 21.5219C18.104 22.4906 16.6783 23.2041 15.1554 23.6218C13.6325 24.0394 12.0422 24.1531 10.4754 23.9561C8.90856 23.7592 7.39587 23.2556 6.02368 22.4741C4.65149 21.6925 3.44667 20.6484 2.47801 19.4012C1.50935 18.1541 0.795817 16.7283 0.378159 15.2054C-0.0394993 13.6825 -0.153108 12.0922 0.0438207 10.5254C0.240749 8.9586 0.744357 7.44591 1.52589 6.07372L11.975 12.025L22.424 17.9763Z"
            fill="#FF9500"
          />
        </mask>
        <g mask="url(#mask0_27_278)">
          <path
            d="M11.9961 19.1C9.1744 19.1 7.44995 17.4695 7.44995 14.5453C7.44995 11.6997 9.2215 10.0064 11.9961 10.0064C14.7863 10.0064 16.55 11.6839 16.55 14.5453C16.55 17.4774 14.8255 19.1 11.9961 19.1ZM11.9961 16.803C12.7955 16.803 13.2892 16.0035 13.2892 14.5532C13.2892 13.1421 12.7876 12.3033 11.9961 12.3033C11.2123 12.3033 10.7107 13.1421 10.7107 14.5532C10.7107 16.0035 11.1887 16.803 11.9961 16.803Z"
            fill="#FFD100"
          />
          <path
            d="M14.1907 7.41125C13.9566 8.74157 13.2283 10.6805 12.7649 10.599C12.3013 10.5173 12.2781 8.44621 12.5121 7.11589C12.746 5.78557 13.3115 4.77308 13.7749 4.85457C14.2385 4.93606 14.4245 6.0807 14.1907 7.41125Z"
            fill="#FFD100"
          />
          <path
            d="M9.96636 7.41125C10.2004 8.74157 10.9288 10.6805 11.3922 10.599C11.8558 10.5173 11.879 8.44621 11.6449 7.11589C11.411 5.78557 10.8456 4.77308 10.3819 4.85457C9.91853 4.93606 9.73251 6.0807 9.96636 7.41125Z"
            fill="#FFD100"
          />
          <path
            d="M12.4273 13.4872C12.4273 13.6949 12.2587 13.8633 12.0509 13.8633C11.8431 13.8633 11.6748 13.6949 11.6748 13.4872C11.6748 13.2793 11.8431 13.1108 12.0509 13.1108C12.2587 13.1108 12.4273 13.2793 12.4273 13.4872Z"
            fill="#FFD100"
          />
        </g>
        <path
          d="M12 22.4C6.26523 22.4 1.59998 17.7345 1.59998 12C1.59998 6.26543 6.26523 1.59998 12 1.59998C17.7347 1.59998 22.4 6.26543 22.4 12C22.4 17.7345 17.7347 22.4 12 22.4ZM12 2.39998C6.70663 2.39998 2.39998 6.70663 2.39998 12C2.39998 17.2933 6.70663 21.6 12 21.6C17.2933 21.6 21.6 17.2933 21.6 12C21.6 6.70663 17.2933 2.39998 12 2.39998Z"
          fill="white"
        />
        <path
          d="M20.9903 17.1597C20.316 18.3436 19.4151 19.3831 18.3391 20.2188C17.2631 21.0546 16.0329 21.6702 14.719 22.0306C13.4051 22.3909 12.033 22.4889 10.6812 22.319C9.32934 22.1491 8.02421 21.7146 6.8403 21.0403C5.65639 20.366 4.61689 19.4652 3.78115 18.3891C2.9454 17.3131 2.32978 16.083 1.96943 14.769C1.60908 13.4551 1.51106 12.083 1.68096 10.7312C1.85087 9.37938 2.28538 8.07425 2.95967 6.89034L3.66429 7.29165C3.04269 8.38303 2.64214 9.58615 2.48552 10.8323C2.32889 12.0785 2.41925 13.3433 2.75143 14.5546C3.08362 15.7658 3.65113 16.8998 4.42155 17.8917C5.19198 18.8837 6.15024 19.7141 7.24161 20.3357C8.33299 20.9573 9.53611 21.3579 10.7823 21.5145C12.0285 21.6711 13.2933 21.5808 14.5045 21.2486C15.7158 20.9164 16.8498 20.3489 17.8417 19.5784C18.8336 18.808 19.6641 17.8498 20.2857 16.7584L20.9903 17.1597Z"
          fill="#FFD100"
        />
      </g>
      <defs>
        <clipPath id="clip0_27_278">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
