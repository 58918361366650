import { useMutation } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { TelegramApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';

export function useConnectTonWalletMutation() {
  const telegramApi = useDi(TelegramApi);

  return useMutation({
    mutationKey: ['connect ton wallet'],
    mutationFn: telegramApi.connectTonWallet,
    onSuccess: (redirectUrl) => {
      window.location.href = redirectUrl;
    },
    onError: (error) => {
      notify({
        message: `Cannot connect wallet: ${error.message}`,
        variant: 'error',
      });
    },
  });
}
