import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import {
  loadQuestQueryKeys,
  loadQuestsQueryKeys,
 type Quest } from '~/entities/quest/index.ts';
import { QuestApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';

export function useCompleteQuestTaskMutation(questId: Quest['id']) {
  const queryClient = useQueryClient();
  const questApi = useDi(QuestApi);

  return useMutation({
    mutationKey: ['complete task'],
    mutationFn: async (taskId: number) => {
      await questApi.completeTaskAsync(questId, taskId);
    },
    onError: (error) => {
      notify({
        message: `Cant complete task in quest #${questId}: ${error.message}`,
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: loadQuestsQueryKeys(),
      });
      queryClient.invalidateQueries({
        queryKey: loadQuestQueryKeys(questId),
      });
    },
  });
}
