import { type FakeApiDatabase } from '../fakeApiDatabase/index.ts';
import { ApiError } from './ApiError.ts';
import { ApiResponseStatus } from './ApiResponseStatus.ts';

export async function findCurrentUser(db: FakeApiDatabase) {
  const currentUserId = await db.currentUser.get('userID');
  if (currentUserId == null) {
    throw new ApiError({
      status: ApiResponseStatus.NotAuthorized,
      error: 'current user id is not found',
    });
  }

  const currentUser = await db.user.get(currentUserId);
  if (!currentUser) {
    throw new ApiError({
      status: ApiResponseStatus.NotFound,
      error: 'current user is not found',
    });
  }

  return currentUser;
}
