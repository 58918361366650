import { ApiBase } from '../ApiBase.ts';
import { type MeetingRealApi } from './MeetingRealApi.ts';
import { type MeetingsFakeApi } from './MeetingsFakeApi.ts';

export class MeetingApi extends ApiBase {
  constructor(
    private realApi: MeetingRealApi,
    private fakeApi: MeetingsFakeApi
  ) {
    super();
  }

  getMeetings: MeetingRealApi['getMeetings'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.getMeetings(...args);
    }
    return this.realApi.getMeetings(...args);
  };

  getMeetingById: MeetingRealApi['getMeetingById'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.getMeetingById(...args);
    }
    return this.realApi.getMeetingById(...args);
  };

  leaveMeeting: MeetingRealApi['leaveMeeting'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.leaveMeeting(...args);
    }
    return this.realApi.leaveMeeting(...args);
  };

  acceptMeeting: MeetingRealApi['acceptMeeting'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.acceptMeeting(...args);
    }
    return this.realApi.acceptMeeting(...args);
  };

  rejectMeeting: MeetingRealApi['rejectMeeting'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.rejectMeeting(...args);
    }
    return this.realApi.rejectMeeting(...args);
  };

  setMeetingReminder: MeetingRealApi['setMeetingReminder'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.setMeetingReminder(...args);
    }
    return this.realApi.setMeetingReminder(...args);
  };

  createMeeting: MeetingRealApi['createMeeting'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.createMeeting(...args);
    }
    return this.realApi.createMeeting(...args);
  };

  updateMeeting: MeetingRealApi['updateMeeting'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.updateMeeting(...args);
    }
    return this.realApi.updateMeeting(...args);
  };

  cancelMeeting: MeetingRealApi['cancelMeeting'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.cancelMeeting(...args);
    }
    return this.realApi.cancelMeeting(...args);
  };

  kickUserFromMeeting: MeetingRealApi['kickUserFromMeeting'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.kickUserFromMeeting(...args);
    }
    return this.realApi.kickUserFromMeeting(...args);
  };

  setMeetingOwner: MeetingRealApi['setMeetingOwner'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.setMeetingOwner(...args);
    }
    return this.realApi.setMeetingOwner(...args);
  };
}
