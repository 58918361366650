import { Photo } from '~/shared/atoms/Photo';
import { useAvatarTouch } from './useAvatarTouch';
import classes from './SwipeAblePhotos.module.scss';

type Props = {
  activePhotoUrl: string | null | undefined;
  setPhotoIndex: (stateUpdate: (photoIndex: number) => number) => void;
};

export const SwipeAblePhotos = (props: Props) => {
  const { activePhotoUrl, setPhotoIndex } = props;

  const { containerRef, onTouchStart, onTouchMove, onTouchEnd, translateX } =
    useAvatarTouch((translateX) => {
      setPhotoIndex((photoIndex) => {
        if (translateX > 0) {
          return photoIndex - 1;
        } else if (translateX < 0) {
          return photoIndex + 1;
        }
        return photoIndex;
      });
    });

  return (
    <div
      ref={containerRef}
      className={classes.roulette}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      style={{
        transform: `translateX(${translateX}px)`,
      }}
    >
      <Photo avatarUrl={activePhotoUrl} scale="contain" loader="spinner" />
    </div>
  );
};
