/** returns number of specified time in milliseconds
 * @example
 * time(5, 'seconds') // 5 * 1000
 * @example
 * time(5, 'minutes') // 5 * 60 * 1000
 * */
export function time(number: number, dimension: 'seconds' | 'minutes') {
  const seconds = number * 1e3;
  if (dimension === 'seconds') {
    return seconds;
  }

  const minutes = seconds * 60;
  if (dimension === 'minutes') {
    return minutes;
  }

  return number;
}
