import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMeetingDraftContext } from '~/entities/meeting/index.ts';
import { MeetingDates } from '~/entities/meeting/ui/MeetingDates.tsx';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { Button } from '~/shared/molecules/Button/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { routes } from '~/shared/routing/index.ts';
import classes from './MeetingCreatedDisplay.module.scss';

const MeetingCreatedDisplay = () => {
  const navigate = useNavigate();

  const { meetingDraft, removeDraft } = useMeetingDraftContext() ?? {};

  const endDate = useMemo(() => {
    if (!meetingDraft || !meetingDraft.howLong) {
      return null;
    }
    return meetingDraft.when.add(meetingDraft.howLong);
  }, [meetingDraft?.when, meetingDraft?.howLong]);

  if (!meetingDraft) {
    return <Navigate to={routes.meetings.url()} replace={true} />;
  }

  return (
    <ContentSlot className={classes.root}>
      <Icon variant="calendarCheck" />

      <div className={classes.textContainer}>
        <Title variant="h1">Meeting Scheduled</Title>

        <div>
          <Message variant="body2">You Scheduled the Meeting for:</Message>
          <MeetingDates
            startDate={meetingDraft?.when ?? dayjs()}
            endDate={endDate}
          />
        </div>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          removeDraft?.();
          navigate(routes.meetings.url());
        }}
        className={classes.button}
      >
        Done
      </Button>
    </ContentSlot>
  );
};

const Title = styled(Typography)({
  '&.MuiTypography-root': {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '30px',

    /* gray/gray-1 */
    color: '#212529',
  },
});
const Message = styled(Typography)({
  '&.MuiTypography-root': {
    textAlign: 'center',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',

    /* gray/gray-3 */
    color: '#495057',
  },
});

export { MeetingCreatedDisplay };
