import { Typography } from '@mui/material';
import dayjs, { type Dayjs } from 'dayjs';
import { memo } from 'react';

type Props = {
  date: Dayjs;
};

const DialogDate = (props: Props) => {
  const { date } = props;

  const now = dayjs();
  const isSameDay = date.startOf('day').isSame(now.startOf('day'));
  if (isSameDay) {
    return <Typography>{date.format('HH:mm')}</Typography>;
  }

  const isSameWeek = date.startOf('week').isSame(now.startOf('week'));
  if (isSameWeek) {
    // Mon / Tue / Wed ...
    return <Typography>{date.format('ddd')}</Typography>;
  }

  const isSameYear = date.startOf('year').isSame(now.startOf('year'));
  if (isSameYear) {
    // 12 Dec
    return <Typography>{date.format('DD MMM')}</Typography>;
  }

  return <Typography>{date.format('DD/MM/YYYY')}</Typography>;
};

const memoizedComponent = memo(DialogDate);

export { memoizedComponent as DialogDate };
export type { Props as MeetingDatesProps };
