import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAccount = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    // color: theme.palette.text.secondary, // todo: doesnt work ?
    color: '#868E96',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
  },
}));
