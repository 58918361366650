import { type FC, useState } from 'react';
import { type Meeting } from '~/entities/meeting/index.ts';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { notify } from '~/shared/notification/index.ts';
import { DestructionDialog } from '~/shared/templates/DestructionDialog/index.ts';
import { FooterButton } from '~/shared/templates/FooterButton/index.ts';
import { useNavbarLoader } from '~/shared/ui/index.ts';
import { useOnBack } from '../../navigation/index.ts';
import { useLeaveMeetingMutation } from '../api/useLeaveMeetingMutation.ts';

type Props = {
  meeting: Meeting | undefined;
};

export const LeaveMeetingFooterButton: FC<Props> = (props) => {
  const { meeting } = props;

  const [showDestructionDialog, setShowDestructionDialog] = useState(false);

  const onBack = useOnBack({});

  const { data: profile, isLoading: profileIsLoading } = useCurrentUser();

  const { mutate: leaveMeeting, isPending: isMeetingLeaving } =
    useLeaveMeetingMutation({
      onSuccessCallback: onBack,
    });

  useNavbarLoader(profileIsLoading || isMeetingLeaving);

  return (
    <>
      <FooterButton
        color="error"
        onClick={() => {
          setShowDestructionDialog(true);
        }}
      >
        Leave Meeting
      </FooterButton>

      <DestructionDialog
        open={showDestructionDialog}
        title="Are you sure?"
        cancelButtonText="Stay in"
        confirmButtonText="Leave"
        onCancelOrClose={() => {
          setShowDestructionDialog(false);
        }}
        onConfirm={() => {
          if (meeting) {
            if (profile?.userId === meeting.meetingOwner.userId) {
              notify({
                message:
                  'Владелец не может выйти из встречи. Если хотите, вы можете её отменить',
                variant: 'warning',
              });
            } else {
              leaveMeeting(meeting.meetingId);
            }
          }
        }}
      >
        {!meeting && 'You are about to leave the meeting'}
        {meeting &&
          `You are about to leave the meeting "${meeting.name}", and you will not be able to change your decision later.`}
      </DestructionDialog>
    </>
  );
};
