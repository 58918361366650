import { type InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import type { Department } from '~/entities/department/crossExports.ts';
import type { Interest } from '~/entities/interest/crossExports.ts';
import type { JobPosition } from '~/entities/jobPosition/crossExports.ts';
import type { Skill } from '~/entities/skill/crossExports.ts';
import {
  COLLEAGUES_PAGE_SIZE,
  MatcherApi,
  UserForCarouselDto,
} from '~/shared/api/index.ts';
import { time } from '~/utils/index.ts';
import { useLoadProfileImageUrls } from '../../profile/index.ts';
import { ColleagueProfile } from '../model/ColleagueProfile.ts';

export type Filters = {
  interestIds: Interest['id'][];
  skillIds: Skill['id'][];
  jobPositionIds: JobPosition['id'][];
  departmentIds: Department['id'][];
};

export const loadColleaguesQueryKeys = (filters: Filters) => [
  'load colleagues',
  { ...filters },
];

export function useLoadColleaguesCarousel(filters: Filters) {
  const matcherApi = useDi(MatcherApi);
  const loadProfileImageUrls = useLoadProfileImageUrls();

  return useInfiniteQuery({
    queryKey: loadColleaguesQueryKeys(filters),
    initialPageParam: 0,
    queryFn: async ({ pageParam: offset }) => {
      const { usersToMatch } = await matcherApi.getUsersForCarousel({
        filters,
        page: offset,
        pageSize: COLLEAGUES_PAGE_SIZE,
      });

      const profiles = usersToMatch.map((dtoItem) =>
        Mapper.map(UserForCarouselDto, ColleagueProfile, dtoItem)
      );

      await loadProfileImageUrls(profiles);

      return profiles;
    },
    getNextPageParam: (lastGroup, paginatedResults) => {
      if (!lastGroup) {
        return 0;
      }
      if (lastGroup.length === 0) {
        return null;
      }
      return paginatedResults.length;
    },
    staleTime: time(5, 'minutes'),
    select: mapResponse,
  });
}

const mapResponse = (
  data: InfiniteData<
    ColleagueProfile[],
    number // PageParam => offset => number
  >
) => {
  return data?.pages.flatMap((profiles) => profiles) ?? [];
};
