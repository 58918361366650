import { useThemeColor } from '~/shared/useThemeColor';
import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const ProfileOutlinedIcon = (props: Props) => {
  const { height = 20, color = '#868E96', ...rest } = props;

  const themeColor = useThemeColor(color);

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0277 10.7093C13.9637 9.93843 14.6469 8.8813 14.9822 7.68501C15.3176 6.48872 15.2884 5.21276 14.8987 4.03464C14.5091 2.85652 13.7784 1.83483 12.8082 1.11171C11.8381 0.388591 10.6768 0 9.48584 0C8.29491 0 7.13359 0.388591 6.16345 1.11171C5.19331 1.83483 4.46259 2.85652 4.07295 4.03464C3.68331 5.21276 3.65412 6.48872 3.98946 7.68501C4.32479 8.8813 5.00797 9.93843 5.94394 10.7093C4.34013 11.382 2.94075 12.4976 1.89499 13.9374C0.849226 15.3771 0.19628 17.0869 0.00575854 18.8846C-0.00803236 19.0159 0.00300573 19.1487 0.0382423 19.2754C0.0734789 19.4022 0.132224 19.5205 0.211124 19.6235C0.370469 19.8316 0.602234 19.9648 0.855434 19.994C1.10863 20.0231 1.36253 19.9458 1.56126 19.779C1.75999 19.6122 1.88729 19.3695 1.91514 19.1045C2.12478 17.1508 3.01465 15.3465 4.41473 14.0362C5.81482 12.7259 7.62696 12.0016 9.50494 12.0016C11.3829 12.0016 13.1951 12.7259 14.5951 14.0362C15.9952 15.3465 16.8851 17.1508 17.0947 19.1045C17.1207 19.3501 17.2326 19.5769 17.4089 19.7411C17.5852 19.9054 17.8134 19.9955 18.0494 19.994H18.1544C18.4047 19.9638 18.6334 19.8314 18.7908 19.6254C18.9482 19.4195 19.0214 19.1568 18.9946 18.8946C18.8031 17.0918 18.1467 15.3776 17.0955 13.9357C16.0444 12.4937 14.6382 11.3784 13.0277 10.7093ZM9.48584 9.99973C8.73056 9.99973 7.99224 9.76527 7.36425 9.326C6.73626 8.88672 6.2468 8.26237 5.95776 7.53189C5.66873 6.8014 5.5931 5.9976 5.74045 5.22212C5.8878 4.44665 6.2515 3.73433 6.78557 3.17524C7.31963 2.61615 8.00007 2.23541 8.74084 2.08115C9.4816 1.9269 10.2494 2.00607 10.9472 2.30865C11.645 2.61122 12.2414 3.12362 12.661 3.78103C13.0806 4.43845 13.3046 5.21137 13.3046 6.00204C13.3046 7.06229 12.9023 8.07912 12.1861 8.82883C11.47 9.57855 10.4986 9.99973 9.48584 9.99973Z"
        fill={themeColor}
      />
    </svg>
  );
};

export type { Props as ProfileOutlinedIconProps };
export { ProfileOutlinedIcon };
