import { type FC, useEffect, useMemo } from 'react';
import type { Filters } from '~/entities/colleague/api/useLoadColleaguesCarousel.ts';
import { useLoadColleaguesCarousel } from '~/entities/colleague/index.ts';
import { CoinBalance } from '~/entities/level/index.ts';
import {
  OpenColleaguesFiltersIconButton,
  useColleaguesFilterContext,
  useColleaguesViewStore,
} from '~/features/colleague/index.ts';
import { ContentSlot } from '~/shared/organisms/slots';
import { FooterTabs } from '~/shared/templates/FooterTabs.tsx';
import { Navbar, NotFoundView } from '~/shared/ui/index.ts';
import { SwipeColleagueCard } from '~/widgets/swipe-colleague-card/index.ts';
import classes from './ColleaguesCarouselDisplay.module.scss';

const WHEN_START_TO_LOAD_NEXT_COLLEAGUES_PACKAGE = 2;

export const ColleaguesCarouselDisplay: FC = () => {
  const {
    selectedDepartmentIds,
    selectedJobPositionIds,
    selectedInterestIds,
    selectedSkillIds,
  } = useColleaguesFilterContext() ?? {};

  const filters = useMemo<Filters>(
    () => ({
      departmentIds: (selectedDepartmentIds
        ? Array.from(selectedDepartmentIds)
        : []
      ).filter((id) => id != null),
      jobPositionIds: (selectedJobPositionIds
        ? Array.from(selectedJobPositionIds)
        : []
      ).filter((id) => id != null),
      interestIds: selectedInterestIds ? Array.from(selectedInterestIds) : [],
      skillIds: selectedSkillIds ? Array.from(selectedSkillIds) : [],
    }),
    [
      selectedDepartmentIds,
      selectedInterestIds,
      selectedJobPositionIds,
      selectedSkillIds,
    ]
  );

  const {
    data: colleagues,
    isLoading: colleaguesAreLoading,
    fetchNextPage,
    hasNextPage,
  } = useLoadColleaguesCarousel(filters);

  const viewedUserIds = useColleaguesViewStore((store) => store.viewedUserIds);

  const colleaguesToMeet = useMemo(
    () =>
      colleagues?.filter((colleague) => !viewedUserIds.has(colleague.userId)),
    [colleagues, viewedUserIds]
  );

  const colleagueToView = colleaguesToMeet?.at(0);

  useEffect(() => {
    if (!hasNextPage || !colleaguesToMeet) {
      return;
    }

    if (colleaguesToMeet.length < WHEN_START_TO_LOAD_NEXT_COLLEAGUES_PACKAGE) {
      fetchNextPage();
    }
  }, [colleaguesToMeet, fetchNextPage, hasNextPage]);

  const noFilters =
    !selectedDepartmentIds?.size &&
    !selectedJobPositionIds?.size &&
    !selectedInterestIds?.size &&
    !selectedSkillIds?.size;

  return (
    <>
      <Navbar
        title="Colleagues"
        left={<CoinBalance />}
        right={<OpenColleaguesFiltersIconButton hideBadge={noFilters} />}
        partialLoading={colleaguesAreLoading}
        withBorderBottom
      />

      {colleagueToView ? (
        <ContentSlot className={classes.colleagues}>
          <SwipeColleagueCard colleague={colleagueToView} />
        </ContentSlot>
      ) : noFilters ? (
        <ContentSlot>
          <NotFoundView>You’ve viewed all users</NotFoundView>
        </ContentSlot>
      ) : (
        <ContentSlot>
          <NotFoundView>
            No Users Found with the Selected Filters, Try Changing Your Search
          </NotFoundView>
        </ContentSlot>
      )}

      <FooterTabs />
    </>
  );
};
