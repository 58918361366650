import { type CommonIconProps } from '~/shared/atoms/icons/CommonProps';
import { useThemeColor } from '~/shared/useThemeColor';

const ChevronRightIcon = (props: CommonIconProps) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  const themeColor = useThemeColor(color);

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6702 11.3636L9.78622 5.31818C9.36593 4.89394 8.7355 4.89394 8.31522 5.31818C7.89493 5.74242 7.89493 6.37879 8.31522 6.80303L13.4637 12L8.31522 17.197C8.10507 17.4091 8 17.6212 8 17.9394C8 18.5758 8.42029 19 9.05072 19C9.36593 19 9.57608 18.8939 9.78622 18.6818L15.7753 12.6364C16.0905 12.4242 16.0905 11.7879 15.6702 11.3636Z"
        fill={themeColor}
      />
    </svg>
  );
};

export { ChevronRightIcon };
