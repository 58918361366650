import { ApiBase } from '../ApiBase.ts';
import { type ProfileFakeApi } from './ProfileFakeApi.ts';
import { type ProfileRealApi } from './ProfileRealApi.ts';

export class ProfileApi extends ApiBase {
  constructor(
    private realApi: ProfileRealApi,
    private fakeApi: ProfileFakeApi
  ) {
    super();
  }

  getProfile: ProfileRealApi['getProfile'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.getProfile(...args);
    }
    return this.realApi.getProfile(...args);
  };
  getCurrentUser: ProfileRealApi['getCurrentUser'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.getCurrentUser(...args);
    }
    return this.realApi.getCurrentUser(...args);
  };

  updateProfileInfo: ProfileRealApi['updateProfileInfo'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.updateProfileInfo(...args);
    }
    return this.realApi.updateProfileInfo(...args);
  };
  updateProfileCareer: ProfileRealApi['updateProfileCareer'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.updateProfileCareer(...args);
    }
    return this.realApi.updateProfileCareer(...args);
  };

  uploadPhoto: ProfileRealApi['uploadPhoto'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.uploadPhoto(...args);
    }
    return this.realApi.uploadPhoto(...args);
  };
  deletePhoto: ProfileRealApi['deletePhoto'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.deletePhoto(...args);
    }
    return this.realApi.deletePhoto(...args);
  };
  setAvatar: ProfileRealApi['setAvatar'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.setAvatar(...args);
    }
    return this.realApi.setAvatar(...args);
  };

  getAvatarUrl = (...args: Parameters<ProfileRealApi['getAvatarUrl']>) => {
    if (this.isFakeApi) {
      return this.fakeApi.getAvatarUrl(...args);
    }
    return this.realApi.getAvatarUrl(...args);
  };

  addSkill: ProfileRealApi['addSkill'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.addSkill(...args);
    }
    return this.realApi.addSkill(...args);
  };
  removeSkill: ProfileRealApi['removeSkill'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.removeSkill(...args);
    }
    return this.realApi.removeSkill(...args);
  };
  changeSkillsBulk: ProfileRealApi['changeSkillsBulk'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.changeSkillsBulk(...args);
    }
    return this.realApi.changeSkillsBulk(...args);
  };

  addInterest: ProfileRealApi['addInterest'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.addInterest(...args);
    }
    return this.realApi.addInterest(...args);
  };
  removeInterest: ProfileRealApi['removeInterest'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.removeInterest(...args);
    }
    return this.realApi.removeInterest(...args);
  };
  changeInterestsBulk: ProfileRealApi['changeInterestsBulk'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.changeInterestsBulk(...args);
    }
    return this.realApi.changeInterestsBulk(...args);
  };

  addJobPosition: ProfileRealApi['addJobPosition'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.addJobPosition(...args);
    }
    return this.realApi.addJobPosition(...args);
  };
  removeJobPosition: ProfileRealApi['removeJobPosition'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.removeJobPosition(...args);
    }
    return this.realApi.removeJobPosition(...args);
  };

  addDepartment: ProfileRealApi['addDepartment'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.addDepartment(...args);
    }
    return this.realApi.addDepartment(...args);
  };
  removeDepartment: ProfileRealApi['removeDepartment'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.removeDepartment(...args);
    }
    return this.realApi.removeDepartment(...args);
  };
}
