import * as yup from 'yup';
import { LoginUserCredentials } from './LoginUserCredentials.ts';

export class RegisterUserCredentials {
  email: string | undefined;
  password: string | undefined;
  confirmPassword: string | undefined;

  static validationSchema = LoginUserCredentials.validationSchema.concat(
    yup.object({
      confirmPassword: yup
        .string()
        .test(
          'passwords-match',
          'Passwords must match',
          function (confirmPassword) {
            return this.parent.password === confirmPassword;
          }
        ),
    })
  );
}
