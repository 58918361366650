import { MapFunction, Mapper } from '@tomas-light/mapper-js';
import dayjs from 'dayjs';
import { type Duration } from 'dayjs/plugin/duration';
import { MeetingDto, MeetingMemberDto } from '~/shared/api/index.ts';
import { AcceptRejectEnum } from '~/shared/enums/AcceptRejectEnum.ts';
import { Profile } from '~/entities/profile/crossExports.ts';
import { ContactProfile } from '~/entities/contact/crossExports.ts';
import { type InvitationStatus } from './InvitationStatus.ts';
import { Meeting } from './Meeting.ts';
import { MeetingMember } from './MeetingMember.ts';

export const meetingMappingProfile = [
  new MapFunction(
    MeetingMemberDto,
    MeetingMember,
    mapMeetingMemberDtoToMeetingMember
  ),
  new MapFunction(Profile, MeetingMember, mapProfileToMeetingMember),
  new MapFunction(
    ContactProfile,
    MeetingMember,
    mapContactProfileToMeetingMember
  ),
  new MapFunction(MeetingDto, Meeting, mapMeetingDtoToMeeting),
];

function mapStatus(acceptRejectEnum: AcceptRejectEnum): InvitationStatus {
  switch (acceptRejectEnum) {
    case AcceptRejectEnum.Reject:
      return 'reject';

    case AcceptRejectEnum.Accept:
      return 'accept';

    case AcceptRejectEnum.Invited:
      return 'invited';

    case AcceptRejectEnum.Owner:
      return 'owner';

    case AcceptRejectEnum.Unknown:
    default:
      return 'unknown';
  }
}

function mapMeetingMemberDtoToMeetingMember(
  dto: MeetingMemberDto
): MeetingMember {
  return {
    userId: dto.userId,
    firstName: dto.firstName,
    lastName: dto.lastName,
    avatar: {
      fileName: dto.userAvatarPhotoFileName,
      url: dto.userAvatarPhotoFileName,
    },
    status: mapStatus(dto.status),
    jobPositionId: dto.jobPosition,
  };
}

function mapProfileToMeetingMember(profile: Profile): MeetingMember {
  return {
    userId: profile.userId,
    firstName: profile.firstName,
    lastName: profile.lastName,
    avatar: profile.avatar,
    status: 'unknown',
    jobPositionId: profile.jobPositionId,
  };
}

function mapContactProfileToMeetingMember(
  contactProfile: ContactProfile
): MeetingMember {
  return {
    userId: contactProfile.userId,
    firstName: contactProfile.firstName,
    lastName: contactProfile.lastName,
    avatar: contactProfile.avatar,
    status: 'unknown',
    jobPositionId: contactProfile.jobPositionId,
  };
}

function mapMeetingDtoToMeeting(dto: MeetingDto): Meeting {
  const when = dayjs(dto.startAt);

  let howLong: Duration | null = null;
  if (dto.howLong) {
    howLong = dayjs.duration({ minutes: dto.howLong });
  }

  let remindBefore: Duration | null = null;
  if (dto.reminderInMinutes) {
    remindBefore = dayjs.duration({ minutes: dto.reminderInMinutes });
  }

  const owner = dto.members.find(
    (member) => member.status === AcceptRejectEnum.Owner
  );
  if (!owner) {
    throw new Error('Has no owner of the meeting');
  }

  return {
    meetingId: dto.meetingId,
    name: dto.name,
    when,
    howLong: howLong,
    remindBefore: remindBefore,
    meetingOwner: Mapper.map(MeetingMemberDto, MeetingMember, owner),
    invitedMembers: dto.members
      .filter((member) => member.status === AcceptRejectEnum.Invited)
      .map((dto) => Mapper.map(MeetingMemberDto, MeetingMember, dto)),
    acceptedMembers: dto.members
      .filter((member) => member.status === AcceptRejectEnum.Accept)
      .map((dto) => Mapper.map(MeetingMemberDto, MeetingMember, dto)),
  };
}
