import {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type RegistrationContextType = {
  registrationStep: 'information' | 'credentials';
  goToInformationStep: VoidFunction;
  goToCredentialsStep: VoidFunction;

  areRulesAccepted: boolean;
  onAreRulesAccepted: (areRulesAccepted: boolean) => void;

  email: string;
  onEmailChange: (email: string) => void;

  password: string;
  onPasswordChange: (password: string) => void;

  confirmPassword: string;
  onConfirmPasswordChange: (confirmPassword: string) => void;

  firstName: string;
  onFirstNameChange: (firstName: string) => void;

  lastName: string;
  onLastNameChange: (lastName: string) => void;

  registrationError: string | null;
  setRegistrationError: (registrationError: string) => void;
};

const RegistrationContext = createContext<RegistrationContextType | null>(null);

export const useRegistrationContext = () => useContext(RegistrationContext);

type Props = PropsWithChildren<{
  firstName?: string;
  lastName?: string;
}>;

export const RegistrationContextProvider: FC<Props> = (props) => {
  const {
    children,
    firstName: propsFirstName,
    lastName: propsLastName,
  } = props;

  const [registrationStep, setRegistrationStep] =
    useState<RegistrationContextType['registrationStep']>('credentials');

  const goToCredentialsStep = useCallback(
    () => setRegistrationStep('credentials'),
    []
  );
  const goToInformationStep = useCallback(
    () => setRegistrationStep('information'),
    []
  );

  const [areRulesAccepted, setAreRulesAccepted] =
    useState<RegistrationContextType['areRulesAccepted']>(false);

  const [email, setEmail] = useState<RegistrationContextType['email']>('');

  const [password, setPassword] =
    useState<RegistrationContextType['password']>('');

  const [confirmPassword, setConfirmPassword] =
    useState<RegistrationContextType['confirmPassword']>('');

  const [firstName, setFirstName] = useState<
    RegistrationContextType['firstName']
  >(propsFirstName ?? '');
  useEffect(() => {
    if (propsFirstName) {
      setFirstName(propsFirstName);
    }
  }, [propsFirstName]);

  const [lastName, setLastName] = useState<RegistrationContextType['lastName']>(
    propsLastName ?? ''
  );
  useEffect(() => {
    if (propsLastName) {
      setLastName(propsLastName);
    }
  }, [propsLastName]);

  const [registrationError, setRegistrationError] =
    useState<RegistrationContextType['registrationError']>(null);

  const context = useMemo<RegistrationContextType>(
    () => ({
      registrationStep,
      goToCredentialsStep,
      goToInformationStep,

      areRulesAccepted,
      onAreRulesAccepted: setAreRulesAccepted,

      email,
      onEmailChange: setEmail,

      password,
      onPasswordChange: setPassword,

      confirmPassword,
      onConfirmPasswordChange: setConfirmPassword,

      firstName,
      onFirstNameChange: setFirstName,

      lastName,
      onLastNameChange: setLastName,

      registrationError,
      setRegistrationError,
    }),
    [
      confirmPassword,
      email,
      firstName,
      goToCredentialsStep,
      goToInformationStep,
      lastName,
      password,
      registrationError,
      registrationStep,
    ]
  );

  return (
    <RegistrationContext.Provider value={context}>
      {children}
    </RegistrationContext.Provider>
  );
};
