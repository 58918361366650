import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { type FC } from 'react';
import classes from '~/pages/SettingsDisplay/SettingsDisplay.module.scss';
import { OtherApi } from '~/shared/api/index.ts';

export const ApiVersion: FC = () => {
  const otherApi = useDi(OtherApi);

  const { data: apiVersion, isLoading } = useQuery({
    queryKey: ['get api version'],
    queryFn: otherApi.getVersion,
  });

  return (
    <p className={classes.version}>
      API Version{' '}
      {isLoading ? (
        <StyledSkeleton width={100} height={24} />
      ) : (
        <span className={classes.versionNumber}>{apiVersion}</span>
      )}
    </p>
  );
};

const StyledSkeleton = styled(Skeleton)({
  display: 'inline-block',
});
