import type { UserForCarouselDto } from '~/shared/api/index.ts';
import { createStore } from '~/shared/store/index.ts';

export const useColleaguesViewStore = createStore({
  viewedUserIds: new Set<UserForCarouselDto['userId']>(),
});

export const viewUser = (userId: UserForCarouselDto['userId']) =>
  useColleaguesViewStore.setState(({ viewedUserIds }) => ({
    viewedUserIds: new Set(viewedUserIds).add(userId),
  }));
