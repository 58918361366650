import { ApiBase } from '../ApiBase.ts';
import { type DeveloperFakeApi } from './DeveloperFakeApi.ts';
import { type DeveloperRealApi } from './DeveloperRealApi.ts';

export class DeveloperApi extends ApiBase {
  constructor(
    private realApi: DeveloperRealApi,
    private fakeApi: DeveloperFakeApi
  ) {
    super();
  }

  deleteMatches: DeveloperRealApi['deleteMatches'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.deleteMatches(...args);
    }
    return this.realApi.deleteMatches(...args);
  };

  deleteMessagesBetweenMeAndUser: DeveloperRealApi['deleteMessagesBetweenMeAndUser'] =
    (...args) => {
      if (this.isFakeApi) {
        return this.fakeApi.deleteMessagesBetweenMeAndUser(...args);
      }
      return this.realApi.deleteMessagesBetweenMeAndUser(...args);
    };
}
