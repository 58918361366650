import { useMutation } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { useSuccessLoginHandler } from '~/entities/auth/index.ts';
import { AuthApi } from '~/shared/api/index.ts';
import { useRegistrationContext } from '../model/RegistrationContext.tsx';

export function useRegisterUserMutation() {
  const authApi = useDi(AuthApi);
  const onSuccessLogin = useSuccessLoginHandler();

  const { setRegistrationError } = useRegistrationContext() ?? {};

  return useMutation({
    mutationKey: ['login'],
    mutationFn: async (payload: {
      email: string;
      password: string;
      confirmPassword: string;
      firstName: string;
      lastName: string;
    }) => {
      const { email, password, confirmPassword, firstName, lastName } = payload;
      return await authApi.register({
        email,
        firstName,
        lastName,
        password,
        confirmPassword,
        timezoneOffset: 0, // todo: use string
      });
    },
    onSuccess: async (response) => {
      await onSuccessLogin(response.token);
    },
    onError: (error) => {
      setRegistrationError?.(`Registration failed: ${error.message}`);
    },
  });
}
