import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { useNavigate } from 'react-router-dom';
import { loadMeetingsQueryKey } from '~/entities/meeting/api/useMeetingsQuery.ts';
import {
  type MeetingDraft,
  type MeetingRoutes,
} from '~/entities/meeting/index.ts';
import { MeetingApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';

export function useCreateMeetingQuery(options?: {
  meetingRoutes?: MeetingRoutes;
}) {
  const { meetingRoutes } = options ?? {};

  const queryClient = useQueryClient();
  const meetingApi = useDi(MeetingApi);
  const navigate = useNavigate();

  return useMutation({
    mutationKey: ['create meeting'],
    mutationFn: async (meetingDraft: MeetingDraft) => {
      if (!meetingDraft.name) {
        throw new Error('Meeting Title Cannot Be Empty');
      }

      if (!meetingDraft.invitedMembers.length) {
        throw new Error('Invite At Least One Participant');
      }

      return meetingApi.createMeeting({
        name: meetingDraft.name,
        startAt: meetingDraft.when.toISOString(),
        howLong: meetingDraft.howLong?.asMinutes() ?? null,
        invitedUserIds: meetingDraft.invitedMembers.map(
          (member) => member.userId
        ),
        reminderInMinutes: meetingDraft.remindBefore?.asMinutes() ?? null,
        description: '',
      });
    },
    onSuccess: () => {
      if (meetingRoutes) {
        navigate(meetingRoutes.details.meeting_created.url());
      }
      queryClient.invalidateQueries({
        queryKey: loadMeetingsQueryKey(),
      });
    },
    onError: (error) => {
      notify({
        message: `Can't create meeting: ${error.message}`,
        variant: 'error',
      });
    },
  });
}
