import { type AxiosRequestConfig } from 'axios';
import { API_BASE_URL } from '../../config/environment/index.ts';
import { type ApiInterceptor } from './ApiInterceptor.ts';

export type Url =
  | string
  | {
      url: string;
      searchParams: {
        [name: string]: string;
      };
    };

export abstract class RealApiBase {
  constructor(private interceptor: ApiInterceptor) {
    if (!interceptor) {
      throw new Error("Can't instantiate Api. Have not enough arguments.");
    }
  }

  setAuthorizationTokenToApiInterceptor(authorizationToken: string) {
    if (this.interceptor) {
      this.interceptor.authorizationToken = authorizationToken;
    }
  }

  removeAuthorizationToken() {
    if (this.interceptor) {
      this.interceptor.authorizationToken = undefined;
    }
  }

  get axios() {
    return this.interceptor.axios;
  }

  protected async get<Data = unknown>(
    url: Url,
    config?: AxiosRequestConfig
  ): Promise<Data> {
    let urlString: string;
    if (typeof url === 'object') {
      // we can't build url without mandatory part (https://my-site.com/)
      const _url = new URL(API_BASE_URL + url.url);

      Object.entries(url.searchParams).forEach(([name, value]) => {
        _url.searchParams.append(name, value.toString());
      });

      // remove extra part in url
      urlString = _url.toString().substring(API_BASE_URL.length);
    } else {
      urlString = url;
    }

    const response = await this.axios.get<Data>(urlString, config);
    return response.data;
  }

  protected async post<Data = unknown>(url: string, data?: any): Promise<Data> {
    const response = await this.axios.post<Data>(url, data);
    return response.data;
  }

  protected async put<Data = unknown>(url: string, data?: any): Promise<Data> {
    const response = await this.axios.put<Data>(url, data);
    return response.data;
  }

  protected async patch<Data = unknown>(
    url: string,
    data?: any
  ): Promise<Data> {
    const response = await this.axios.patch<Data>(url, data);
    return response.data;
  }

  protected async delete<Data = unknown>(url: string): Promise<Data> {
    const response = await this.axios.delete<Data>(url);
    return response.data;
  }
}
