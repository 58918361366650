import { MapFunction } from '@tomas-light/mapper-js';
import { TagDto } from '~/shared/api/TagDto.ts';
import { JobPosition } from './JobPosition.ts';

export const jobPositionMappingProfile = [
  //
  new MapFunction(TagDto, JobPosition, mapTagDtoToPosition),
];

function mapTagDtoToPosition(tagDto: TagDto): JobPosition {
  return {
    id: tagDto.id,
    name: tagDto.name,
    linkedCount: tagDto.userByCount,
  };
}
