import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  type ContactProfile,
  ContactsInfiniteList,
} from '~/entities/contact/index.ts';
import { CancelNavigationButton } from '~/features/navigation/index.ts';
import { NavbarInput } from '~/shared/molecules/NavbarInput';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { FooterTabs } from '~/shared/templates/FooterTabs.tsx';
import { Navbar } from '~/shared/ui/index.ts';
import classes from './NewChatDisplay.module.scss';

export const NewChatDisplay = () => {
  const navigate = useNavigate();
  const [searchedString, setSearchedString] = useState('');

  const onRowClick = useCallback(
    (contact: ContactProfile) => {
      navigate(routes.chat.userId(contact.userId.toString()).url(), {
        replace: true,
      });
    },
    [navigate]
  );

  return (
    <>
      <Navbar
        title="New message"
        left={<CancelNavigationButton />}
        classes={{
          children: classes.navbarChildren,
        }}
        withBorderBottom
      >
        <NavbarInput
          placeholder="Search"
          value={searchedString}
          onChange={setSearchedString}
        />
      </Navbar>

      <ContentSlot>
        <ContactsInfiniteList
          view="list"
          onRowClick={onRowClick}
          searchedContactName={searchedString}
          emptyListMessage="You haven't chatted with anyone yet."
        />
      </ContentSlot>

      <FooterTabs />
    </>
  );
};
