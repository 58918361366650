import { type FC, type PropsWithChildren, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '~/shared/routing/index.ts';
import { useDisplayLoader } from '~/shared/ui/index.ts';
import { useIsUserAuthorized } from '../api/useIsUserAuthorized.ts';

/** Redirects to main page if user is authorized */
export const RedirectIfAuthorized: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const navigate = useNavigate();

  const goToMain = useCallback(() => {
    navigate(routes.url());
  }, [navigate]);

  const { isAuthorized, isLoading } = useIsUserAuthorized({
    actionOnSuccess: goToMain,
  });

  useDisplayLoader(isLoading);

  if (!isAuthorized) {
    return children;
  }

  return null;
};
