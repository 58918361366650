import { useMatch, useParams } from 'react-router-dom';
import { useCurrentUser } from '~/entities/profile/crossExports.ts';
import { useParseInt } from '~/shared/numbers/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { useUserById } from '../../colleague/index.ts';
import { useLoadSkillsById } from './useLoadSkillsById.ts';

export function useProfileSkills() {
  const currentProfileMatch = useMatch(routes.profile.skills.url());
  const colleagueProfileMatch = useMatch(
    routes.colleagues.userId().skills.url()
  );
  const { userId: stringUserId } = useParams<{ userId: string | undefined }>();
  const userId = useParseInt(stringUserId);

  const isCurrentProfile = currentProfileMatch != null;
  const isColleagueProfile = colleagueProfileMatch != null;

  const { data: currentProfile, isLoading: currentProfileIsLoading } =
    useCurrentUser({ enabled: isCurrentProfile });
  const { data: currentProfileSkills, isLoading: currentProfileAreLoading } =
    useLoadSkillsById(currentProfile?.skillIds, { enabled: isCurrentProfile });

  const { data: profile, isLoading: profileIsLoading } = useUserById(userId, {
    enabled: isColleagueProfile,
  });
  const { data: profileSkills, isLoading: profileAreLoading } =
    useLoadSkillsById(profile?.skillIds, { enabled: isColleagueProfile });

  return {
    skills: currentProfileSkills || profileSkills,
    skillsAreLoading:
      currentProfileIsLoading ||
      currentProfileAreLoading ||
      profileIsLoading ||
      profileAreLoading,
  };
}
