import { ApiBase } from '../ApiBase.ts';
import { type BlockListFakeApi } from './BlockListFakeApi.ts';
import type { BlockListRealApi } from './BlockListRealApi.ts';

export class BlockListApi extends ApiBase {
  constructor(
    private realApi: BlockListRealApi,
    private fakeApi: BlockListFakeApi
  ) {
    super();
  }

  getBlockUsers: BlockListRealApi['getBlockUsers'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.getBlockUsers(...args);
    }
    return this.realApi.getBlockUsers(...args);
  };

  blockUser: BlockListRealApi['blockUser'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.blockUser(...args);
    }
    return this.realApi.blockUser(...args);
  };

  unblockUser: BlockListRealApi['unblockUser'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.unblockUser(...args);
    }
    return this.realApi.unblockUser(...args);
  };
}
