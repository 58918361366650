import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLoadQuestById } from '~/entities/quest/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { useParseInt } from '~/shared/numbers/index.ts';
import { ContentSlot } from '~/shared/organisms/slots';
import { Display, Navbar } from '~/shared/ui/index.ts';
import classes from '../QuestsDisplay.module.scss';
import { Quest } from './Quest';

export const QuestDisplay = () => {
  const { questId: stringQuestId } = useParams<{ questId: string }>();
  const questId = useParseInt(stringQuestId);

  const { data: quest, isLoading: isQuestLoading } = useLoadQuestById(questId);

  return (
    <Display
      documentTitle={quest ? quest.title : `Quest #${questId}`}
      animationVariant="card-effect"
    >
      <Navbar
        title={quest?.title}
        className={classes.gridNavbar}
        left={<BackIconNavigationButton />}
        partialLoading={isQuestLoading}
      />

      <ContentSlot className={classes.fullHeight}>
        {quest && <Quest quest={quest} />}

        {!quest && !isQuestLoading && (
          <div>
            <Typography>Quest #{questId} not found</Typography>
          </div>
        )}
      </ContentSlot>
    </Display>
  );
};
