import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { invalidateCurrentUserQuery, useCurrentUser } from '~/entities/profile';
import { ProfileApi } from '~/shared/api/index.ts';

export function useTimezoneUpdate() {
  const queryClient = useQueryClient();
  const profileApi = useDi(ProfileApi);

  const { data: profile } = useCurrentUser();

  return useMutation({
    mutationKey: ['update timezone', profile],
    mutationFn: (timezone: string) => {
      if (!profile) {
        throw new Error('current profile is not loaded');
      }
      return profileApi.updateProfileInfo({
        timezoneId: timezone,
        aboutMe: profile.bio,
        firstName: profile.firstName,
        lastName: profile.lastName,
      });
    },
    onSuccess: () => {
      invalidateCurrentUserQuery(queryClient);
    },
  });
}
