import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useAcceptMeetingMutation,
  useRejectMeetingMutation,
} from '~/features/meeting/index.ts';
import { Avatar } from '~/shared/atoms/Avatar/index.ts';
import { Button } from '~/shared/molecules/Button/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { useCurrentUser } from '../../profile/index.ts';
import {
  type KindOfMeeting,
  type Meeting,
  useMeetingDraftContext,
} from '../index.ts';
import { MeetingDates } from './MeetingDates.tsx';
import classes from './MeetingCard.module.scss';

type Props = {
  kind?: KindOfMeeting;
  meeting: Meeting;
};

export const MeetingCard = (props: Props) => {
  const navigate = useNavigate();
  const { meeting, kind } = props;

  const { data: profile } = useCurrentUser();
  const { mutate: acceptMeeting, isPending: acceptIsPending } =
    useAcceptMeetingMutation();
  const { mutate: rejectMeeting, isPending: rejectIsPending } =
    useRejectMeetingMutation();

  const { newClonedDraft } = useMeetingDraftContext() ?? {};

  const endDate = useMemo(() => {
    if (!meeting.howLong) {
      return null;
    }
    return meeting.when.add(meeting.howLong);
  }, [meeting.when, meeting.howLong]);

  if (!profile) {
    return null;
  }

  return (
    <div
      className={classes.root}
      onClick={() => {
        navigate(routes.meetings.meetingId(meeting.meetingId.toString()).url());
      }}
    >
      <Typography className={classes.title}>{meeting.name}</Typography>

      <div className={classes.date}>
        <MeetingDates startDate={meeting.when} endDate={endDate} />
      </div>

      <div className={classes.participants}>
        <Avatar
          firstName={meeting.meetingOwner.firstName}
          lastName={meeting.meetingOwner.lastName}
          avatarUrl={meeting.meetingOwner.avatar.url}
          skeleton={{ width: 40 }}
        />

        {meeting.acceptedMembers.map((meetingMember) => {
          const { avatar, firstName, lastName, userId } = meetingMember;

          return (
            <Avatar
              key={userId}
              firstName={firstName}
              lastName={lastName}
              avatarUrl={avatar.url}
              skeleton={{ width: 40 }}
            />
          );
        })}
        {meeting.invitedMembers.map((meetingMember) => {
          const { avatar, firstName, lastName, userId } = meetingMember;

          return (
            <Avatar
              key={userId}
              firstName={firstName}
              lastName={lastName}
              avatarUrl={avatar.url}
              skeleton={{ width: 40 }}
            />
          );
        })}
      </div>

      {kind !== 'accepted' && (
        <div className={classes.buttons}>
          {kind === 'invites' && (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={(event) => {
                  rejectMeeting(meeting.meetingId);
                  event.stopPropagation();
                }}
              >
                Decline
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={(event) => {
                  acceptMeeting(meeting.meetingId);
                  event.stopPropagation();
                }}
              >
                Accept
              </Button>
            </>
          )}

          {kind === 'completed' && (
            <Button
              variant="contained"
              color="secondary"
              onClick={(event) => {
                navigate(
                  routes.meetings.new.url({
                    clone: meeting.meetingId.toString(),
                  })
                );
                event.stopPropagation();
              }}
            >
              Repeat
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
