import MuiCheckbox from '@mui/material/Checkbox';
import { Icon } from '~/shared/atoms/icons';

type Props = {
  checked: boolean;
};

const Checkbox = (props: Props) => {
  const { checked } = props;

  return (
    <MuiCheckbox
      checked={checked}
      icon={<Icon variant="check" color="#CED4DA" />}
      checkedIcon={<Icon variant="check" />}
    />
  );
};

export { Checkbox };
export type { Props as CheckboxProps };
