import { useCallback } from 'react';
import {
  ContactRowWithCheckbox,
  type ContactRowWithCheckboxProps,
} from '../index.ts';
import type { ModelImage } from '../../profile/index.ts';

export const CONTACT_ROW_HEIGHT = 64; // by design

type Contact = {
  userId: number;
  firstName: string;
  lastName: string;
  avatar?: ModelImage;
  jobPositionId: number | null;
};

type Props<TContact extends Contact> = Omit<
  ContactRowWithCheckboxProps,
  'onRowClick' | 'avatarProps' | 'name' | 'secondaryRow' | 'jobPositionId'
> & {
  contact: TContact | undefined;
  onClick: (model: TContact) => void;
};

export type { Props as ContactItemProps, Contact };

export function ContactItem<TContact extends Contact>(props: Props<TContact>) {
  const { contact, isLoading, onClick: propsOnClick, ...rest } = props;

  const onClick = useCallback(() => {
    if (contact && propsOnClick) {
      propsOnClick(contact);
    }
  }, [contact, propsOnClick]);

  return (
    <ContactRowWithCheckbox
      {...rest}
      isLoading={isLoading}
      onRowClick={onClick}
      avatarProps={{
        avatarUrl: contact?.avatar?.url,
        firstName: contact?.firstName,
        lastName: contact?.lastName,
      }}
      jobPositionId={contact?.jobPositionId}
    />
  );
}
