import { MapFunction, Mapper } from '@tomas-light/mapper-js';
import dayjs from 'dayjs';
import type { CryptoWallet } from '~/entities/wallet/crossExports.ts';
import { MyUserDto, UserForCarouselDto } from '~/shared/api/index.ts';
import { MyProfile } from './MyProfile.ts';
import { Profile } from './Profile.ts';

export const profileMappingProfile = [
  new MapFunction(UserForCarouselDto, Profile, mapUserForCarouselDtoToProfile),
  new MapFunction(MyUserDto, MyProfile, mapMyUserDtoToProfile),
  new MapFunction(MyProfile, MyProfile, mapProfileLikeToProfile),
];

function mapUserForCarouselDtoToProfile(dto: UserForCarouselDto): Profile {
  let joinedCompanyDate: Profile['joinedCompanyDate'] = null;
  if (dto.workInCompanyStartDate) {
    joinedCompanyDate = dayjs(dto.workInCompanyStartDate);
  }

  let workExperienceSince: Profile['workExperienceSince'] = null;
  if (dto.careerStartDate) {
    workExperienceSince = dayjs(dto.careerStartDate);
  }

  return new Profile({
    userId: dto.userId,
    firstName: dto.firstName,
    lastName: dto.lastName,

    avatar: {
      fileName: dto.userAvatarPhotoFileName,
      url: dto.userAvatarPhotoFileName,
    },
    photos: dto.profilePhotoNames.map((fileName) => ({
      fileName,
      url: fileName,
    })),

    jobPositionId: dto.jobPosition,
    departmentId: dto.department,

    joinedCompanyDate,
    workExperienceSince,

    bio: dto.aboutMe ?? '',

    interestIds: dto.interests,
    skillIds: dto.skills,
  });
}

function mapMyUserDtoToProfile(dto: MyUserDto): MyProfile {
  const profile = Mapper.map(UserForCarouselDto, Profile, dto);

  return new MyProfile({
    ...profile,
    email: dto.sensitiveInformation.email,
    timezone: dto.timezoneId,
    wasSaved: dto.wasSaved,
    connectedWallets: Object.entries(dto.tonWallets).reduce(
      (wallets, [walletName, walletDto]) => {
        wallets.push({
          ...walletDto,
          name: walletName as CryptoWallet['name'],
        });
        return wallets;
      },
      [] as CryptoWallet[]
    ),
    coins: dto.coins,
    levelableSkills: dto.levelableSkills,
  });
}

function mapProfileLikeToProfile(profileLike: MyProfile): MyProfile {
  return new MyProfile({
    ...profileLike,
  });
}
