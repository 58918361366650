import { type FC } from 'react';
import { type Meeting } from '~/entities/meeting/index.ts';
import { FooterButton } from '~/shared/templates/FooterButton/index.ts';
import { useNavbarLoader } from '~/shared/ui/index.ts';
import { useRejectMeetingMutation } from '../api/useRejectMeetingMutation.ts';

type Props = {
  meeting: Meeting | undefined;
};

export const DeclineMeetingFooterButton: FC<Props> = (props) => {
  const { meeting } = props;

  const { mutate: rejectMeeting, isPending: rejectIsPending } =
    useRejectMeetingMutation();

  useNavbarLoader(rejectIsPending);

  return (
    <FooterButton
      color="secondary"
      onClick={() => {
        if (meeting) {
          rejectMeeting(meeting.meetingId);
        }
      }}
    >
      Decline
    </FooterButton>
  );
};
