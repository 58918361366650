import clsx from 'clsx';
import { type FC, type PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { useLoadSkillsById } from '~/entities/skill/api/useLoadSkillsById.ts';
import { CancelNavigationButton } from '~/features/navigation/index.ts';
import {
  SaveSkillsButton,
  useProfileSkillsEditContext,
} from '~/features/profile/index.ts';
import { Icon } from '~/shared/atoms/icons';
import { Button } from '~/shared/molecules/Button';
import { ContentSlot, SlotPortal } from '~/shared/organisms/slots';
import { routes } from '~/shared/routing/index.ts';
import { Display, Navbar, NotFoundView } from '~/shared/ui/index.ts';
import { TagListView } from '../TagListView/index.ts';
import { useEditedProfileSkills } from './useEditedProfileSkills.ts';
import classes from './EditProfileSkillsDisplay.module.scss';

export const EditProfileSkillsDisplay: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const { deleteSkill, addSkill } = useProfileSkillsEditContext() ?? {};

  const { data: profile, isLoading: profileIsLoading } = useCurrentUser();
  const { isLoading: skillsAreLoading } = useLoadSkillsById(profile?.skillIds);

  const { skills, added, removed } = useEditedProfileSkills();

  const navigate = useNavigate();

  const AddSkillButton = () => (
    <Button
      variant="contained"
      color="primary"
      startIcon={<Icon variant="add" />}
      onClick={() => navigate(routes.profile.settings.edit.skills.new.url())}
      className={clsx({ [classes.button]: skills?.length !== 0 })}
    >
      Add skills
    </Button>
  );

  return (
    <>
      <Display
        documentTitle="edit profile skills"
        animationVariant="card-effect"
      >
        <Navbar
          title="Skills"
          left={<CancelNavigationButton />}
          right={<SaveSkillsButton />}
          partialLoading={profileIsLoading || skillsAreLoading}
        />

        <ContentSlot>
          {!skills?.length ? (
            <NotFoundView button={<AddSkillButton />}>No Skills</NotFoundView>
          ) : (
            <>
              <TagListView
                list={skills}
                added={added}
                removed={removed}
                onRestore={addSkill}
                onRemove={deleteSkill}
              />

              <SlotPortal slotName="footer">
                <div className={classes.footer}>
                  <AddSkillButton />
                </div>
              </SlotPortal>
            </>
          )}
        </ContentSlot>
      </Display>

      {children}
    </>
  );
};
