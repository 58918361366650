import { useThemeColor } from '~/shared/useThemeColor';
import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const StudyIcon = (props: Props) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  const themeColor = useThemeColor(color);

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4869 9.63819L20.4869 9.08543L11.4869 4.0603H11.3769C11.3156 4.03425 11.252 4.01406 11.1869 4H10.9969H10.8169C10.7486 4.01407 10.6817 4.03425 10.6169 4.0603H10.5069L1.50695 9.08543C1.35305 9.17308 1.22504 9.3002 1.13598 9.45381C1.04692 9.60741 1 9.78202 1 9.9598C1 10.1376 1.04692 10.3122 1.13598 10.4658C1.22504 10.6194 1.35305 10.7465 1.50695 10.8342L3.99695 12.2211V16.9849C3.99695 17.7846 4.31302 18.5515 4.87563 19.1169C5.43824 19.6823 6.2013 20 6.99695 20H14.9969C15.7926 20 16.5557 19.6823 17.1183 19.1169C17.6809 18.5515 17.9969 17.7846 17.9969 16.9849V12.2211L19.9969 11.0955V13.9698C19.9969 14.2364 20.1023 14.492 20.2898 14.6805C20.4774 14.869 20.7317 14.9749 20.9969 14.9749C21.2622 14.9749 21.5165 14.869 21.7041 14.6805C21.8916 14.492 21.9969 14.2364 21.9969 13.9698V10.5126C21.9966 10.3345 21.9493 10.1597 21.8596 10.006C21.77 9.8524 21.6414 9.72545 21.4869 9.63819ZM15.9969 16.9849C15.9969 17.2515 15.8916 17.5071 15.7041 17.6956C15.5165 17.8841 15.2622 17.99 14.9969 17.99H6.99695C6.73173 17.99 6.47738 17.8841 6.28984 17.6956C6.10231 17.5071 5.99695 17.2515 5.99695 16.9849V13.3367L10.5069 15.8492L10.6569 15.9095H10.7469C10.83 15.9201 10.9139 15.9201 10.9969 15.9095C11.08 15.9201 11.1639 15.9201 11.2469 15.9095H11.3369C11.39 15.8983 11.4408 15.8779 11.4869 15.8492L15.9969 13.3367V16.9849ZM10.9969 13.8291L4.05695 9.94975L10.9969 6.07035L17.9369 9.94975L10.9969 13.8291Z"
        fill={themeColor}
      />
    </svg>
  );
};

export type { Props as StudyIconProps };
export { StudyIcon };
