import { UserForCarouselDto } from './UserForCarouselDto.ts';

export abstract class MyUserDto extends UserForCarouselDto {
  abstract sensitiveInformation: {
    email: string;
  };
  wasSaved?: boolean;

  abstract coins: number;
  abstract levelableSkills: {
    name: string;
    level: number;
  }[];

  abstract tonWallets: {
    [walletName in 'telegram-wallet']?: {
      address: string;
      isAlive: boolean;
    };
  };
}
