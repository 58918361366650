import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { type CSSProperties, type ReactNode, type RefCallback, useMemo } from 'react';
import { Avatar, type AvatarProps } from '~/shared/atoms/Avatar/index.ts';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { noop } from '~/utils/index.ts';
import { useLoadJobPositionById } from '../../jobPosition/index.ts';
import { ContactProfile } from '../model/ContactProfile.ts';
import classes from './ContactRow.module.scss';

type Props = {
  className?: string;
  secondaryAction?: ReactNode;
  onRowClick?: () => void;
  avatarProps?: AvatarProps;
  isFavourite?: boolean;
  jobPositionId: undefined | null | number;
  secondaryRow?: ReactNode;
  hasNextPage?: boolean;
  isLoading?: boolean;
  style?: CSSProperties;
  forwardedRef?: RefCallback<HTMLElement>;
};

export { type Props as ContactRowProps };

export function ContactRow(props: Props) {
  const {
    secondaryAction,
    onRowClick = noop,
    className,
    avatarProps = {},
    isFavourite,
    jobPositionId,
    secondaryRow,
    isLoading,
    hasNextPage,
    style,
    forwardedRef,
    ...rest
  } = props;

  const name = useMemo(
    () => ContactProfile.getName(avatarProps),
    [avatarProps]
  );
  const { data: jobPosition } = useLoadJobPositionById(jobPositionId);

  if (isLoading) {
    if (hasNextPage) {
      return (
        <StyledListItem
          ref={forwardedRef}
          className={clsx(classes.listItem, className)}
          style={style}
          {...rest}
        >
          <Skeleton variant="circular" height="100%" width="100%" />
          <Skeleton variant="rectangular" height="100%" width="100%" />
        </StyledListItem>
      );
    }

    return 'Nothing more to load';
  }

  return (
    <StyledListItem
      ref={forwardedRef}
      secondaryAction={secondaryAction}
      onClick={onRowClick}
      className={clsx(classes.listItem, className)}
      style={style}
      {...rest}
    >
      <StyledListItemAvatar className={classes.avatarListItem}>
        {isFavourite ? (
          <div className={classes.favourite}>
            <Icon variant="star" color="secondary" />
          </div>
        ) : (
          <Avatar {...avatarProps} skeleton={{ height: 40 }} />
        )}
      </StyledListItemAvatar>

      <ListItemText
        primary={name}
        secondary={jobPositionId != null ? jobPosition?.name : secondaryRow}
      />
    </StyledListItem>
  );
}

const StyledListItem = styled(ListItem)({
  '&.MuiListItem-root': {
    display: 'grid',
  },
});
const StyledListItemAvatar = styled(ListItemAvatar)({
  '&.MuiListItemAvatar-root': {
    minWidth: 'unset',
  },
});
