import { Button, Divider, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Section } from '~/pages/profile/Section';
import { useLoadSkillsById } from '../api/useLoadSkillsById.ts';
import type { Skill } from '../model/Skill.ts';
import { SkillCard } from './SkillCard.tsx';
import classes from './ShortSkillList.module.scss';

const shortListAmount = 6;

type Props = {
  onShowAll: VoidFunction;
  skillIds: Skill['id'][] | undefined;
};

export const ShortSkillList = (props: Props) => {
  const { skillIds, onShowAll } = props;

  const { data: skills, isLoading } = useLoadSkillsById(skillIds);

  const skillSlice = useMemo(
    () => skills?.slice(0, shortListAmount) ?? [],
    [skills]
  );

  if (skillSlice.length === 0) {
    return null;
  }

  return (
    <>
      <Divider />

      <Section>
        <Typography variant="h3" className={classes.title}>
          Skills
        </Typography>

        {skills != null && skillSlice.length < skills.length && (
          <Button
            onClick={onShowAll}
            variant="text"
            color="primary"
            className={classes.link}
          >
            Show all
          </Button>
        )}

        <div className={classes.cards}>
          {skillSlice.map((skill) => (
            <SkillCard key={skill.id} skill={skill} />
          ))}
        </div>
      </Section>
    </>
  );
};
