import { ApiBase } from '../ApiBase.ts';
import { type JobPositionFakeApi } from './JobPositionFakeApi.ts';
import { type JobPositionRealApi } from './JobPositionRealApi.ts';

export class JobPositionApi extends ApiBase {
  constructor(
    private realApi: JobPositionRealApi,
    private fakeApi: JobPositionFakeApi
  ) {
    super();
  }

  createJobPosition: JobPositionRealApi['createJobPosition'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.createJobPosition(...args);
    }
    return this.realApi.createJobPosition(...args);
  };

  searchJobPositions: JobPositionRealApi['searchJobPositions'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.searchJobPositions(...args);
    }
    return this.realApi.searchJobPositions(...args);
  };

  searchJobPositionsById: JobPositionRealApi['searchJobPositionsById'] = (
    ...args
  ) => {
    if (this.isFakeApi) {
      return this.fakeApi.searchJobPositionsById(...args);
    }
    return this.realApi.searchJobPositionsById(...args);
  };
}
