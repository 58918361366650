import { faker } from '@faker-js/faker';
import dayjs from 'dayjs';
import { type FakeApiDatabase } from '../../fakeApiDatabase/index.ts';
import { createInitialQuestsForUser } from '../../fakeApiDatabase/tables/QuestTable.ts';
import type { UserWalletEntity } from '../../fakeApiDatabase/tables/UserTableEntity.ts';
import { routes } from '../../routing/index.ts';
import { findCurrentUser } from '../findCurrentUser.ts';
import { type TelegramRealApi } from './TelegramRealApi.ts';

export class TelegramFakeApi {
  private readonly findCurrentUser: () => ReturnType<typeof findCurrentUser>;

  constructor(readonly db: FakeApiDatabase) {
    this.findCurrentUser = findCurrentUser.bind(null, db);
  }

  /**
   * has contains following fields:
   * - "query_id"
   * - "user"
   * - "auth_date"
   * - "hash"
   * - "tgWebAppVersion"
   * - "tgWebAppPlatform"
   * - "tgWebAppThemeParams"
   * @example
   * hash = '#tgWebAppData=query_id...'
   * */
  login: TelegramRealApi['login'] = async (telegramHash) => {
    const searchParams = new URLSearchParams(
      telegramHash.replace('#tgWebAppData=', '')
    );

    let userJson = searchParams.get('user');
    if (!userJson) {
      userJson = JSON.stringify({
        id: faker.number.int(),
        first_name: faker.person.firstName(),
        last_name: faker.person.lastName(),
        username: faker.internet.userName(),
      });
    }

    const user = JSON.parse(userJson) as {
      id: number;
      first_name: string;
      last_name: string;
      username: string;
      allows_write_to_pm: boolean;
      is_premium: boolean;
      language_code: string;
    };

    const profiles = await this.db.user.getAll();
    let currentUser = profiles.find(
      (profile) => profile.sensitiveInformation.email === user.username
    );

    if (!currentUser) {
      currentUser = {
        userId: user.id,
        sensitiveInformation: {
          email: user.username,
        },
        firstName: user.first_name,
        lastName: user.last_name,
        timezoneId: dayjs.tz.guess(),
        department: null,
        jobPosition: null,
        profilePhotoNames: [],
        workInCompanyStartDate: null,
        careerStartDate: null,
        skills: [],
        interests: [],
        aboutMe: null,
        userAvatarPhotoFileName: null,
        connectedWallets: [],
        coins: 250,
        levelableSkills: [],
      };

      await this.db.user.insert(currentUser.userId, currentUser);

      const userQuests = createInitialQuestsForUser(currentUser, {
        questId: faker.number.int(),
        taskId: faker.number.int(),
      });

      for (const quest of userQuests) {
        await this.db.quest.insert(quest.questId, quest);
      }
    }

    await this.db.currentUser.update('userID', currentUser.userId);

    return {
      token: currentUser.userId.toString(),
      tonLink: '',
    };
  };

  connectTonWallet: TelegramRealApi['connectTonWallet'] = async () => {
    const redirectUrl =
      window.location.origin +
      routes.fake_ton_wallet_connect.url({
        walletId: faker.finance.ethereumAddress(),
      });
    return Promise.resolve(redirectUrl);
  };

  confirmTonWalletConnection = async (tonWalletId: string) => {
    const currentUser = await this.findCurrentUser();

    const tonWallet: UserWalletEntity = {
      id: tonWalletId,
      type: 'telegram-wallet',
    };

    await this.db.user.update(currentUser.userId, {
      ...currentUser,
      connectedWallets: [tonWallet],
    });
  };

  isTonWalletConnected: TelegramRealApi['isTonWalletConnected'] = async () => {
    const currentUser = await this.findCurrentUser();
    return currentUser.connectedWallets.some(
      (wallet) => wallet.type === 'telegram-wallet'
    );
  };
}
