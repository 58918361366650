import { RealApiBase } from '../RealApiBase.ts';
import { apiRoutes } from '../apiRoutes.ts';
import { type QuestDto, type QuestsDto } from './QuestDtos.ts';

export class QuestRealApi extends RealApiBase {
  getQuestsAsync = async (payload?: {
    isActive?: boolean;
    isTemplate?: boolean;
    mainQuest?: number;
    page?: number;
    userProfileId?: number;
    tags?: number[];
  }) => {
    const { isActive, isTemplate, mainQuest, page, userProfileId, tags } =
      payload ?? {};

    return this.get<QuestsDto>(apiRoutes.quest.quests.url(), {
      data: {
        is_active: isActive,
        is_template: isTemplate,
        main_quest: mainQuest,
        page: page,
        user_profile: userProfileId,
        tags: tags,
      },
    });
  };

  getQuestByIdAsync = async (questId: number) => {
    return this.get<QuestDto>(
      apiRoutes.quest.quests.questId(questId.toString()).url()
    );
  };

  completeTaskAsync = async (questId: number, taskId: number) => {
    return this.post<void>(
      apiRoutes.quest.quests.questId(questId.toString()).complete_task.url(),
      { taskId }
    );
  };

  deCompleteTaskAsync = async (questId: number, taskId: number) => {
    return this.post<void>(
      apiRoutes.quest.quests.questId(questId.toString()).decomplete_task.url(),
      { taskId }
    );
  };
}
