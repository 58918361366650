import { type FC, type PropsWithChildren } from 'react';
import { useRedirectToFirstLoginInformation } from '../api/useRedirectToFirstLoginInformation.tsx';

export const CheckFirstTimeLogin: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  useRedirectToFirstLoginInformation();

  return children;
};
