import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { loadMeetingsQueryKey } from '~/entities/meeting/index.ts';
import { MeetingApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';

export function useCancelMeetingMutation(options?: {
  onSuccessCallback?: VoidFunction;
}) {
  const { onSuccessCallback } = options ?? {};

  const meetingApi = useDi(MeetingApi);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['cancel meeting'],
    mutationFn: meetingApi.cancelMeeting,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: loadMeetingsQueryKey(),
      });
      onSuccessCallback?.();
    },
    onError: (error) => {
      notify({
        message: `Cannot cancel meeting ${error.message}`,
        variant: 'error',
      });
    },
  });
}
