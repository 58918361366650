import { useMatch, useParams } from 'react-router-dom';
import { useParseInt } from '~/shared/numbers/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { useCurrentUser } from '~/entities/profile/crossExports.ts';
import { useUserById } from '../../colleague/index.ts';
import { useLoadJobPositionById } from './useLoadJobPositionById.ts';

export function useProfileJobPosition() {
  const currentProfileMatch = useMatch(routes.profile.url());
  const colleagueProfileMatch = useMatch(routes.colleagues.userId().url());
  const { userId: stringUserId } = useParams<{ userId: string | undefined }>();
  const userId = useParseInt(stringUserId);

  const isCurrentProfile = currentProfileMatch != null;
  const isColleagueProfile = colleagueProfileMatch != null;

  const { data: currentProfile, isLoading: currentProfileIsLoading } =
    useCurrentUser({
      enabled: isCurrentProfile,
    });
  const {
    data: currentProfileJobPosition,
    isLoading: currentProfileJobPositionIsLoading,
  } = useLoadJobPositionById(currentProfile?.jobPositionId, {
    enabled: isCurrentProfile,
  });

  const { data: profile, isLoading: profileIsLoading } = useUserById(userId, {
    enabled: isColleagueProfile,
  });
  const { data: profileJobPosition, isLoading: profileJobPositionIsLoading } =
    useLoadJobPositionById(profile?.jobPositionId, {
      enabled: isColleagueProfile,
    });

  return {
    jobPosition: currentProfileJobPosition || profileJobPosition,
    jobPositionIsLoading:
      currentProfileIsLoading ||
      currentProfileJobPositionIsLoading ||
      profileIsLoading ||
      profileJobPositionIsLoading,
  };
}
