import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { type Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Meetings, type MeetingsProps } from '~/entities/meeting/index.ts';
import {
  MeetingsSwitcher,
  SelectDateForMeeting,
} from '~/features/meeting/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { type Tab, Tabs } from '~/shared/molecules/Tabs/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { FooterTabs } from '~/shared/templates/FooterTabs.tsx';
import { Navbar } from '~/shared/ui/index.ts';
import classes from './MeetingsDisplay.module.scss';

type MeetingTab = Tab & {
  kind: MeetingsProps['kind'];
};

const MeetingsDisplay = () => {
  const [view, setView] = useState<'calendar' | 'list'>('calendar');
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  const [activeMeetingTabIndex, setActiveMeetingTabIndex] = useState(0);

  const meetingTabs = useMemo<MeetingTab[]>(
    () => [
      {
        kind: 'accepted',
        label: 'Meetings',
        // counter: acceptedMeetings.length,
        counter: 0,
      },
      {
        kind: 'invites',
        label: 'Invites',
        // counter: invites.length,
        counter: 0,
      },
      {
        kind: 'completed',
        label: 'Completed',
        // counter: completed.length,
        counter: 0,
      },
    ],
    []
  );

  const isAcceptedMeetingsTab = activeMeetingTabIndex === 0;
  const isInvitationsTab = activeMeetingTabIndex === 1;
  const isEndedMeetingsTab = activeMeetingTabIndex === 2;

  const navigate = useNavigate();

  return (
    <>
      <Navbar title="Meetings" left={<BackIconNavigationButton />} />

      <ContentSlot className={classes.root}>
        <div className={classes.header}>
          <MeetingsSwitcher
            checked={view === 'calendar'}
            setChecked={(checked) => {
              if (checked) {
                setView('calendar');
              } else {
                setView('list');
                setSelectedDate(null);
              }
            }}
          />

          <Tabs
            className={classes.meetingTabs}
            tabs={meetingTabs}
            activeTabIndex={activeMeetingTabIndex}
            onChangeActiveTab={(event, tabIndex) => {
              setActiveMeetingTabIndex(tabIndex);
            }}
          />
        </div>

        {view === 'calendar' && (
          <SelectDateForMeeting
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            kind={meetingTabs[activeMeetingTabIndex].kind}
            disablePast={isAcceptedMeetingsTab || isInvitationsTab}
            disableFuture={isEndedMeetingsTab}
          />
        )}

        {view !== 'calendar' && (
          <div className={classes.content}>
            <Meetings
              kind={meetingTabs[activeMeetingTabIndex].kind}
              selectedDate={selectedDate}
            />
          </div>
        )}

        <SpeedDial
          ariaLabel="Add new meeting"
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
          onClick={() =>
            navigate(
              routes.meetings.new.url({
                colleagueIds: [],
              })
            )
          }
        />
      </ContentSlot>

      <FooterTabs />
    </>
  );
};

export { MeetingsDisplay };
