type ItemIndex = number;

// complexity is O(2n)
function distinct<Item, ComparedItem = Item>(
  array: Item[],
  getComparedObject?: (item: Item) => ComparedItem
) {
  if (typeof getComparedObject !== 'function') {
    getComparedObject = (item: Item) => item as unknown as ComparedItem;
  }

  const map = new Map<ComparedItem, ItemIndex>();
  array.forEach((item, index) => {
    const comparedItem = getComparedObject!(item);
    if (!map.has(comparedItem)) {
      map.set(comparedItem, index);
    }
  });

  return array.filter((item, index) => {
    const comparedItem = getComparedObject!(item);
    const foundIndex = map.get(comparedItem);
    return foundIndex === index;
  });
}

export { distinct };
