import { type FC } from 'react';
import { ConfirmButton, useNavbarLoader } from '~/shared/ui/index.ts';
import { useSaveProfileSkills } from '../api/useSaveProfileSkills.ts';
import { useProfileSkillsEditContext } from '../model/ProfileSkillsEditContext.tsx';

type Props = {
  disabled?: boolean;
};

export const SaveSkillsButton: FC<Props> = (props) => {
  const { disabled } = props;

  const { selectedSkillIds } = useProfileSkillsEditContext() ?? {};
  const { mutate: saveSkills, isPending } = useSaveProfileSkills();
  useNavbarLoader(isPending);

  return (
    <ConfirmButton
      onClick={() => {
        if (selectedSkillIds) {
          saveSkills(selectedSkillIds);
        }
      }}
      disabled={disabled || isPending}
    />
  );
};
