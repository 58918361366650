import { Switch } from '@mui/material';
import { useCallback } from 'react';
import { useBoolean } from 'usehooks-ts';
import {
  getTelegramWebAppInitData,
  useTelegramLoginMutation,
} from '~/entities/auth/index.ts';
import { Button } from '~/shared/molecules/Button/index.ts';

export const TelegramLoginButton = () => {
  const { mutate: login, isPending: loginInProgress } =
    useTelegramLoginMutation();

  const { value: showInitData, toggle } = useBoolean(false);

  const onLogin = useCallback(() => {
    const telegramInitData = getTelegramWebAppInitData();
    if (telegramInitData) {
      login(telegramInitData);
    }
  }, [login]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={loginInProgress}
        onClick={onLogin}
      >
        Telegram Log in
      </Button>

      <div>
        <label>
          Show telegram initData
          <Switch checked={showInitData} onChange={toggle} />
        </label>

        {showInitData && <pre>{window.Telegram?.WebApp?.initData}</pre>}
      </div>
    </>
  );
};
