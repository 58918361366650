import { type FC } from 'react';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { ConfirmButton, useDisplayLoader } from '~/shared/ui/index.ts';
import { type CareerSavingData, useSaveCareer } from '../api/useSaveCareer.ts';
import { useProfileCareerEditContext } from '../model/ProfileCareerEditContext.tsx';

type Props = Pick<
  CareerSavingData,
  'joinedCompanyDate' | 'workExperienceSince'
>;

export const SaveCareerButton: FC<Props> = (props) => {
  const { data: profile } = useCurrentUser();

  const { mutate: saveCareer, isPending } = useSaveCareer();
  useDisplayLoader(isPending);

  const { selectedDepartmentId, selectedJobPositionId } =
    useProfileCareerEditContext() ?? {};

  return (
    <ConfirmButton
      onClick={() =>
        saveCareer({
          ...props,
          departmentId: selectedDepartmentId ?? profile?.departmentId ?? null,
          jobPositionId:
            selectedJobPositionId ?? profile?.jobPositionId ?? null,
        })
      }
    />
  );
};
