import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { loadLoadJobPositionsQueryKeys } from '~/entities/jobPosition/index.ts';
import { JobPositionApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';

export function useCreateJobPosition() {
  const queryClient = useQueryClient();
  const jobPositionApi = useDi(JobPositionApi);

  return useMutation({
    mutationKey: ['create job position'],
    mutationFn: jobPositionApi.createJobPosition,
    onError: (error) => {
      notify({
        message: `Cant create job position: ${error.message}`,
        variant: 'error',
      });
    },
    onSuccess: () => {
      notify({
        message: 'Job position created',
        variant: 'success',
      });

      queryClient.invalidateQueries({
        queryKey: loadLoadJobPositionsQueryKeys(),
      });
    },
  });
}
