import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  type Chat,
  ChatsInfiniteList,
  PrivateChat,
} from '~/entities/chat/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { NavbarInput } from '~/shared/molecules/NavbarInput/index.ts';
import { notify } from '~/shared/notification/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { FooterTabs } from '~/shared/templates/FooterTabs.tsx';
import { Navbar } from '~/shared/ui/index.ts';
import classes from './ChatsDisplay.module.scss';

export const ChatsDisplay = () => {
  const navigate = useNavigate();
  const [searchedString, setSearchedString] = useState('');

  const onAddNewChatClick = useCallback(() => {
    navigate(routes.chat.new.url());
  }, [navigate]);

  const onRowClick = useCallback(
    (chat: Chat) => {
      if (chat instanceof PrivateChat) {
        navigate(routes.chat.userId(chat.interlocutorId.toString()).url());
      } else {
        notify({
          message:
            "We can't open this chat, because of its type. This functionality is under construction",
          variant: 'error',
        });
      }
    },
    [navigate]
  );

  return (
    <>
      <Navbar
        title="Chats"
        left={<BackIconNavigationButton />}
        classes={{
          children: classes.navbarChildren,
        }}
        withBorderBottom
      >
        {/* todo: make search in all messages of all chats */}
        <NavbarInput
          placeholder="Search"
          value={searchedString}
          onChange={setSearchedString}
        />
      </Navbar>

      <ContentSlot>
        <ChatsInfiniteList
          searchedName={searchedString}
          onRowClick={onRowClick}
          emptyListMessage="You haven't chatted with anyone yet."
        />
      </ContentSlot>

      <SpeedDial
        ariaLabel="Add new meeting"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClick={onAddNewChatClick}
      />

      <FooterTabs />
    </>
  );
};
