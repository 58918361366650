import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { memo } from 'react';
import { type ColleagueProfile } from '~/entities/colleague/index.ts';
import { useLoadInterestsById } from '~/entities/interest/index.ts';
import { useLoadJobPositionById } from '~/entities/jobPosition/index.ts';
import { useLoadSkillsById } from '~/entities/skill/index.ts';
import { useColleaguesFilterContext } from '~/features/colleague/index.ts';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { useNavbarLoader } from '~/shared/ui/index.ts';
import { sortListByFilter } from './sortListByFilter.ts';
import { TextOrChip } from './TextOrChip.tsx';
import classes from './FilteredColleagueInfo.module.scss';

type Props = {
  colleague: ColleagueProfile;
  onLike: () => void;
  onDislike: () => void;
  handleHeaderClick: () => void;
};

const component = memo(FilteredColleagueInfo);
export { component as FilteredColleagueInfo };

function FilteredColleagueInfo(props: Props) {
  const { colleague, onDislike, onLike, handleHeaderClick } = props;

  const { selectedJobPositionIds, selectedInterestIds, selectedSkillIds } =
    useColleaguesFilterContext() ?? {};

  const { data: interests, isLoading: interestsAreLoading } =
    useLoadInterestsById(colleague?.interestIds);
  const { data: skills, isLoading: skillsAreLoading } = useLoadSkillsById(
    colleague?.skillIds
  );
  const { data: jobPosition, isLoading: bobPositionIsLoading } =
    useLoadJobPositionById(colleague?.jobPositionId);

  useNavbarLoader(
    interestsAreLoading || skillsAreLoading || bobPositionIsLoading
  );

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <div className={classes.header}>
          <h3 className={classes.name} onClick={handleHeaderClick}>
            {colleague.name}
          </h3>

          {jobPosition != null && (
            <p
              className={clsx(classes.position, {
                [classes.presentedInFilter]: selectedJobPositionIds?.has(
                  jobPosition.id
                ),
              })}
            >
              {jobPosition.name}
            </p>
          )}
        </div>

        <div className={classes.description}>
          {colleague.interestIds.length > 0 && (
            <p className={classes.interests}>
              <Typography
                className={classes.listTitle}
                variant="body1"
                component="span"
              >
                Interests:
              </Typography>

              {sortListByFilter(interests, selectedInterestIds)?.map(
                (interest, index) => {
                  const isPresentedInFilter =
                    selectedInterestIds?.has(interest.id) ?? false;

                  const isNotLastItem =
                    index < colleague.interestIds.length - 1;

                  return (
                    <TextOrChip
                      key={interest.id}
                      text={interest?.name ?? ''}
                      isHighLighted={isPresentedInFilter}
                      addComma={isNotLastItem}
                    />
                  );
                }
              )}
            </p>
          )}

          {colleague.skillIds.length > 0 && (
            <div className={classes.skills}>
              <Typography
                className={classes.listTitle}
                variant="body1"
                component="span"
              >
                Skills:
              </Typography>

              {sortListByFilter(skills, selectedSkillIds)?.map(
                (skill, index) => {
                  const isPresentedInFilter =
                    selectedSkillIds?.has(skill.id) ?? false;

                  const isNotLastItem = index < colleague.skillIds.length - 1;

                  return (
                    <TextOrChip
                      key={skill.id}
                      text={skill?.name ?? ''}
                      isHighLighted={isPresentedInFilter}
                      addComma={isNotLastItem}
                    />
                  );
                }
              )}
            </div>
          )}

          {colleague.bio && (
            <Typography
              component="p"
              className={clsx(classes.bio, classes.secondText)}
            >
              {colleague.bio}
            </Typography>
          )}
        </div>
      </div>

      <div className={classes.buttons}>
        <SkipIconButton className={classes.button} onClick={onDislike}>
          <Icon variant="cross" />
        </SkipIconButton>

        <LikeIconButton className={classes.button} onClick={onLike}>
          <Icon variant="smile" />
        </LikeIconButton>
      </div>
    </div>
  );
}

const SkipIconButton = styled(IconButton)({
  '&.MuiIconButton-root': {
    backgroundColor: 'rgba(255 95 97 / 100%)', // red
  },
  '&.MuiIconButton-root:hover': {
    backgroundColor: 'rgba(255 95 97 / 80%)', // red
  },
});
const LikeIconButton = styled(IconButton)({
  '&.MuiIconButton-root': {
    backgroundColor: 'rgba(30 205 140 / 100%)', // green
  },
  '&.MuiIconButton-root:hover': {
    backgroundColor: 'rgba(30 205 140 / 80%)', // green
  },
});
