import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const CrossIcon = (props: Props) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9097 11.9991L18.2062 7.71268C18.3943 7.52453 18.5 7.26935 18.5 7.00326C18.5 6.73718 18.3943 6.482 18.2062 6.29385C18.018 6.1057 17.7628 6 17.4967 6C17.2307 6 16.9755 6.1057 16.7873 6.29385L12.5009 10.5903L8.21439 6.29385C8.02624 6.1057 7.77105 6 7.50497 6C7.23889 6 6.9837 6.1057 6.79555 6.29385C6.60741 6.482 6.50171 6.73718 6.50171 7.00326C6.50171 7.26935 6.60741 7.52453 6.79555 7.71268L11.092 11.9991L6.79555 16.2856C6.7019 16.3785 6.62757 16.489 6.57684 16.6108C6.52612 16.7325 6.5 16.8631 6.5 16.995C6.5 17.1269 6.52612 17.2575 6.57684 17.3793C6.62757 17.501 6.7019 17.6116 6.79555 17.7044C6.88844 17.7981 6.99895 17.8724 7.12071 17.9232C7.24247 17.9739 7.37307 18 7.50497 18C7.63687 18 7.76747 17.9739 7.88923 17.9232C8.01099 17.8724 8.1215 17.7981 8.21439 17.7044L12.5009 13.408L16.7873 17.7044C16.8802 17.7981 16.9907 17.8724 17.1125 17.9232C17.2342 17.9739 17.3648 18 17.4967 18C17.6286 18 17.7592 17.9739 17.881 17.9232C18.0028 17.8724 18.1133 17.7981 18.2062 17.7044C18.2998 17.6116 18.3741 17.501 18.4249 17.3793C18.4756 17.2575 18.5017 17.1269 18.5017 16.995C18.5017 16.8631 18.4756 16.7325 18.4249 16.6108C18.3741 16.489 18.2998 16.3785 18.2062 16.2856L13.9097 11.9991Z"
        fill={color}
      />
    </svg>
  );
};

export type { Props as CrossIconProps };
export { CrossIcon };
