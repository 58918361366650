import { type FC } from 'react';
import { Button, type ButtonProps } from '../../molecules/Button';

type Props = Pick<
  ButtonProps<'contained'>,
  'className' | 'onClick' | 'disabled'
>;

export const CancelButton: FC<Props> = (props) => {
  const { onClick } = props;

  if (!onClick) {
    return null;
  }

  return (
    <Button {...props} variant="text" color="inherit">
      Cancel
    </Button>
  );
};
