import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Icon, type IconVariant } from '~/shared/atoms/icons';
import classes from './TagCard.module.scss';

type Props = {
  icon: IconVariant;
  name: string;
  confirmsCount: number;
};

const TagCard = (props: Props) => {
  const { icon, name, confirmsCount } = props;

  return (
    <Tooltip title={name}>
      <div className={classes.root}>
        <Typography className={classes.name} noWrap>
          {name}
        </Typography>

        <div className={classes.iconPlacementInGrid}>
          <div className={classes.iconContainer}>
            <Icon variant={icon} />
          </div>
        </div>

        <Typography className={classes.confirms}>
          <b>{confirmsCount}</b> Confirmed
        </Typography>
      </div>
    </Tooltip>
  );
};

export { TagCard };
export type { Props as TagCardProps };
