import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { useMeetingQuery } from '~/entities/meeting/api/useMeetingQuery.ts';
import { loadMeetingsQueryKey } from '~/entities/meeting/api/useMeetingsQuery.ts';
import {
  type Meeting,
  type MeetingDraft,
  type MeetingMember,
} from '~/entities/meeting/index.ts';
import { MeetingApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';

export function useUpdateMeetingQuery(options?: {
  meetingId?: Meeting['meetingId'];
  onSuccessCallback?: VoidFunction;
}) {
  const { meetingId, onSuccessCallback } = options ?? {};

  const queryClient = useQueryClient();
  const meetingApi = useDi(MeetingApi);
  const { data: editedMeeting } = useMeetingQuery(meetingId);

  return useMutation({
    mutationKey: ['create meeting'],
    mutationFn: async (meetingDraft: MeetingDraft) => {
      if (!editedMeeting) {
        throw new Error('There is no edited meeting');
      }
      if (!meetingDraft) {
        throw new Error('Meeting draft is null');
      }
      if (meetingDraft.meetingId == null) {
        throw new Error('Meeting ID is null');
      }
      if (!meetingDraft.name) {
        throw new Error('Meeting Title Cannot Be Empty');
      }
      if (!meetingDraft.invitedMembers.length) {
        throw new Error('Invite At Least One Participant');
      }

      await meetingApi.updateMeeting({
        meetingId: meetingDraft.meetingId,
        name: meetingDraft.name,
        startAt: meetingDraft.when.toISOString(),
        howLong: meetingDraft.howLong?.asMinutes() ?? null,
        // invitedUserIds: meetingDraft.invitedMembers.map(
        //   (member) => member.userId
        // ),
        description: '',
      });

      await meetingApi.setMeetingReminder(
        meetingDraft.meetingId,
        meetingDraft.remindBefore?.asMinutes() ?? null
      );

      const memberMapper = (member: MeetingMember) => member.userId;

      const existedAcceptedIds =
        editedMeeting.acceptedMembers.map(memberMapper);
      const newAcceptedIds = meetingDraft.acceptedMembers.map(memberMapper);
      const existedInvitedIds = editedMeeting.invitedMembers.map(memberMapper);
      const newInvitedIds = meetingDraft.invitedMembers.map(memberMapper);

      const kickedMemberIds_1 = new Set(existedAcceptedIds).difference(
        new Set(newAcceptedIds)
      );
      const kickedMemberIds_2 = new Set(existedInvitedIds).difference(
        new Set(newInvitedIds)
      );
      const kickedUserIds = Array.from(kickedMemberIds_1).concat(
        Array.from(kickedMemberIds_2)
      );

      // todo: there is no endpoint for adding members to meeting
      // const newInvites = Array.from(new Set(newInvitedIds).difference(new Set(existedInvitedIds)));

      await Promise.all(
        kickedUserIds.map((userId) =>
          meetingApi.kickUserFromMeeting(editedMeeting.meetingId, userId)
        )
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: loadMeetingsQueryKey(),
      });
      onSuccessCallback?.();
    },
    onError: (error) => {
      notify({
        message: `Cant remove messages with user: ${error.message}`,
        variant: 'error',
      });
    },
  });
}
