import { Typography } from '@mui/material';
import { type FC } from 'react';
import { Icon } from '~/shared/atoms/icons';
import { Slat } from '~/shared/molecules/Slat';

type Props = {
  onClick: VoidFunction;
  expandMore: boolean;
};

export const EditProfileSlat: FC<Props> = (props) => {
  const { expandMore, onClick } = props;

  return (
    <Slat
      iconProps={{
        variant: 'profileOutlined',
        color: 'text',
      }}
      onClick={onClick}
      rightChildren={
        <Icon variant={expandMore ? 'expandLess' : 'expandMore'} />
      }
    >
      <Typography>Edit profile</Typography>
    </Slat>
  );
};
