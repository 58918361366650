import { useEffect, useMemo } from 'react';
import { guid } from '~/utils/index.ts';
import {
  addDisplayLoader,
  removeDisplayLoader,
} from './model/useDisplayLoaderStore.ts';

export function useDisplayLoader(loading: boolean) {
  const loaderId = useMemo(() => guid(), []);

  useEffect(() => {
    addDisplayLoader({
      id: loaderId,
      loading,
    });

    return () => {
      removeDisplayLoader(loaderId);
    };
  }, [loaderId, loading]);
}
