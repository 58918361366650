import { type FC } from 'react';
import { Button, type ButtonProps } from '../../molecules/Button';

type Props = Pick<
  ButtonProps<'contained'>,
  'onClick' | 'className' | 'disabled' | 'children'
>;

export const ConfirmButton: FC<Props> = (props) => {
  const { children = 'Готово' } = props;

  return (
    <Button {...props} variant="text" color="primary">
      {children}
    </Button>
  );
};
