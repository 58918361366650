import { type QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import {
  type ForwardedRef,
  forwardRef,
  type ReactElement,
  type RefAttributes,
} from 'react';
import type { PaginationDto } from '../../api/PaginationDto.ts';
import {
  InfiniteScrollRender,
  type InfiniteScrollRenderProps,
  type InfiniteScrollRenderRef,
} from './InfiniteScrollRender.tsx';

type Props<RowEntity extends {}> = Omit<
  InfiniteScrollRenderProps<RowEntity>,
  'query'
> & {
  apiRequest: (offset: number) => Promise<PaginationDto<RowEntity>>;
  queryKeys: QueryKey;
};

export type PageOffsetInfiniteScrollRef<RowEntity extends {}> =
  InfiniteScrollRenderRef<RowEntity>;

const component = forwardRef(PageOffsetInfiniteScroll) as <
  RowEntity extends {},
>(
  props: Props<RowEntity> &
    RefAttributes<PageOffsetInfiniteScrollRef<RowEntity>>
) => ReactElement;

export {
  component as PageOffsetInfiniteScroll,
  type Props as PaginatedInfiniteScrollProps,
};

function PageOffsetInfiniteScroll<RowEntity extends {}>(
  props: Props<RowEntity>,
  ref: ForwardedRef<PageOffsetInfiniteScrollRef<RowEntity>>
) {
  const { apiRequest, queryKeys, ...infiniteListProps } = props;

  const query = useInfiniteQuery({
    queryKey: queryKeys,
    initialPageParam: 0,
    queryFn: ({ pageParam }) => apiRequest(pageParam),
    getNextPageParam: (lastGroup, paginatedResults) => {
      if (!lastGroup) {
        return 0;
      }
      if (lastGroup.items.length === 0) {
        return null;
      }
      return paginatedResults.length;
    },
  });

  return (
    <InfiniteScrollRender ref={ref} query={query} {...infiniteListProps} />
  );
}
