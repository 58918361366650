import type { RouteObject } from 'react-router-dom';
import { routes } from '~/shared/routing/index.ts';
import { Display } from '~/shared/ui/index.ts';
import { LevelPage } from '../ui/LevelPage.tsx';

export const levelRoutes: RouteObject[] = [
  {
    path: routes.profile.settings.level.relativeUrl(),
    element: (
      <Display documentTitle="Level" animationVariant="card-effect">
        <LevelPage />
      </Display>
    ),
  },
];
