import { RealApiBase } from '../RealApiBase.ts';
import { apiRoutes, type Search, type TagDto } from '../index.ts';
import type { PaginationDto } from '../PaginationDto.ts';

export class SkillRealApi extends RealApiBase {
  createSkill = (skillName: string) => {
    return this.post<{ createdTag: TagDto }>(
      apiRoutes.tags.skill.create.url(),
      {
        name: skillName,
      }
    );
  };

  searchSkills = (search: Search<'skillName'>) => {
    return this.post<PaginationDto<TagDto>>(apiRoutes.tags.skill.search.url(), {
      query: search.skillName,
      page: search.page,
      pageSize: search.pageSize,
    });
  };

  searchSkillsById = (skillIds: number[]) => {
    return this.post<PaginationDto<TagDto>>(
      apiRoutes.tags.skill.search_by_ids.url(),
      {
        tagIds: skillIds,
      }
    );
  };
}
