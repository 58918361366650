import { fakerRU as faker } from '@faker-js/faker';
import dayjs from 'dayjs';
import { type FakeApiDatabase } from '../../fakeApiDatabase/index.ts';
import type { UserTableEntity } from '../../fakeApiDatabase/tables/UserTableEntity.ts';
import { ApiError } from '../ApiError.ts';
import { ApiResponseStatus } from '../ApiResponseStatus.ts';
import { type AuthRealApi } from './AuthRealApi.ts';

export class AuthFakeApi {
  constructor(readonly db: FakeApiDatabase) {}

  login = async (login: string): ReturnType<AuthRealApi['login']> => {
    const profiles = await this.db.user.getAll();
    const currentUser = profiles.find(
      (profile) => profile.sensitiveInformation.email === login
    );
    if (!currentUser) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: 'user was not found',
      });
    }

    await this.db.currentUser.update('userID', currentUser.userId);

    return {
      token: currentUser.userId.toString(),
    };
  };

  register: AuthRealApi['register'] = async (dto) => {
    const newUser: UserTableEntity = {
      userId: faker.number.int({ max: 1000 }),
      sensitiveInformation: {
        email: dto.email,
      },
      firstName: dto.firstName,
      lastName: dto.lastName,
      timezoneId: dayjs.tz.guess(),
      department: null,
      jobPosition: null,
      profilePhotoNames: [],
      workInCompanyStartDate: null,
      careerStartDate: null,
      skills: [],
      interests: [],
      aboutMe: null,
      userAvatarPhotoFileName: null,
      connectedWallets: [],
      coins: 250,
      levelableSkills: [],
    };

    await this.db.user.insert(newUser.userId, newUser);
    await this.db.currentUser.update('userID', newUser.userId);

    return {
      token: newUser.userId.toString(),
    };
  };

  isAuthTokenValid: AuthRealApi['isAuthTokenValid'] = async (
    authorizationToken
  ) => {
    const existedToken = await this.db.currentUser.get('userID');
    if (
      !existedToken ||
      existedToken.toString() != authorizationToken.toString()
    ) {
      throw new ApiError({
        status: ApiResponseStatus.BadRequest,
      });
    }

    const currentUser = await this.db.user.get(existedToken);
    if (!currentUser) {
      throw new ApiError({
        status: ApiResponseStatus.BadRequest,
      });
    }

    const tonWallet = currentUser.connectedWallets.find(
      (wallet) => wallet.type === 'telegram-wallet'
    );

    return {
      user: {
        userId: currentUser.userId,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        userAvatarPhotoFileName: currentUser.userAvatarPhotoFileName,
        department: currentUser.department,
        jobPosition: currentUser.jobPosition,
        aboutMe: currentUser.aboutMe,
        timezoneId: currentUser.timezoneId,
        workInCompanyStartDate: currentUser.workInCompanyStartDate,
        careerStartDate: currentUser.careerStartDate,
        skills: currentUser.skills,
        interests: currentUser.interests,
        profilePhotoNames: currentUser.profilePhotoNames,
        sensitiveInformation: currentUser.sensitiveInformation,
        wasSaved: currentUser.wasSaved,
        tonWallets: tonWallet
          ? {
              'telegram-wallet': {
                address: tonWallet.id,
                isAlive: true,
              },
            }
          : {},
        coins: currentUser.coins,
        levelableSkills: currentUser.levelableSkills,
      },
    };
  };

  logout: AuthRealApi['logout'] = async () => {
    await this.db.currentUser.delete('userID');
  };
}
