import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useBoolean } from 'usehooks-ts';
import { Icon } from '../atoms/icons/index.ts';
import { FormField } from '../form/FormField.tsx';
import {
  TextField,
  type TextFieldProps,
} from '../organisms/TextField/index.ts';

type Props = Omit<TextFieldProps, 'endAdornment'> & {
  name: 'password' | 'confirmPassword';
};

export const PasswordField = (props: Props) => {
  const { name, ...rest } = props;
  const { value: isPasswordVisible, toggle: togglePasswordVisibility } =
    useBoolean(false);

  return (
    <FormField<{ [key in typeof name]: string | undefined }> name={name}>
      {({ register, errors }) => (
        <TextField
          type={isPasswordVisible ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={togglePasswordVisibility}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
                edge="end"
              >
                {isPasswordVisible ? (
                  <Icon variant="hiddenPassword" />
                ) : (
                  <Icon variant="visiblePassword" />
                )}
              </IconButton>
            </InputAdornment>
          }
          clearable
          isError={errors?.[name] != null}
          helpText={errors?.[name]?.message}
          {...rest}
          {...register(rest)}
        />
      )}
    </FormField>
  );
};
