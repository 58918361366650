import { createContext, useContext } from 'react';
import type { SubmitHandler, UseFormReturn } from 'react-hook-form';

export type FormContextType<FormValues extends object> = {
  form: UseFormReturn<FormValues>;
  setOnValidSubmit: (handler: SubmitHandler<FormValues>) => void;
};

export const FormContext = createContext<FormContextType<any> | null>(null);

export const useFormContext = <FormValues extends object>() =>
  useContext(FormContext) as FormContextType<FormValues> | null;
