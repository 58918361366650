import { container, type ContainerImpl } from 'cheap-di';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useFakeApi } from '~/features/fake-api/index.ts';
import { configureApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';
import { resetAllStores } from '~/shared/store/index.ts';
import { configureMapper } from './configureMapper.ts';

export function useAppConfiguration() {
  const [configuredContainer, setConfiguredContainer] =
    useState<ContainerImpl | null>(null);

  const isMirageApi = useFakeApi();

  useEffect(() => {
    dayjs.locale('en');
    const mapper = configureMapper();

    (async () => {
      await configureApi(container, { isMirageApi });
      setConfiguredContainer(container);
    })();

    return () => {
      mapper.clear();
      container.clear();
      setConfiguredContainer(null);
    };
  }, [isMirageApi]);

  useOnErrorNotifications();

  return configuredContainer;
}

function useOnErrorNotifications() {
  useEffect(() => {
    window.onerror = (event, source, lineno, colno, error) => {
      notify({
        variant: 'error',
        message: JSON.stringify(
          { message: error?.message, stack: error?.stack },
          null,
          2
        ),
        manualDismiss: true,
        dismissAction: {
          buttonText: 'Очистить кэш',
          onClick: () => {
            resetAllStores();

            setTimeout(() => {
              window.location.reload();
            });
          },
        },
      });
    };
  }, []);
}
