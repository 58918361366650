import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const BookSourceIcon = (props: Props) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 19V5C4 3.89543 4.89543 3 6 3H19.4C19.7314 3 20 3.26863 20 3.6V16.7143"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M6 17H20" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M6 21H20" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path
        d="M6 21C4.89543 21 4 20.1046 4 19C4 17.8954 4.89543 17 6 17"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 6V10" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path
        d="M12 14.01L12.01 13.9989"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export type { Props as BookSourceIconProps };
export { BookSourceIcon };
