import { useEffect } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  useNavigate,
} from 'react-router-dom';
import { RequireAuth } from '~/entities/auth/ui/RequireAuth.tsx';
import { CheckFirstTimeLogin } from '~/entities/profile/index.ts';
import { authRouteObject } from '~/pages/authorization/index.ts';
import { chatRouteObject } from '~/pages/chat/index.ts';
import { colleagueRouteObjects } from '~/pages/colleagues/index.ts';
import { contactRouteObjects } from '~/pages/contacts/index.ts';
import { meetingRouteObject } from '~/pages/meetings/index.ts';
import { petsRouteObject } from '~/pages/pets/index.ts';
import { profileRouteObject } from '~/pages/profile/index.ts';
import { questsRouteObject } from '~/pages/Quests/questsRouteObject.tsx';
import { Sandbox } from '~/pages/Sandbox/index.ts';
import { FakeTonWalletConnectDisplay } from '~/pages/wallet/index.ts';
import { routes } from '~/shared/routing/routes.ts';
import { Display } from '~/shared/ui/index.ts';
import { App } from '../ui/App.tsx';
import { ErrorPage } from '../ui/ErrorPage.tsx';

export const router = createBrowserRouter([
  {
    path: routes.url(),
    element: (
      <App>
        <Outlet />
      </App>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to={routes.profile.relativeUrl()} replace />,
      },
      ...authRouteObject,
      {
        path: routes.sandbox.relativeUrl(),
        element: (
          <Display documentTitle="sandbox">
            <Sandbox />
          </Display>
        ),
      },
      {
        path: '*',
        element: <FallbackRedirect />,
      },
    ],
  },
  {
    path: routes.url(),
    errorElement: <ErrorPage />,
    element: (
      <App>
        <RequireAuth>
          <CheckFirstTimeLogin>
            <Outlet />
          </CheckFirstTimeLogin>
        </RequireAuth>
      </App>
    ),
    children: [
      ...contactRouteObjects,
      ...chatRouteObject,
      ...meetingRouteObject,
      ...colleagueRouteObjects,
      ...profileRouteObject,
      ...questsRouteObject,
      ...petsRouteObject,
      {
        path: routes.fake_ton_wallet_connect.relativeUrl(),
        element: <FakeTonWalletConnectDisplay />,
      },
    ],
  },
]);

function FallbackRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(routes.profile.url());
  }, [navigate]);

  return null;
}
