import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { useCallback } from 'react';
import {
  CONTACTS_PAGE_SIZE,
  MatcherApi,
  UserForCarouselDto,
} from '~/shared/api/index.ts';
import type { PaginationDto } from '~/shared/api/PaginationDto.ts';
import { useLoadProfileImageUrls } from '../../profile/index.ts';
import { ContactProfile } from '../model/ContactProfile.ts';
import { loadContactsQueryByNameKeys } from './useContactsQueryByName.ts';

export const infiniteContactsListQueryKeys = (
  contactName?: ContactProfile['name']
) => [...loadContactsQueryByNameKeys(contactName), 'infinitely'];

export function useInfiniteContactsListRequest(
  searchedContactName?: ContactProfile['name']
) {
  const matcherApi = useDi(MatcherApi);
  const loadProfileImageUrls = useLoadProfileImageUrls();

  return useCallback(
    async (offset: number): Promise<PaginationDto<ContactProfile>> => {
      const { items: contactDtos, totalItemsCount } =
        await matcherApi.getMatches({
          pageSize: CONTACTS_PAGE_SIZE,
          page: offset,
          userName: searchedContactName,
        });

      const contacts = contactDtos.map((dto) =>
        Mapper.map(UserForCarouselDto, ContactProfile, dto)
      );

      await loadProfileImageUrls(contacts);

      return {
        items: contacts,
        totalCount: totalItemsCount,
      };
    },
    [loadProfileImageUrls, matcherApi, searchedContactName]
  );
}
