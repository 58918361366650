import dayjs from 'dayjs';
import type { Database } from '../../database/index.ts';
import type { QuestTableEntity } from './QuestTableEntity.ts';
import type { UserTableEntity } from './UserTableEntity.ts';

export type QuestTable = {
  key: QuestTableEntity['questId'];
  value: QuestTableEntity;
};

export function iniQuestTable(options: {
  db: Database<{
    quest: QuestTable;
  }>;
  users: UserTableEntity[];
}) {
  const { db, users } = options;

  const quests: QuestTableEntity[] = [];

  const ids: Ids = {
    questId: 0,
    taskId: 0,
  };

  users.forEach((user) => {
    const userQuests = createInitialQuestsForUser(user, ids);
    quests.push(...userQuests);
  });

  quests.forEach((quest, index) => {
    db.quest.insert(quest.questId, quest);
  });

  return quests;
}

type Ids = {
  questId: number;
  taskId: number;
};

export function createInitialQuestsForUser(
  user: UserTableEntity,
  ids: Ids
): QuestTableEntity[] {
  return [
    {
      userId: user.userId,
      questId: ++ids.questId,
      title: 'Connect wallet',
      createdAt: dayjs().toISOString(),
      startAt: dayjs().toISOString(),
      endAt: dayjs().add(1, 'month').toISOString(),
      isActive: true,
      tags: [],
      tasks: [
        {
          taskId: ++ids.taskId,
          content:
            'Connect TON wallet to your account.\n Go to profile settings -> Wallets -> Connect TON wallet',
          isComplete: false,
          priority: 0,
        },
      ],
      isTemplate: false,
    },
    {
      userId: user.userId,
      questId: ++ids.questId,
      title: 'Swipes 100',
      createdAt: dayjs().toISOString(),
      startAt: dayjs().toISOString(),
      endAt: dayjs().add(1, 'month').toISOString(),
      isActive: true,
      tags: [],
      tasks: [
        {
          taskId: ++ids.taskId,
          content: 'Swipe 100 users',
          isComplete: false,
          priority: 0,
        },
      ],
      isTemplate: false,
    },
    {
      userId: user.userId,
      questId: ++ids.questId,
      title: 'Matches 20',
      createdAt: dayjs().toISOString(),
      startAt: dayjs().toISOString(),
      endAt: dayjs().add(1, 'month').toISOString(),
      isActive: true,
      tags: [],
      tasks: [
        {
          taskId: ++ids.taskId,
          content: 'Get 20 matches with users in people carousel',
          isComplete: false,
          priority: 0,
        },
      ],
      isTemplate: false,
    },
  ];
}
