import { type QueryClient, useQuery } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { useEffect } from 'react';
import { AuthApi } from '~/shared/api/index.ts';
import { useApiErrorNotification } from '~/shared/notification/index.ts';
import { time } from '~/utils/index.ts';

const isTokenValidQueryKey = 'check if authorization token valid';

export function invalidateIsTokenValidQuery(queryClient: QueryClient) {
  return queryClient.invalidateQueries({
    queryKey: [isTokenValidQueryKey],
  });
}

export function useIsTokenValidQuery(authorizationToken: string | null) {
  const authApi = useDi(AuthApi);

  const query = useQuery({
    enabled: authorizationToken != null,
    queryKey: [
      isTokenValidQueryKey,
      {
        authorizationToken,
      },
    ],
    queryFn: async () => {
      if (authorizationToken == null) {
        throw new Error('there is no authorization token');
      }
      return await authApi.isAuthTokenValid(authorizationToken);
    },
    staleTime: time(1, 'minutes'),
    refetchInterval: time(1, 'minutes'),
  });

  useApiErrorNotification(query.error);

  useEffect(() => {
    if (authorizationToken && query.isSuccess) {
      authApi.setAuthorizationTokenToApiInterceptor(authorizationToken);
    }
  }, [authApi, authorizationToken, query.isSuccess]);

  return query;
}
