import { createNiceWebRoutes } from 'nice-web-routes';

export const apiRoutes = createNiceWebRoutes(
  {
    auth: {
      login: {},
      register: {},
      is_token_valid: {},
    },
    blocklist: {
      block_users_list: {},
      block_user: {},
      unblock_user: {},
    },
    developer: {
      delete_all_queue_likes: {},
      dev_delete_messages_between: {},
    },
    matcher: {
      get_queue: {},
      like: {},
      dislike: {},
      match_list: {},
    },
    leveling: {
      rise_skill_level: {},
    },
    meetings: {
      get_meetings_list: {},
      get_meeting_by_id: {},
      leave_meeting: {},
      accept_meeting: {},
      reject_meeting: {},
      set_meeting_reminder: {},
      create_meeting: {},
      update_meeting: {},
      cancel_meeting: {},
      kick_user_from_meeting: {},
      set_meeting_owner: {},
    },
    other: {
      version: {},
    },
    profile: {
      set_skill: {},
      bulk_change_skills: {},
      set_interest: {},
      bulk_change_interests: {},
      set_job_position: {},
      set_department: {},
      update_profile_info: {},
      update_profile_career_info: {},
      get_profile: {},
      me: {},
      get_profile_photo: {},
      upload_profile_photos_base64: {},
      delete_profile_photo: {},
      set_main_avatar: {},
    },
    quest: {
      quests: {
        questId: () => ({
          complete_task: {},
          decomplete_task: {},
        }),
      },
    },
    tags: {
      skill: {
        create: {},
        search: {},
        search_by_ids: {},
      },
      job_position: {
        create: {},
        search: {},
        search_by_ids: {},
      },
      interest: {
        create: {},
        search: {},
        search_by_ids: {},
      },
      department: {
        create: {},
        search: {},
        search_by_ids: {},
      },
    },
    telegram: {
      telegram_entry: {},
      connect_tg_wallet: {},
      is_tg_wallet_connected: {},
    },
  },
  { parentRoute: '/api' }
);
