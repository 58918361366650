import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#5295F0',
    },
    secondary: {
      main: '#424242',
      light: '#bdbdbd',
      dark: '#212121',
    },
    success: {
      main: '#008000',
    },
  },
});
