import Avatar, { type AvatarProps } from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import { type CSSProperties, type FC, useEffect, useState } from 'react';

type ImageState = 'initial' | 'loaded' | 'failed';

type Props = Omit<AvatarProps, 'src'> & {
  avatarUrl?: string | null;
  scale?: CSSProperties['objectFit'];
  loader?: 'skeleton' | 'spinner';
};

export const Photo = (props: Props) => {
  const { avatarUrl, scale = 'cover', loader = 'skeleton', ...rest } = props;

  const [state, setState] = useState<ImageState>('initial');

  useEffect(() => {
    setState('initial');
  }, [avatarUrl]);

  return (
    <>
      {loader === 'skeleton' && (
        <Skeleton
          variant="rectangular"
          height="100%"
          style={{
            display: avatarUrl && state === 'initial' ? 'block' : 'none',
            ...rest.style,
          }}
        />
      )}
      <StyledAvatar
        src={avatarUrl ?? undefined}
        {...rest}
        sx={{ borderRadius: 'unset', ...rest.sx }}
        scale={scale}
        data-loaded={state}
        style={{
          display: !avatarUrl || state !== 'initial' ? 'block' : 'none',
          ...rest.style,
        }}
        onLoad={() => setState('loaded')}
        onError={() => setState('failed')}
      />
      {loader === 'spinner' && (
        <CircularProgress
          color="inherit"
          style={{
            display: avatarUrl && state === 'initial' ? 'block' : 'none',
            ...rest.style,
          }}
        />
      )}
    </>
  );
};

const StyledAvatar = styled(
  Avatar as FC<
    AvatarProps & {
      scale: CSSProperties['objectFit'];
      'data-loaded': ImageState;
    }
  >
)((props) => ({
  '&.MuiAvatar-root': {
    minHeight: '100%',
    minWidth: '100%',
    backgroundColor:
      props.src && props['data-loaded'] === 'loaded' ? '#3a3636' : '#E5EFFD',
    color: '#B1CEF9',
    '& > img': {
      objectFit: props.scale,
    },
    '.MuiSvgIcon-root.MuiAvatar-fallback': {
      width: '100%',
      height: '100%',
    },
  },
}));
