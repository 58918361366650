import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const ChatIcon = (props: Props) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 7.12799C13.5 6.72875 14.5 6.52912 15.5 6.52912C16.3 6.52912 17.1 6.62893 17.8 6.82856C16 2.73626 11.3 0.939642 7.3 2.63645C3.3 4.43307 1.4 9.12424 3.2 13.2165C3.5 13.8154 3.8 14.4143 4.2 14.9133L2.8 16.3107C2.4 16.71 2.4 17.3088 2.8 17.7081C3 17.9077 3.2 18.0075 3.5 18.0075H7.6C5.9 13.6158 8.1 8.72499 12.5 7.12799ZM22.2 20.3032L21.1 19.2053C23.4 16.1111 22.8 11.7194 19.7 9.42367C16.6 7.12799 12.2 7.72687 9.9 10.821C7.6 13.9152 8.2 18.307 11.3 20.6026C12.5 21.5009 14 22 15.5 22H21.5C22.1 22 22.5 21.6008 22.5 21.0019C22.5 20.7024 22.4 20.5028 22.2 20.3032Z"
        fill={color}
      />
    </svg>
  );
};

export type { Props as ChatIconProps };
export { ChatIcon };
