import { Profile } from '~/entities/profile/crossExports.ts';

export class ColleagueProfile extends Profile {
  liked: boolean;
  disliked: boolean;

  constructor(colleagueProfile: Omit<ColleagueProfile, 'name'>) {
    super(colleagueProfile);

    this.liked = colleagueProfile.liked;
    this.disliked = colleagueProfile.disliked;
  }
}
