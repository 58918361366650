import React from 'react';
import { Button, type ButtonProps } from '~/shared/molecules/Button';
import classes from './FooterButton.module.scss';

type Props = Pick<
  ButtonProps<'contained'>,
  'onClick' | 'children' | 'disabled' | 'color'
> & {};

export const FooterButton = (props: Props) => {
  return (
    <div className={classes.container}>
      <Button
        variant="contained"
        color="primary"
        sx={{ width: '100%' }}
        {...props}
      />
    </div>
  );
};
