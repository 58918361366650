import { type FC, type PropsWithChildren } from 'react';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { TonWallet } from '~/entities/wallet/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { ConnectTonWalletButton } from '~/features/wallet/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { Navbar, NotFoundView, useNavbarLoader } from '~/shared/ui/index.ts';

export const WalletsDisplay: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const { data: currentUser, isLoading } = useCurrentUser();
  useNavbarLoader(isLoading);

  const tonWallet = currentUser?.connectedWallets.find(
    (wallet) => wallet.name === 'ton' && wallet.isAlive
  );

  return (
    <>
      <Navbar
        title="Wallets"
        left={<BackIconNavigationButton />}
        withBorderBottom
      />

      {!isLoading && !tonWallet ? (
        <ContentSlot>
          <NotFoundView button={<ConnectTonWalletButton />}>
            You don't have connected TON wallet
          </NotFoundView>
        </ContentSlot>
      ) : (
        <ContentSlot>
          {tonWallet && <TonWallet tonWallet={tonWallet} />}
        </ContentSlot>
      )}

      {children}
    </>
  );
};
