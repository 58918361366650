import { Typography } from '@mui/material';
import { type Duration } from 'dayjs/plugin/duration';
import { useMemo } from 'react';
import { getDurationValueElement } from '~/entities/meeting/index.ts';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { Slat } from '~/shared/molecules/Slat/index.ts';
import classes from './SlatReminder.module.scss';

type Props = {
  duration: Duration | null;
  onClick?: () => void;
  disabled?: boolean;
};

const SlatReminder = (props: Props) => {
  const { duration, onClick, disabled } = props;

  const label = useMemo(() => {
    return getDurationValueElement(duration).props.label;
  }, [duration]);

  return (
    <Slat
      disabled={disabled}
      onClick={onClick}
      iconProps={{
        variant: 'notification',
        color: 'primary',
      }}
      rightChildren={
        <div className={classes.timeContainer}>
          <Typography>{label}</Typography>

          {onClick && (
            <Icon
              variant="chevron"
              direction="right"
              color="#ADB5BD"
              onClick={onClick}
            />
          )}
        </div>
      }
    >
      <Typography>Reminder</Typography>
    </Slat>
  );
};

export { SlatReminder };
export type { Props as SlatReminderProps };
