import { type FC } from 'react';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { FooterTabs } from '~/shared/templates/FooterTabs.tsx';
import { Display, Navbar, NotFoundView } from '~/shared/ui/index.ts';

export const PetsDisplay: FC = () => {
  return (
    <Display documentTitle="pets">
      <Navbar title="Pets" />
      <ContentSlot>
        <NotFoundView>You have not pets</NotFoundView>
      </ContentSlot>
      <FooterTabs />
    </Display>
  );
};
