import type { Database } from '../database/index.ts';
import { createTableAccessor } from './createTableAccessor.ts';
import type { FakeApiDbSchema } from './FakeApiDbSchema.ts';

export class FakeApiDatabase {
  db: Database<FakeApiDbSchema> | undefined;

  blockedUser = createTableAccessor(this, 'blockedUser');
  currentUser = createTableAccessor(this, 'currentUser');
  department = createTableAccessor(this, 'department');
  privateDialog = createTableAccessor(this, 'privateDialog');
  image = createTableAccessor(this, 'image');
  interest = createTableAccessor(this, 'interest');
  jobPosition = createTableAccessor(this, 'jobPosition');
  match = createTableAccessor(this, 'match');
  meeting = createTableAccessor(this, 'meeting');
  meetingDialog = createTableAccessor(this, 'meetingDialog');
  message = createTableAccessor(this, 'message');
  quest = createTableAccessor(this, 'quest');
  skill = createTableAccessor(this, 'skill');
  user = createTableAccessor(this, 'user');
}
