import * as yup from 'yup';

export class ProfilePersonalFormModel {
  firstName: string | undefined;
  lastName: string | undefined;
  timezone: string | undefined;
  bio: string | undefined;

  static validationSchema = yup.object({
    firstName: yup
      .string()
      .required('First name is required')
      .min(3, 'Minimum 3 characters'),

    lastName: yup
      .string()
      .required('Last name is required')
      .min(3, 'Minimum 3 characters'),

    bio: yup
      .string()
      .optional()
      .transform((value) =>
        value.substring(0, ProfilePersonalFormModel.bioMaxLength)
      ),

    timezone: yup.string().optional(),
  });

  static bioMaxLength = 255;
}
