import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { type ReactNode } from 'react';
import { Icon } from '../../atoms/icons/index.ts';
import classes from './NotFoundView.module.scss';

type Props = {
  children: string | ReactNode;
  button?: ReactNode;
};

export const NotFoundView = (props: Props) => {
  const { children, button } = props;

  return (
    <div className={classes.root}>
      <Icon className={classes.icon} variant="notFound" />

      <StyledNotFoundText className={classes.text}>
        {children}
      </StyledNotFoundText>

      {button ? <div className={classes.button}>{button}</div> : null}
    </div>
  );
};

const StyledNotFoundText = styled(Typography)({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',

  /* gray/gray-3 */
  color: '#495057',
});
