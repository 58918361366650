import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { ContactRow } from '~/entities/contact/crossExports.ts';
import { useCurrentUser } from '~/entities/profile/crossExports.ts';
import { DialogDate } from '~/pages/chat/DialogDate.tsx';
import {
  PageOffsetInfiniteScroll,
  type PaginatedInfiniteScrollProps,
  useVirtualRow,
} from '~/shared/ui/index.ts';
import {
  infiniteChatsListQueryKeys,
  useInfiniteChatsListRequest,
} from '../api/useInfiniteChatsListRequest.ts';
import { type Chat } from '../model/Chat.ts';
import { PrivateChat } from '../model/PrivateChat.ts';
import { CHATS_PAGE_SIZE } from './chatsPageSize.ts';
import classes from './ChatsInfiniteList.module.scss';

type Props = {
  searchedName?: Chat['name'];

  onRowClick?: (contact: Chat) => void;
} & Pick<PaginatedInfiniteScrollProps<Chat>, 'emptyListMessage'>;

export const ChatsInfiniteList = (props: Props) => {
  const { searchedName, onRowClick, ...infiniteProps } = props;

  const { data: profile } = useCurrentUser();

  const Row = useVirtualRow<Chat>(
    (props) => {
      const {
        isLoading,
        rowEntity: chat,
        hasNextPage,
        forwardedRef,
        ...rest
      } = props;

      let messageContent = '';
      if (profile && chat?.messagePreview?.authorId === profile.userId) {
        messageContent = `You: ${chat.messagePreview!.content}`;
      } else {
        messageContent = chat?.messagePreview?.content ?? '';
      }

      const isCurrentUser =
        chat instanceof PrivateChat && chat.interlocutorId === profile?.userId;

      return (
        <ContactRow
          forwardedRef={forwardedRef}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          isFavourite={isCurrentUser}
          avatarProps={{
            firstName: chat?.name,
            avatarUrl: chat?.avatar.url,
            sx: { height: 50, width: 50 },
          }}
          jobPositionId={null}
          secondaryRow={<>{messageContent}</>}
          onRowClick={() => {
            if (chat) {
              onRowClick?.(chat);
            }
          }}
          secondaryAction={
            <div className={classes.chatTime}>
              {chat?.messagePreview?.date && (
                <DialogDate date={chat.messagePreview.date} />
              )}

              {!!chat && chat.unreadMessagesCount > 0 && (
                <StyledBadge
                  badgeContent={chat.unreadMessagesCount}
                  color="primary"
                />
              )}
            </div>
          }
          {...rest}
        />
      );
    },
    [onRowClick, profile]
  );

  return (
    <PageOffsetInfiniteScroll
      estimatedRowHeight={CHATS_PAGE_SIZE}
      queryKeys={infiniteChatsListQueryKeys(searchedName)}
      apiRequest={useInfiniteChatsListRequest(searchedName)}
      Row={Row}
      {...infiniteProps}
    />
  );
};

const StyledBadge = styled(Badge)({
  '.MuiBadge-badge': {
    position: 'relative',
    transform: 'unset',
  },
});
