import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  type Department,
  DepartmentInfiniteList,
  loadDepartmentsQueryKeys,
} from '~/entities/department/index.ts';
import { useIsPublicVersion } from '~/entities/device/index.ts';
import { useCurrentUser } from '~/entities/profile';
import { useCreateDepartment } from '~/features/department/api/useCreateDepartment.ts';
import {
  CancelNavigationButton,
  useOnBack,
} from '~/features/navigation/index.ts';
import { useProfileCareerEditContext } from '~/features/profile/index.ts';
import { type TagDto } from '~/shared/api/index.ts';
import { Icon } from '~/shared/atoms/icons';
import { NavbarInput } from '~/shared/molecules/NavbarInput';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import {
  ConfirmButton,
  Display,
  Navbar,
  type PageOffsetInfiniteScrollRef,
} from '~/shared/ui/index.ts';
import classes from './EditProfileDepartmentDisplay.module.scss';

const queryKey = (searchedDepartmentName?: Department['name']) => [
  ...loadDepartmentsQueryKeys(searchedDepartmentName),
  'infinitely',
];

export const EditProfileDepartmentDisplay = () => {
  const isPublicVersion = useIsPublicVersion();
  const queryClient = useQueryClient();

  const [searchedDepartmentName, setSearchedDepartmentName] =
    useState<Department['name']>('');

  const { data: profile } = useCurrentUser();
  const { mutate: createDepartment, isPending: departmentIsCreating } =
    useCreateDepartment();
  const { selectedDepartmentId, setSelectedDepartmentId } =
    useProfileCareerEditContext() ?? {};

  const selectedDepartmentIds = useMemo(() => {
    if (selectedDepartmentId != null) {
      return new Set([selectedDepartmentId]);
    } else if (profile?.departmentId != null) {
      return new Set([profile.departmentId]);
    }
  }, [profile?.departmentId, selectedDepartmentId]);

  const scrollRef = useRef<PageOffsetInfiniteScrollRef<TagDto>>(null);

  const onBack = useOnBack({});

  const addOrCreate = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    let department: TagDto | undefined;

    const loadedDepartments = scrollRef.current.getAllRows();

    if (searchedDepartmentName) {
      department = loadedDepartments.at(0);
    } else {
      department = loadedDepartments.find(
        (_department) =>
          _department.name.toLowerCase() ===
          searchedDepartmentName.toLowerCase()
      );
    }

    if (department) {
      setSelectedDepartmentId?.(department.id);
      onBack();
    } else {
      createDepartment(searchedDepartmentName, {
        onSuccess: async ({ createdTag }) => {
          await queryClient.invalidateQueries({
            queryKey: queryKey(),
          });
          setSelectedDepartmentId?.(createdTag.id);
          onBack();
        },
      });
    }
  }, [
    createDepartment,
    onBack,
    queryClient,
    searchedDepartmentName,
    setSelectedDepartmentId,
  ]);

  return (
    <Display
      documentTitle="edit profile department"
      animationVariant="card-effect"
    >
      <Navbar
        title={isPublicVersion ? 'Company' : 'Department'}
        left={<CancelNavigationButton />}
        right={
          <ConfirmButton
            onClick={searchedDepartmentName ? addOrCreate : undefined}
          >
            Add
          </ConfirmButton>
        }
        classes={{
          children: classes.navbarChildren,
        }}
        partialLoading={departmentIsCreating}
        withBorderBottom
      >
        <NavbarInput
          placeholder={`Name of the ${isPublicVersion ? 'Company' : 'Department'}`}
          icon={<Icon variant="search" />}
          value={searchedDepartmentName}
          onChange={setSearchedDepartmentName}
        />
      </Navbar>

      <ContentSlot>
        <DepartmentInfiniteList
          ref={scrollRef}
          selectedIcon="check"
          searchedDepartmentName={searchedDepartmentName}
          onRowClick={(department) => {
            setSelectedDepartmentId?.(department.id);
            onBack();
          }}
          selectedDepartmentIds={selectedDepartmentIds}
        />
      </ContentSlot>
    </Display>
  );
};
