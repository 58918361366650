import { useDi } from 'cheap-di-react';
import { useCallback } from 'react';
import { ProfileApi } from '~/shared/api/index.ts';
import { type Profile } from '../model/Profile.ts';

type ProfileWithImages = Pick<Profile, 'userId' | 'avatar'> &
  Partial<Pick<Profile, 'photos'>>;

export function useLoadProfileImageUrls() {
  const profileApi = useDi(ProfileApi);

  return useCallback(
    async (profiles: ProfileWithImages[]) => {
      const promises: Promise<unknown>[] = [];

      for (const profile of profiles) {
        promises.push(
          (async () => {
            if (profile.avatar.fileName) {
              profile.avatar.url = await profileApi.getAvatarUrl({
                userId: profile.userId,
                fileName: profile.avatar.fileName,
              });
            }
          })()
        );

        profile.photos?.forEach(({ fileName }, index) => {
          promises.push(
            (async () => {
              if (fileName) {
                const url = await profileApi.getAvatarUrl({
                  userId: profile.userId,
                  fileName,
                });
                if (url) {
                  profile.photos![index].url = url;
                }
              }
            })()
          );
        });
      }

      await Promise.allSettled(promises);
    },
    [profileApi]
  );
}
