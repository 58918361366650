import { type FC } from 'react';
import { IconTwoColumnRow } from '~/shared/ui';
import { useProfileJobPosition } from '../api/useProfileJobPosition.ts';

export const JobPositionTwoColumnRow: FC = () => {
  const { jobPosition, jobPositionIsLoading } = useProfileJobPosition();

  return (
    <IconTwoColumnRow
      icon="positionFilled"
      label="Position:"
      content={jobPosition?.name}
      isLoading={jobPositionIsLoading}
    />
  );
};
