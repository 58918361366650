import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
} from 'axios';
import { API_BASE_URL } from '../../config/environment/index.ts';
import { resetAllStores } from '../store/index.ts';
import { ApiResponseStatus } from './ApiResponseStatus.ts';

export class ApiInterceptor {
  static singleton: ApiInterceptor;
  readonly axios!: AxiosInstance;
  public authorizationToken?: string;

  constructor() {
    if (ApiInterceptor.singleton) {
      return ApiInterceptor.singleton;
    }
    ApiInterceptor.singleton = this;

    const config: AxiosRequestConfig = {
      baseURL: API_BASE_URL,
      headers: {
        // ...CROSS_SITE_HEADER,
        // ...INCLUDE_CREDENTIALS_HEADER,
      },
    };
    this.axios = axios.create(config);

    this.axios.interceptors.request.use(
      this.requestFulfilled.bind(this),
      this.requestRejected.bind(this)
    );
  }

  private async requestFulfilled<Config extends AxiosRequestConfig>(
    config: Config
  ): Promise<Config> {
    if (!config.headers) {
      config.headers = {};
    }

    if (this.authorizationToken) {
      config.headers['Authorization'] = this.authorizationToken;
    }

    return config;
  }

  private requestRejected = (error: AxiosError): Promise<AxiosError> => {
    if (error.status === ApiResponseStatus.NotAuthorized) {
      // todo: redirect to login
      resetAllStores();
    }

    return Promise.reject(error);
  };
}
