import { faker } from '@faker-js/faker';
import dayjs from 'dayjs';
import { AcceptRejectEnum } from '../../enums/AcceptRejectEnum.ts';
import { MeetingStatusEnum } from '../../enums/MeetingStatusEnum.ts';
import { type FakeApiDatabase } from '../../fakeApiDatabase/index.ts';
import { ApiError } from '../ApiError.ts';
import { ApiResponseStatus } from '../ApiResponseStatus.ts';
import { findCurrentUser } from '../findCurrentUser.ts';
import type { MeetingDto } from './MeetingDto.ts';
import type { MeetingMemberDto } from './MeetingMemberDto.ts';
import { type MeetingRealApi } from './MeetingRealApi.ts';
import { MEETINGS_PAGE_SIZE } from './MEETINGS_PAGE_SIZE.ts';

export class MeetingsFakeApi {
  private readonly findCurrentUser: () => ReturnType<typeof findCurrentUser>;

  constructor(readonly db: FakeApiDatabase) {
    this.findCurrentUser = findCurrentUser.bind(null, db);
  }

  getMeetings: MeetingRealApi['getMeetings'] = async (dto) => {
    const currentUser = await this.findCurrentUser();

    const { meetingName, page, pageSize, participantStatusFilter } = dto;
    const meetings = await this.db.meeting.getAll();

    const normalizedSize = pageSize ?? MEETINGS_PAGE_SIZE;
    const startIndex = (page ?? 0) * normalizedSize;
    const endIndex = startIndex + normalizedSize;

    const filtered = meetings.filter((meeting) => {
      if (meetingName && meetingName !== meeting.name) {
        return false;
      }
      // no further filtration
      if (!participantStatusFilter) {
        return true;
      }

      // get meetings that I accepted/rejected/...

      const currentUserAsMeetingMember = meeting.members.find(
        (member) => member.userId === currentUser.userId
      );
      if (!currentUserAsMeetingMember) {
        return false;
      }
      if (participantStatusFilter !== currentUserAsMeetingMember.status) {
        return false;
      }
    });

    const orderedMeetings = filtered.sort((left, right) => {
      const leftDate = dayjs(left.startAt);
      const rightDate = dayjs(right.startAt);

      if (leftDate.isBefore(rightDate)) {
        return -1;
      }
      if (leftDate.isAfter(rightDate)) {
        return 1;
      }
      return 0;
    });

    // const batch = orderedMeetings.slice(startIndex, endIndex);
    const batch = orderedMeetings;

    return {
      totalCount: filtered.length,
      items: batch,
    };
  };

  getMeetingById: MeetingRealApi['getMeetingById'] = async (meetingId) => {
    const meeting = await this.db.meeting.get(meetingId);
    if (!meeting) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: `Meeting not found, ID: ${meetingId}`,
      });
    }

    return {
      meeting,
    };
  };

  leaveMeeting: MeetingRealApi['leaveMeeting'] = async (meetingId) => {
    const currentUser = await this.findCurrentUser();

    const meeting = await this.db.meeting.get(meetingId);
    if (!meeting) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: `Meeting not found, ID: ${meetingId}`,
      });
    }

    // todo: is it correct? just remove current user from meeting members? ask from backend
    meeting.members = meeting.members.filter(
      (member) => member.userId !== currentUser.userId
    );

    await this.db.meeting.update(meeting.meetingId, meeting);
    return {};
  };

  acceptMeeting: MeetingRealApi['acceptMeeting'] = async (meetingId) => {
    const currentUser = await this.findCurrentUser();

    const meeting = await this.db.meeting.get(meetingId);
    if (!meeting) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: `Meeting not found, ID: ${meetingId}`,
      });
    }

    const currentUserMember = meeting.members.find(
      (member) => member.userId === currentUser.userId
    );
    if (currentUserMember) {
      currentUserMember.status = AcceptRejectEnum.Accept;
      await this.db.meeting.update(meeting.meetingId, meeting);
    }

    return {};
  };

  rejectMeeting: MeetingRealApi['rejectMeeting'] = async (meetingId) => {
    const currentUser = await this.findCurrentUser();

    const meeting = await this.db.meeting.get(meetingId);
    if (!meeting) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: `Meeting not found, ID: ${meetingId}`,
      });
    }

    const currentUserMember = meeting.members.find(
      (member) => member.userId === currentUser.userId
    );
    if (currentUserMember) {
      currentUserMember.status = AcceptRejectEnum.Reject;
      await this.db.meeting.update(meeting.meetingId, meeting);
    }

    return {};
  };

  setMeetingReminder: MeetingRealApi['setMeetingReminder'] = async (
    meetingId,
    remindBeforeInMinutes
  ) => {
    // just check if user authorized
    await this.findCurrentUser();

    const meeting = await this.db.meeting.get(meetingId);
    if (!meeting) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: `Meeting not found, ID: ${meetingId}`,
      });
    }

    meeting.reminderInMinutes = remindBeforeInMinutes;

    await this.db.meeting.update(meeting.meetingId, meeting);
    return {};
  };

  createMeeting: MeetingRealApi['createMeeting'] = async (dto) => {
    const currentUser = await this.findCurrentUser();

    const allUsers = await this.db.user.getAll();
    const memberIdsSet = new Set(dto.invitedUserIds);

    const members: MeetingMemberDto[] = allUsers
      .filter((user) => memberIdsSet.has(user.userId))
      .concat(currentUser)
      .map((user) => ({
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        userAvatarPhotoFileName: user.userAvatarPhotoFileName,
        department: user.department,
        jobPosition: user.jobPosition,
        status:
          user.userId === currentUser.userId
            ? AcceptRejectEnum.Owner
            : AcceptRejectEnum.Invited,
      }));

    const newMeeting: MeetingDto = {
      meetingId: faker.number.int({ max: 1000 }),
      name: dto.name,
      startAt: dto.startAt,
      members: members,
      reminderInMinutes: dto.reminderInMinutes,
      howLong: dto.howLong,
      description: dto.description,
      meetingStatus: MeetingStatusEnum.Planned,
    };

    await this.db.meeting.insert(newMeeting.meetingId, newMeeting);

    return newMeeting;
  };

  updateMeeting: MeetingRealApi['updateMeeting'] = async (dto) => {
    const meeting = await this.db.meeting.get(dto.meetingId);
    if (!meeting) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: `Meeting not found, ID: ${dto.meetingId}`,
      });
    }

    meeting.howLong = dto.howLong;
    meeting.name = dto.name;
    meeting.description = dto.description;
    meeting.startAt = dto.startAt;

    await this.db.meeting.update(meeting.meetingId, meeting);
    return {};
  };

  cancelMeeting: MeetingRealApi['cancelMeeting'] = async (meetingId) => {
    const meeting = await this.db.meeting.get(meetingId);
    if (!meeting) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: `Meeting not found, ID: ${meetingId}`,
      });
    }

    meeting.meetingStatus = MeetingStatusEnum.Canceled;

    await this.db.meeting.update(meeting.meetingId, meeting);
    return {};
  };

  kickUserFromMeeting: MeetingRealApi['kickUserFromMeeting'] = async (
    meetingId,
    userId
  ) => {
    const currentUser = await this.findCurrentUser();

    const meeting = await this.db.meeting.get(meetingId);
    if (!meeting) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: `Meeting not found, ID: ${meetingId}`,
      });
    }

    const owner = meeting.members.find(
      (user) => user.status === AcceptRejectEnum.Owner
    );
    if (!owner || owner.userId != currentUser.userId) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: 'You are not an owner of the meeting, you cannot kick any user',
      });
    }

    // todo: is it correct? just remove current user from meeting members? ask from backend
    meeting.members = meeting.members.filter(
      (member) => member.userId !== userId
    );

    await this.db.meeting.update(meeting.meetingId, meeting);
    return {};
  };

  setMeetingOwner: MeetingRealApi['setMeetingOwner'] = async (
    meetingId,
    newOwnerUserId
  ) => {
    // just check if user authorized
    await this.findCurrentUser();

    const meeting = await this.db.meeting.get(meetingId);
    if (!meeting) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
        error: `Meeting not found, ID: ${meetingId}`,
      });
    }

    const owner = meeting.members.find(
      (user) => user.status === AcceptRejectEnum.Owner
    );
    if (owner) {
      owner.status = AcceptRejectEnum.Accept;
    }

    const newOwner = meeting.members.find(
      (user) => user.userId === newOwnerUserId
    );
    if (newOwner) {
      newOwner.status = AcceptRejectEnum.Owner;
    }

    await this.db.meeting.update(meeting.meetingId, meeting);
    return {};
  };
}
