import { type FC } from 'react';
import { IconTwoColumnRow } from '~/shared/ui';
import { useIsPublicVersion } from '../../device/index.ts';
import { useProfileDepartment } from '../api/useProfileDepartment.ts';

export const DepartmentTwoColumnRow: FC = () => {
  const { department, departmentIsLoading } = useProfileDepartment();

  const isPublicVersion = useIsPublicVersion();

  return (
    <IconTwoColumnRow
      icon="networkFilled"
      label={isPublicVersion ? 'Company:' : 'Department:'}
      content={department?.name}
      isLoading={departmentIsLoading}
    />
  );
};
