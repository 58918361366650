import { type FC, type ReactNode } from 'react';
import classes from './Section.module.scss';

type Props = {
  children: ReactNode;
};

const Section: FC<Props> = ({ children }) => (
  <div className={classes.root}>{children}</div>
);

export { Section };
