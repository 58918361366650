import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { DepartmentApi, TagDto } from '~/shared/api/index.ts';
import { defined } from '~/utils/defined.ts';
import { time } from '~/utils/index.ts';
import { Department } from '../model/Department.ts';

export const loadDepartmentByIdQueryKey = (
  departmentId: Department['id'] | null | undefined
) =>
  ['load department', departmentId ? { departmentId } : undefined].filter(
    (key) => key != null
  );

export function useLoadDepartmentById(
  departmentId: Department['id'] | null | undefined,
  options?: {
    enabled: boolean;
  }
) {
  const { enabled = true } = options ?? {};

  const departmentApi = useDi(DepartmentApi);

  const queryResult = useQuery({
    queryKey: loadDepartmentByIdQueryKey(departmentId),
    queryFn: () =>
      departmentApi.searchDepartmentsById([departmentId].filter(defined)),
    enabled: departmentId != null && enabled,
    staleTime: time(5, 'minutes'),
    select: mapResponse,
  });

  return {
    ...queryResult,
    data: queryResult.data?.at(0),
  };
}

const mapResponse = (
  dto: Awaited<ReturnType<DepartmentApi['searchDepartmentsById']>>
) => dto.items.map((dtoItem) => Mapper.map(TagDto, Department, dtoItem));
