import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const PositionFilledIcon = (props: Props) => {
  const { height = 16, color = 'currentColor', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 9.21053C11.5 9.62105 11.22 9.89474 10.8 9.89474C10.38 9.89474 10.1 9.62105 10.1 9.21053V8.52632H5.9V9.21053C5.9 9.62105 5.62 9.89474 5.2 9.89474C4.78 9.89474 4.5 9.62105 4.5 9.21053V8.52632H1V11.9474C1 13.1105 1.91 14 3.1 14H12.9C14.09 14 15 13.1105 15 11.9474V8.52632H11.5V9.21053ZM12.9 3.73684H10.8V3.05263C10.8 1.88947 9.89 1 8.7 1H7.3C6.11 1 5.2 1.88947 5.2 3.05263V3.73684H3.1C1.91 3.73684 1 4.62632 1 5.78947V7.1579H15V5.78947C15 4.62632 14.09 3.73684 12.9 3.73684ZM9.4 3.73684H6.6V3.05263C6.6 2.64211 6.88 2.36842 7.3 2.36842H8.7C9.12 2.36842 9.4 2.64211 9.4 3.05263V3.73684Z"
        fill={color}
      />
    </svg>
  );
};

export type { Props as PositionFilledIconProps };
export { PositionFilledIcon };
