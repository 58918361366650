import dayjs, { type Dayjs } from 'dayjs';
import { type FC, useCallback, useEffect } from 'react';
import {
  type KindOfMeeting,
  Meetings,
  useCalendarEvents,
  useMeetingDraftContext,
  useMeetingsByKind,
} from '~/entities/meeting/index.ts';
import { Calendar } from '~/shared/organisms/Calendar/index.ts';
import { useNavbarLoader } from '~/shared/ui/index.ts';
import classes from './SelectDateForMeeting.module.scss';

type Props = {
  selectedDate: Dayjs | null;
  setSelectedDate: (selectedDate: Dayjs | null) => void;
  kind?: KindOfMeeting;
  disablePast?: boolean;
  disableFuture?: boolean;
};

export const SelectDateForMeeting: FC<Props> = (props) => {
  const { selectedDate, setSelectedDate, kind, disablePast, disableFuture } =
    props;

  const { meetings, meetingsAreLoading } = useMeetingsByKind(kind);

  useNavbarLoader(meetingsAreLoading);

  const calendarEvents = useCalendarEvents(meetings);

  const { meetingDraft } = useMeetingDraftContext() ?? {};

  useEffect(() => {
    if (!meetingDraft) {
      setSelectedDate(dayjs().startOf('day'));
    }
  }, [meetingDraft, setSelectedDate]);

  const onSelectDate = useCallback(
    (date: Dayjs | null) => {
      if (date && selectedDate && date.isSame(selectedDate)) {
        setSelectedDate(null);
      } else {
        setSelectedDate(date);
      }
    },
    [selectedDate, setSelectedDate]
  );

  return (
    <div className={classes.root}>
      <Calendar
        value={selectedDate?.utc()}
        onChange={onSelectDate}
        disablePast={disablePast}
        disableFuture={disableFuture}
        events={calendarEvents}
      />

      <Meetings kind={kind} selectedDate={selectedDate} />
    </div>
  );
};
