import { Typography } from '@mui/material';
import { useDiContext } from 'cheap-di-react';
import { type FC } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '~/shared/molecules/Button/index.ts';
import { routes } from '~/shared/routing/index.ts';
import classes from './FakeTonWalletConnectDisplay.module.scss';

export const FakeTonWalletConnectDisplay: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const container = useDiContext() ?? {};

  const walletId = searchParams.get('walletId');
  if (walletId == null) {
    return <Navigate to={routes.url()} replace={true} />;
  }

  return (
    <div className={classes.root}>
      <Typography>Are you sure about TON wallet connecting?</Typography>

      <div className={classes.buttons}>
        <Button
          variant="text"
          onClick={() => {
            navigate(routes.url());
          }}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={async () => {
            if (!container.container) {
              return;
            }

            const module = await import(
              '~/shared/api/telegram/TelegramFakeApi'
            );
            const fakeTelegramApi = container.container.resolve(
              module.TelegramFakeApi
            );
            if (fakeTelegramApi) {
              await fakeTelegramApi.confirmTonWalletConnection(walletId);
              navigate(routes.url());
            }
          }}
        >
          Connect
        </Button>
      </div>
    </div>
  );
};
