import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const ApproveIcon = (props: Props) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7 2C7.20001 2 2.70001 6.5 2.70001 12C2.70001 17.5 7.20001 22 12.7 22C18.2 22 22.7 17.5 22.7 12C22.7 6.5 18.2 2 12.7 2ZM16.9 10.3L12.1 15.1C11.7 15.5 11.1 15.5 10.7 15.1L8.50001 12.9C8.10001 12.5 8.10001 11.9 8.50001 11.5C8.90001 11.1 9.50001 11.1 9.90001 11.5L11.4 13L15.5 8.9C15.9 8.5 16.5 8.5 16.9 8.9C17.3 9.3 17.3 9.9 16.9 10.3Z"
        fill={color}
      />
    </svg>
  );
};

export type { Props as ApproveIconProps };
export { ApproveIcon };
