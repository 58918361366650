import { IconButton, Paper, Typography } from '@mui/material';
import { Mapper } from '@tomas-light/mapper-js';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContactRow } from '~/entities/contact/index.ts';
import {
  MeetingMember,
  useMeetingDraftContext,
} from '~/entities/meeting/index.ts';
import { Profile, useCurrentUser } from '~/entities/profile/index.ts';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { Slat } from '~/shared/molecules/Slat/index.ts';
import { routes } from '~/shared/routing/index.ts';
import classes from './MemberList.module.scss';

type Props<TMember extends MeetingMember> = {
  members: TMember[];
  onRemoveMemberClick?: (member: TMember) => void;
  mode: 'edit' | 'view';
  meetingOwner?: MeetingMember;
};

export function MemberList<TMember extends MeetingMember>(
  props: Props<TMember>
) {
  const { members, onRemoveMemberClick, mode, meetingOwner } = props;

  const { meetingRoutes } = useMeetingDraftContext() ?? {};

  const navigate = useNavigate();
  const { data: profile } = useCurrentUser();

  const owner = useMemo<Omit<MeetingMember, 'userId'>>(() => {
    if (meetingOwner) {
      return meetingOwner;
    }

    if (!profile) {
      return {
        firstName: '',
        lastName: '',
        userId: 0,
        status: 'unknown',
        jobPositionId: null,
        avatar: {
          url: '',
          fileName: '',
        },
      };
    }

    const ownerMember = Mapper.map(Profile, MeetingMember, profile);
    ownerMember.status = 'owner';
    return ownerMember;
  }, [meetingOwner, profile]);

  if (!owner) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Typography variant="body1" className={classes.title}>
        Participants ({members.length + 1 /* include owner */})
      </Typography>

      <Slat
        iconProps={{
          variant: 'addPersonOutline',
          color: '#5295F0',
        }}
        onClick={() => {
          if (meetingRoutes) {
            navigate(meetingRoutes.details.member.url());
          }
        }}
      >
        <Typography>Add Participants</Typography>
      </Slat>

      <div>
        <Paper variant="outlined" square>
          <ContactRow
            className={classes.member}
            avatarProps={{
              firstName: owner.firstName,
              lastName: owner.lastName,
              avatarUrl: owner.avatar.url,
            }}
            jobPositionId={profile?.jobPositionId}
            secondaryAction={<Typography>Organizer</Typography>}
          />
        </Paper>

        {members.map((member) => (
          <Paper key={member.userId} variant="outlined" square>
            <ContactRow
              className={classes.member}
              avatarProps={{
                firstName: member.firstName,
                lastName: member.lastName,
                avatarUrl: member.avatar.url,
                icon:
                  member.status === 'accept' ? (
                    <Icon
                      variant="check"
                      color="success"
                      className={classes.checkIcon}
                    />
                  ) : undefined,
              }}
              jobPositionId={member.jobPositionId}
              secondaryAction={
                mode === 'edit' ? (
                  /* a meeting have to contain at least one invited colleague */
                  members.length > 1 && (
                    <IconButton onClick={() => onRemoveMemberClick?.(member)}>
                      <Icon variant="removePerson" />
                    </IconButton>
                  )
                ) : (
                  <IconButton
                    onClick={() =>
                      navigate(
                        routes.colleagues.userId(member.userId.toString()).url()
                      )
                    }
                  >
                    <Icon variant="profileOutlined" />
                  </IconButton>
                )
              }
            />
          </Paper>
        ))}
      </div>
    </div>
  );
}
