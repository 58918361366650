const stringColorsMap = new Map<string, string>();

// taken from https://mui.com/material-ui/react-avatar/#main-content
function stringToColor(string: string) {
  if (stringColorsMap.has(string)) {
    return stringColorsMap.get(string);
  }

  let hash = 0;

  for (let index = 0; index < string.length; index += 1) {
    hash = string.charCodeAt(index) + ((hash << 5) - hash);
  }

  let color = '#';

  for (let index = 0; index < 3; index += 1) {
    const value = (hash >> (index * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  stringColorsMap.set(string, color);

  return color;
}

export { stringToColor };
