import { IconButton, Typography } from '@mui/material';
import React, {
  type FC,
  type PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  type ContactProfile,
  ContactsInfiniteList,
} from '~/entities/contact/index.ts';
import { Icon } from '~/shared/atoms/icons';
import { HorizontalUserList } from '~/shared/components';
import { Button } from '~/shared/molecules/Button';
import { NavbarInput } from '~/shared/molecules/NavbarInput';
import { ContentSlot, SlotPortal } from '~/shared/organisms/slots';
import { routes } from '~/shared/routing/index.ts';
import { FooterButton } from '~/shared/templates/FooterButton';
import { FooterTabs } from '~/shared/templates/FooterTabs.tsx';
import { CancelButton, Display, Navbar } from '~/shared/ui/index.ts';
import classes from './ContactsDisplay.module.scss';

export const ContactsDisplay: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const navigate = useNavigate();

  const [view, setView] = useState<'list' | 'invite'>('list');
  const [selectedContacts, setSelectedContacts] = useState(
    new Set<ContactProfile>()
  );
  const [searchedContactName, setSearchedContactName] = useState('');

  const selectedContactIds = useMemo(
    () =>
      new Set(Array.from(selectedContacts).map((contact) => contact.userId)),
    [selectedContacts]
  );

  const onCancel = useCallback(() => {
    setView('list');
  }, []);

  const onInviteClick = useCallback(() => {
    setView('invite');
  }, []);

  const onChatsClick = useCallback(() => {
    navigate(routes.chat.url());
  }, [navigate]);

  const onMeetingClick = useCallback(() => {
    navigate(routes.meetings.url());
  }, [navigate]);

  const openContact = useCallback(
    (contact: ContactProfile) => {
      navigate(routes.contacts.userId(contact.userId.toString()).url());
    },
    [navigate]
  );

  const unSelectContact = useCallback((contact: ContactProfile) => {
    setSelectedContacts((state) => {
      const newState = new Set(state);
      newState.delete(contact);
      return newState;
    });
  }, []);

  const onRowClick = useCallback(
    (contact: ContactProfile) => {
      if (view === 'list') {
        openContact(contact);
        return;
      }

      setSelectedContacts((state) => {
        const checked = state.has(contact);
        if (checked) {
          const newState = new Set(state);
          newState.delete(contact);
          return newState;
        } else {
          return new Set(state.add(contact));
        }
      });
    },
    [openContact, view]
  );

  return (
    <>
      <Display documentTitle="contacts">
        <Navbar
          title={view === 'invite' ? 'Invite' : 'Contacts'}
          left={
            <>
              {view === 'invite' ? (
                <CancelButton onClick={onCancel} />
              ) : undefined}

              <Button variant="text" color="primary" onClick={onMeetingClick}>
                Meetings
              </Button>
            </>
          }
          right={
            <Button variant="text" color="primary" onClick={onChatsClick}>
              Chats
            </Button>
          }
          classes={{
            children: classes.navbar,
          }}
          withBorderBottom
        >
          <NavbarInput
            placeholder="Search"
            icon={<Icon variant="search" />}
            value={searchedContactName}
            onChange={setSearchedContactName}
          />

          {view === 'list' ? (
            <div className={classes.inviteButtonContainer}>
              <IconButton color="primary" sx={{ background: '#E5EFFD' }}>
                <Icon variant="addPerson" />
              </IconButton>

              <Button variant="text" color="primary" onClick={onInviteClick}>
                Invite to Meeting
              </Button>
            </div>
          ) : selectedContacts.size === 0 ? (
            <Typography variant="body1" className={classes.chooseColleagues}>
              Select People to Invite to the Meeting
            </Typography>
          ) : (
            <HorizontalUserList
              users={Array.from(selectedContacts)}
              onRemove={unSelectContact}
            />
          )}
        </Navbar>

        <ContentSlot>
          <ContactsInfiniteList
            view={view}
            selectedContactIds={selectedContactIds}
            onRowClick={onRowClick}
            searchedContactName={searchedContactName}
          />
        </ContentSlot>

        {view === 'invite' && selectedContacts.size > 0 ? (
          <SlotPortal slotName="footer">
            <FooterButton
              onClick={() => {
                navigate(
                  routes.contacts.new_meeting.url({
                    colleagueIds: Array.from(selectedContacts).map((contact) =>
                      contact.userId.toString()
                    ),
                  })
                );
              }}
            >
              Next
            </FooterButton>
          </SlotPortal>
        ) : (
          <FooterTabs />
        )}
      </Display>

      {children}
    </>
  );
};
