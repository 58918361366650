import {
  type MouseEvent as ReactMouseEvent,
  type MouseEventHandler,
  useCallback,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDisplayContext } from '~/shared/ui/index.ts';

type Options = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

type Callback<T extends Options> = T['onClick'] extends Function
  ? (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void
  : VoidFunction;

export function useOnBack<T extends Options>(options: T): Callback<T> {
  const { onClick } = options;

  const navigate = useNavigate();
  const location = useLocation();
  const displayContext = useDisplayContext();

  return useCallback(
    (event: undefined | ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
      function goBack() {
        if (onClick) {
          onClick(event!);
        } else {
          navigate(-1);
        }
      }

      location.key;

      if (!displayContext) {
        goBack();
      } else {
        displayContext.onClose(goBack);
      }
    },
    [displayContext, navigate, onClick, location]
  ) as Callback<typeof options>;
}
