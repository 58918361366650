import { RealApiBase } from '../RealApiBase.ts';
import { apiRoutes } from '../apiRoutes.ts';
import { type MyUserDto } from '../profile/MyUserDto.ts';
import type { LoginDto } from './LoginDto.ts';
import type { RegistrationRequestDto } from './RegistrationRequestDto.ts';

export class AuthRealApi extends RealApiBase {
  login = (login: string, password: string): Promise<LoginDto> => {
    return this.post(apiRoutes.auth.login.url(), {
      login,
      password,
    });
  };

  register = (dto: RegistrationRequestDto): Promise<LoginDto> => {
    return this.post(apiRoutes.auth.register.url(), dto);
  };

  isAuthTokenValid = (
    authorizationToken: string
  ): Promise<{ user: MyUserDto }> => {
    return this.post(apiRoutes.auth.is_token_valid.url(), {
      token: authorizationToken,
    });
  };

  logout = async (): Promise<void> => {
    return Promise.resolve();
  };
}
