import { type Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { useNavbarLoader } from '~/shared/ui/index.ts';
import {
  type KindOfMeeting,
  MeetingCard,
  useMeetingsByKind,
} from '../index.ts';
import classes from './Meetings.module.scss';

type Props = {
  kind?: KindOfMeeting;
  selectedDate: Dayjs | null;
};

export type { Props as MeetingsProps };

export const Meetings = (props: Props) => {
  const { kind, selectedDate } = props;

  const { meetings: kindMeetings, meetingsAreLoading } =
    useMeetingsByKind(kind);

  useNavbarLoader(meetingsAreLoading);

  const meetings = useMemo(() => {
    if (!selectedDate) {
      return kindMeetings;
    }

    const meetingsOfSelectedDay = kindMeetings.filter((meeting) =>
      meeting.when.startOf('day').isSame(selectedDate.startOf('day'))
    );
    return meetingsOfSelectedDay;
  }, [selectedDate, kindMeetings]);

  return (
    <div className={classes.root}>
      {meetings.map((meeting) => (
        <MeetingCard key={meeting.meetingId} meeting={meeting} kind={kind} />
      ))}
    </div>
  );
};
