import { FormField } from '../form/FormField.tsx';
import {
  TextField,
  type TextFieldProps,
} from '../organisms/TextField/index.ts';

type Props = Omit<TextFieldProps, 'clearable'> & {
  name: string;
};

export const NameField = (props: Props) => {
  const { name, ...rest } = props;

  return (
    <FormField<{ [key in typeof name]: string | undefined }> name={name}>
      {({ register, errors }) => (
        <TextField
          clearable
          isError={errors?.[name] != null}
          helpText={errors?.[name]?.message}
          {...rest}
          {...register(rest)}
        />
      )}
    </FormField>
  );
};
