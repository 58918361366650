import { useMutation } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { useSuccessLoginHandler } from '~/entities/auth/index.ts';
import { ApiResponseStatus } from '~/shared/api/ApiResponseStatus.ts';
import { AuthApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';
import { getApiErrorAuthorizationMessage } from './getApiErrorAuthorizationMessage.ts';

export function useLoginMutation() {
  const authApi = useDi(AuthApi);
  const onSuccessLogin = useSuccessLoginHandler();

  return useMutation({
    mutationKey: ['login'],
    mutationFn: authApi.login,
    onSuccess: async (response) => {
      await onSuccessLogin(response.token);
    },
    onError: (error) => {
      let errorMessage = error.message;

      if (isApiError(error)) {
        if (
          [
            ApiResponseStatus.BadRequest,
            ApiResponseStatus.NotAuthorized,
          ].includes(error.status)
        ) {
          errorMessage = 'Неправильно указан логин или пароль';
        } else {
          // error details, like password rules (special, numbers and so on)
          errorMessage = getApiErrorAuthorizationMessage({
            error: {
              errorText: error.message,
            },
          });
        }
      }

      notify({
        message: errorMessage,
        variant: 'error',
      });
    },
  });
}

function isApiError(error: Error): error is Error & { status: number } {
  return 'status' in error;
}
