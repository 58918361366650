import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { MeetingApi, MeetingDto } from '~/shared/api/index.ts';
import { type AcceptRejectEnum } from '~/shared/enums/AcceptRejectEnum.ts';
import { useApiErrorNotification } from '~/shared/notification/index.ts';
import { time } from '../../../utils/index.ts';
import { useLoadProfileImageUrls } from '../../profile/index.ts';
import { Meeting } from '../model/Meeting.ts';

export const loadMeetingsQueryKey = (options?: {
  meetingName?: Meeting['name'];
  status?: AcceptRejectEnum;
}) =>
  [
    'load meetings',
    Object.keys(options ?? {}).length > 0 ? options : undefined,
  ].filter((key) => key != null);

export function useMeetingsQuery(options?: {
  meetingName?: Meeting['name'];
  status?: AcceptRejectEnum;
}) {
  const { meetingName, status } = options ?? {};

  const meetingApi = useDi(MeetingApi);
  const loadProfileImageUrls = useLoadProfileImageUrls();

  const query = useQuery({
    queryKey: loadMeetingsQueryKey(options),
    queryFn: async () => {
      const response = await meetingApi.getMeetings({
        meetingName,
        participantStatusFilter: status,
      });

      const meetings = response.items.map((dto) =>
        Mapper.map(MeetingDto, Meeting, dto)
      );

      await loadProfileImageUrls(
        meetings.flatMap((meeting) => [
          meeting.meetingOwner,
          ...meeting.invitedMembers,
          ...meeting.acceptedMembers,
        ])
      );

      return meetings;
    },
    staleTime: time(5, 'minutes'),
  });

  useApiErrorNotification(query.error);

  return query;
}

function mapResponse(response: Awaited<ReturnType<MeetingApi['getMeetings']>>) {
  return response.items.map((dto) => Mapper.map(MeetingDto, Meeting, dto));
}
