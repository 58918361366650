import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useRef, useState } from 'react';
import {
  type Interest,
  InterestsInfiniteList,
  loadInterestsQueryKeys,
} from '~/entities/interest/index.ts';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { useCreateInterest } from '~/features/interest/index.ts';
import {
  CancelNavigationButton,
  useOnBack,
} from '~/features/navigation/index.ts';
import { useProfileInterestsEditContext } from '~/features/profile/index.ts';
import { type TagDto } from '~/shared/api/index.ts';
import { Icon } from '~/shared/atoms/icons';
import { Button } from '~/shared/molecules/Button';
import { NavbarInput } from '~/shared/molecules/NavbarInput';
import { ContentSlot, SlotPortal } from '~/shared/organisms/slots';
import {
  ConfirmButton,
  Navbar,
  type PageOffsetInfiniteScrollRef,
} from '~/shared/ui/index.ts';
import classes from './AddProfileInterestDisplay.module.scss';

const MAX_INTEREST_NAME_LENGTH = 30;

export const AddProfileInterestDisplay = () => {
  const queryClient = useQueryClient();

  const { data: profile } = useCurrentUser();
  const { mutate: createInterest, isPending: interestIsCreating } =
    useCreateInterest();
  const { selectedInterestIds, addInterest, deleteInterest } =
    useProfileInterestsEditContext() ?? {};

  const [searchedInterestName, setSearchedInterestName] =
    useState<Interest['name']>('');

  const scrollRef = useRef<PageOffsetInfiniteScrollRef<TagDto>>(null);
  const onBack = useOnBack({});

  const addOrCreate = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    let interest: TagDto | undefined;

    const loadedInterests = scrollRef.current.getAllRows();

    if (searchedInterestName) {
      interest = loadedInterests.at(0);
    } else {
      interest = loadedInterests.find(
        (_interest) =>
          _interest.name.toLowerCase() === searchedInterestName.toLowerCase()
      );
    }

    if (interest) {
      addInterest?.(interest.id);
      onBack();
    } else {
      createInterest(searchedInterestName, {
        onSuccess: async ({ createdTag }) => {
          await queryClient.invalidateQueries({
            queryKey: loadInterestsQueryKeys(),
          });
          addInterest?.(createdTag.id);
          onBack();
        },
      });
    }
  }, [addInterest, createInterest, onBack, queryClient, searchedInterestName]);

  return (
    <>
      <Navbar
        title="Add Interests"
        left={<CancelNavigationButton />}
        right={
          <ConfirmButton
            onClick={searchedInterestName ? addOrCreate : undefined}
          >
            Add
          </ConfirmButton>
        }
        classes={{
          children: classes.navbarChildren,
        }}
        partialLoading={interestIsCreating}
        withBorderBottom
      >
        <NavbarInput
          placeholder="Interest Name"
          icon={<Icon variant="search" />}
          value={searchedInterestName}
          onChange={(newInterestName) => {
            if (newInterestName.length <= MAX_INTEREST_NAME_LENGTH) {
              setSearchedInterestName(newInterestName);
            }
          }}
        />
      </Navbar>

      <ContentSlot>
        <InterestsInfiniteList
          ref={scrollRef}
          searchedInterestName={searchedInterestName}
          selectedInterestIds={selectedInterestIds}
          onRowClick={(interest) => {
            if (selectedInterestIds?.has(interest.id)) {
              deleteInterest?.(interest.id);
            } else {
              addInterest?.(interest.id);
            }
          }}
        />
      </ContentSlot>

      <SlotPortal slotName="footer">
        <div className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon variant="check" />}
            onClick={onBack}
            className={classes.button}
            disabled={
              !selectedInterestIds?.size && !profile?.interestIds.length
            }
          >
            Confirm Selection
          </Button>
        </div>
      </SlotPortal>
    </>
  );
};
