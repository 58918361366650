import { type ForwardedRef, forwardRef } from 'react';
import {
  PageOffsetInfiniteScroll,
  type PageOffsetInfiniteScrollRef,
  type PaginatedInfiniteScrollProps,
  useVirtualRow,
} from '~/shared/ui/index.ts';
import {
  infiniteContactsListQueryKeys,
  useInfiniteContactsListRequest,
} from '../api/useInfiniteContactsListRequest.ts';
import type { ContactProfile } from '../model/ContactProfile.ts';
import { CONTACT_ROW_HEIGHT, ContactItem } from './ContactItem.tsx';

type Props = {
  /** if used search, this name will be passed to api for searching */
  searchedContactName?: ContactProfile['name'];

  /** if some contacts are selected, and you want to shw check icon for them, use this prop */
  selectedContactIds?: Set<ContactProfile['userId']>;

  onRowClick?: (contact: ContactProfile) => void;

  view: 'invite' | 'list';
} & Pick<PaginatedInfiniteScrollProps<ContactProfile>, 'emptyListMessage'>;

const ContactsInfiniteList = (
  props: Props,
  ref: ForwardedRef<PageOffsetInfiniteScrollRef<ContactProfile>>
) => {
  const {
    searchedContactName,
    selectedContactIds,
    onRowClick,
    view,
    ...infiniteProps
  } = props;

  const Row = useVirtualRow<ContactProfile>(
    (props) => {
      const {
        forwardedRef,
        isLoading,
        rowEntity: contact,
        hasNextPage,
        ...rest
      } = props;

      let selected: boolean = false;
      if (contact && selectedContactIds) {
        selected = selectedContactIds.has(contact.userId);
      }

      return (
        <ContactItem
          forwardedRef={forwardedRef}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          interactible={view === 'invite'}
          checked={selected}
          contact={contact}
          onClick={() => {
            if (contact) {
              onRowClick?.(contact);
            }
          }}
          {...rest}
        />
      );
    },
    [selectedContactIds, view, onRowClick]
  );

  return (
    <PageOffsetInfiniteScroll
      ref={ref}
      estimatedRowHeight={CONTACT_ROW_HEIGHT}
      queryKeys={infiniteContactsListQueryKeys(searchedContactName)}
      apiRequest={useInfiniteContactsListRequest(searchedContactName)}
      Row={Row}
      {...infiniteProps}
    />
  );
};

const component = forwardRef(ContactsInfiniteList);
export { component as ContactsInfiniteList };
