import { CircularProgress, Fade } from '@mui/material';
import clsx from 'clsx';
import { type FC, type ReactNode } from 'react';
import { useActiveDisplayLoader } from './model/useDisplayLoaderStore.ts';
import classes from './DisplayLoader.module.scss';

type Props = {
  className?: string;
  children: ReactNode;
};

export { type Props as DisplayLoaderProps };

export const DisplayLoader: FC<Props> = (props) => {
  const { className, children, ...rest } = props;
  const { loading } = useActiveDisplayLoader() ?? {};

  return (
    <>
      <div className={classes.loader}>
        <Fade in={loading}>
          <CircularProgress />
        </Fade>
      </div>

      <Fade in={!loading}>
        <div className={clsx(classes.content, className)} {...rest}>
          {children}
        </div>
      </Fade>
    </>
  );
};
