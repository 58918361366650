import { Paper, Typography } from '@mui/material';
import { type FC, type PropsWithChildren, useEffect, useMemo } from 'react';
import { Navigate, useMatch, useNavigate, useParams } from 'react-router-dom';
import { ContactRow } from '~/entities/contact/index.ts';
import {
  MeetingDraftContextProvider,
  useMeetingDraftContext,
  useMeetingQuery,
} from '~/entities/meeting/index.ts';
import { MeetingDates } from '~/entities/meeting/ui/MeetingDates.tsx';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { SlatReminder } from '~/features/meeting/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { IconButton } from '~/shared/molecules/IconButton/index.ts';
import { useParseInt } from '~/shared/numbers/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { Navbar, NotFoundView, useDisplayLoader } from '~/shared/ui/index.ts';
import { ViewMeetingDisplayFooter } from '~/widgets/meeting/index.ts';
import classes from './ViewMeetingDisplay.module.scss';

export const ViewMeetingDisplay: FC<PropsWithChildren> = ({ children }) => {
  const { meetingId } = useParams<{ meetingId: string | undefined }>();

  return (
    <MeetingDraftContextProvider
      parentRoute={routes.meetings.meetingId(meetingId).edit}
    >
      <ViewMeeting />
      {children}
    </MeetingDraftContextProvider>
  );
};

const ViewMeeting = () => {
  const { meetingId: stringMeetingId } = useParams<{
    meetingId: string | undefined;
  }>();
  const meetingId = useParseInt(stringMeetingId);

  const navigate = useNavigate();
  const { newEditDraft, meetingDraft } = useMeetingDraftContext() ?? {};

  const { data: profile, isLoading: profileIsLoading } = useCurrentUser();

  const { data: meeting, isLoading: isMeetingLoading } =
    useMeetingQuery(meetingId);

  const meetingEditMatch = useMatch(routes.meetings.meetingId().edit.url());

  useEffect(() => {
    if (meetingEditMatch && !meetingDraft) {
      if (newEditDraft && meeting) {
        newEditDraft({ meeting });
      }
    }
  }, [meeting, meetingDraft, meetingEditMatch, newEditDraft]);

  useDisplayLoader(isMeetingLoading);

  const isOwner =
    Boolean(profile) && profile!.userId === meeting?.meetingOwner.userId;

  const endDate = useMemo(() => {
    if (!meeting || !meeting.howLong) {
      return null;
    }
    return meeting.when.add(meeting.howLong);
  }, [meeting]);

  if (meetingId == null) {
    return <Navigate to={routes.meetings.url()} replace={true} />;
  }

  return (
    <>
      <Navbar
        title={meeting?.name ?? ''}
        left={<BackIconNavigationButton />}
        right={
          isOwner ? (
            <IconButton
              variant="edit"
              onClick={() => {
                if (meeting && newEditDraft) {
                  newEditDraft({ meeting });
                  navigate(
                    routes.meetings
                      .meetingId(meeting.meetingId.toString())
                      .edit.url()
                  );
                }
              }}
            />
          ) : undefined
        }
        withBorderBottom
        partialLoading={profileIsLoading || isMeetingLoading}
      />

      {!meeting ? (
        <ContentSlot>
          <NotFoundView>We Couldn't Find Your Meeting 😥</NotFoundView>
        </ContentSlot>
      ) : (
        <>
          <ContentSlot>
            <div className={classes.content}>
              <Paper variant="outlined" square className={classes.description}>
                <Typography>{meeting.description}</Typography>
                <MeetingDates startDate={meeting.when} endDate={endDate} />
              </Paper>

              <SlatReminder duration={meeting.remindBefore} disabled />

              <div className={classes.membersContainer}>
                <Typography variant="body1" className={classes.membersTitle}>
                  Participants (
                  {meeting.acceptedMembers.length + 1 /* includes owner */})
                </Typography>

                <Paper variant="outlined" square>
                  <ContactRow
                    className={classes.member}
                    avatarProps={{
                      firstName: meeting.meetingOwner.firstName,
                      lastName: meeting.meetingOwner.lastName,
                      avatarUrl: meeting.meetingOwner.avatar.url,
                    }}
                    jobPositionId={meeting.meetingOwner.jobPositionId}
                    secondaryAction={<Typography>Organizer</Typography>}
                  />
                </Paper>

                {meeting.acceptedMembers.length > 0 && (
                  <div>
                    {meeting.acceptedMembers.map((member) => (
                      <Paper key={member.userId} variant="outlined" square>
                        <ContactRow
                          className={classes.member}
                          avatarProps={{
                            firstName: member.firstName,
                            lastName: member.lastName,
                            avatarUrl: member.avatar.url,
                          }}
                          jobPositionId={member.jobPositionId}
                        />
                      </Paper>
                    ))}
                  </div>
                )}

                {meeting.invitedMembers.length > 0 && (
                  <>
                    <Typography
                      variant="body1"
                      className={classes.membersTitle}
                    >
                      Invited ({meeting.invitedMembers.length})
                    </Typography>

                    <div>
                      {meeting.invitedMembers.map((member) => (
                        <Paper key={member.userId} variant="outlined" square>
                          <ContactRow
                            className={classes.member}
                            avatarProps={{
                              firstName: member.firstName,
                              lastName: member.lastName,
                              avatarUrl: member.avatar.url,
                            }}
                            jobPositionId={member.jobPositionId}
                          />
                        </Paper>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </ContentSlot>

          <ViewMeetingDisplayFooter meeting={meeting} isOwner={isOwner} />
        </>
      )}
    </>
  );
};
