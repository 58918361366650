import { type ComponentProps, type FC } from 'react';
import { ContainedButton } from './ContainedButton';
import { TextButton } from './TextButton';

const variants = {
  text: TextButton,
  contained: ContainedButton,
};

type Variants = typeof variants;

type Props<Variant extends keyof Variants> = ComponentProps<
  Variants[Variant]
> & {
  variant: Variant;
};

function Button<Variant extends keyof Variants>(props: Props<Variant>) {
  const { variant, ...rest } = props;

  const Component = variants[variant] as FC<typeof rest>;
  if (!Component) {
    throw new Error(`Unsupported variant (${variant}) for Button`);
  }

  return <Component {...rest} />;
}

export { Button };
export type { Props as ButtonProps };
