import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  type JobPosition,
  JobPositionInfiniteList,
  loadLoadJobPositionsQueryKeys,
} from '~/entities/jobPosition/index.ts';
import { useCurrentUser } from '~/entities/profile/index.ts';
import {
  CancelNavigationButton,
  useOnBack,
} from '~/features/navigation/index.ts';
import { useCreateJobPosition } from '~/features/position/index.ts';
import { useProfileCareerEditContext } from '~/features/profile/index.ts';
import { type TagDto } from '~/shared/api/index.ts';
import { Icon } from '~/shared/atoms/icons';
import { NavbarInput } from '~/shared/molecules/NavbarInput';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import {
  ConfirmButton,
  Display,
  Navbar,
  type PageOffsetInfiniteScrollRef,
} from '~/shared/ui/index.ts';
import classes from './EditProfileJobPositionDisplay.module.scss';

const queryKeys = (searchedJobPositionName?: JobPosition['name']) => [
  ...loadLoadJobPositionsQueryKeys(searchedJobPositionName),
  'infinitely',
];

export const EditProfileJobPositionDisplay = () => {
  const queryClient = useQueryClient();

  const [searchedJobPositionName, setSearchedJobPositionName] =
    useState<JobPosition['name']>('');

  const { data: profile } = useCurrentUser();
  const { mutate: createJobPosition, isPending: jobPositionIsCreating } =
    useCreateJobPosition();
  const { selectedJobPositionId, setSelectedJobPositionId } =
    useProfileCareerEditContext() ?? {};

  const selectedJobPositionIds = useMemo(() => {
    if (selectedJobPositionId != null) {
      return new Set([selectedJobPositionId]);
    } else if (profile?.jobPositionId != null) {
      return new Set([profile.jobPositionId]);
    }
  }, [profile?.jobPositionId, selectedJobPositionId]);

  const scrollRef = useRef<PageOffsetInfiniteScrollRef<TagDto>>(null);

  const onBack = useOnBack({});

  const addOrCreate = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    let department: TagDto | undefined;

    const loadedJobPositions = scrollRef.current.getAllRows();

    if (searchedJobPositionName) {
      department = loadedJobPositions.at(0);
    } else {
      department = loadedJobPositions.find(
        (_department) =>
          _department.name.toLowerCase() ===
          searchedJobPositionName.toLowerCase()
      );
    }

    if (department) {
      setSelectedJobPositionId?.(department.id);
      onBack();
    } else {
      createJobPosition(searchedJobPositionName, {
        onSuccess: async ({ createdTag }) => {
          await queryClient.invalidateQueries({
            queryKey: queryKeys(),
          });
          setSelectedJobPositionId?.(createdTag.id);
          onBack();
        },
      });
    }
  }, [
    createJobPosition,
    onBack,
    queryClient,
    searchedJobPositionName,
    setSelectedJobPositionId,
  ]);

  return (
    <Display
      documentTitle="edit profile job position"
      animationVariant="card-effect"
    >
      <Navbar
        title="Position"
        left={<CancelNavigationButton />}
        right={
          <ConfirmButton
            onClick={searchedJobPositionName ? addOrCreate : undefined}
          >
            Add
          </ConfirmButton>
        }
        classes={{
          children: classes.navbarChildren,
        }}
        partialLoading={jobPositionIsCreating}
        withBorderBottom
      >
        <NavbarInput
          placeholder="Position Name"
          icon={<Icon variant="search" />}
          value={searchedJobPositionName}
          onChange={setSearchedJobPositionName}
        />
      </Navbar>

      <ContentSlot>
        <JobPositionInfiniteList
          ref={scrollRef}
          selectedIcon="check"
          searchedJobPositionName={searchedJobPositionName}
          onRowClick={(jobPosition) => {
            setSelectedJobPositionId?.(jobPosition.id);
            onBack();
          }}
          selectedJobPositionIds={selectedJobPositionIds}
        />
      </ContentSlot>
    </Display>
  );
};
