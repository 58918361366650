import { TagCard } from '~/shared/molecules/TagCard/index.ts';
import { type Interest } from '../index.ts';

type Props = {
  interest: Interest;
};

export const InterestCard = (props: Props) => {
  const { interest } = props;

  return (
    <TagCard
      icon="book"
      name={interest.name}
      confirmsCount={interest.linkedCount}
    />
  );
};
