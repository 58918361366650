import clsx from 'clsx';
import { type FC, type PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoadInterestsById } from '~/entities/interest/api/useLoadInterestsById.ts';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { CancelNavigationButton } from '~/features/navigation/index.ts';
import {
  SaveInterestsButton,
  useProfileInterestsEditContext,
} from '~/features/profile/index.ts';
import { Icon } from '~/shared/atoms/icons';
import { Button } from '~/shared/molecules/Button';
import { ContentSlot, SlotPortal } from '~/shared/organisms/slots';
import { routes } from '~/shared/routing/index.ts';
import { Display, Navbar, NotFoundView } from '~/shared/ui/index.ts';
import { TagListView } from '../TagListView/index.ts';
import { useEditedProfileInterests } from './useEditedProfileInterests.tsx';
import classes from './EditProfileInterestsDisplay.module.scss';

export const EditProfileInterestsDisplay: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const { deleteInterest, addInterest } =
    useProfileInterestsEditContext() ?? {};
  const { data: profile, isLoading: profileIsLoading } = useCurrentUser();
  const { isLoading: interestsAreLoading } = useLoadInterestsById(
    profile?.interestIds
  );

  const navigate = useNavigate();

  const { interests, removed, added } = useEditedProfileInterests();

  const AddInterestButton = () => (
    <Button
      variant="contained"
      color="primary"
      startIcon={<Icon variant="add" />}
      onClick={() => navigate(routes.profile.settings.edit.interests.new.url())}
      className={clsx({ [classes.button]: interests?.length !== 0 })}
    >
      Add Interest
    </Button>
  );

  return (
    <>
      <Display
        documentTitle="Edit profile interests"
        animationVariant="card-effect"
      >
        <Navbar
          title="Interests"
          left={<CancelNavigationButton />}
          right={<SaveInterestsButton />}
          partialLoading={profileIsLoading || interestsAreLoading}
        />

        <ContentSlot>
          {!interests?.length ? (
            <NotFoundView button={<AddInterestButton />}>
              No Interests
            </NotFoundView>
          ) : (
            <>
              <TagListView
                list={interests}
                removed={removed}
                added={added}
                onRestore={addInterest}
                onRemove={deleteInterest}
              />

              <SlotPortal slotName="footer">
                <div className={classes.footer}>
                  <AddInterestButton />
                </div>
              </SlotPortal>
            </>
          )}
        </ContentSlot>
      </Display>

      {children}
    </>
  );
};
