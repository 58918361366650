import { type FC, type ReactElement } from 'react';
import { useRegistrationContext } from '~/features/auth/index.ts';
import { RegisterUserInformation } from '~/features/auth/model/RegisterUserInformation.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { Checkbox } from '~/shared/components/Checkbox/index.tsx';
import { FormField } from '~/shared/form/FormField.tsx';
import { FormProvider } from '~/shared/form/FormProvider.tsx';
import { Navbar } from '~/shared/ui/index.ts';
import { NameField } from '~/shared/ui/NameField.tsx';
import { UserRules } from './UserRules.tsx';
import classes from './RegistrationStep.module.scss';

type Props = {
  hideBackButton?: boolean;
  onSubmit?: (data: RegisterUserInformation) => void;
  SubmitButton: ReactElement;
};

export const InformationStep: FC<Props> = (props) => {
  const { hideBackButton, SubmitButton, onSubmit } = props;

  const {
    goToCredentialsStep,
    firstName,
    onFirstNameChange,
    lastName,
    onLastNameChange,
    areRulesAccepted,
    onAreRulesAccepted,
  } = useRegistrationContext() ?? {};

  return (
    <>
      <Navbar
        left={
          hideBackButton ? undefined : (
            <BackIconNavigationButton onClick={goToCredentialsStep} />
          )
        }
        hideHealthButton
      />

      <FormProvider<RegisterUserInformation>
        className={classes.fields}
        initialValues={{
          firstName,
          lastName,
          areRulesAccepted,
        }}
        validationSchema={RegisterUserInformation.validationSchema}
        onValidSubmit={onSubmit}
      >
        <NameField
          name="firstName"
          placeholder="First Name"
          onChangeValue={onFirstNameChange}
        />

        <NameField
          name="lastName"
          placeholder="Last Name"
          onChangeValue={onLastNameChange}
        />

        <FormField<RegisterUserInformation> name="areRulesAccepted">
          {({ register, errors }) => (
            <Checkbox
              label={<UserRules />}
              isError={errors?.areRulesAccepted != null}
              helpText={errors?.areRulesAccepted?.message}
              {...register({
                onChangeValue: onAreRulesAccepted,
              })}
            />
          )}
        </FormField>

        {SubmitButton}
      </FormProvider>
    </>
  );
};
