import { useCallback, useState } from 'react';

export function useStateSet<Entity>() {
  const [state, setState] = useState(() => new Set<Entity>());

  const add = useCallback((entity: Entity) => {
    setState((prevState) => {
      if (prevState.has(entity)) {
        return prevState;
      }

      const newSet = new Set(prevState);
      newSet.add(entity);
      return newSet;
    });
  }, []);

  const remove = useCallback((entity: Entity) => {
    setState((prevState) => {
      if (!prevState.has(entity)) {
        return prevState;
      }

      const newSet = new Set(prevState);
      newSet.delete(entity);
      return newSet;
    });
  }, []);

  const clear = useCallback(() => {
    setState(new Set());
  }, []);

  return {
    state,
    setState,
    add,
    remove,
    clear,
  };
}
