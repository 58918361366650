import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { useNavigate } from 'react-router-dom';
import { removeAuthorizationTokenFromConfig } from '~/entities/auth/index.ts';
import { AuthApi } from '~/shared/api/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { resetAllStores } from '~/shared/store/index.ts';

export function useLogoutQuery() {
  const navigate = useNavigate();
  const authApi = useDi(AuthApi);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['log out'],
    mutationFn: async () => {
      await authApi.logout();
      removeAuthorizationTokenFromConfig();
      resetAllStores();
      queryClient.clear();
      navigate(routes.auth.login.url());
    },
  });
}
