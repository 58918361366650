import { type FC, type PropsWithChildren } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserById } from '~/entities/colleague/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { Photo } from '~/shared/atoms/Photo/index.ts';
import { useParseInt } from '~/shared/numbers/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { FooterTabs } from '~/shared/templates/FooterTabs.tsx';
import { Display, Navbar, useDisplayLoader } from '~/shared/ui/index.ts';
import { ProfileMainInfo } from '~/widgets/profile/index.ts';
import classes from '~/widgets/profile/ui/CurrentProfileInformation.module.scss';

export const ColleagueProfileDisplay: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const navigate = useNavigate();

  const { userId: stringUserId } = useParams<{ userId: string | undefined }>();
  const userId = useParseInt(stringUserId);

  const { data: colleague, isLoading: colleagueIsLoading } =
    useUserById(userId);

  useDisplayLoader(colleagueIsLoading);

  return (
    <>
      <Display
        documentTitle={`${colleague?.name} profile`}
        animationVariant="card-effect"
      >
        <Navbar
          title={colleague?.firstName ?? ''}
          left={<BackIconNavigationButton />}
        />

        <ContentSlot className={classes.root}>
          <Photo
            avatarUrl={colleague?.avatar.url}
            onClick={() =>
              navigate(routes.colleagues.userId(stringUserId).avatars.url())
            }
          />

          <ProfileMainInfo
            userId={userId}
            userRoutes={routes.colleagues.userId(stringUserId)}
          />
        </ContentSlot>

        <FooterTabs />
      </Display>

      {children}
    </>
  );
};
