import { useEffect } from 'react';
import { notify } from './notify.ts';

export function useApiErrorNotification(error: Error | null) {
  useEffect(() => {
    if (error) {
      notify({
        variant: 'error',
        message: error.message,
      });
    }
  }, [error]);
}
