import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoadJobPositionById } from '~/entities/jobPosition/index.ts';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { Icon } from '~/shared/atoms/icons';
import { TextField } from '~/shared/organisms/TextField';
import { routes } from '~/shared/routing/index.ts';
import { useProfileCareerEditContext } from '../../profile/index.ts';

export const EditUserPositionField: FC = () => {
  const { selectedJobPositionId, setSelectedJobPositionId } =
    useProfileCareerEditContext() ?? {};

  const navigate = useNavigate();

  const { data: profile } = useCurrentUser();
  const { data: selectedJobPosition } = useLoadJobPositionById(
    selectedJobPositionId
  );
  const { data: savedJobPosition } = useLoadJobPositionById(
    profile?.jobPositionId
  );

  const jobPosition = selectedJobPosition ?? savedJobPosition;

  return (
    <TextField
      label="Position"
      value={jobPosition?.name ?? ''}
      onClick={() =>
        navigate(routes.profile.settings.edit.career.position.url())
      }
      // isError={Boolean(jobPositionError)}
      // helpText={jobPositionError}
      endAdornment={
        selectedJobPositionId != null && (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={(event) => {
                setSelectedJobPositionId?.(null);
                event.stopPropagation(); // do not open job position list on clear action
              }}
            >
              <Icon variant="cross" />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};
