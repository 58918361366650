import { type FC, type ReactNode } from 'react';
import classes from './Experience.module.scss';

type Props = {
  children: ReactNode[];
};

export const Experience: FC<Props> = (props) => {
  const { children } = props;

  return <div className={classes.root}>{children}</div>;
};
