import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    // color: theme.palette.text.primary, // todo: doesnt work ?
    color: '#5295F0',
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '30px',
  },
}));
