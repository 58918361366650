import clsx from 'clsx';
import {
  type CSSProperties,
  type FC,
  type MouseEvent,
  type ReactNode,
} from 'react';
import classes from './ModalViewButton.module.scss';

type Props = {
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  classname?: string;
  style?: CSSProperties;
  message?: ReactNode;
  isDanger?: boolean;
  children: ReactNode;
};

export const ModalViewButton: FC<Props> = (props) => {
  const { children, onClick, classname, style, message, isDanger } = props;

  return (
    <div
      className={clsx(classes.root, classname, {
        [classes.danger]: isDanger,
      })}
      style={style}
    >
      {message && <div className={classes.message}>{message}</div>}

      <div className={classes.label} onClick={onClick}>
        {children}
      </div>
    </div>
  );
};
