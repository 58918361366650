import { useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import {
  PickersDay,
  type PickersDayProps,
} from '@mui/x-date-pickers/PickersDay';
import clsx from 'clsx';
import { type Dayjs } from 'dayjs';
import { type CSSProperties, type FC, useMemo } from 'react';
import classes from './CalendarEventDay.module.scss';

export type CalendarEvent = {
  date: Dayjs;
  count: number;
};

export type CalendarEventDayProps = PickersDayProps<Dayjs> & {
  events?: CalendarEvent[];
};

export const CalendarEventDay: FC<CalendarEventDayProps> = (props) => {
  const { events, day, selected, outsideCurrentMonth, ...pickerProps } = props;

  const theme = useTheme();

  const dayEvent = useMemo(
    () =>
      events?.find((event) =>
        event.date.startOf('day').isSame(day.startOf('day'))
      ),
    [day, events]
  );

  const DayComponent = (
    <PickersDay
      classes={{
        root: clsx(classes.root, {
          [classes.withBadge]: dayEvent && dayEvent.count > 0,
          [classes.selected]: selected,
        }),
      }}
      day={day}
      selected={selected}
      outsideCurrentMonth={outsideCurrentMonth}
      style={{
        ['--theme-palette-primary-main' as keyof CSSProperties]:
          theme.palette.primary.main,
      }}
      {...pickerProps}
    />
  );

  if (!outsideCurrentMonth && dayEvent && dayEvent.count > 0) {
    return (
      <Badge
        badgeContent={dayEvent.count}
        color="primary"
        classes={{
          badge: classes.badge,
        }}
      >
        {DayComponent}
      </Badge>
    );
  }

  return DayComponent;
};
