import { type FC } from 'react';
import { CancelButton } from '~/shared/ui/index.ts';
import { useOnBack } from './useOnBack.ts';

type Props = {
  disabled?: boolean;
};

export const CancelNavigationButton: FC<Props> = ({ disabled }) => {
  const onBack = useOnBack({});

  return <CancelButton onClick={onBack} disabled={disabled} />;
};
