import { useEffect } from 'react';
import { useBoolean } from 'usehooks-ts';
import { useCurrentUser } from '../../../../entities/profile/api/useCurrentUser.ts';
import { useTimezoneModalConfig } from '../../config/useTimezoneModalConfig.ts';

export function useShouldProposeTimezoneChange(currentDeviceTimezone: string) {
  const { data: profile } = useCurrentUser();

  const hasUserDisabledTimezoneProposalDialog = useTimezoneModalConfig(
    (config) => config.hasUserDisabledTimezoneProposalDialog
  );

  const {
    value: shouldProposeTimezoneChange,
    setFalse: stopProposeTimezoneChange,
    setValue: setShouldProposeTimezoneChange,
  } = useBoolean(false);

  useEffect(() => {
    if (!profile) {
      return;
    }

    if (hasUserDisabledTimezoneProposalDialog) {
      stopProposeTimezoneChange();
    } else {
      const isTimezoneDifferent = profile.timezone !== currentDeviceTimezone;
      setShouldProposeTimezoneChange(isTimezoneDifferent);
    }
  }, [
    profile?.timezone,
    hasUserDisabledTimezoneProposalDialog,
    currentDeviceTimezone,
  ]);

  return [shouldProposeTimezoneChange, stopProposeTimezoneChange] as const;
}
