import { Switch as MuiSwitch } from '@mui/material';
import { styled } from '@mui/material/styles';

type SwitchConfig = {
  leftLabel: string;
  rightLabel: string;
};

const createSwitch = (config: SwitchConfig) =>
  styled(MuiSwitch)(({ theme }) => ({
    margin: 0,
    padding: 0,
    height: '40px',
    width: '100%',
    backgroundColor: '#F1F3F5',
    borderRadius: '32px',

    '& .MuiSwitch-switchBase': {
      width: '50%',
      height: '100%',
      margin: 0,
      transform: 'translateX(100%)',
      backgroundColor: '#5295F1',
      borderRadius: '32px',
      padding: 0,

      '&:before, &:after': {
        position: 'absolute',
        width: '50%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Roboto',
      },

      // left is unchecked
      '&:before': {
        content: `"${config.leftLabel}"`,
        color: '#868E96',
        left: '-75%',
        fontWeight: 'normal',
      },
      // right is checked
      '&:after': {
        content: `"${config.rightLabel}"`,
        color: 'white',
        right: '25%',
        fontWeight: 500,
      },

      // left is checked
      '&.Mui-checked:before': {
        color: 'white',
        left: '25%',
        fontWeight: 500,
      },
      // right is unchecked
      '&.Mui-checked:after': {
        color: '#868E96',
        right: '-75%',
        fontWeight: 'normal',
      },

      '&.Mui-checked': {
        transform: 'translateX(0)',
        '& + .MuiSwitch-track': {
          backgroundColor: '#F1F3F5',
        },
      },
      '&.Mui-checked:hover': {
        backgroundColor: '#5295F1',
      },
    },
    '& .MuiSwitch-switchBase:hover': {
      backgroundColor: '#5295F1',
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#F1F3F5',
    },
  }));

export { createSwitch };
