import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

export const AddEventIcon = (props: Props) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V8H20V21Z"
        fill={color}
      />
      <path
        d="M16 14.5714H12.5714V18H11.4286V14.5714H8V13.4286H11.4286V10H12.5714V13.4286H16V14.5714Z"
        fill={color}
      />
    </svg>
  );
};
