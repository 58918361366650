import type dayjs from 'dayjs';
import { type FC } from 'react';
import { DateField } from '~/shared/organisms/fields/index.ts';

type Props = {
  joinedCompanyDate: dayjs.Dayjs | null;
  onChange: (joinedCompanyDate: Props['joinedCompanyDate']) => void;
};

export const EditJoinCompanyDateField: FC<Props> = (props) => {
  const { joinedCompanyDate, onChange } = props;

  return (
    <DateField
      label="Start Date at Company"
      value={joinedCompanyDate?.utc()}
      onChange={onChange}
      disableFuture
      openTo="year"
      views={['year', 'month']}
    />
  );
};
