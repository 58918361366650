import {
  type FC,
  type PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import { SlotContext, type SlotContextType } from './SlotContext';

export const SlotProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const reservedSlotsMapRef = useRef<SlotContextType['reservedSlotsMap']>(
    new Map()
  );
  const [slotsMap, setSlotsMap] = useState<SlotContextType['slotsMap']>(
    () => new Map()
  );
  const [, startTransition] = useTransition();

  const onUpdateSlots = useCallback<SlotContextType['onUpdateSlots']>(
    (update) => {
      startTransition(() => {
        setSlotsMap((prevMap) => {
          const newMap = new Map(prevMap);
          update(newMap);
          return newMap;
        });
      });
    },
    []
  );

  const contextValue = useMemo<SlotContextType>(
    () => ({
      slotsMap,
      reservedSlotsMap: reservedSlotsMapRef.current,
      onUpdateSlots,
    }),
    [onUpdateSlots, slotsMap]
  );

  return (
    <SlotContext.Provider value={contextValue}>{children}</SlotContext.Provider>
  );
};
