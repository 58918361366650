import { type FC } from 'react';
import {
  IconButton,
  type IconButtonProps,
} from '~/shared/molecules/IconButton';

type Props = Pick<IconButtonProps<'filterOff'>, 'onClick' | 'disabled'>;

export const FilterOffIconButton: FC<Props> = (props) => {
  return (
    <IconButton
      {...props}
      aria-label="clear filters button"
      title="Clear filters"
      size="large"
      variant="filterOff"
    />
  );
};
