import { Typography } from '@mui/material';
import { type FC } from 'react';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { useCurrentUser } from '../../profile/index.ts';
import classes from './CoinBalance.module.scss';

type Props = {};

export const CoinBalance: FC<Props> = (props) => {
  const {} = props;

  const { data: user } = useCurrentUser();

  return (
    <div className={classes.root}>
      <Icon variant="coin" />
      <Typography>{user?.coins}</Typography>
    </div>
  );
};
