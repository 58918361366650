import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '~/shared/routing/index.ts';
import { useCurrentUser } from './useCurrentUser.ts';

export function useRedirectToFirstLoginInformation() {
  const navigate = useNavigate();
  const { data: myProfile } = useCurrentUser();

  useEffect(() => {
    if (myProfile != null && !myProfile.wasSaved) {
      navigate(routes.profile.first_login.url());
    }
  }, [myProfile, navigate]);
}
