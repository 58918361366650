import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { DepartmentTwoColumnRow } from '~/entities/department';
import { ShortInterestList } from '~/entities/interest';
import { JobPositionTwoColumnRow } from '~/entities/jobPosition';
import {
  CompanyExperienceTwoColumnRow,
  useCurrentUser,
  WorkExperienceTwoColumnRow,
} from '~/entities/profile';
import { ShortSkillList } from '~/entities/skill';
import { About } from '~/pages/profile/About.tsx';
import { Experience } from '~/pages/profile/Experience/Experience.tsx';
import { NameAndEmail } from '~/pages/profile/NameAndEmail';
import { Photo } from '~/shared/atoms/Photo';
import { ContentSlot } from '~/shared/organisms/slots';
import { routes } from '~/shared/routing/index.ts';
import { useDisplayLoader } from '~/shared/ui/index.ts';
import classes from './CurrentProfileInformation.module.scss';

export const CurrentProfileInformation: FC = () => {
  const navigate = useNavigate();

  const { data: profile, isLoading: profileIsLoading } = useCurrentUser();
  const { jobPositionId, departmentId, skillIds, interestIds } = profile ?? {};

  useDisplayLoader(!profile);

  if (!profile) {
    return null;
  }

  return (
    <ContentSlot className={classes.root}>
      <Photo
        avatarUrl={profile.avatar.url}
        onClick={() => navigate(routes.profile.avatars.url())}
      />

      <div className={classes.info}>
        <NameAndEmail name={profile.name} email={profile.email} />

        <Experience>
          <DepartmentTwoColumnRow />
          <JobPositionTwoColumnRow />

          <CompanyExperienceTwoColumnRow
            joinedCompanyDate={profile.joinedCompanyDate}
            isLoading={profileIsLoading}
          />

          <WorkExperienceTwoColumnRow
            workExperienceSince={profile.workExperienceSince}
            isLoading={profileIsLoading}
          />
        </Experience>

        <About bio={profile.bio} />
        <ShortInterestList
          interestIds={interestIds}
          onShowAll={() => navigate(routes.profile.interests.url())}
        />
        <ShortSkillList
          skillIds={skillIds}
          onShowAll={() => navigate(routes.profile.skills.url())}
        />
      </div>
    </ContentSlot>
  );
};
