import {
  createContext,
  type HTMLAttributes,
  type MutableRefObject,
} from 'react';
import { type SlotName } from './SlotName';

export type Slot = HTMLAttributes<HTMLDivElement>;

export type SlotContextType = {
  slotsMap: Map<SlotName, Slot>;
  onUpdateSlots: (update: (slotsMap: Map<SlotName, Slot>) => void) => void;
  reservedSlotsMap: Map<SlotName, MutableRefObject<HTMLElement>>;
};

export const SlotContext = createContext<SlotContextType | null>(null);
