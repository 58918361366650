import {
  IS_DEV_MODE,
  TELEGRAM_TEST_AUTH_INIT_DATA,
} from '~/config/environment/index.ts';

export function getTelegramWebAppInitData(): string | undefined {
  const telegramInitData = window.Telegram?.WebApp?.initData;
  const testTelegramInitData =
    IS_DEV_MODE && TELEGRAM_TEST_AUTH_INIT_DATA
      ? TELEGRAM_TEST_AUTH_INIT_DATA
      : '';

  return telegramInitData || testTelegramInitData;
}
