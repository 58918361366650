import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { type ForwardedRef, forwardRef } from 'react';
import ReactSelect, {
  type GroupBase,
  type Props as ReactSelectProps,
} from 'react-select';
import type Select from 'react-select/base';
import { type SelectOption } from './SelectOption';

const component = forwardRef(SelectField) as typeof SelectField;
export { component as SelectField };

function SelectField<
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: ReactSelectProps<Option, IsMulti, Group> & {
    label: string;
    isError?: boolean;
    helpText?: string;
    onChangeValue?: (
      newValue: IsMulti extends true
        ? Array<Option['value']>
        : NonNullable<Option>['value'] | null
    ) => void;
  },
  ref: ForwardedRef<Select<Option, IsMulti, Group>>
) {
  const {
    label,
    components = {},
    options,
    isError,
    helpText,
    onChange,
    onChangeValue,
    ...selectProps
  } = props;

  return (
    <StyledFormControl error={isError}>
      <StyledInputLabel shrink={Boolean(selectProps.value)}>
        {label}
      </StyledInputLabel>

      <ReactSelect
        placeholder=""
        hideSelectedOptions={false}
        isClearable={false}
        options={options}
        onChange={(optionValue, actionMeta) => {
          onChange?.(optionValue, actionMeta);

          if (Array.isArray(optionValue)) {
            const options = optionValue as Option[];
            const values = options.map((option) => option.value);
            onChangeValue?.(values as any);
          } else {
            const option = optionValue as Option | null;
            const value = option?.value ?? null;
            onChangeValue?.(value as any);
          }
        }}
        {...selectProps}
        styles={{
          control: (base) => ({
            ...base,
            borderRadius: 10,
            ...(isError ? { borderColor: 'red' } : {}),
          }),
          valueContainer: (base) => ({
            ...base,
            padding: '8px 12px 9px',
          }),
          input: (base) => ({
            ...base,
            margin: 0,
          }),
        }}
        components={{
          IndicatorSeparator: null,
          ...components,
        }}
        ref={ref}
      />

      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </StyledFormControl>
  );
}

const StyledFormControl = styled(FormControl)({
  '&.MuiFormControl-root': {
    margin: 0,
  },
});

const StyledInputLabel = styled(InputLabel)({
  '&.MuiFormLabel-root.MuiInputLabel-root': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    color: '#495057',

    '&[data-shrink="true"]': {
      backgroundColor: 'white',
      padding: '0px 6px',
      marginLeft: '-6px',
    },
  },
});
