import { Fade, LinearProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import {
  type CSSProperties,
  memo,
  type ReactElement,
  type ReactNode,
} from 'react';
import { ApiDevToolsButton } from '~/features/apiDevTools/ui/ApiDevToolsButton.tsx';
import { SlotPortal } from '../../organisms/slots/index.ts';
import { useActiveNavbarLoader } from './model/useNavbarLoaderStore.ts';
import classes from './Navbar.module.scss';

type NavbarClasses =
  | 'root'
  | 'title'
  | 'leftButton'
  | 'rightButton'
  | 'children';
type Props = {
  title?: string | ReactNode;
  children?: ReactNode | ReactNode[];

  className?: string;
  classes?: Partial<{
    [className in NavbarClasses]: string;
  }>;
  style?: CSSProperties;

  left?: ReactElement;
  right?: ReactElement;

  partialLoading?: boolean;
  withBorderBottom?: boolean;
  hideHealthButton?: boolean;
};

function Navbar(props: Props) {
  const {
    title,
    children,
    className,
    classes: propsClasses = {},
    style,
    left: LeftButton,
    right: RightButton,
    partialLoading = false,
    withBorderBottom = false,
    hideHealthButton = false,
  } = props;

  const activeLoader = useActiveNavbarLoader();

  return (
    <div
      className={clsx(
        classes.navbar,
        {
          [classes.withBorderBottom]: withBorderBottom,
        },
        className,
        propsClasses.root
      )}
      style={style}
    >
      <div className={classes.leftButtonSlot}>{LeftButton}</div>

      <StyledTitle
        variant="h4"
        className={clsx(classes.title, propsClasses.title)}
      >
        {title}
      </StyledTitle>

      <div className={classes.rightButtonSlot}>
        {!hideHealthButton && <ApiDevToolsButton />}
        {RightButton}
      </div>

      {children ? (
        <div className={clsx(classes.navbarChildren, propsClasses.children)}>
          {children}
        </div>
      ) : null}

      <Fade
        in={partialLoading || activeLoader?.loading}
        className={classes.progress}
      >
        <LinearProgress />
      </Fade>
    </div>
  );
}

const MemoizedNavbar = memo(Navbar);

const StyledTitle = styled(Typography)({
  '&.MuiTypography-root': {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
  },
});

function NavbarInPortal(props: Props) {
  return (
    <SlotPortal slotName="navbar">
      <MemoizedNavbar {...props} />
    </SlotPortal>
  );
}

export { NavbarInPortal as Navbar };
export type { Props as NavbarProps };
