import { useRegistrationContext } from '~/features/auth/index.ts';
import { RegisterUserCredentials } from '~/features/auth/model/RegisterUserCredentials.ts';
import { FormProvider } from '~/shared/form/FormProvider.tsx';
import { Button } from '~/shared/molecules/Button/index.ts';
import { EmailField } from '~/shared/ui/EmailField.tsx';
import { PasswordField } from '~/shared/ui/PasswordField.tsx';
import classes from './RegistrationStep.module.scss';

const CredentialsStep = () => {
  const {
    goToInformationStep,
    email,
    onEmailChange,
    password,
    onPasswordChange,
    confirmPassword,
    onConfirmPasswordChange,
  } = useRegistrationContext() ?? {};

  return (
    <FormProvider<RegisterUserCredentials>
      className={classes.fields}
      initialValues={{
        email,
        password,
        confirmPassword,
      }}
      validationSchema={RegisterUserCredentials.validationSchema}
      onValidSubmit={goToInformationStep}
    >
      <EmailField onChangeValue={onEmailChange} />

      <PasswordField
        name="password"
        placeholder="Password"
        onChangeValue={onPasswordChange}
      />

      <PasswordField
        name="confirmPassword"
        placeholder="Confirm Password"
        onChangeValue={onConfirmPasswordChange}
      />

      <Button variant="contained" color="primary" type="submit">
        Next step
      </Button>
    </FormProvider>
  );
};

export { CredentialsStep };
