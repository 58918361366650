import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { loadMeetingsQueryKey } from '~/entities/meeting/api/useMeetingsQuery.ts';
import { MeetingApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';

export function useLeaveMeetingMutation(options?: {
  onSuccessCallback?: VoidFunction;
}) {
  const { onSuccessCallback } = options ?? {};
  const meetingApi = useDi(MeetingApi);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['leave meeting'],
    mutationFn: meetingApi.leaveMeeting,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: loadMeetingsQueryKey(),
      });
      onSuccessCallback?.();
    },
    onError: (error) => {
      notify({
        message: `Cannot leave meeting ${error.message}`,
        variant: 'error',
      });
    },
  });
}
