import { isPromise } from './isPromise.ts';

export function createApiLogger(apiName: string) {
  return <Api extends object>(api: Api): Api => {
    return new Proxy(api, {
      get(target, propertyName) {
        const value = target[propertyName as keyof typeof target];
        if (typeof value !== 'function') {
          return value;
        }

        const apiMethod = value;
        const loggedName = `${apiName}.${propertyName.toString()}`;

        return (...args: unknown[]) => {
          console.info(`🌐⬆️ ${loggedName}`, ...args);
          const response = apiMethod.apply(target, args);
          if (isPromise(response)) {
            response.then((result) => {
              if (result) {
                console.info(`🌐✅ ${loggedName}`, result);
              }
            });
          } else if (response) {
            console.info(`🌐✅ ${loggedName}`, response);
          }

          return response;
        };
      },
    });
  };
}
