import { Drawer, List, ListItemButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useDi } from 'cheap-di-react';
import { type FC, useEffect, useState } from 'react';
import type { LoginUserCredentials } from '~/features/auth/model/LoginUserCredentials.ts';
import { useFakeApi } from '~/features/fake-api/index.ts';
import { MatcherApi, type MyUserDto } from '~/shared/api/index.ts';
import { FakeApiDatabase } from '~/shared/fakeApiDatabase/index.ts';
import { useFormContext } from '~/shared/form/FormContext.ts';
import { IconButton } from '~/shared/molecules/IconButton/index.ts';
import classes from './MirageUsers.module.scss';

export const MirageUsers: FC = () => {
  const isMirageApi = useFakeApi();
  if (!isMirageApi) {
    return null;
  }

  return <Users />;
};

function Users() {
  const fakeApiDatabase = useDi(FakeApiDatabase);
  const mirageApi = useDi(MatcherApi);

  const { form } = useFormContext<LoginUserCredentials>() ?? {};

  const [users, setUsers] = useState<
    Pick<MyUserDto, 'userId' | 'sensitiveInformation'>[]
  >([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      const users = await fakeApiDatabase.user.getAll();
      setUsers(users);

      await mirageApi.getFakeMutualMatches();
    })();
  }, []);

  return (
    <div className={classes.root}>
      <Tooltip title={`${show ? 'Скрыть' : 'Показать'} список пользователей`}>
        <IconButton
          variant="users"
          onClick={() => {
            setShow((state) => !state);
          }}
          color="primary"
        />
      </Tooltip>

      <Drawer open={show} onClose={() => setShow(false)}>
        {show && (
          <List className={classes.usersList}>
            {users.length > 0 ? (
              users.map((user) => (
                <ListItemButton
                  key={user.userId}
                  onClick={async () => {
                    form?.setValue('email', user.sensitiveInformation.email);
                    setShow(false);
                  }}
                >
                  {user.sensitiveInformation.email}
                </ListItemButton>
              ))
            ) : (
              <ListItemButton
                onClick={() => {
                  setShow(false);
                }}
              >
                Пользователей нет
              </ListItemButton>
            )}
          </List>
        )}
      </Drawer>
    </div>
  );
}
