import { ApiBase } from '../ApiBase.ts';
import type { AuthFakeApi } from './AuthFakeApi.ts';
import type { AuthRealApi } from './AuthRealApi.ts';

export class AuthApi extends ApiBase {
  constructor(
    private realApi: AuthRealApi,
    private fakeApi: AuthFakeApi
  ) {
    super();
  }

  setAuthorizationTokenToApiInterceptor = (authorizationToken: string) => {
    this.realApi.setAuthorizationTokenToApiInterceptor(authorizationToken);
  };

  login = (payload: {
    login: string;
    password?: string;
  }): ReturnType<AuthRealApi['login']> => {
    const { login, password } = payload;

    if (this.isFakeApi) {
      return this.fakeApi.login(login);
    }

    if (password == null) {
      throw new Error('Password is required for real Auth api login');
    }

    return this.realApi.login(login, password);
  };

  register: AuthRealApi['register'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.register(...args);
    }
    return this.realApi.register(...args);
  };

  isAuthTokenValid: AuthRealApi['isAuthTokenValid'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.isAuthTokenValid(...args);
    }
    return this.realApi.isAuthTokenValid(...args);
  };

  logout: AuthRealApi['logout'] = async (...args) => {
    if (this.isFakeApi) {
      await this.fakeApi.logout(...args);
    } else {
      await this.realApi.logout(...args);
    }

    this.realApi.removeAuthorizationToken();
  };
}
