import type { NewMeetingNotificationDto } from '../NewMeetingNotificationDto.ts';

export abstract class MeetingSignal {
  abstract start(authorizationToken: string): Promise<void>;
  abstract stop(): Promise<void>;

  abstract onNewMeeting(
    callback: (dto: NewMeetingNotificationDto) => void
  ): void;
}
