import { type Constructor, type Container } from 'cheap-di';
import type { ConfigurationOptions } from '../ConfigurationOptions.ts';
import { createApiLogger } from '../createApiLogger.ts';
import { MatcherApi } from './MatcherApi.ts';
import { MatchSignal } from './MatchSignal.ts';

export async function configureMatcherApi(
  container: Container,
  options: ConfigurationOptions
) {
  container
    .registerImplementation(MatcherApi)
    .asSingleton()
    .enrich(createApiLogger('matcher'));

  await configureMatchSignal(container, options);
}

async function configureMatchSignal(
  container: Container,
  options: ConfigurationOptions
) {
  let signal:
    | {
        implementation: Constructor<MatchSignal>;
        name: string;
      }
    | undefined;

  if (options.isMirageApi) {
  } else {
    const { MatchRealSignal } = await import('./MatchRealSignal.ts');
    signal = {
      implementation: MatchRealSignal,
      name: 'MatchRealSignal',
    };
  }

  if (signal) {
    container
      .registerImplementation(signal.implementation)
      .as(MatchSignal)
      .enrich(createApiLogger(signal.name));
  }
}
