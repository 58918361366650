import IconButton from '@mui/material/IconButton';
import { Icon } from '~/shared/atoms/icons/index.ts';
import {
  ContactRow,
  type ContactRowProps,
} from '~/entities/contact/crossExports.ts';
import type { BlockedUser } from '../model/BlockedUser.ts';

type Props = Omit<ContactRowProps, 'secondaryAction' | 'jobPositionId'> & {
  user?: BlockedUser;
  onUnblockClick: () => void;
};

export const BlockedUserRow = (props: Props) => {
  const { user, onUnblockClick, ...rest } = props;

  return (
    <ContactRow
      {...rest}
      secondaryAction={
        <IconButton
          edge="end"
          onClick={onUnblockClick}
          sx={{ color: '#ADB5BD' }}
        >
          <Icon variant="removeFromList" />
        </IconButton>
      }
      avatarProps={{
        firstName: user?.firstName,
        lastName: user?.lastName,
        avatarUrl: user?.avatar.url,
      }}
      jobPositionId={user?.jobPositionId}
    />
  );
};
