import { type FC } from 'react';
import { type Meeting } from '~/entities/meeting/index.ts';
import { FooterButton } from '~/shared/templates/FooterButton/index.ts';
import { useNavbarLoader } from '~/shared/ui/index.ts';
import { useAcceptMeetingMutation } from '../api/useAcceptMeetingMutation.ts';

type Props = {
  meeting: Meeting | undefined;
};

export const AcceptMeetingFooterButton: FC<Props> = (props) => {
  const { meeting } = props;

  const { mutate: acceptMeeting, isPending: acceptIsPending } =
    useAcceptMeetingMutation();

  useNavbarLoader(acceptIsPending);

  return (
    <FooterButton
      onClick={() => {
        if (meeting) {
          acceptMeeting(meeting.meetingId);
        }
      }}
    >
      Accept
    </FooterButton>
  );
};
