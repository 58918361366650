import {
  type HTMLAttributes,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { SlotContext } from './SlotContext';
import type { SlotName } from './SlotName.ts';

type Props = HTMLAttributes<HTMLDivElement> & {
  slotName: SlotName;
  /** if for some reason delayedRerender works incorrectly, you can disable it manually */
  disableDelayedRerender?: boolean;
  /** disables `console.warn` for cases, when reserved slot was not provided in time and it is correct flow */
  disableWarning?: boolean;
  /** enables `console.debug` for cases, when ref element of reserved slot was not provided in time */
  enableDebug?: boolean;
};

const component = memo(SlotPortal);
export { component as SlotPortal };

function SlotPortal(props: Props) {
  const {
    slotName,
    children,
    disableDelayedRerender,
    disableWarning,
    enableDebug = false,
    ...rest
  } = props;

  const { onUpdateSlots, reservedSlotsMap } = useContext(SlotContext) ?? {};
  const [, delayedRerender] = useState({});
  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const propsHash = JSON.stringify(rest);

  useEffect(() => {
    onUpdateSlots?.((slotsMap) => {
      slotsMap.set(slotName, rest);
    });
  }, [onUpdateSlots, propsHash, slotName]);

  useEffect(() => {
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
        timerId.current = null;
      }

      onUpdateSlots?.((slotsMap) => {
        slotsMap.delete(slotName);
      });
    };
  }, [onUpdateSlots]);

  const reservedSlot = reservedSlotsMap?.get(slotName);
  if (!reservedSlot) {
    if (!disableWarning) {
      console.warn(
        `[ui-kit Slot] Reserved slot is not provided for "${slotName}"`
      );
    }
    return null;
  }

  if (!reservedSlot.current) {
    if (enableDebug) {
      console.debug(`[ui-kit Slot] wait ref element for slot "${slotName}"`);
    }

    if (!disableDelayedRerender) {
      // ref on element of reserved slot was not settled yet
      // try to create portal lately
      timerId.current = setTimeout(() => {
        delayedRerender({});
      }, 10);
    }

    return null;
  }

  if (timerId.current) {
    clearTimeout(timerId.current);
    timerId.current = null;
  }
  return createPortal(children, reservedSlot.current);
}
