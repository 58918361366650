import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { invalidateBlockedUsersQueryKey } from '~/entities/block-list/index.ts';
import { BlockListApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';

export function useBlockUserMutation() {
  const queryClient = useQueryClient();
  const blockedListApi = useDi(BlockListApi);

  return useMutation({
    mutationKey: ['block user'],
    mutationFn: blockedListApi.blockUser,
    onError: (error) => {
      notify({
        message: `Cant block user: ${error.message}`,
        variant: 'error',
      });
    },
    onSuccess: () => {
      invalidateBlockedUsersQueryKey(queryClient);

      notify({
        message: 'User blocked',
        variant: 'success',
      });
    },
  });
}
