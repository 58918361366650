import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { type Interest } from '~/entities/interest/index.ts';
import { type Skill } from '~/entities/skill/index.ts';
import type { UserForCarouselDto } from '~/shared/api/index.ts';
import { useStateSet } from '~/shared/useStateSet.ts';

export type ColleaguesFilterContextType = {
  selectedDepartmentIds: Set<UserForCarouselDto['department']>;
  setSelectedDepartmentIds: (
    selectedDepartmentIds: Set<UserForCarouselDto['department']>
  ) => void;
  clearSelectedDepartmentIds: VoidFunction;
  addDepartment: (departmentId: UserForCarouselDto['department']) => void;
  deleteDepartment: (departmentId: UserForCarouselDto['department']) => void;

  selectedJobPositionIds: Set<UserForCarouselDto['jobPosition']>;
  setSelectedJobPositionIds: (
    selectedJobPositionIds: Set<UserForCarouselDto['jobPosition']>
  ) => void;
  clearSelectedJobPositionIds: VoidFunction;
  addJobPosition: (jobPositionId: UserForCarouselDto['jobPosition']) => void;
  deleteJobPosition: (jobPositionId: UserForCarouselDto['jobPosition']) => void;

  selectedInterestIds: Set<Interest['id']>;
  setSelectedInterestIds: (selectedInterestIds: Set<Interest['id']>) => void;
  clearSelectedInterestIds: VoidFunction;
  addInterest: (interestId: Interest['id']) => void;
  deleteInterest: (interestId: Interest['id']) => void;

  selectedSkillIds: Set<Skill['id']>;
  setSelectedSkillIds: (selectedSkillIds: Set<Skill['id']>) => void;
  clearSelectedSkillIds: VoidFunction;
  addSkill: (skillId: Skill['id']) => void;
  deleteSkill: (skillId: Skill['id']) => void;
};

const ColleaguesFilterContext =
  createContext<ColleaguesFilterContextType | null>(null);

export const ColleaguesFilterContextProvider: FC<PropsWithChildren> = (
  props
) => {
  const { children } = props;

  const {
    state: selectedDepartmentIds,
    setState: setSelectedDepartmentIds,
    add: addDepartment,
    remove: deleteDepartment,
    clear: clearSelectedDepartmentIds,
  } = useStateSet<UserForCarouselDto['department']>();
  const {
    state: selectedJobPositionIds,
    setState: setSelectedJobPositionIds,
    add: addJobPosition,
    remove: deleteJobPosition,
    clear: clearSelectedJobPositionIds,
  } = useStateSet<UserForCarouselDto['jobPosition']>();
  const {
    state: selectedInterestIds,
    setState: setSelectedInterestIds,
    add: addInterest,
    remove: deleteInterest,
    clear: clearSelectedInterestIds,
  } = useStateSet<Interest['id']>();
  const {
    state: selectedSkillIds,
    setState: setSelectedSkillIds,
    add: addSkill,
    remove: deleteSkill,
    clear: clearSelectedSkillIds,
  } = useStateSet<Skill['id']>();

  const context = useMemo<ColleaguesFilterContextType>(
    () => ({
      selectedDepartmentIds,
      setSelectedDepartmentIds,
      addDepartment,
      deleteDepartment,
      clearSelectedDepartmentIds,

      selectedJobPositionIds,
      setSelectedJobPositionIds,
      addJobPosition,
      deleteJobPosition,
      clearSelectedJobPositionIds,

      selectedInterestIds,
      setSelectedInterestIds,
      addInterest,
      deleteInterest,
      clearSelectedInterestIds,

      selectedSkillIds,
      setSelectedSkillIds,
      addSkill,
      deleteSkill,
      clearSelectedSkillIds,
    }),
    [
      selectedDepartmentIds,
      setSelectedDepartmentIds,
      addDepartment,
      deleteDepartment,
      clearSelectedDepartmentIds,

      selectedJobPositionIds,
      setSelectedJobPositionIds,
      addJobPosition,
      deleteJobPosition,
      clearSelectedJobPositionIds,

      selectedInterestIds,
      setSelectedInterestIds,
      addInterest,
      deleteInterest,
      clearSelectedInterestIds,

      selectedSkillIds,
      setSelectedSkillIds,
      addSkill,
      deleteSkill,
      clearSelectedSkillIds,
    ]
  );

  return (
    <ColleaguesFilterContext.Provider value={context}>
      {children}
    </ColleaguesFilterContext.Provider>
  );
};

export const useColleaguesFilterContext = () =>
  useContext(ColleaguesFilterContext);
