import { type Color, type PaletteColor, useTheme } from '@mui/material';
import {
  type Palette,
  type TypeText,
} from '@mui/material/styles/createPalette';

export type ThemeColor = keyof {
  [color in keyof Palette as Palette[color] extends
    | PaletteColor
    | Color
    | TypeText
    ? color
    : never]: unknown;
};

export function useThemeColor(color: string) {
  const theme = useTheme();

  const themeColor = color as ThemeColor;
  switch (themeColor) {
    case 'primary':
    case 'secondary':
    case 'info':
    case 'success':
    case 'warning':
    case 'error':
      return theme.palette[themeColor].main;

    case 'text':
      return theme.palette.text.secondary;

    case 'grey':
      return theme.palette.grey['400'];
  }

  return color;
}
