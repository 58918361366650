import { useQuery } from '@tanstack/react-query';
import { useApiErrorNotification } from '~/shared/notification/index.ts';
import type { ContactProfile } from '../model/ContactProfile.ts';
import { useInfiniteContactsListRequest } from './useInfiniteContactsListRequest.ts';

export const loadContactsQueryByNameKeys = (
  contactName?: ContactProfile['name']
) => ['load contacts', { contactName }];

export function useContactsQueryByName(
  searchedContactName?: ContactProfile['name']
) {
  const request = useInfiniteContactsListRequest(searchedContactName);

  const query = useQuery({
    queryKey: loadContactsQueryByNameKeys(searchedContactName),
    queryFn: async () => {
      const response = await request(0);
      return response.items;
    },
  });

  useApiErrorNotification(query.error);

  return query;
}
