import { type Task } from './Task.ts';

export abstract class Quest {
  abstract id: number;
  abstract title: string;
  abstract isActive: boolean;
  abstract isTemplate: boolean;
  abstract createdAt: Date;
  abstract startAt: Date;
  abstract endAt: Date;
  abstract taskCounts: {
    completed: number;
    total: number;
  };
  abstract tasks: Task[];
}
