import { Typography } from '@mui/material';
import { type FC } from 'react';
import { Slat } from '~/shared/molecules/Slat';
import { useLogoutQuery } from '../api/useLogoutQuery.ts';

export const LogoutSlat: FC = () => {
  const { mutate: logout } = useLogoutQuery();

  return (
    <Slat
      iconProps={{
        variant: 'logout',
        color: 'error',
      }}
      onClick={() => {
        logout();
      }}
    >
      <Typography>Log Out</Typography>
    </Slat>
  );
};
