import { MapFunction, Mapper } from '@tomas-light/mapper-js';
import { UserForCarouselDto } from '~/shared/api/index.ts';
import { Profile } from '~/entities/profile/crossExports.ts';
import { ContactProfile } from './ContactProfile.ts';

export const contactsMappingProfile = [
  new MapFunction(
    UserForCarouselDto,
    ContactProfile,
    mapUserForCarouselDtoToContactProfile
  ),
  new MapFunction(ContactProfile, ContactProfile, mapProfileLikeTotProfile),
];

function mapUserForCarouselDtoToContactProfile(
  dto: UserForCarouselDto
): ContactProfile {
  const profile = Mapper.map(UserForCarouselDto, Profile, dto);

  return new ContactProfile({
    ...profile,
  });
}

function mapProfileLikeTotProfile(profileLike: ContactProfile): ContactProfile {
  return new ContactProfile({
    ...profileLike,
  });
}
