import { Outlet, type RouteObject } from 'react-router-dom';
import { MeetingDraftContextProvider } from '~/entities/meeting/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { Display } from '~/shared/ui/index.ts';
import { MeetingAddMembersDisplay } from '../MeetingAddMembersDisplay.tsx';
import { MeetingCreatedDisplay } from '../MeetingCreatedDisplay.tsx';
import { MeetingDateDisplay } from '../MeetingDateDisplay.tsx';
import { MeetingDetailsDisplay } from '../MeetingDetailsDisplay.tsx';
import { MeetingReminderDisplay } from '../MeetingReminderDisplay.tsx';
import { MeetingsDisplay } from '../MeetingsDisplay.tsx';
import { ViewMeetingDisplay } from '../ViewMeetingDisplay.tsx';

export const meetingRouteObject: RouteObject[] = [
  {
    path: routes.meetings.relativeUrl(),
    children: [
      {
        index: true,
        element: (
          <Display documentTitle="meetings">
            <MeetingsDisplay />
          </Display>
        ),
      },
      {
        path: routes.meetings.meetingId().relativeUrl(),
        element: (
          <Display documentTitle="view meeting">
            <ViewMeetingDisplay>
              <Outlet />
            </ViewMeetingDisplay>
          </Display>
        ),
        children: [
          {
            index: true,
            element: null,
          },
          {
            path: routes.meetings.meetingId().edit.relativeUrl(),
            element: (
              <Display
                documentTitle="edit meeting"
                animationVariant="card-effect"
              >
                <MeetingDateDisplay />
                <Outlet />
              </Display>
            ),
            children: [
              {
                index: true,
                element: null,
              },
              {
                path: routes.meetings.meetingId().edit.details.relativeUrl(),
                element: (
                  <MeetingDetailsDisplay>
                    <Outlet />
                  </MeetingDetailsDisplay>
                ),
                children: [
                  {
                    index: true,
                    element: null,
                  },
                  {
                    path: routes.meetings
                      .meetingId()
                      .edit.details.member.relativeUrl(),
                    element: (
                      <Display
                        documentTitle="edit meeting members"
                        animationVariant="card-effect"
                      >
                        <MeetingAddMembersDisplay />
                      </Display>
                    ),
                  },
                  {
                    path: routes.meetings
                      .meetingId()
                      .edit.details.reminder.relativeUrl(),
                    element: (
                      <Display
                        documentTitle="edit meeting reminder"
                        animationVariant="card-effect"
                      >
                        <MeetingReminderDisplay />
                      </Display>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: routes.meetings.new.relativeUrl(),
        element: (
          <MeetingDraftContextProvider parentRoute={routes.meetings.new}>
            <Display documentTitle="new meeting" animationVariant="card-effect">
              <MeetingDateDisplay />
              <Outlet />
            </Display>
          </MeetingDraftContextProvider>
        ),
        children: [
          {
            index: true,
            element: null,
          },
          {
            path: routes.meetings.new.details.relativeUrl(),
            element: (
              <MeetingDetailsDisplay>
                <Outlet />
              </MeetingDetailsDisplay>
            ),
            children: [
              {
                index: true,
                element: null,
              },
              {
                path: routes.meetings.new.details.member.relativeUrl(),
                element: (
                  <Display documentTitle="new meeting details">
                    <MeetingAddMembersDisplay />
                  </Display>
                ),
              },
              {
                path: routes.meetings.new.details.reminder.relativeUrl(),
                element: (
                  <Display documentTitle="new meeting reminder">
                    <MeetingReminderDisplay />
                  </Display>
                ),
              },
              {
                path: routes.meetings.new.details.meeting_created.relativeUrl(),
                element: (
                  <Display documentTitle="meeting has created">
                    <MeetingCreatedDisplay />
                  </Display>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
];
