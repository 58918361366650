import * as yup from 'yup';

export class RegisterUserInformation {
  firstName: string | undefined;
  lastName: string | undefined;
  areRulesAccepted: boolean | undefined;

  static validationSchema = yup.object({
    firstName: yup
      .string()
      .required('First name is required')
      .min(3, 'Minimum 3 characters'),

    lastName: yup
      .string()
      .required('Last name is required')
      .min(3, 'Minimum 3 characters'),

    areRulesAccepted: yup
      .boolean()
      .test(
        'are-rules-accepted',
        'You should accept our rules before start using service',
        (accepted) => accepted === true
      ),
  });
}
