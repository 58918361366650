import type { ApiResponseStatus } from './ApiResponseStatus.ts';

export class ApiError extends Error {
  readonly status: ApiResponseStatus;

  constructor(response: {
    status: ApiResponseStatus;
    error?: string | null;
    errorStack?: string | null;
  }) {
    super(response.error ?? undefined);
    this.status = response.status;
    this.stack = response.errorStack ?? undefined;
  }
}
