import { type QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import {
  type ForwardedRef,
  forwardRef,
  type ReactElement,
  type RefAttributes,
} from 'react';
import {
  InfiniteScrollRender,
  type InfiniteScrollRenderProps,
  type InfiniteScrollRenderRef,
  type PagedData,
} from './InfiniteScrollRender.tsx';

type Props<RowEntity extends {}> = Omit<
  InfiniteScrollRenderProps<RowEntity>,
  'query'
> & {
  apiRequest: (lastRow: RowEntity | undefined) => Promise<PagedData<RowEntity>>;
  queryKeys: QueryKey;
  pageSize: number;
  enabled?: boolean;
};

export type InfiniteScrollRef<RowEntity extends {}> =
  InfiniteScrollRenderRef<RowEntity>;

const component = forwardRef(InfiniteScroll) as <RowEntity extends {}>(
  props: Props<RowEntity> & RefAttributes<InfiniteScrollRef<RowEntity>>
) => ReactElement;

export { component as InfiniteScroll, type Props as InfiniteScrollProps };

function InfiniteScroll<RowEntity extends {}>(
  props: Props<RowEntity>,
  ref: ForwardedRef<InfiniteScrollRef<RowEntity>>
) {
  const { apiRequest, queryKeys, pageSize, enabled, ...infiniteListProps } =
    props;

  const initialPageParam = {
    lastRow: undefined,
  };

  const query = useInfiniteQuery({
    enabled,
    queryKey: queryKeys,
    initialPageParam: initialPageParam,
    queryFn: ({ pageParam }) => apiRequest(pageParam.lastRow) as any,
    getNextPageParam: (lastGroup, paginatedResults) => {
      if (lastGroup == null) {
        return initialPageParam;
      }
      if (lastGroup.items.length < pageSize) {
        console.log(
          `has no next page, last group items length: ${lastGroup.items.length}`
        );
        return null;
      }
      return {
        lastRow: lastGroup.items.at(-1),
      };
    },
  });

  return (
    <InfiniteScrollRender ref={ref} query={query} {...infiniteListProps} />
  );
}
