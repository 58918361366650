import { type RouteObject } from 'react-router-dom';
import { routes } from '~/shared/routing/index.ts';
import { Display } from '~/shared/ui/index.ts';
import { ChatsDisplay } from './ChatsDisplay';
import { NewChatDisplay } from './NewChatDisplay.tsx';
import { UserChatDisplay } from './UserChatDisplay';

export const chatRouteObject: RouteObject[] = [
  {
    path: routes.chat.relativeUrl(),
    children: [
      {
        index: true,
        element: (
          <Display documentTitle="chat">
            <ChatsDisplay />
          </Display>
        ),
      },
      {
        path: routes.chat.userId().relativeUrl(),
        element: <UserChatDisplay />,
      },
      {
        path: routes.chat.new.relativeUrl(),
        element: (
          <Display documentTitle="new chat" animationVariant="card-effect">
            <NewChatDisplay />
          </Display>
        ),
      },
    ],
  },
];
