import { type RouteObject } from 'react-router-dom';
import { routes } from '~/shared/routing/index.ts';
import { QuestsDisplay } from '../Quests';
import { QuestDisplay } from '../Quests/QuestDisplay';

export const questsRouteObject: RouteObject[] = [
  {
    path: routes.quests.relativeUrl(),
    children: [
      {
        index: true,
        element: <QuestsDisplay />,
      },
      {
        path: routes.quests.questId().relativeUrl(),
        element: <QuestDisplay />,
      },
    ],
  },
];
