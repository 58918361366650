import { IconButton } from '@mui/material';
import Badge from '@mui/material/Badge';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { routes } from '~/shared/routing/index.ts';
import classes from './OpenColleaguesFiltersIconButton.module.scss';

type Props = {
  hideBadge?: boolean;
};

export const OpenColleaguesFiltersIconButton: FC<Props> = (props) => {
  const { hideBadge } = props;

  const navigate = useNavigate();

  return (
    <IconButton
      color="primary"
      onClick={() => navigate(routes.colleagues.filters.url())}
      className={classes.filtersButton}
    >
      <Badge color="error" variant="dot" invisible={hideBadge}>
        <Icon variant="filters" />
      </Badge>
    </IconButton>
  );
};
