import { type FC } from 'react';
import {
  IconButton,
  type IconButtonProps,
} from '~/shared/molecules/IconButton';
import { useOnBack } from './useOnBack.ts';

type Props = Pick<IconButtonProps<'chevron'>, 'onClick' | 'color'>;

export const BackIconNavigationButton: FC<Props> = (props) => {
  const { onClick, ...rest } = props;

  const onBack = useOnBack({ onClick });

  return (
    <IconButton
      aria-label="back button"
      title="Back"
      size="large"
      onClick={onBack}
      variant="chevron"
      direction="left"
      {...rest}
    />
  );
};
