import type { StoreNames } from 'idb';
import type { Database } from '../database/index.ts';
import type { Table } from '../database/Table.ts';
import type { FakeApiDbSchema } from './FakeApiDbSchema.ts';

export function createTableAccessor<
  StoreName extends StoreNames<FakeApiDbSchema>,
>(
  provider: {
    db: Database<FakeApiDbSchema> | undefined;
  },
  tableName: StoreName
): Table<FakeApiDbSchema, StoreName> {
  type Params<MethodName extends keyof Table<FakeApiDbSchema, StoreName>> =
    Parameters<Table<FakeApiDbSchema, StoreName>[MethodName]>;

  return {
    async getAll(...args: Params<'getAll'>) {
      if (!provider.db) {
        provider.db = await createDatabase();
      }
      return provider.db[tableName].getAll(...args);
    },

    async get(...args: Params<'get'>) {
      if (!provider.db) {
        provider.db = await createDatabase();
      }
      return provider.db[tableName].get(...args);
    },

    async insert(...args: Params<'insert'>) {
      if (!provider.db) {
        provider.db = await createDatabase();
      }
      return provider.db[tableName].insert(...args);
    },

    async update(...args: Params<'update'>) {
      if (!provider.db) {
        provider.db = await createDatabase();
      }
      return provider.db[tableName].update(...args);
    },

    async delete(...args: Params<'delete'>) {
      if (!provider.db) {
        provider.db = await createDatabase();
      }
      return provider.db[tableName].delete(...args);
    },

    async deleteAll(...args: Params<'deleteAll'>) {
      if (!provider.db) {
        provider.db = await createDatabase();
      }
      return provider.db[tableName].deleteAll(...args);
    },
  };
}

async function createDatabase() {
  const { createFakeDatabase } = await import('./createFakeDatabase.ts');
  return await createFakeDatabase();
}
