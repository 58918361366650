import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const LogoIcon = (props: Props) => {
  const { height = 88, color = '#5295F0', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 44C0.5 25.2354 0.5 15.8531 5.45952 9.38972C6.73633 7.72575 8.22575 6.23633 9.88972 4.95952C16.3531 0 25.7354 0 44.5 0C63.2646 0 72.6469 0 79.1103 4.95952C80.7742 6.23633 82.2637 7.72575 83.5405 9.38972C88.5 15.8531 88.5 25.2354 88.5 44C88.5 62.7646 88.5 72.1469 83.5405 78.6103C82.2637 80.2742 80.7742 81.7637 79.1103 83.0405C72.6469 88 63.2646 88 44.5 88C25.7354 88 16.3531 88 9.88972 83.0405C8.22575 81.7637 6.73633 80.2742 5.45952 78.6103C0.5 72.1469 0.5 62.7646 0.5 44Z"
        fill={color}
      />
      <path
        d="M35.6219 52.5158V35.844C35.6219 35.5356 35.7161 35.2843 35.9044 35.0901C36.1037 34.8845 36.3474 34.7817 36.6354 34.7817H40.3568C40.6447 34.7817 40.8828 34.8845 41.0711 35.0901C41.2705 35.2843 41.3702 35.5356 41.3702 35.844V36.9235C43.2752 35.2672 45.302 34.439 47.4506 34.439C49.6104 34.439 51.3659 35.1187 52.7171 36.478C54.0683 37.8259 54.7439 39.8935 54.7439 42.6807V52.5158C54.7439 52.8128 54.6442 53.0641 54.4449 53.2697C54.2566 53.4639 54.0129 53.561 53.7139 53.561H50.0091C49.7211 53.561 49.4775 53.4639 49.2781 53.2697C49.0898 53.0641 48.9957 52.8128 48.9957 52.5158V42.6807C48.9957 39.9506 47.938 38.5855 45.8225 38.5855C44.4381 38.5855 42.954 39.4023 41.3702 41.0358V52.5158C41.3702 52.8128 41.2705 53.0641 41.0711 53.2697C40.8828 53.4639 40.6447 53.561 40.3568 53.561H36.6354C36.3474 53.561 36.1037 53.4639 35.9044 53.2697C35.7161 53.0641 35.6219 52.8128 35.6219 52.5158Z"
        fill="white"
      />
      <path
        d="M72.5 44C72.5 45.8859 70.9712 47.4147 69.0854 47.4147C67.1995 47.4147 65.6707 45.8859 65.6707 44C65.6707 42.1142 67.1995 40.5854 69.0854 40.5854C70.9712 40.5854 72.5 42.1142 72.5 44Z"
        fill="white"
      />
      <path
        d="M68.411 53.3352C69.992 53.9724 70.7705 55.7779 69.9621 57.2756C67.3766 62.0655 63.4356 66.0215 58.5996 68.6301C52.8543 71.7291 46.2055 72.7323 39.7985 71.4667C33.3915 70.2011 27.6276 66.7461 23.4996 61.6968C19.3716 56.6475 17.138 50.3201 17.1836 43.8045C17.2292 37.289 19.5511 30.9933 23.7494 26.002C27.9477 21.0107 33.7594 17.6363 40.1835 16.46C46.6075 15.2838 53.2416 16.3795 58.943 19.5582C63.742 22.2339 67.6273 26.2445 70.1455 31.0699C70.9329 32.5787 70.1291 34.3732 68.5394 34.9883C66.9497 35.6035 65.1795 34.8021 64.3455 33.3184C62.3794 29.8203 59.4744 26.9113 55.9305 24.9354C51.4835 22.456 46.3089 21.6014 41.2981 22.5188C36.2874 23.4363 31.7542 26.0683 28.4796 29.9615C25.2049 33.8548 23.3938 38.7654 23.3582 43.8475C23.3227 48.9297 25.0649 53.865 28.2847 57.8035C31.5046 61.742 36.0004 64.4369 40.9979 65.424C45.9953 66.4112 51.1814 65.6287 55.6627 63.2115C59.2338 61.2852 62.1792 58.4169 64.1941 54.9466C65.0488 53.4746 66.83 52.698 68.411 53.3352Z"
        fill="white"
      />
    </svg>
  );
};

export type { Props as LogoIconProps };
export { LogoIcon };
