import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useChatByUserIdQuery } from '~/entities/chat/api/useChatByUserIdQuery.ts';
import {
  ChatMessagesInfiniteList,
  type Message,
} from '~/entities/chat/index.ts';
import { useCurrentUser } from '~/entities/profile/index.ts';
import {
  UserChatInputBlock,
  useSendMessageMutation,
} from '~/features/chat/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { Avatar } from '~/shared/atoms/Avatar/index.ts';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { useParseInt } from '~/shared/numbers/index.ts';
import { ContentSlot, SlotPortal } from '~/shared/organisms/slots/index.ts';
import { Display, Navbar } from '~/shared/ui/index.ts';
import type { InfiniteScrollRef } from '~/shared/ui/InfiniteScroll/InfiniteScroll.tsx';
import { isNullOrUndefined } from '../../utils/index.ts';
import classes from './UserChatDisplay.module.scss';

export const UserChatDisplay = () => {
  const { userId: stringUserId } = useParams<{ userId: string | undefined }>();
  const userId = useParseInt(stringUserId);

  const { data: privateChat, isLoading: isPrivateChatLoading } =
    useChatByUserIdQuery(userId);

  const { data: myProfile, isLoading: myProfileIsLoading } = useCurrentUser();

  const { mutate: sendMessage, isPending: isMessageSending } =
    useSendMessageMutation({
      chatId: privateChat?.chatId,
      userId,
      onSuccessCallback: () => {
        // scroll to newest message
        infiniteScrollRef.current?.scrollToIndex(0);
      },
    });

  const isCurrentUser = privateChat?.interlocutorId === myProfile?.userId;

  const splattedName = privateChat?.name.split(' ') ?? [];
  const [chatFirstName, chatLastName] = splattedName;

  const infiniteScrollRef = useRef<InfiniteScrollRef<Message>>(null);
  // useEffect(() => {
  //   setTimeout(
  //     () => {
  //       if (infiniteScrollRef.current) {
  //         infiniteScrollRef.current.scrollToIndex(0);
  //       }
  //     },
  //     time(1, 'seconds')
  //   );
  // }, []);

  // useReversedScroll(`.${classes.display} > div > div`, [messages]);

  return (
    <Display documentTitle="chat with user">
      <Navbar
        title={
          <>
            {isCurrentUser ? (
              <div className={classes.favourite}>
                <Icon variant="star" color="secondary" />
              </div>
            ) : (
              <Avatar
                avatarUrl={privateChat?.avatar.url}
                firstName={chatFirstName}
                lastName={chatLastName}
              />
            )}
            <span>{privateChat?.name}</span>
          </>
        }
        classes={{ title: classes.title }}
        left={<BackIconNavigationButton />}
        partialLoading={
          myProfileIsLoading || isPrivateChatLoading || isMessageSending
        }
      />

      <ContentSlot>
        <ChatMessagesInfiniteList
          ref={infiniteScrollRef}
          className={classes.display}
          chatId={privateChat?.chatId}
          userId={userId}
        />
      </ContentSlot>

      <SlotPortal slotName="footer">
        <UserChatInputBlock
          onSubmit={sendMessage}
          disabled={isNullOrUndefined(userId)}
        />
      </SlotPortal>
    </Display>
  );
};
