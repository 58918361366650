export function getApiErrorAuthorizationMessage(response: {
  error: {
    details?: string;
    errorText?: string;
  };
}) {
  if (Array.isArray(response.error.details)) {
    return response.error.details.reduce((entireErrorText, details) => {
      entireErrorText += `${details.error}/n`;
      return entireErrorText;
    }, '');
  }

  if (typeof response.error === 'string') {
    return response.error;
  }

  return 'Упс, что-то пошло не так';
}
