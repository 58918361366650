import { Typography } from '@mui/material';
import { type Dayjs } from 'dayjs';

// Saturday, 19 November 18:02
const format = 'dddd, D MMMM H:mm';
const formatWithYear = 'dddd, D MMMM YY H:mm';

type Props = {
  startDate: Dayjs;
  endDate: Dayjs | null;
};

export function MeetingDates(props: Props) {
  const { startDate, endDate } = props;

  if (!endDate) {
    return <Typography>{capitalize(startDate.format(format))}</Typography>;
  }

  if (isSameDay(startDate, endDate)) {
    return (
      <Typography>
        {capitalize(`${startDate.format(format)} - ${endDate.format('H:mm')}`)}
      </Typography>
    );
  }

  if (isSameYear(startDate, endDate)) {
    return (
      <>
        <Typography>{capitalize(startDate.format(format))} -</Typography>
        <Typography>{endDate.format(format)}</Typography>
      </>
    );
  }

  return (
    <>
      <Typography>{capitalize(startDate.format(formatWithYear))} -</Typography>
      <Typography>{endDate.format(formatWithYear)}</Typography>
    </>
  );
}

function isSameDay(leftDate: Dayjs, rightDate: Dayjs) {
  return leftDate.startOf('day').isSame(rightDate.startOf('day'));
}

function isSameYear(leftDate: Dayjs, rightDate: Dayjs) {
  return leftDate.startOf('year').isSame(rightDate.startOf('year'));
}

function capitalize(text: string) {
  return text[0].toUpperCase() + text.substring(1);
}
