import { Typography } from '@mui/material';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { Slat } from '~/shared/molecules/Slat/index.ts';
import { routes } from '~/shared/routing/index.ts';

export const BlockListNavigationSlat: FC = () => {
  const navigate = useNavigate();

  return (
    <Slat
      iconProps={{
        variant: 'blockList',
        color: 'text',
      }}
      onClick={() => navigate(routes.profile.settings.blockList.url())}
      rightChildren={<Icon variant="chevron" direction="right" />}
    >
      <Typography>Block list</Typography>
    </Slat>
  );
};
