import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { type MyProfile } from '~/entities/profile/index.ts';

export type ProfileCareerEditContextType = {
  selectedDepartmentId: undefined | null | MyProfile['departmentId'];
  setSelectedDepartmentId: (
    selectedDepartmentId: ProfileCareerEditContextType['selectedDepartmentId']
  ) => void;

  selectedJobPositionId: undefined | null | MyProfile['jobPositionId'];
  setSelectedJobPositionId: (
    selectedJobPositionId: ProfileCareerEditContextType['selectedJobPositionId']
  ) => void;
};

const ProfileCareerEditContext =
  createContext<ProfileCareerEditContextType | null>(null);

export const ProfileCareerEditContextProvider: FC<PropsWithChildren> = (
  props
) => {
  const { children } = props;

  const [selectedDepartmentId, setSelectedDepartmentId] =
    useState<ProfileCareerEditContextType['selectedDepartmentId']>();
  const [selectedJobPositionId, setSelectedJobPositionId] =
    useState<ProfileCareerEditContextType['selectedJobPositionId']>();

  const context = useMemo<ProfileCareerEditContextType>(
    () => ({
      selectedDepartmentId,
      setSelectedDepartmentId,
      selectedJobPositionId,
      setSelectedJobPositionId,
    }),
    [selectedDepartmentId, selectedJobPositionId]
  );

  return (
    <ProfileCareerEditContext.Provider value={context}>
      {children}
    </ProfileCareerEditContext.Provider>
  );
};

export const useProfileCareerEditContext = () =>
  useContext(ProfileCareerEditContext);
