import { MapFunction } from '@tomas-light/mapper-js';
import { TagDto } from '~/shared/api/TagDto.ts';
import { Department } from './Department.ts';

export const departmentMappingProfile = [
  //
  new MapFunction(TagDto, Department, mapTagDtoToDepartment),
];
function mapTagDtoToDepartment(tagDto: TagDto): Department {
  return {
    id: tagDto.id,
    name: tagDto.name,
    linkedCount: tagDto.userByCount,
  };
}
