import { Mapper } from '@tomas-light/mapper-js';
import { blockListMappingProfile } from '~/entities/block-list/model/blockListMappingProfile.ts';
import { chatMappingProfile } from '~/entities/chat/model/chatMappingProfile.ts';
import { colleaguesMappingProfile } from '~/entities/colleague/model/colleaguesMappingProfile.ts';
import { contactsMappingProfile } from '~/entities/contact/model/contactsMappingProfile.ts';
import { departmentMappingProfile } from '~/entities/department/model/departmentMappingProfile.ts';
import { interestMappingProfile } from '~/entities/interest/model/interestMappingProfile.ts';
import { jobPositionMappingProfile } from '~/entities/jobPosition/model/jobPositionMappingProfile.ts';
import { meetingMappingProfile } from '~/entities/meeting/model/meetingMappingProfile.ts';
import { profileMappingProfile } from '~/entities/profile/model/profileMappingProfile.ts';
import { questMappingProfile } from '~/entities/quest/model/questMappingProfile.ts';
import { skillMappingProfile } from '~/entities/skill/model/skillMappingProfile.ts';

export function configureMapper() {
  const mapper = new Mapper();
  mapper.addMapFunctions(...questMappingProfile);
  mapper.addMapFunctions(...colleaguesMappingProfile);
  mapper.addMapFunctions(...profileMappingProfile);
  mapper.addMapFunctions(...skillMappingProfile);
  mapper.addMapFunctions(...jobPositionMappingProfile);
  mapper.addMapFunctions(...departmentMappingProfile);
  mapper.addMapFunctions(...interestMappingProfile);
  mapper.addMapFunctions(...meetingMappingProfile);
  mapper.addMapFunctions(...contactsMappingProfile);
  mapper.addMapFunctions(...blockListMappingProfile);
  mapper.addMapFunctions(...chatMappingProfile);
  return mapper;
}
