import {
  type ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

type TakePictureRef = {
  takePicture: () => Promise<string>;
};

const TakePicture = (props: {}, ref: ForwardedRef<TakePictureRef>) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const streamingRef = useRef<boolean>(false);

  useImperativeHandle(
    ref,
    () => ({
      takePicture: async () => {
        if (!videoRef.current || !canvasRef.current || streamingRef.current) {
          return '';
        }

        // const userPhoto = await Camera.getPhoto({
        //   quality: 75,
        //   allowEditing: false,
        //   presentationStyle: 'popover',
        //   webUseInput: true,
        //   saveToGallery: false,
        //   source: CameraSource.Prompt,
        //   resultType: CameraResultType.DataUrl,
        //   promptLabelPhoto: 'Галерея',
        //   promptLabelPicture: 'Камера',
        //   promptLabelHeader: 'Добавить фото',
        //   promptLabelCancel: 'Отмена',
        // });

        // https://developer.mozilla.org/en-US/docs/Web/API/Media_Capture_and_Streams_API/Taking_still_photos#capturing_a_frame_from_the_stream
        const video = videoRef.current;
        const canvas = canvasRef.current;
        streamingRef.current = true;

        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: 'user',
          },
        });
        video.srcObject = mediaStream;
        await video.play();

        // const height = (video.videoHeight / video.videoWidth) * width;
        // video.setAttribute("width", width);
        // video.setAttribute("height", height);
        // canvas.setAttribute("width", width);
        // canvas.setAttribute("height", height);
        canvas.setAttribute('width', video.videoWidth.toString());
        canvas.setAttribute('height', video.videoHeight.toString());

        const context = canvas.getContext('2d');
        if (!context) {
          return '';
        }
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        const picture = canvas.toDataURL('image/png');

        video.pause();
        video.removeAttribute('src');
        video.remove();
        if (video.srcObject) {
          video.srcObject = null;
        }
        streamingRef.current = false;

        return picture;
      },
    }),
    [videoRef, canvasRef]
  );

  return (
    <div style={{ display: 'none' }}>
      <video ref={videoRef}>Video stream not available.</video>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

const componentWithRef = forwardRef(TakePicture);
export { componentWithRef as TakePicture, type TakePictureRef };
