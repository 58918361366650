import { DatePicker, type DatePickerProps } from '@mui/x-date-pickers';
import { type Dayjs } from 'dayjs';
import { StyledTextField } from '../../TextField';
import classes from './DateField.module.scss';

type Props = DatePickerProps<Dayjs>;
export type { Props as DateFieldProps };

export const DateField = (props: Props) => {
  return (
    <DatePicker
      {...props}
      openTo="year"
      views={['year', 'month']}
      slotProps={{
        toolbar: {
          hidden: true,
        },
        monthButton: {
          className: classes.monthButton,
        },
        yearButton: {
          className: classes.yearButton,
        },
      }}
      slots={{
        textField: StyledTextField,
      }}
    />
  );
};
