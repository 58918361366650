import { useMemo } from 'react';
import type { Tab } from '~/shared/molecules/Tabs/index.ts';

export function useFilterTabs(isPublicVersion: boolean) {
  const tabsObject = useMemo(
    () =>
      ({
        department: {
          index: 2,
          label: isPublicVersion ? 'Company' : 'Department',
        },
        interest: {
          index: 0,
          label: 'Interests',
        },
        jobPosition: {
          index: 3,
          label: 'Position',
        },
        skill: {
          index: 1,
          label: 'Skills',
        },
      }) satisfies Record<string, Tab & { index: number }>,
    [isPublicVersion]
  );

  const tabs = useMemo<Tab[]>(
    () =>
      Array.from(Object.values(tabsObject)).sort(
        (left, right) => left.index - right.index
      ),
    [tabsObject]
  );

  const tabIndexes = useMemo(
    () =>
      Object.entries(tabsObject).reduce(
        (previousValue, [key, { index }]) => ({
          ...previousValue,
          [key]: index,
        }),
        {} as Record<keyof typeof tabsObject, number>
      ),
    [tabsObject]
  );

  return {
    tabIndexes,
    tabs,
  };
}
