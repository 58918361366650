import { type FC, type ReactNode } from 'react';
import { DisplayLoader, type DisplayLoaderProps } from '../../ui/index.ts';
import { SlotPortal } from './SlotPortal';

type Props = Omit<DisplayLoaderProps, 'children'> & {
  children: ReactNode;
};

export const ContentSlot: FC<Props> = (props) => {
  const { children, ...rest } = props;
  return (
    <SlotPortal slotName="content">
      <DisplayLoader {...rest}>{children}</DisplayLoader>
    </SlotPortal>
  );
};
