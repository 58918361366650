import { MapFunction } from '@tomas-light/mapper-js';
import { Profile } from '~/entities/profile/crossExports.ts';
import { BlockUserDto } from '~/shared/api/index.ts';
import { BlockedUser } from './BlockedUser.ts';

function mapBlockUserDtoToBlockedUser(dto: BlockUserDto): BlockedUser {
  return {
    userId: dto.userId,
    firstName: dto.firstName,
    lastName: dto.lastName,
    name: Profile.getName(dto),
    avatar: {
      fileName: dto.userAvatarPhotoFileName,
      url: dto.userAvatarPhotoFileName,
    },
    jobPositionId: dto.jobPositionId,
  };
}

export const blockListMappingProfile = [
  new MapFunction(BlockUserDto, BlockedUser, mapBlockUserDtoToBlockedUser),
];
