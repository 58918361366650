import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { DepartmentApi, TagDto } from '~/shared/api/index.ts';
import { time } from '~/utils/index.ts';
import { Department } from '../model/Department.ts';

export const loadDepartmentsQueryKeys = (
  departmentName?: Department['name']
) => ['load departments', { departmentName }];

export function useLoadDepartments(departmentName?: Department['name']) {
  const departmentApi = useDi(DepartmentApi);

  return useQuery({
    queryKey: loadDepartmentsQueryKeys(departmentName),
    queryFn: () => departmentApi.searchDepartments({ departmentName }),
    staleTime: time(5, 'minutes'),
    select: mapResponse,
  });
}

const mapResponse = (
  dto: Awaited<ReturnType<DepartmentApi['searchDepartments']>>
) => ({
  totalCount: dto.totalCount,
  departments: dto.items.map((dtoItem) =>
    Mapper.map(TagDto, Department, dtoItem)
  ),
});
