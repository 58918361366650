import { RealApiBase } from '../RealApiBase.ts';
import { apiRoutes, type Search, type TagDto } from '../index.ts';
import type { PaginationDto } from '../PaginationDto.ts';

export class DepartmentRealApi extends RealApiBase {
  createDepartment = (departmentName: string) => {
    return this.post<{ createdTag: TagDto }>(
      apiRoutes.tags.department.create.url(),
      {
        name: departmentName,
      }
    );
  };

  searchDepartments = (search: Search<'departmentName'>) => {
    return this.post<PaginationDto<TagDto>>(
      apiRoutes.tags.department.search.url(),
      {
        query: search.departmentName,
        page: search.page,
        pageSize: search.pageSize,
      }
    );
  };

  searchDepartmentsById = (departmentIds: number[]) => {
    return this.post<PaginationDto<TagDto>>(
      apiRoutes.tags.department.search_by_ids.url(),
      {
        tagIds: departmentIds,
      }
    );
  };
}
