import { type FakeApiDatabase } from '../../fakeApiDatabase/index.ts';
import type { ImageTableEntity } from '../../fakeApiDatabase/tables/ImageTableEntity.ts';
import { ApiError } from '../ApiError.ts';
import { ApiResponseStatus } from '../ApiResponseStatus.ts';
import { findCurrentUser } from '../findCurrentUser.ts';
import type { PhotoUrlRequest } from './PhotoUrlRequest.ts';
import { type ProfileRealApi } from './ProfileRealApi.ts';

export class ProfileFakeApi {
  private readonly findCurrentUser: () => ReturnType<typeof findCurrentUser>;

  constructor(readonly db: FakeApiDatabase) {
    this.findCurrentUser = findCurrentUser.bind(null, db);
  }

  addSkill: ProfileRealApi['addSkill'] = (skillId) => {
    throw new Error('addSkill Method not implemented.');
  };
  removeSkill: ProfileRealApi['removeSkill'] = (skillId) => {
    throw new Error('removeSkill Method not implemented.');
  };

  changeSkillsBulk: ProfileRealApi['changeSkillsBulk'] = async (payload) => {
    const currentUser = await this.findCurrentUser();

    const { addIds, deleteIds } = payload;
    if (addIds) {
      currentUser.skills.push(...addIds);
    }
    if (deleteIds) {
      const deletedSet = new Set(deleteIds);
      currentUser.skills = currentUser.skills.filter(
        (id) => !deletedSet.has(id)
      );
    }

    await this.db.user.update(currentUser.userId, currentUser);
  };
  addInterest: ProfileRealApi['addInterest'] = (interestId) => {
    throw new Error('addInterest Method not implemented.');
  };
  removeInterest: ProfileRealApi['removeInterest'] = (interestId) => {
    throw new Error('removeInterest Method not implemented.');
  };
  changeInterestsBulk: ProfileRealApi['changeInterestsBulk'] = async (
    payload
  ) => {
    const currentUser = await this.findCurrentUser();

    const { addIds, deleteIds } = payload;
    if (addIds) {
      currentUser.interests.push(...addIds);
    }
    if (deleteIds) {
      const deletedSet = new Set(deleteIds);
      currentUser.interests = currentUser.interests.filter(
        (id) => !deletedSet.has(id)
      );
    }

    await this.db.user.update(currentUser.userId, currentUser);
  };
  addJobPosition: ProfileRealApi['addJobPosition'] = (jobPositionId) => {
    throw new Error('addJobPosition Method not implemented.');
  };
  removeJobPosition: ProfileRealApi['removeJobPosition'] = (jobPositionId) => {
    throw new Error('removeJobPosition Method not implemented.');
  };
  addDepartment: ProfileRealApi['addDepartment'] = (departmentId) => {
    throw new Error('addDepartment Method not implemented.');
  };
  removeDepartment: ProfileRealApi['removeDepartment'] = (departmentId) => {
    throw new Error('removeDepartment Method not implemented.');
  };
  updateProfileInfo: ProfileRealApi['updateProfileInfo'] = async (dto) => {
    const currentUser = await this.findCurrentUser();

    await this.db.user.update(currentUser.userId, {
      ...currentUser,
      ...dto,
      // firstName: dto.firstName,
      // lastName: dto.lastName,
      // aboutMe: dto.aboutMe,
      // timezoneId: dto.timezoneId,
      wasSaved: true,
    });
  };
  updateProfileCareer: ProfileRealApi['updateProfileCareer'] = async (dto) => {
    const currentUser = await this.findCurrentUser();

    await this.db.user.update(currentUser.userId, {
      ...currentUser,
      jobPosition: dto.jobPositionTagId!,
      department: dto.departmentTagId!,
      careerStartDate: dto.careerStartDate,
      workInCompanyStartDate: dto.workInCompanyStartDate,
    });
  };
  getProfile: ProfileRealApi['getProfile'] = async (userId) => {
    const user = await this.db.user.get(userId);
    if (!user) {
      throw new ApiError({
        status: ApiResponseStatus.NotFound,
      });
    }
    return {
      user: user,
    };
  };

  getCurrentUser: ProfileRealApi['getCurrentUser'] = async () => {
    const currentUser = await this.findCurrentUser();

    const tonWallet = currentUser.connectedWallets.find(
      (wallet) => wallet.type === 'telegram-wallet'
    );

    return {
      user: {
        userId: currentUser.userId,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        userAvatarPhotoFileName: currentUser.userAvatarPhotoFileName,
        department: currentUser.department,
        jobPosition: currentUser.jobPosition,
        aboutMe: currentUser.aboutMe,
        timezoneId: currentUser.timezoneId,
        workInCompanyStartDate: currentUser.workInCompanyStartDate,
        careerStartDate: currentUser.careerStartDate,
        skills: currentUser.skills,
        interests: currentUser.interests,
        profilePhotoNames: currentUser.profilePhotoNames,
        sensitiveInformation: currentUser.sensitiveInformation,
        wasSaved: currentUser.wasSaved,
        tonWallets: tonWallet
          ? {
              'telegram-wallet': {
                address: tonWallet.id,
                isAlive: true,
              },
            }
          : {},
        coins: currentUser.coins,
        levelableSkills: currentUser.levelableSkills,
      },
    };
  };

  uploadPhoto: ProfileRealApi['uploadPhoto'] = async ({
    fileName,
    photoBase64,
  }) => {
    const currentUser = await this.findCurrentUser();

    const imageResponse = await fetch(photoBase64);
    if (imageResponse.status !== ApiResponseStatus.Ok) {
      return { uploadedUserPhotos: [] };
    }

    const image = await imageResponse.arrayBuffer();

    // data:image/png;base64,iVBORw0KGgoAAAANSUhE....
    const dataIndex = photoBase64.indexOf('data:') + 'data:'.length;
    const base64Index = photoBase64.indexOf(';base64');
    const mimeType = photoBase64.slice(dataIndex, base64Index);

    const newImage: ImageTableEntity = {
      fileName,
      image,
      mimeType,
    };

    await this.db.image.insert(newImage.fileName, newImage);

    currentUser.profilePhotoNames.push(fileName);
    await this.db.user.update(currentUser.userId, currentUser);

    return {
      uploadedUserPhotos: [
        {
          fileName,
          isAvatar: false,
        },
      ],
    };
  };

  deletePhoto: ProfileRealApi['deletePhoto'] = async (fileName) => {
    const currentUser = await this.findCurrentUser();

    await this.db.image.delete(fileName);

    currentUser.profilePhotoNames = currentUser.profilePhotoNames.filter(
      (photoFileName) => photoFileName !== fileName
    );
    await this.db.user.update(currentUser.userId, currentUser);
  };

  setAvatar: ProfileRealApi['setAvatar'] = async (fileName) => {
    const currentUser = await this.findCurrentUser();

    if (
      !currentUser.profilePhotoNames.every(
        (photoFileName) => photoFileName === fileName
      )
    ) {
      throw new ApiError({
        status: ApiResponseStatus.BadRequest,
        error: `photo with file name ${fileName} not found in user's photos`,
      });
    }

    currentUser.userAvatarPhotoFileName = fileName;
    await this.db.user.update(currentUser.userId, currentUser);
  };

  getAvatarUrl = async (request: Pick<PhotoUrlRequest, 'fileName'>) => {
    const { fileName } = request;

    const imageEntity = await this.db.image.get(fileName);
    if (!imageEntity) {
      return undefined;
    }

    return URL.createObjectURL(
      new Blob([imageEntity.image], { type: imageEntity.mimeType })
    );
  };
}
