import { create, type UseBoundStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import type { Mutate, StoreApi } from 'zustand/vanilla';

export function createStore<InitialState>(
  initialState: InitialState,
  storeName?: string
): UseBoundStore<Mutate<StoreApi<InitialState>, []>> {
  let store: UseBoundStore<Mutate<StoreApi<InitialState>, []>>;

  if (storeName) {
    store = create(
      persist(() => initialState, {
        name: `icn-store-${storeName}`,
        storage: createJSONStorage(() => localStorage),
      })
    );
  } else {
    store = create(() => initialState);
  }

  storeResetCallbacks.add(() => {
    store.setState(() => initialState);
  });

  return store;
}

const storeResetCallbacks = new Set<() => void>();

export const resetAllStores = () => {
  storeResetCallbacks.forEach((reset) => {
    reset();
  });
};
