import type { Dayjs } from 'dayjs';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { type ContactProfile } from '~/entities/contact/index.ts';
import { useMeetingDraftContext } from '~/entities/meeting/index.ts';
import classes from '~/pages/meetings/MeetingDateDisplay.module.scss';
import { Button } from '~/shared/molecules/Button/index.ts';
import { SlotPortal } from '~/shared/organisms/slots/index.ts';

type Props = {
  selectedDate: Dayjs | null;
  contacts: ContactProfile[] | undefined;
};

export const ProceedWithMeetingDate: FC<Props> = (props) => {
  const { selectedDate, contacts } = props;

  const navigate = useNavigate();
  const { meetingDraft, newDraft, meetingRoutes, setDate } =
    useMeetingDraftContext() ?? {};

  if (selectedDate == null /* || contacts == null*/) {
    return null;
  }

  return (
    <SlotPortal slotName="footer">
      <div className={classes.footerNext}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (!newDraft || !meetingRoutes || !setDate) {
              return;
            }

            if (!meetingDraft || meetingDraft.draftKind === 'new-meeting') {
              newDraft({
                when: selectedDate.set('hours', 8),
                contacts,
              });
            } else {
              setDate(selectedDate);
            }

            navigate(meetingRoutes.details.url());
          }}
          sx={{ width: '100%' }}
        >
          Next
        </Button>
      </div>
    </SlotPortal>
  );
};
