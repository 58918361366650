import type { ModelImage } from '~/entities/profile/model/ModelImage.ts';

export abstract class BlockedUser {
  abstract userId: number;
  abstract firstName: string;
  abstract lastName: string;
  abstract name: string;
  abstract avatar: ModelImage;
  abstract jobPositionId: number | null;
}
