import { type FC, lazy, Suspense, useEffect } from 'react';
import { useApiDevToolsConfig } from '../config/useApiDevToolsConfig.ts';

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/production').then((module) => ({
    default: module.ReactQueryDevtools,
  }))
);

export const ApiDevTools: FC<{ className?: string }> = (props) => {
  const { className } = props;

  const visible = useApiDevToolsConfig((config) => config.visible);

  useEffect(() => {
    localStorage.setItem('TanstackQueryDevtools.open', true.toString());
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <div className={className}>
        <ReactQueryDevtoolsProduction initialIsOpen={true} />
      </div>
    </Suspense>
  );
};
