import { TagCard } from '~/shared/molecules/TagCard/index.ts';
import { type Skill } from '../index.ts';

type Props = {
  skill: Skill;
};

export const SkillCard = (props: Props) => {
  const { skill } = props;

  return (
    <TagCard icon="study" name={skill.name} confirmsCount={skill.linkedCount} />
  );
};
