import { useState } from 'react';
import { BlockedUsers } from '~/entities/block-list/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { NavbarInput } from '~/shared/molecules/NavbarInput/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { Display, Navbar } from '~/shared/ui/index.ts';
import classes from './BlockListDisplay.module.scss';

export const BlockListDisplay = () => {
  const [searchedUserName, setSearchedUserName] = useState('');

  return (
    <Display documentTitle="block list" animationVariant="card-effect">
      <Navbar
        title="Block list"
        left={<BackIconNavigationButton />}
        classes={{
          children: classes.navbar,
        }}
        withBorderBottom
      >
        <NavbarInput
          placeholder="Search"
          icon={<Icon variant="search" />}
          value={searchedUserName}
          onChange={setSearchedUserName}
        />
      </Navbar>

      <ContentSlot>
        <BlockedUsers searchedName={searchedUserName} />
      </ContentSlot>
    </Display>
  );
};
