import { Skeleton, Typography } from '@mui/material';
import { type FC, memo, type ReactNode } from 'react';
import { Icon, type IconVariant } from '../../atoms/icons';
import classes from './IconTwoColumnRow.module.scss';

type Props = {
  icon: IconVariant;
  label: string;
  content?: string | ReactNode;
  isLoading?: boolean;
};

const IconTwoColumnRow: FC<Props> = (props) => {
  const { icon, label, content, isLoading } = props;

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <Icon variant={icon} />
      </div>

      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>

      {typeof content === 'string' ? (
        <Typography variant="body1" className={classes.text}>
          {content}
        </Typography>
      ) : (
        content
      )}

      {content == null && isLoading && (
        <Skeleton className={classes.text} height={24} />
      )}
    </div>
  );
};

const memoizedComponent = memo(IconTwoColumnRow);
export {
  memoizedComponent as IconTwoColumnRow,
  type Props as IconTwoColumnRowProps,
};
