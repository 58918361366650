import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { type ForwardedRef, forwardRef, useMemo } from 'react';
import { Interest } from '~/entities/interest/model/Interest.ts';
import { INTEREST_PAGE_SIZE, TagDto } from '~/shared/api/index.ts';
import {
  ADDABLE_ITEM_ROW_HEIGHT,
  AddableItemInList,
  PageOffsetInfiniteScroll,
  type PageOffsetInfiniteScrollRef,
  useVirtualRow,
} from '~/shared/ui/index.ts';
import { InterestApi } from '~/shared/api/interest/InterestApi.ts';
import { loadInterestsQueryKeys } from '../api/useLoadInterests.ts';

export const infiniteInterestsQueryKeys = (
  searchedInterestName?: Interest['name']
) => [...loadInterestsQueryKeys(searchedInterestName), 'infinitely'];

type Props = {
  /** if used search, this name will be passed to api for searching */
  searchedInterestName?: Interest['name'];

  /** if some interests are selected, and you want to shw check icon for them, use this prop */
  selectedInterestIds?: Set<Interest['id']>;

  onRowClick?: (interest: Interest) => void;
};

const InterestsInfiniteList = (
  props: Props,
  ref: ForwardedRef<PageOffsetInfiniteScrollRef<TagDto>>
) => {
  const { searchedInterestName, selectedInterestIds, onRowClick } = props;

  const interestApi = useDi(InterestApi);

  const Row = useVirtualRow<TagDto>(
    (props) => {
      const { forwardedRef, isLoading, rowEntity, hasNextPage, ...rest } =
        props;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const interest = useMemo(
        () => (rowEntity ? Mapper.map(TagDto, Interest, rowEntity) : undefined),
        [rowEntity]
      );

      let selected: boolean = false;
      if (interest && selectedInterestIds) {
        selected = selectedInterestIds.has(interest.id);
      }

      return (
        <AddableItemInList
          model={interest}
          onClick={() => {
            if (interest) {
              onRowClick?.(interest);
            }
          }}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          forwardedRef={forwardedRef}
          iconVariant={selected ? 'cross' : 'add'}
          {...rest}
        />
      );
    },
    [selectedInterestIds, onRowClick]
  );

  return (
    <PageOffsetInfiniteScroll
      ref={ref}
      estimatedRowHeight={ADDABLE_ITEM_ROW_HEIGHT}
      queryKeys={infiniteInterestsQueryKeys(searchedInterestName)}
      apiRequest={(offset) =>
        interestApi.searchInterests({
          pageSize: INTEREST_PAGE_SIZE,
          page: offset,
          interestName: searchedInterestName,
        })
      }
      Row={Row}
    />
  );
};

const component = forwardRef(InterestsInfiniteList);
export { component as InterestsInfiniteList };
