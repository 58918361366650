import { IconButton } from '@mui/material';
import FilledInput, { type FilledInputProps } from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { type ReactNode } from 'react';
import { Icon } from '~/shared/atoms/icons';

type Props = Pick<FilledInputProps, 'value' | 'placeholder'> & {
  onChange?: (newValue: string) => void;
  icon?: ReactNode;
};

const NavbarInput = (props: Props) => {
  const { onChange, icon, ...restProps } = props;

  return (
    <StyledInput
      {...restProps}
      startAdornment={
        icon && (
          <InputAdornment position="start" sx={{ marginTop: 0 }}>
            {icon}
          </InputAdornment>
        )
      }
      endAdornment={
        <InputAdornment position="end" sx={{ marginTop: 0 }}>
          <IconButton onClick={() => onChange?.('')}>
            <Icon variant="cross" />
          </IconButton>
        </InputAdornment>
      }
      slotProps={{
        input: {
          sx: {
            paddingTop: '10px',
            paddingBottom: '10px',
          },
        } as any,
      }}
      fullWidth
      onChange={(event) => onChange?.(event.target.value)}
    />
  );
};

const defaultTransformTransition =
  'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms';

const StyledInput = styled(FilledInput)({
  '&.MuiInputBase-root.MuiFilledInput-root': {
    paddingLeft: 10,
    paddingRight: 0,
    borderRadius: 8,
    backgroundColor: '#F1F3F5',
  },
  '&.MuiInputBase-root.MuiFilledInput-root:before': {
    borderRadius: 8,
    borderColor: '#868E96',
  },
  '&.MuiInputBase-root.MuiFilledInput-root:after': {
    transition: `${defaultTransformTransition}, border-radius 400ms`,
    height: '100%',
  },
  '&.MuiInputBase-root.Mui-focused.MuiFilledInput-root:after': {
    borderRadius: 8,
  },
});

export { NavbarInput };
export type { Props as NavbarInputProps };
