import { type FC, useRef } from 'react';
import { useLoginMutation } from '~/features/auth/api/useLoginMutation.ts';
import { TelegramLoginButton, useLoginContext } from '~/features/auth/index.ts';
import { LoginUserCredentials } from '~/features/auth/model/LoginUserCredentials.ts';
import { FakeApiSwitcher, useFakeApi } from '~/features/fake-api/index.ts';
import {
  FormProvider,
  type FormProviderRef,
} from '~/shared/form/FormProvider.tsx';
import { Button } from '~/shared/molecules/Button/index.ts';
import { EmailField } from '~/shared/ui/EmailField.tsx';
import { PasswordField } from '~/shared/ui/PasswordField.tsx';
import { MirageUsers } from './MirageUsers.tsx';
import classes from './LoginForm.module.scss';

export const LoginForm: FC = () => {
  const formRef = useRef<FormProviderRef>(null);
  const isMirageApi = useFakeApi();

  const { email, password } = useLoginContext() ?? {};

  const { mutate, isPending: loginInProgress } = useLoginMutation();

  return (
    <div>
      <FormProvider<LoginUserCredentials>
        ref={formRef}
        className={classes.fields}
        initialValues={{
          email: email,
          password: isMirageApi ? 'AnyValidPassword_1' : password,
        }}
        validationSchema={LoginUserCredentials.validationSchema}
        onValidSubmit={(data) => {
          mutate({ login: data.email!, password: data.password });
        }}
      >
        {isMirageApi && (
          <EmailField
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                formRef.current?.triggerSubmit();
              }
            }}
          />
        )}

        {/* now only login via telegram will available */}
        {!isMirageApi && false && (
          <PasswordField
            name="password"
            placeholder="Password"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                formRef.current?.triggerSubmit();
              }
            }}
          />
        )}

        <MirageUsers />

        {isMirageApi && (
          <Button
            variant="contained"
            color="primary"
            disabled={loginInProgress}
            type="submit"
          >
            Log in
          </Button>
        )}
      </FormProvider>

      <TelegramLoginButton />

      <FakeApiSwitcher />
    </div>
  );
};
