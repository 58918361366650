import { type Dayjs } from 'dayjs';
import { type MessageStatus } from './MessageStatus.ts';
import { type MessageType } from './MessageType.ts';
import { type PrivateChat } from './PrivateChat.ts';

export class SentMessage {
  readonly type: MessageType = 'sent-message';

  /** generated on FE to sync out coming message with created message on BE */
  messageGuid: string;

  authorId: number;
  content: string;
  status: MessageStatus;
  date: Dayjs;

  interlocutorId: PrivateChat['interlocutorId'];

  constructor(message: Omit<SentMessage, 'type'>) {
    this.messageGuid = message.messageGuid;
    this.authorId = message.authorId;
    this.content = message.content;
    this.status = message.status;
    this.date = message.date;

    this.interlocutorId = message.interlocutorId;
  }

  static sortFormNewToOld(left: SentMessage, right: SentMessage) {
    if (left.date.isAfter(right.date)) {
      // place left item after right item
      return -1;
    }

    if (right.date.isAfter(left.date)) {
      // place left item before right item
      return 1;
    }

    return 0;
  }
}
