import { type FC } from 'react';
import {
  IconButton,
  type IconButtonProps,
} from '../../molecules/IconButton/IconButton';

type Props = Pick<IconButtonProps<'more'>, 'onClick' | 'color'>;

export const OptionsIconButton: FC<Props> = (props) => {
  const { ...rest } = props;

  return <IconButton {...rest} size="large" variant="more" />;
};
