import { Alert, Snackbar, type SnackbarProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { type SyntheticEvent as ReactSyntheticEvent } from 'react';
import { Button } from '../../molecules/Button';
import { dismissNotification } from '../action/dismissNotification.ts';
import { useNotificationStore } from '../config/useNotificationStore.ts';
import classes from './NotificationStack.module.scss';

const AUTO_HIDE_DURATION = 5e3;

const CustomizedSnackbar = styled(Snackbar)<SnackbarProps>(() => ({
  position: 'relative',
  left: 'unset',
  right: 'unset',
  bottom: 'unset',
  top: 'unset',
  '@media (min-width: 600px)': {
    left: 'unset',
    right: 'unset',
    bottom: 'unset',
    top: 'unset',
  },
}));

type Props = {
  className?: string;
};

const NotificationStack = (props: Props) => {
  const { className } = props;

  const notifications = useNotificationStore((store) => store.notifications);

  const dismissAlert =
    (notificationKey: string) =>
    (event?: ReactSyntheticEvent | Event, reason?: string) => {
      if (reason !== 'clickaway') {
        dismissNotification(notificationKey);
      }
    };

  if (!notifications.length) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.notifications}>
        {notifications.map((notification) => (
          <CustomizedSnackbar
            key={notification.key}
            open
            onClose={dismissAlert(notification.key)}
            autoHideDuration={
              notification.manualDismiss ? undefined : AUTO_HIDE_DURATION
            }
          >
            <Alert
              onClose={dismissAlert(notification.key)}
              severity={notification.variant ?? 'info'}
              elevation={6}
              variant="filled"
              sx={{ width: '100%' }}
            >
              {notification.message}
              {notification.dismissAction ? (
                <Button
                  variant="contained"
                  color="error"
                  onClick={notification.dismissAction.onClick ?? (() => {})}
                  sx={{ marginTop: '8px', display: 'block' }}
                >
                  {notification.dismissAction.buttonText ?? 'Dismiss'}
                </Button>
              ) : null}
            </Alert>
          </CustomizedSnackbar>
        ))}
      </div>
    </div>
  );
};

export { NotificationStack };
