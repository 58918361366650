import type { ModelImage } from '../../profile/index.ts';
import { type ChatType } from './ChatType.ts';
import { type Message } from './Message.ts';
import { type SentMessage } from './SentMessage.ts';

export abstract class Chat {
  chatId: number;
  type: ChatType;
  avatar: ModelImage;
  name: string;
  unreadMessagesCount: number;

  messagePreview: SentMessage | Message | null;

  constructor(chat: Chat) {
    this.chatId = chat.chatId;
    this.type = chat.type;
    this.name = chat.name;
    this.avatar = chat.avatar;
    this.unreadMessagesCount = chat.unreadMessagesCount;
    this.messagePreview = chat.messagePreview;
  }

  static sortByMessagePreviewDate(left: Chat, right: Chat) {
    if (left.messagePreview?.date.isAfter(right.messagePreview?.date)) {
      return -1;
    }
    if (right.messagePreview?.date.isAfter(left.messagePreview?.date)) {
      return 1;
    }
    return 0;
  }
}
