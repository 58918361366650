import type { AcceptRejectEnum } from '../../enums/index.ts';
import { RealApiBase } from '../RealApiBase.ts';
import { apiRoutes } from '../apiRoutes.ts';
import type { PaginationDto } from '../PaginationDto.ts';
import type { Search } from '../Search.ts';
import type { CreateMeetingRequestDto } from './CreateMeetingRequestDto.ts';
import type { MeetingDto } from './MeetingDto.ts';
import type { UpdateMeetingRequestDto } from './UpdateMeetingRequestDto.ts';

export class MeetingRealApi extends RealApiBase {
  getMeetings = (
    dto: Search<'meetingName'> & {
      participantStatusFilter?: AcceptRejectEnum;
    }
  ) => {
    return this.post<PaginationDto<MeetingDto>>(
      apiRoutes.meetings.get_meetings_list.url(),
      {
        query: dto.meetingName,
        page: dto.page,
        pageSize: dto.pageSize,
        participantStatusFilter: dto.participantStatusFilter,
      }
    );
  };

  getMeetingById = (meetingId: number) => {
    return this.post<{
      meeting: MeetingDto | null;
    }>(apiRoutes.meetings.get_meeting_by_id.url(), {
      meetingId,
    });
  };

  leaveMeeting = (meetingId: number) => {
    return this.post<{}>(apiRoutes.meetings.leave_meeting.url(), {
      meetingId,
    });
  };

  acceptMeeting = (meetingId: number) => {
    return this.patch<{}>(apiRoutes.meetings.accept_meeting.url(), {
      meetingId,
    });
  };

  rejectMeeting = (meetingId: number) => {
    return this.patch<{}>(apiRoutes.meetings.reject_meeting.url(), {
      meetingId,
    });
  };

  setMeetingReminder = (
    meetingId: number,
    remindBeforeInMinutes: number | null
  ) => {
    return this.patch<{}>(apiRoutes.meetings.set_meeting_reminder.url(), {
      meetingId: meetingId,
      minutes: remindBeforeInMinutes,
    });
  };

  createMeeting = (dto: CreateMeetingRequestDto) => {
    return this.post<MeetingDto>(apiRoutes.meetings.create_meeting.url(), dto);
  };

  updateMeeting = (dto: UpdateMeetingRequestDto) => {
    return this.put<{}>(apiRoutes.meetings.update_meeting.url(), dto);
  };

  cancelMeeting = (meetingId: number) => {
    return this.delete<{}>(
      apiRoutes.meetings.cancel_meeting.url({
        meetingId: meetingId.toString(),
      })
    );
  };

  kickUserFromMeeting = (meetingId: number, userId: number) => {
    return this.post<{}>(apiRoutes.meetings.kick_user_from_meeting.url(), {
      meetingId,
      userId,
    });
  };

  setMeetingOwner = (meetingId: number, newOwnerUserId: number) => {
    return this.post<{}>(apiRoutes.meetings.set_meeting_owner.url(), {
      meetingId,
      newOwnerUserId,
    });
  };
}
