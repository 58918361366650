import { useMemo } from 'react';

const MAX_ROTATION_ANGLE_TO_SWIPE = 60;

function useRotationDegree(translateX: number) {
  const halfScreenWidth = useMemo(
    () => window.innerWidth / 2,
    [window.innerWidth]
  );

  return useMemo(() => {
    let maxOffsetByX: number;

    if (translateX < -halfScreenWidth) {
      maxOffsetByX = -halfScreenWidth;
    } else if (translateX > halfScreenWidth) {
      maxOffsetByX = halfScreenWidth;
    } else {
      maxOffsetByX = translateX;
    }

    const percentage = maxOffsetByX / halfScreenWidth; // in range [0, 1]
    const rotation = MAX_ROTATION_ANGLE_TO_SWIPE * percentage;
    return rotation;
  }, [translateX, halfScreenWidth]);
}

export { useRotationDegree, MAX_ROTATION_ANGLE_TO_SWIPE };
