import { useEffect, useRef, useState } from 'react';

export function useLinesCount(lineHeight: number) {
  const ref = useRef<HTMLParagraphElement>(null);
  const [numberOfLines, setNumberOfLines] = useState<number | null>(null);

  useEffect(() => {
    const countLines = () => {
      if (!ref.current) {
        return;
      }

      const rect = ref.current.getBoundingClientRect();
      const lines = Math.trunc(rect.height / lineHeight);
      setNumberOfLines(lines);
    };

    countLines();

    window.addEventListener('resize', countLines);

    return () => {
      window.removeEventListener('resize', countLines);
    };
  }, []);

  return {
    numberOfLines,
    ref,
  };
}
