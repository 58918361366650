import { type FC, type PropsWithChildren, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '~/shared/routing/index.ts';
import { resetAllStores } from '~/shared/store/index.ts';
import { useDisplayLoader } from '~/shared/ui/index.ts';
import { useIsUserAuthorized } from '../api/useIsUserAuthorized.ts';

/** Redirects to login page if user is not authorized */
export const RequireAuth: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const goToLogin = useCallback(() => {
    resetAllStores();
    navigate(routes.auth.login.url(), { state: { from: location } });
  }, [location, navigate]);

  const { isAuthorized, isLoading } = useIsUserAuthorized({
    actionOnError: goToLogin,
  });

  useDisplayLoader(isLoading);

  if (isAuthorized) {
    return children;
  }

  return null;
};
