import { FormControl, RadioGroup } from '@mui/material';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import {
  durationValueMap,
  getDurationValueElement,
  useMeetingDraftContext,
} from '~/entities/meeting/index.ts';
import { SlatRadio } from '~/features/meeting/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { Navbar } from '~/shared/ui/index.ts';
import classes from './MeetingReminderDisplay.module.scss';

dayjs.extend(durationPlugin);

const MeetingReminderDisplay = () => {
  const { meetingDraft, setReminder } = useMeetingDraftContext() ?? {};

  const reminderFormValue = useMemo(() => {
    return getDurationValueElement(meetingDraft?.remindBefore).props.value;
  }, [meetingDraft?.remindBefore]);

  if (!meetingDraft) {
    return <Navigate to={routes.meetings.url()} replace={true} />;
  }

  return (
    <>
      <Navbar
        title="Reminder"
        left={<BackIconNavigationButton />}
        withBorderBottom
      />

      <ContentSlot className={classes.root}>
        <FormControl>
          <RadioGroup
            value={reminderFormValue}
            onChange={(event) => {
              const valueMapElement =
                durationValueMap[
                  event.target.value as keyof typeof durationValueMap
                ];

              if (valueMapElement.duration) {
                setReminder?.(
                  dayjs.duration({ minutes: valueMapElement.duration })
                );
              } else {
                setReminder?.(null);
              }
            }}
          >
            <div className={classes.noReminderContainer}>
              <SlatRadio {...durationValueMap.no.props} />
            </div>

            <SlatRadio {...durationValueMap.halfHour.props} />
            <SlatRadio {...durationValueMap.oneHour.props} />
            <SlatRadio {...durationValueMap.twoHours.props} />
            <SlatRadio {...durationValueMap.oneDay.props} />
            <SlatRadio {...durationValueMap.twoDays.props} />
            <SlatRadio {...durationValueMap.oneWeek.props} />
          </RadioGroup>
        </FormControl>
      </ContentSlot>
    </>
  );
};

export { MeetingReminderDisplay };
