import type { CryptoWallet } from '~/entities/wallet/crossExports.ts';
import { Profile } from './Profile.ts';

export class MyProfile extends Profile {
  email: string;
  timezone: string;
  wasSaved?: boolean;
  connectedWallets: CryptoWallet[];
  coins: number;
  levelableSkills: {
    name: string;
    level: number;
  }[];

  constructor(myProfile: Omit<MyProfile, 'name'>) {
    super(myProfile);

    this.email = myProfile.email;
    this.timezone = myProfile.timezone;
    this.wasSaved = myProfile.wasSaved;
    this.connectedWallets = myProfile.connectedWallets;
    this.coins = myProfile.coins;
    this.levelableSkills = myProfile.levelableSkills;
  }
}
