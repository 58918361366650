import { MapFunction } from '@tomas-light/mapper-js';
import { TagDto } from '~/shared/api/index.ts';
import { Skill } from './Skill.ts';

function mapCreatedTagToSkill(tag: TagDto): Skill {
  return {
    id: tag.id,
    name: tag.name,
    linkedCount: tag.userByCount,
  };
}

export const skillMappingProfile = [
  //
  new MapFunction(TagDto, Skill, mapCreatedTagToSkill),
];
