import { useMemo } from 'react';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { useLoadSkillsById } from '~/entities/skill/index.ts';
import { useProfileSkillsEditContext } from '~/features/profile/index.ts';
import { useNavbarLoader } from '~/shared/ui/index.ts';

export function useEditedProfileSkills() {
  const { selectedSkillIds } = useProfileSkillsEditContext() ?? {};

  const { data: profile, isLoading: profileIsLoading } = useCurrentUser();
  const { data: profileSkills, isLoading: skillsAreLoading } =
    useLoadSkillsById(profile?.skillIds);

  const { data: selectedSkills, isLoading: selectedSkillsAreLoading } =
    useLoadSkillsById(Array.from(selectedSkillIds ?? []));

  useNavbarLoader(
    profileIsLoading || skillsAreLoading || selectedSkillsAreLoading
  );

  return useMemo(() => {
    const map = new Map(profileSkills?.map((skill) => [skill.id, skill]));

    const added = new Set(selectedSkillIds).difference(
      new Set(profile?.skillIds)
    );
    const removed = new Set(profile?.skillIds).difference(
      new Set(selectedSkillIds)
    );

    selectedSkills?.forEach((skill) => {
      if (!map.has(skill.id)) {
        map.set(skill.id, skill);
      }
    });

    return {
      skills: Array.from(map.values()),
      added,
      removed,
    };
  }, [profileSkills, selectedSkills, selectedSkillIds, profile?.interestIds]);
}
