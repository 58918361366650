import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { loadDepartmentsQueryKeys } from '~/entities/department/index.ts';
import { DepartmentApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';

export function useCreateDepartment() {
  const queryClient = useQueryClient();
  const departmentApi = useDi(DepartmentApi);

  return useMutation({
    mutationKey: ['create department'],
    mutationFn: departmentApi.createDepartment,
    onError: (error) => {
      notify({
        message: `Cant create department: ${error.message}`,
        variant: 'error',
      });
    },
    onSuccess: () => {
      notify({
        message: 'Department created',
        variant: 'success',
      });

      queryClient.invalidateQueries({
        queryKey: loadDepartmentsQueryKeys(),
      });
    },
  });
}
