import { ApiBase } from '../ApiBase.ts';
import { type DepartmentFakeApi } from './DepartmentFakeApi.ts';
import { type DepartmentRealApi } from './DepartmentRealApi.ts';

export class DepartmentApi extends ApiBase {
  constructor(
    private realApi: DepartmentRealApi,
    private fakeApi: DepartmentFakeApi
  ) {
    super();
  }

  createDepartment: DepartmentRealApi['createDepartment'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.createDepartment(...args);
    }
    return this.realApi.createDepartment(...args);
  };

  searchDepartments: DepartmentRealApi['searchDepartments'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.searchDepartments(...args);
    }
    return this.realApi.searchDepartments(...args);
  };

  searchDepartmentsById: DepartmentRealApi['searchDepartmentsById'] = (
    ...args
  ) => {
    if (this.isFakeApi) {
      return this.fakeApi.searchDepartmentsById(...args);
    }
    return this.realApi.searchDepartmentsById(...args);
  };
}
