import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { type RefCallback } from 'react';
import { type Tag } from '~/commonModels';
import { Icon } from '~/shared/atoms/icons';

const ADDABLE_ITEM_ROW_HEIGHT = 64; // by design

type Props<Model extends Tag> = {
  hasNextPage: boolean;
  isLoading: boolean;
  model: Model | undefined;
  onClick: (model: Model) => void;
  /** it responsible for an icon is displayed on a row */
  iconVariant?: 'add' | 'cross' | 'check';
  forwardedRef?: RefCallback<HTMLElement>;
};

function AddableItemInList<Model extends Tag>(props: Props<Model>) {
  const {
    model,
    onClick,
    isLoading,
    hasNextPage,
    iconVariant,
    forwardedRef,
    ...rest
  } = props;

  if (isLoading) {
    if (hasNextPage) {
      return (
        <StyledListItemButton ref={forwardedRef} disabled {...rest}>
          <Skeleton variant="circular" height="100%" />
          <Skeleton variant="rectangular" height="100%" />
        </StyledListItemButton>
      );
    }

    return 'Nothing more to load';
  }

  if (!model) {
    return null;
  }

  return (
    <Tooltip title={model.name} {...rest}>
      <StyledListItemButton ref={forwardedRef} onClick={() => onClick(model)}>
        <ListItemIcon
          style={{
            backgroundColor: '#F5F9FF',
            borderRadius: '50%',
            padding: 12,
          }}
          sx={{ minWidth: 'unset' }}
        >
          {iconVariant === 'add' && <Icon variant="add" color="primary" />}
          {iconVariant === 'check' && (
            <Icon variant="check" color="primary" />
          )}
          {iconVariant === 'cross' && (
            <Icon variant="cross" color="#FF3134" />
          )}
        </ListItemIcon>

        {/* todo: add count rounding: "12345" => "12,3 тыс." */}
        <ListItemText
          primary={model.name}
          secondary={`Совпадения: ${model.linkedCount}`}
          primaryTypographyProps={{ noWrap: true }}
        />
      </StyledListItemButton>
    </Tooltip>
  );
}

const StyledListItemButton = styled(ListItemButton)({
  '&.MuiButtonBase-root.MuiListItemButton-root': {
    display: 'grid',
    gridTemplateAreas: '"icon main"',
    gridTemplateColumns: '48px 1fr',
    columnGap: 12,

    '& > div:nth-of-type(1)': {
      gridArea: 'icon',
    },
    '& > div:nth-of-type(2)': {
      gridArea: 'main',
    },
  },
});

export { AddableItemInList, ADDABLE_ITEM_ROW_HEIGHT };
export type { Props as AddableItemInListProps };
