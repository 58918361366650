import { faker } from '@faker-js/faker';
import { type FakeApiDatabase } from '../../fakeApiDatabase/index.ts';
import { ApiError } from '../ApiError.ts';
import { ApiResponseStatus } from '../ApiResponseStatus.ts';
import type { TagDto } from '../index.ts';
import { INTEREST_PAGE_SIZE } from './INTEREST_PAGE_SIZE.ts';
import { type InterestRealApi } from './InterestRealApi.ts';

export class InterestFakeApi {
  constructor(readonly db: FakeApiDatabase) {}

  createInterest: InterestRealApi['createInterest'] = async (interestName) => {
    const interests = await this.db.interest.getAll();
    const existedInterest = interests.find(
      ({ name }) =>
        name.toLocaleLowerCase() === interestName.toLocaleLowerCase()
    );

    if (existedInterest) {
      throw new ApiError({
        status: ApiResponseStatus.BadRequest,
        error: 'interest with such name already exist',
      });
    }

    const newInterest: TagDto = {
      id: faker.number.int(),
      name: interestName,
      userByCount: 0,
    };
    await this.db.interest.insert(newInterest.id, newInterest);
    return {
      createdTag: newInterest,
    };
  };

  searchInterests: InterestRealApi['searchInterests'] = async (search) => {
    const { interestName, page, pageSize } = search;
    const interests = await this.db.interest.getAll();

    const lowerInterestName = interestName?.toLocaleLowerCase();

    const normalizedSize = pageSize ?? INTEREST_PAGE_SIZE;
    const startIndex = (page ?? 0) * normalizedSize;
    const endIndex = startIndex + normalizedSize;
    const filtered = interests.filter((interest) => {
      if (
        lowerInterestName &&
        !interest.name.toLocaleLowerCase().includes(lowerInterestName)
      ) {
        return false;
      }
      return true;
    });
    const batch = filtered.slice(startIndex, endIndex);

    return {
      totalCount: filtered.length,
      items: batch,
    };
  };

  searchInterestsById: InterestRealApi['searchInterestsById'] = async (
    interestIds
  ) => {
    const interests = await this.db.interest.getAll();

    const set = new Set(interestIds);
    const filtered = interests.filter((interest) => set.has(interest.id));

    return {
      totalCount: filtered.length,
      items: filtered,
    };
  };
}
