import { Typography } from '@mui/material';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '~/shared/atoms/icons';
import { Slat } from '~/shared/molecules/Slat';
import { routes } from '~/shared/routing/index.ts';

export const EditCareerNavigationSlat: FC = () => {
  const navigate = useNavigate();

  return (
    <Slat
      variant="nested-slat"
      iconProps={{ variant: 'position', color: 'text' }}
      onClick={() => navigate(routes.profile.settings.edit.career.url())}
      rightChildren={<Icon variant="chevron" direction="right" />}
    >
      <Typography>Career</Typography>
    </Slat>
  );
};
