import { memo } from 'react';
import { createSwitch } from '~/shared/molecules/Switch/index.ts';

interface Props {
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

const memoizedComponent = memo(MeetingsSwitcher);
export { memoizedComponent as MeetingsSwitcher };

function MeetingsSwitcher(props: Props) {
  const { setChecked, checked } = props;
  return (
    <Switch
      checked={checked}
      onChange={(event) => setChecked(event.target?.checked ?? false)}
    />
  );
}

const Switch = createSwitch({
  leftLabel: 'Calendar',
  rightLabel: 'List',
});
