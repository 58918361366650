import { Navigate, Outlet, type RouteObject } from 'react-router-dom';
import {
  ProfileInterestsEditContextProvider,
  ProfileSkillsEditContextProvider,
} from '~/features/profile/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { Display } from '~/shared/ui/index.ts';
import { BlockListDisplay } from '../../block-list/index.ts';
import { InterestsDisplay } from '../../interests/index.ts';
import { levelRoutes } from '../../level/index.ts';
import { SettingsDisplay } from '../../SettingsDisplay/index.ts';
import { SkillsDisplay } from '../../skills/index.ts';
import { WalletsDisplay } from '../../wallet/index.ts';
import { AddProfileInterestDisplay } from '../AddProfileInterestDisplay/index.ts';
import { AddProfileSkillDisplay } from '../AddProfileSkillDisplay/index.ts';
import { AvatarsDisplay } from '../AvatarsDisplay/index.ts';
import { EditProfileCareerDisplay } from '../EditProfileCareerDisplay/index.ts';
import { EditProfileDepartmentDisplay } from '../EditProfileDepartmentDisplay/index.ts';
import { EditProfileInfoDisplay } from '../EditProfileInfoDisplay/index.ts';
import { EditProfileInterestsDisplay } from '../EditProfileInterestsDisplay/index.ts';
import { EditProfileJobPositionDisplay } from '../EditProfileJobPositionDisplay/index.ts';
import { EditProfileSkillsDisplay } from '../EditProfileSkillsDisplay/index.ts';
import { FillInformationFirstTimeDisplay } from '../FillInformationFirstTimeDisplay.tsx';
import { ProfileDisplay } from '../ProfileDisplay.tsx';

export const profileRouteObject: RouteObject[] = [
  {
    path: routes.profile.first_login.url(),
    element: (
      <Display documentTitle="first login">
        <FillInformationFirstTimeDisplay />
      </Display>
    ),
  },
  {
    path: routes.profile.relativeUrl(),
    element: (
      <ProfileDisplay>
        <Outlet />
      </ProfileDisplay>
    ),
    children: [
      {
        index: true,
        element: null,
      },
      {
        path: routes.profile.avatars.relativeUrl(),
        element: (
          <Display documentTitle="my avatars">
            <AvatarsDisplay />
          </Display>
        ),
      },
      {
        path: routes.profile.skills.relativeUrl(),
        element: <SkillsDisplay />,
      },
      {
        path: routes.profile.interests.relativeUrl(),
        element: <InterestsDisplay />,
      },
      settingsRouteObject(),
    ],
  },
];

function settingsRouteObject(): RouteObject {
  return {
    path: routes.profile.settings.relativeUrl(),
    element: (
      <SettingsDisplay>
        <Outlet />
      </SettingsDisplay>
    ),
    children: [
      {
        index: true,
        element: null,
      },
      ...levelRoutes,
      {
        path: routes.profile.settings.blockList.relativeUrl(),
        element: <BlockListDisplay />,
      },
      {
        path: routes.profile.settings.edit.relativeUrl(),
        children: [
          {
            index: true,
            element: (
              <Navigate
                to={routes.profile.settings.edit.personal.url()}
                replace
              />
            ),
          },
          {
            path: routes.profile.settings.edit.personal.relativeUrl(),
            element: <EditProfileInfoDisplay />,
          },
          {
            path: routes.profile.settings.edit.career.relativeUrl(),
            element: (
              <EditProfileCareerDisplay>
                <Outlet />
              </EditProfileCareerDisplay>
            ),
            children: [
              {
                index: true,
                element: null,
              },
              {
                path: routes.profile.settings.edit.career.department.relativeUrl(),
                element: <EditProfileDepartmentDisplay />,
              },
              {
                path: routes.profile.settings.edit.career.position.relativeUrl(),
                element: <EditProfileJobPositionDisplay />,
              },
            ],
          },
          {
            path: routes.profile.settings.edit.skills.relativeUrl(),
            element: (
              <ProfileSkillsEditContextProvider>
                <EditProfileSkillsDisplay>
                  <Outlet />
                </EditProfileSkillsDisplay>
              </ProfileSkillsEditContextProvider>
            ),
            children: [
              {
                index: true,
                element: null,
              },
              {
                path: routes.profile.settings.edit.skills.new.relativeUrl(),
                element: <AddProfileSkillDisplay />,
              },
            ],
          },
          {
            path: routes.profile.settings.edit.interests.relativeUrl(),
            element: (
              <ProfileInterestsEditContextProvider>
                <EditProfileInterestsDisplay>
                  <Outlet />
                </EditProfileInterestsDisplay>
              </ProfileInterestsEditContextProvider>
            ),
            children: [
              {
                index: true,
                element: null,
              },
              {
                path: routes.profile.settings.edit.interests.new.relativeUrl(),
                element: (
                  <Display
                    documentTitle="edit profile interests"
                    animationVariant="card-effect"
                  >
                    <AddProfileInterestDisplay />
                  </Display>
                ),
              },
            ],
          },
        ],
      },
      {
        path: routes.profile.settings.wallet.relativeUrl(),
        element: (
          <Display documentTitle="wallets" animationVariant="card-effect">
            <WalletsDisplay />
          </Display>
        ),
      },
    ],
  };
}
