import { faker } from '@faker-js/faker';
import { JOB_POSITION_PAGE_SIZE } from '~/entities/jobPosition/ui/jobPositionPageSize.ts';
import { type FakeApiDatabase } from '../../fakeApiDatabase/index.ts';
import { ApiError } from '../ApiError.ts';
import { ApiResponseStatus } from '../ApiResponseStatus.ts';
import type { TagDto } from '../index.ts';
import { type JobPositionRealApi } from './JobPositionRealApi.ts';

export class JobPositionFakeApi {
  constructor(readonly db: FakeApiDatabase) {}

  createJobPosition: JobPositionRealApi['createJobPosition'] = async (
    jobPositionName
  ) => {
    const jobPositions = await this.db.jobPosition.getAll();
    const existedJobPosition = jobPositions.find(
      ({ name }) =>
        name.toLocaleLowerCase() === jobPositionName.toLocaleLowerCase()
    );

    if (existedJobPosition) {
      throw new ApiError({
        status: ApiResponseStatus.BadRequest,
        error: 'job position with such name already exist',
      });
    }

    const newJobPosition: TagDto = {
      id: faker.number.int(),
      name: jobPositionName,
      userByCount: 0,
    };
    await this.db.jobPosition.insert(newJobPosition.id, newJobPosition);
    return {
      createdTag: newJobPosition,
    };
  };

  searchJobPositions: JobPositionRealApi['searchJobPositions'] = async (
    search
  ) => {
    const { jobPositionName, page, pageSize } = search;
    const jobPositions = await this.db.jobPosition.getAll();

    const lowerJobPositionName = jobPositionName?.toLocaleLowerCase();

    const normalizedSize = pageSize ?? JOB_POSITION_PAGE_SIZE;
    const startIndex = (page ?? 0) * normalizedSize;
    const endIndex = startIndex + normalizedSize;
    const filtered = jobPositions.filter((jobPosition) => {
      if (
        lowerJobPositionName &&
        !jobPosition.name.toLocaleLowerCase().includes(lowerJobPositionName)
      ) {
        return false;
      }
      return true;
    });
    const batch = filtered.slice(startIndex, endIndex);

    return {
      totalCount: filtered.length,
      items: batch,
    };
  };

  searchJobPositionsById: JobPositionRealApi['searchJobPositionsById'] = async (
    jobPositionIds
  ) => {
    const jobPositions = await this.db.jobPosition.getAll();

    const set = new Set(jobPositionIds);
    const filtered = jobPositions.filter((jobPosition) =>
      set.has(jobPosition.id)
    );

    return {
      totalCount: filtered.length,
      items: filtered,
    };
  };
}
