import { useEffect, useMemo } from 'react';
import { notify } from '~/shared/notification/index.ts';
import { getTelegramWebAppInitData } from '../config/getTelegramWebAppInitData.ts';
import { useAuthorizationToken } from '../config/useAuthConfig.ts';
import { useIsTokenValidQuery } from './useIsTokenValidQuery.ts';
import { useTelegramLoginMutation } from './useTelegramLoginMutation.ts';

export function useIsUserAuthorized(options: {
  actionOnError?: VoidFunction;
  actionOnSuccess?: VoidFunction;
}) {
  const { actionOnError, actionOnSuccess } = options;

  const telegramInitData = useMemo(() => getTelegramWebAppInitData(), []);

  const {
    mutate: loginViaTelegram,
    isError: isTelegramLoginError,
    isSuccess: isTelegramLoginSuccess,
  } = useTelegramLoginMutation();

  const authorizationToken = useAuthorizationToken();
  const {
    data: currentUser,
    isLoading: isTokenLoading,
    error: tokenValidationError,
  } = useIsTokenValidQuery(authorizationToken);

  useEffect(() => {
    if (tokenValidationError) {
      actionOnError?.();

      notify({
        message: `token validation failed: ${tokenValidationError.message}`,
        variant: 'error',
      });
    }
  }, [actionOnError, tokenValidationError]);

  useEffect(() => {
    if (!authorizationToken) {
      actionOnError?.();
    }
  }, [actionOnError, authorizationToken]);

  useEffect(() => {
    if (currentUser) {
      actionOnSuccess?.();
    }
  }, [actionOnError, actionOnSuccess, currentUser]);

  useEffect(() => {
    const shouldTryAutologinWithTelegram =
      !authorizationToken && !!telegramInitData;

    const wasAttemptToLogin = isTelegramLoginError || isTelegramLoginSuccess;

    if (shouldTryAutologinWithTelegram && !wasAttemptToLogin) {
      loginViaTelegram(telegramInitData);
    }
  }, [
    authorizationToken,
    isTelegramLoginError,
    isTelegramLoginSuccess,
    loginViaTelegram,
    telegramInitData,
  ]);

  const isAuthorized = authorizationToken != null && currentUser;

  return {
    isAuthorized,
    isLoading: isTokenLoading,
  };
}
