import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { type Interest } from '~/entities/interest/index.ts';
import { ProfileApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification';
import {
  invalidateCurrentUserQuery,
  useCurrentUser,
} from '../../../entities/profile';
import { useOnBack } from '../../navigation/index.ts';

export function useSaveProfileInterests() {
  const queryClient = useQueryClient();
  const profileApi = useDi(ProfileApi);
  const onBack = useOnBack({});

  const { data: profile } = useCurrentUser();

  return useMutation({
    mutationKey: [
      'save user interests',
      { profileInterestIds: profile?.interestIds },
    ],
    mutationFn: (selectedInterestIds: Set<Interest['id']>) => {
      if (!profile) {
        throw new Error('current profile is not loaded');
      }

      const interests = new Set(profile.interestIds);

      const newIds = Array.from(selectedInterestIds.difference(interests));
      const deletedIds = Array.from(interests.difference(selectedInterestIds));

      return profileApi.changeInterestsBulk({
        addIds: newIds,
        deleteIds: deletedIds,
      });
    },
    onSuccess: () => {
      invalidateCurrentUserQuery(queryClient);
      onBack();
    },
    onError: (error) => {
      notify({
        message: `Cant save profile: ${error.message}`,
        variant: 'error',
      });
    },
  });
}
