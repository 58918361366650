import clsx from 'clsx';
import {
  createElement,
  type HTMLAttributes,
  memo,
  type MutableRefObject,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { SlotContext } from './SlotContext';
import type { SlotName } from './SlotName.ts';

type Props = HTMLAttributes<HTMLDivElement> & {
  slotName: SlotName;
  component?: keyof JSX.IntrinsicElements;
};

function ReservedSlot(props: Props) {
  const { slotName, component = 'div', ...rest } = props;

  const ref = useRef<Element | null>(null);

  const { slotsMap, reservedSlotsMap } = useContext(SlotContext) ?? {};
  const slot = slotsMap?.get(slotName);

  if (!reservedSlotsMap?.has(slotName)) {
    reservedSlotsMap?.set(slotName, ref as MutableRefObject<HTMLElement>);
  }

  useEffect(() => {
    return () => {
      reservedSlotsMap?.delete(slotName);
    };
  }, [reservedSlotsMap, slotName]);

  return createElement(component, {
    ...rest,
    ...slot,
    className: clsx(rest.className, slot?.className),
    ref,
  });
}

const memoizedComponent = memo(ReservedSlot) as unknown as typeof ReservedSlot;

export { memoizedComponent as ReservedSlot };
