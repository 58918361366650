import clsx from 'clsx';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '~/shared/molecules/Button/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { useRegistrationContext } from '../model/RegistrationContext.tsx';
import { StyledAccount } from './StyledAccount.tsx';
import classes from './SwitchButton.module.scss';

type Props = {
  className?: string;
};

export const RegistrationSwitchButton: FC<Props> = (props) => {
  const { className } = props;

  const navigate = useNavigate();
  const { registrationStep } = useRegistrationContext() ?? {};

  if (registrationStep !== 'credentials') {
    return;
  }

  return (
    <div className={clsx(classes.appInfo, className)}>
      <div className={classes.toggler}>
        <StyledAccount variant="body1">
          Already have an account?
        </StyledAccount>

        <Button
          variant="text"
          color="primary"
          onClick={() => {
            navigate(routes.auth.login.url());
          }}
        >
          Log in
        </Button>
      </div>
    </div>
  );
};
