import { Mapper } from '@tomas-light/mapper-js';
import dayjs, { type Dayjs } from 'dayjs';
import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ContactProfile } from '~/entities/contact/crossExports.ts';
import { Profile , useCurrentUser } from '~/entities/profile/crossExports.ts';
import type { Meeting } from './Meeting.ts';
import { MeetingDraft } from './MeetingDraft.ts';
import { MeetingMember } from './MeetingMember.ts';
import type { MeetingRoutes } from './MeetingRoutes.ts';

type MeetingDraftContextType = {
  meetingDraft: MeetingDraft | undefined;
  removeDraft: VoidFunction;

  newDraft: (options: { contacts?: ContactProfile[]; when: Dayjs }) => void;
  newEditDraft: (options: { meeting: Meeting }) => void;
  newClonedDraft: (options: { meeting: Meeting }) => void;

  setDate: (date: Dayjs) => void;
  setEndTime: (date: Dayjs | null) => void;
  inviteMember: (meetingMember: MeetingMember) => void;
  removeMember: (meetingMember: MeetingMember) => void;
  changeName: (meetingName: MeetingDraft['name']) => void;
  setReminder: (reminder: MeetingDraft['remindBefore']) => void;

  meetingRoutes: MeetingRoutes;
};

const MeetingDraftContext = createContext<MeetingDraftContextType | null>(null);

export const useMeetingDraftContext = () => useContext(MeetingDraftContext);

type Props = PropsWithChildren & {
  parentRoute: MeetingRoutes;
};

export const MeetingDraftContextProvider: FC<Props> = (props) => {
  const { children, parentRoute } = props;

  const [meetingDraft, setMeetingDraft] =
    useState<MeetingDraftContextType['meetingDraft']>();

  const { data: currentProfile } = useCurrentUser();

  const context = useMemo<MeetingDraftContextType>(
    () => ({
      meetingDraft,
      removeDraft: () => setMeetingDraft(undefined),
      newDraft: ({ contacts, when }) => {
        if (!currentProfile) {
          throw new Error('current profile was no loaded');
        }

        const owner = Mapper.map(Profile, MeetingMember, currentProfile);
        owner.status = 'owner';

        const invitedMembers = contacts?.map((contactProfile) => {
          const member = Mapper.map(
            ContactProfile,
            MeetingMember,
            contactProfile
          );
          member.status = 'invited';
          return member;
        });

        setMeetingDraft(
          new MeetingDraft({
            draftKind: 'new-meeting',
            meetingOwner: owner,
            invitedMembers: invitedMembers,
            name: '',
            when: when.set('hours', 8),
            howLong: dayjs.duration({ hours: 1 }),
            remindBefore: null,
          })
        );
      },

      newEditDraft: ({ meeting }) => {
        setMeetingDraft(
          new MeetingDraft({
            draftKind: 'edit-meeting',
            meetingId: meeting.meetingId,
            name: meeting.name,
            when: meeting.when,
            howLong: meeting.howLong,
            remindBefore: meeting.remindBefore,
            meetingOwner: meeting.meetingOwner,
            invitedMembers: meeting.invitedMembers,
            acceptedMembers: meeting.acceptedMembers,
          })
        );
      },

      newClonedDraft: ({ meeting }) => {
        if (!currentProfile) {
          throw new Error('current profile is was not loaded');
        }

        const newOwner = Mapper.map(Profile, MeetingMember, currentProfile);
        newOwner.status = 'owner';

        const newDraft = new MeetingDraft({
          draftKind: 'clone-meeting',
          meetingId: meeting.meetingId,
          name: meeting.name,
          when: meeting.when,
          howLong: meeting.howLong,
          remindBefore: meeting.remindBefore,
          meetingOwner: newOwner,
          invitedMembers: meeting.invitedMembers.filter(
            (member) => member.userId !== currentProfile.userId
          ),
          acceptedMembers: [],
        });

        newDraft.invitedMembers.push(...meeting.acceptedMembers);

        if (meeting.meetingOwner.userId !== currentProfile?.userId) {
          newDraft.invitedMembers.push(meeting.meetingOwner);
        }

        newDraft.invitedMembers.forEach((member) => {
          member.status = 'invited';
        });

        setMeetingDraft(newDraft);
      },

      setDate: (date) => {
        if (!meetingDraft) {
          throw new Error(
            'you should create meeting draft before setting date'
          );
        }

        setMeetingDraft(meetingDraft.setDate(date));
      },

      setEndTime: (date) => {
        if (!meetingDraft) {
          throw new Error(
            'you should create meeting draft before setting end time'
          );
        }

        setMeetingDraft(meetingDraft.setEndTime(date));
      },

      inviteMember: (meetingMember) => {
        if (!meetingDraft) {
          throw new Error(
            'you should create meeting draft before inviting member'
          );
        }
        setMeetingDraft(meetingDraft.addInvitedMember(meetingMember));
      },

      removeMember: (meetingMember) => {
        if (!meetingDraft) {
          throw new Error(
            'you should create meeting draft before removing member'
          );
        }
        setMeetingDraft(meetingDraft.removeInvitedMember(meetingMember));
      },

      changeName: (meetingName) => {
        if (!meetingDraft) {
          throw new Error(
            'you should create meeting draft before changing name'
          );
        }
        setMeetingDraft(meetingDraft.setName(meetingName));
      },

      setReminder: (reminder) => {
        if (!meetingDraft) {
          throw new Error(
            'you should create meeting draft before setting reminder'
          );
        }
        setMeetingDraft(meetingDraft.setReminder(reminder));
      },

      meetingRoutes: parentRoute,
    }),
    [currentProfile, meetingDraft, parentRoute]
  );

  return (
    <MeetingDraftContext.Provider value={context}>
      {children}
    </MeetingDraftContext.Provider>
  );
};
