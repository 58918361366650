import { RealApiBase } from '../RealApiBase.ts';
import { apiRoutes } from '../apiRoutes.ts';
import type { PaginatedRequestDto } from '../PaginatedRequestDto.ts';
import type { UserForCarouselDto } from '../profile/UserForCarouselDto.ts';
import type { Search } from '../Search.ts';

export class MatcherRealApi extends RealApiBase {
  getUsersForCarousel = async (
    dto: PaginatedRequestDto & {
      filters: {
        interestIds: number[];
        skillIds: number[];
        departmentIds: number[];
        jobPositionIds: number[];
      };
    }
  ) => {
    return this.post<{
      usersToMatch: UserForCarouselDto[];
      // totalCount: number; // not used on frontend
    }>(apiRoutes.matcher.get_queue.url(), {
      filters: {
        interestIds: dto.filters.interestIds,
        skillIds: dto.filters.skillIds,
        departmentIds: dto.filters.departmentIds,
        jobPositionIds: dto.filters.jobPositionIds,
      },
      page: dto.page,
      pageSize: dto.pageSize,
    });
  };

  like = async (userId: number) => {
    return this.post<{}>(apiRoutes.matcher.like.url(), {
      userId,
    });
  };

  dislike = async (userId: number) => {
    return this.post<{}>(apiRoutes.matcher.dislike.url(), {
      userId,
    });
  };

  getMatches = async (search: Search<'userName'>) => {
    return this.post<{
      items: UserForCarouselDto[];
      totalItemsCount: number;
    }>(apiRoutes.matcher.match_list.url(), {
      query: search.userName,
      page: search.page,
      pageSize: search.pageSize,
    });
  };
}
