import { type CommonIconProps } from '~/shared/atoms/icons/CommonProps';
import { useThemeColor } from '~/shared/useThemeColor';

const ChevronUpIcon = (props: CommonIconProps) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  const themeColor = useThemeColor(color);

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.71 11.29L12.71 6.29002C12.6149 6.19898 12.5028 6.12761 12.38 6.08002C12.1365 5.98 11.8635 5.98 11.62 6.08002C11.4973 6.12761 11.3851 6.19898 11.29 6.29002L6.29 11.29C6.19676 11.3833 6.1228 11.4939 6.07234 11.6158C6.02188 11.7376 5.99591 11.8682 5.99591 12C5.99591 12.2663 6.1017 12.5217 6.29 12.71C6.47831 12.8983 6.7337 13.0041 7 13.0041C7.2663 13.0041 7.5217 12.8983 7.71 12.71L11 9.41002V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8947 11.7348 18 12 18C12.2652 18 12.5196 17.8947 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V9.41002L16.29 12.71C16.383 12.8037 16.4936 12.8781 16.6154 12.9289C16.7373 12.9797 16.868 13.0058 17 13.0058C17.132 13.0058 17.2627 12.9797 17.3846 12.9289C17.5064 12.8781 17.617 12.8037 17.71 12.71C17.8037 12.6171 17.8781 12.5065 17.9289 12.3846C17.9797 12.2627 18.0058 12.132 18.0058 12C18.0058 11.868 17.9797 11.7373 17.9289 11.6154C17.8781 11.4936 17.8037 11.383 17.71 11.29Z"
        fill={themeColor}
      />
    </svg>
  );
};

export { ChevronUpIcon };
