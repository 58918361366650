import { useMutation } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { TelegramApi } from '~/shared/api/telegram/TelegramApi.ts';
import { notify } from '~/shared/notification/index.ts';
import { useSuccessLoginHandler } from './useSuccessLoginHandler.ts';

export function useTelegramLoginMutation() {
  const telegramApi = useDi(TelegramApi);
  const onSuccessLogin = useSuccessLoginHandler();

  return useMutation({
    mutationKey: ['login'],
    mutationFn: async (telegramHash: string) => {
      const response = await telegramApi.login(telegramHash);
      if (!response.token) {
        throw new Error('Login failed');
      }

      return response;
    },
    onSuccess: async (response) => {
      await onSuccessLogin(response.token);
    },
    onError: (error) => {
      notify({
        message: `Login with Telegram failed: ${error.message}`,
        variant: 'error',
      });
    },
  });
}
