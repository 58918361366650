import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { NiceWebRoutesNode } from 'nice-web-routes/types/types/NiceWebRoutesNode';
import { type FC, type PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserById } from '~/entities/colleague/index.ts';
import { DepartmentTwoColumnRow } from '~/entities/department/index.ts';
import { ShortInterestList } from '~/entities/interest/index.ts';
import { JobPositionTwoColumnRow } from '~/entities/jobPosition/index.ts';
import {
  CompanyExperienceTwoColumnRow,
  type Profile,
  WorkExperienceTwoColumnRow,
} from '~/entities/profile/index.ts';
import { ShortSkillList } from '~/entities/skill/index.ts';
import { About } from '~/pages/profile/About.tsx';
import { Experience } from '~/pages/profile/Experience/Experience.tsx';
import { useNavbarLoader } from '~/shared/ui/index.ts';
import classes from './CurrentProfileInformation.module.scss';

type Props = PropsWithChildren<{
  userId: Profile['userId'] | undefined;
  userRoutes: NiceWebRoutesNode<{
    interests: {};
    skills: {};
  }>;
}>;

export const ProfileMainInfo: FC<Props> = (props) => {
  const { userId, userRoutes, children } = props;
  const navigate = useNavigate();

  const { data: colleague, isLoading: colleagueIsLoading } =
    useUserById(userId);

  useNavbarLoader(colleagueIsLoading);

  return (
    <div className={classes.info}>
      <StyledName variant="h2">{colleague?.name}</StyledName>

      {children}

      <Experience>
        <DepartmentTwoColumnRow />
        <JobPositionTwoColumnRow />

        <CompanyExperienceTwoColumnRow
          joinedCompanyDate={colleague?.joinedCompanyDate}
        />

        <WorkExperienceTwoColumnRow
          workExperienceSince={colleague?.workExperienceSince}
        />
      </Experience>

      <About bio={colleague?.bio} />

      <ShortInterestList
        interestIds={colleague?.interestIds}
        onShowAll={() => navigate(userRoutes.interests.url())}
      />
      <ShortSkillList
        skillIds={colleague?.skillIds}
        onShowAll={() => navigate(userRoutes.skills.url())}
      />
    </div>
  );
};

const StyledName = styled(Typography)({
  '&.MuiTypography-root': {
    /* Header/H2 - 22px */
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '28px',

    /* gray/gray-1 */
    color: '#212529',
  },
});
