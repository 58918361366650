import { MapFunction, Mapper } from '@tomas-light/mapper-js';
import dayjs from 'dayjs';
import { DialogDto } from '~/shared/api/DialogDto.ts';
import { IncomeMessageDto } from '~/shared/api/IncomeMessageDto.ts';
import { MessageDto } from '~/shared/api/MessageDto.ts';
import { DialogTypeEnum } from '~/shared/enums/DialogTypeEnum.ts';
import { guid } from '../../../utils/index.ts';
import { Chat } from './Chat.ts';
import { ChatType } from './ChatType.ts';
import { mapMessageStatuses } from './mapMessageStatuses.ts';
import { Message } from './Message.ts';
import { PrivateChat } from './PrivateChat.ts';
import { SentMessage } from './SentMessage.ts';

function mapDialogDtoToChat(dto: DialogDto): Chat {
  switch (dto.dialogType) {
    case DialogTypeEnum.PeerToPeer:
      return Mapper.map(DialogDto, PrivateChat, dto);

    case DialogTypeEnum.Unknown:
    default:
      throw new Error(
        `There is no mapping profile for Dialog with type (${dto.dialogType})`
      );
  }
}

function mapDialogDtoToPrivateChat(dto: DialogDto): PrivateChat {
  return new PrivateChat({
    chatId: dto.dialogId,
    name: dto.dialogName,
    avatar: {
      fileName: dto.dialogAvatarName,
      url: dto.dialogAvatarName,
    },
    interlocutorId: dto.entityId,
    unreadMessagesCount: dto.unreadMessagesCount,
    messagePreview: dto.lastMessagePreview
      ? Mapper.map(MessageDto, Message, dto.lastMessagePreview)
      : null,
  });
}

function mapMessageDtoToMessage(dto: MessageDto): Message {
  const status = mapMessageStatuses(dto.messageStatusEnum);

  return new Message({
    messageGuid: guid(),
    messageId: dto.messageId,
    authorId: dto.senderId,
    interlocutorId: -200, // should be settled outside
    content: dto.content,
    date: dayjs(dto.createdAt),
    status: status,
  });
}

function mapIncomeMessageDtoToMessage(dto: IncomeMessageDto): Message {
  return new Message({
    messageGuid: guid(),
    messageId: dto.messageId,
    authorId: dto.senderId,
    interlocutorId: dto.senderId,
    content: dto.content,
    // date: dayjs(dto.createdAt), // todo: wait BE fix
    date: dayjs(),
    status: 'received',
  });
}

function mapChatLikeToChat(chatLike: Chat): Chat {
  switch (chatLike.type) {
    case ChatType.Dialog:
      return new PrivateChat(chatLike as PrivateChat);

    case ChatType.Unknown:
    default:
      throw new Error(
        `There is no mapping profile for Chat with type (${chatLike.type})`
      );
  }
}

function mapMessageLikeToMessage(messageLike: SentMessage): SentMessage {
  switch (messageLike.type) {
    case 'sent-message':
      return new SentMessage(messageLike as SentMessage);

    case 'message':
      return new Message(messageLike as Message);

    default:
      throw new Error(
        `There is no mapping profile for Message with type (${messageLike.type})`
      );
  }
}

export const chatMappingProfile = [
  new MapFunction(DialogDto, Chat, mapDialogDtoToChat),
  new MapFunction(DialogDto, PrivateChat, mapDialogDtoToPrivateChat),
  new MapFunction(MessageDto, Message, mapMessageDtoToMessage),
  new MapFunction(IncomeMessageDto, Message, mapIncomeMessageDtoToMessage),
  new MapFunction(Chat, Chat, mapChatLikeToChat),
  new MapFunction(SentMessage, SentMessage, mapMessageLikeToMessage),
];
