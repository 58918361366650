import { type Dayjs } from 'dayjs';
import type { ModelImage } from './ModelImage.ts';

export class Profile {
  userId: number;

  firstName: string;
  lastName: string;

  bio: string;

  avatar: ModelImage;
  photos: ModelImage[];

  departmentId: number | null;
  jobPositionId: number | null;
  joinedCompanyDate: Dayjs | null;
  workExperienceSince: Dayjs | null;

  interestIds: number[];
  skillIds: number[];

  constructor(profile: Omit<Profile, 'name'>) {
    this.userId = profile.userId;
    this.firstName = profile.firstName;
    this.lastName = profile.lastName;
    this.bio = profile.bio;
    this.avatar = profile.avatar;
    this.photos = profile.photos;
    this.departmentId = profile.departmentId;
    this.jobPositionId = profile.jobPositionId;
    this.joinedCompanyDate = profile.joinedCompanyDate;
    this.workExperienceSince = profile.workExperienceSince;
    this.interestIds = profile.interestIds;
    this.skillIds = profile.skillIds;
  }

  get name() {
    return Profile.getName(this);
  }

  static getName(
    profile: Partial<Pick<Profile, 'firstName' | 'lastName'>>
  ): string {
    if (!profile.lastName) {
      return profile.firstName ?? '';
    }
    if (!profile.firstName) {
      return profile.lastName;
    }
    return `${profile.firstName} ${profile.lastName}`;
  }
}
