import { useMatch, useParams } from 'react-router-dom';
import { useParseInt } from '~/shared/numbers/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { useCurrentUser } from '~/entities/profile/crossExports.ts';
import { useUserById } from '../../colleague/index.ts';
import { useLoadDepartmentById } from './useLoadDepartmentById.ts';

export function useProfileDepartment() {
  const currentProfileMatch = useMatch(routes.profile.url());
  const colleagueProfileMatch = useMatch(routes.colleagues.userId().url());
  const { userId: stringUserId } = useParams<{ userId: string | undefined }>();
  const userId = useParseInt(stringUserId);

  const isCurrentProfile = currentProfileMatch != null;
  const isColleagueProfile = colleagueProfileMatch != null;

  const { data: currentProfile, isLoading: currentProfileIsLoading } =
    useCurrentUser({
      enabled: isCurrentProfile,
    });
  const {
    data: currentProfileDepartment,
    isLoading: currentProfileDepartmentIsLoading,
  } = useLoadDepartmentById(currentProfile?.departmentId, {
    enabled: isCurrentProfile,
  });

  const { data: profile, isLoading: profileIsLoading } = useUserById(userId, {
    enabled: isColleagueProfile,
  });
  const { data: profileDepartment, isLoading: profileDepartmentIsLoading } =
    useLoadDepartmentById(profile?.departmentId, {
      enabled: isColleagueProfile,
    });

  return {
    department: currentProfileDepartment || profileDepartment,
    departmentIsLoading:
      currentProfileIsLoading ||
      currentProfileDepartmentIsLoading ||
      profileIsLoading ||
      profileDepartmentIsLoading,
  };
}
