import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { loadMeetingsQueryKey } from '~/entities/meeting/index.ts';
import { MeetingApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';

export function useRejectMeetingMutation() {
  const meetingApi = useDi(MeetingApi);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['reject meeting'],
    mutationFn: meetingApi.rejectMeeting,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: loadMeetingsQueryKey(),
      });
    },
    onError: (error) => {
      notify({
        message: `Cannot reject meeting ${error.message}`,
        variant: 'error',
      });
    },
  });
}
