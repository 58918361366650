import { API_BASE_URL } from '../../../config/environment/index.ts';
import { RealApiBase } from '../RealApiBase.ts';
import { apiRoutes } from '../apiRoutes.ts';
import { type MyUserDto } from './MyUserDto.ts';
import type { PhotoUrlRequest } from './PhotoUrlRequest.ts';
import type { UpdateProfileCareerRequestDto } from './UpdateProfileCareerRequestDto.ts';
import type { UpdateProfileRequestDto } from './UpdateProfileRequestDto.ts';
import type { UserForCarouselDto } from './UserForCarouselDto.ts';
import type { UserPhotoDto } from './UserPhotoDto.ts';

export class ProfileRealApi extends RealApiBase {
  getProfile = async (userId: number) => {
    return this.get<{ user: UserForCarouselDto }>(
      apiRoutes.profile.get_profile.url({
        userId: userId.toString(),
      })
    );
  };

  getCurrentUser = async () => {
    return this.get<{ user: MyUserDto }>(apiRoutes.profile.me.url());
  };

  updateProfileInfo = async (dto: UpdateProfileRequestDto) => {
    return this.post<void>(apiRoutes.profile.update_profile_info.url(), dto);
  };

  updateProfileCareer = async (dto: UpdateProfileCareerRequestDto) => {
    return this.post<void>(
      apiRoutes.profile.update_profile_career_info.url(),
      dto
    );
  };

  uploadPhoto = async (dto: { fileName: string; photoBase64: string }) => {
    const { fileName, photoBase64 } = dto;

    return this.post<{
      uploadedUserPhotos: UserPhotoDto[];
    }>(apiRoutes.profile.upload_profile_photos_base64.url(), {
      fileName,
      base64Data: photoBase64,
    });
  };

  deletePhoto = async (fileName: string) => {
    return this.delete<void>(
      apiRoutes.profile.delete_profile_photo.url({
        fileName,
      })
    );
  };

  setAvatar = async (fileName: string) => {
    return this.patch<void>(
      apiRoutes.profile.set_main_avatar.url({
        fileName,
      })
    );
  };

  getAvatarUrl = async (request: PhotoUrlRequest) => {
    const { userId, fileName } = request;

    return (
      API_BASE_URL +
      apiRoutes.profile.get_profile_photo.url({
        userId: userId.toString(),
        fileName,
      })
    );
  };

  addSkill = (skillId: number) => {
    return this.put<void>(apiRoutes.profile.set_skill.url(), {
      tagId: skillId,
    });
  };

  removeSkill = async (skillId: number) => {
    return this.delete<void>(
      apiRoutes.profile.set_skill.url({
        tagId: skillId.toString(),
      })
    );
  };

  changeSkillsBulk = async (payload: {
    addIds?: number[];
    deleteIds?: number[];
  }) => {
    return this.post<void>(apiRoutes.profile.bulk_change_skills.url(), payload);
  };

  addInterest = async (interestId: number) => {
    return this.put<void>(apiRoutes.profile.set_interest.url(), {
      tagId: interestId,
    });
  };

  removeInterest = async (interestId: number) => {
    return this.delete<void>(
      apiRoutes.profile.set_interest.url({
        tagId: interestId.toString(),
      })
    );
  };

  changeInterestsBulk = async (payload: {
    addIds?: number[];
    deleteIds?: number[];
  }) => {
    return this.post<void>(
      apiRoutes.profile.bulk_change_interests.url(),
      payload
    );
  };

  addJobPosition = async (jobPositionId: number) => {
    return this.put<void>(apiRoutes.profile.set_job_position.url(), {
      tagId: jobPositionId,
    });
  };

  removeJobPosition = async (jobPositionId: number) => {
    return this.delete<void>(
      apiRoutes.profile.set_job_position.url({
        tagId: jobPositionId.toString(),
      })
    );
  };

  addDepartment = async (departmentId: number) => {
    return this.put<void>(apiRoutes.profile.set_department.url(), {
      tagId: departmentId,
    });
  };

  removeDepartment = async (departmentId: number) => {
    return this.delete<void>(
      apiRoutes.profile.set_department.url({
        tagId: departmentId.toString(),
      })
    );
  };
}
