import type { FC, PropsWithChildren } from 'react';
import { ApiVersion, AppVersion } from '~/entities/development';
import { LogoutSlat } from '~/features/auth';
import { BlockListNavigationSlat } from '~/features/block-list';
import { ProfileLevelSlat } from '~/features/level/index.ts';
import { BackIconNavigationButton } from '~/features/navigation';
import { WalletsNavigationSlat } from '~/features/wallet/index.ts';
import { ContentSlot } from '~/shared/organisms/slots';
import { Display, Navbar } from '~/shared/ui';
import { EditProfileCollapsableSlats } from '~/widgets/profile';
import { RemoveMessagesWithUser } from '../../features/chat';
import { ResetLikesAndDislikesButton } from '../../features/match';
import classes from './SettingsDisplay.module.scss';

export const SettingsDisplay: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <>
      <Display documentTitle="settings" animationVariant="card-effect">
        <Navbar title="Settings" left={<BackIconNavigationButton />} />

        <ContentSlot className={classes.view}>
          <div className={classes.menu}>
            <EditProfileCollapsableSlats />
            <BlockListNavigationSlat />
            <WalletsNavigationSlat />
            <ProfileLevelSlat />
            <LogoutSlat />
          </div>

          <div className={classes.devTools}>
            <AppVersion />
            <ApiVersion />

            <RemoveMessagesWithUser />

            <ResetLikesAndDislikesButton />
          </div>
        </ContentSlot>
      </Display>

      {children}
    </>
  );
};
