import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const ClockFilledIcon = (props: Props) => {
  const { height = 16, color = 'currentColor', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1C4.15 1 1 4.15 1 8C1 11.85 4.15 15 8 15C11.85 15 15 11.85 15 8C15 4.15 11.85 1 8 1ZM8.7 8C8.7 8.28 8.56 8.49 8.35 8.63L6.53 9.68C6.18 9.89 5.76 9.75 5.55 9.4C5.34 9.05 5.48 8.63 5.83 8.42L7.3 7.58V4.5C7.3 4.08 7.58 3.8 8 3.8C8.42 3.8 8.7 4.08 8.7 4.5V8Z"
        fill={color}
      />
    </svg>
  );
};

export type { Props as ClockFilledIconProps };
export { ClockFilledIcon };
