import { ApiBase } from '../ApiBase.ts';
import { type SkillFakeApi } from './SkillFakeApi.ts';
import { type SkillRealApi } from './SkillRealApi.ts';

export class SkillApi extends ApiBase {
  constructor(
    private realApi: SkillRealApi,
    private fakeApi: SkillFakeApi
  ) {
    super();
  }

  createSkill: SkillRealApi['createSkill'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.createSkill(...args);
    }
    return this.realApi.createSkill(...args);
  };

  searchSkills: SkillRealApi['searchSkills'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.searchSkills(...args);
    }
    return this.realApi.searchSkills(...args);
  };

  searchSkillsById: SkillRealApi['searchSkillsById'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.searchSkillsById(...args);
    }
    return this.realApi.searchSkillsById(...args);
  };
}
