import { type FC } from 'react';
import { ConfirmButton, useNavbarLoader } from '~/shared/ui/index.ts';
import { useSaveProfileInterests } from '../api/useSaveProfileInterests.ts';
import { useProfileInterestsEditContext } from '../model/ProfileInterestsEditContext.tsx';

type Props = {
  disabled?: boolean;
};

export const SaveInterestsButton: FC<Props> = (props) => {
  const { disabled } = props;

  const { selectedInterestIds } = useProfileInterestsEditContext() ?? {};
  const { mutate: saveInterests, isPending } = useSaveProfileInterests();
  useNavbarLoader(isPending);

  return (
    <ConfirmButton
      onClick={() => {
        if (selectedInterestIds) {
          saveInterests(selectedInterestIds);
        }
      }}
      disabled={disabled || isPending}
    />
  );
};
