import { Typography } from '@mui/material';
import { type FC } from 'react';
import classes from './ShortInfo.module.scss';

type Props = {
  label: string;
  children: string;
};

const ShortInfo: FC<Props> = (props) => {
  const { label, children } = props;

  return (
    <div className={classes.root}>
      <Typography>{label}</Typography>
      <Typography color="gray">{children}</Typography>
    </div>
  );
};

export { ShortInfo };
export type { Props as ShortInfoProps };
