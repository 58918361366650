import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { InterestApi, TagDto } from '~/shared/api/index.ts';
import { time } from '~/utils/index.ts';
import { Interest } from '../model/Interest.ts';

export const loadInterestsQueryKeys = (interestName?: Interest['name']) => [
  'load interests',
  { interestName },
];

export function useLoadInterests(interestName?: Interest['name']) {
  const interestApi = useDi(InterestApi);

  return useQuery({
    queryKey: loadInterestsQueryKeys(interestName),
    queryFn: () => interestApi.searchInterests({ interestName }),
    staleTime: time(5, 'minutes'),
    select: mapResponse,
  });
}

const mapResponse = (
  dto: Awaited<ReturnType<InterestApi['searchInterests']>>
) => ({
  totalCount: dto.totalCount,
  interests: dto.items.map((dtoItem) => Mapper.map(TagDto, Interest, dtoItem)),
});
