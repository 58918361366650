import { useMemo } from 'react';

export function useParseInt(
  stringNumber: string | undefined
): number | undefined {
  return useMemo(() => {
    if (stringNumber == null) {
      return undefined;
    }
    const parsed = parseInt(stringNumber, 10);
    if (isNaN(parsed)) {
      return undefined;
    }
    return parsed;
  }, [stringNumber]);
}
