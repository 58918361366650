import { type FC, type PropsWithChildren, useMemo } from 'react';
import { Navigate, useMatch, useNavigate } from 'react-router-dom';
import { useMeetingDraftContext } from '~/entities/meeting/index.ts';
import {
  SlatReminder,
  TimeSelector,
  useCreateMeetingQuery,
  useUpdateMeetingQuery,
} from '~/features/meeting/index.ts';
import { CancelNavigationButton } from '~/features/navigation/index.ts';
import { Button } from '~/shared/molecules/Button/index.ts';
import { NavbarInput } from '~/shared/molecules/NavbarInput/index.ts';
import { ContentSlot, SlotPortal } from '~/shared/organisms/slots/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { Display, Navbar } from '~/shared/ui/index.ts';
import { MemberList } from '~/widgets/meeting/index.ts';
import { noop } from '../../utils/index.ts';
import classes from './MeetingDetailsDisplay.module.scss';

export const MeetingDetailsDisplay: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const {
    meetingDraft,
    meetingRoutes,
    removeMember,
    changeName,
    setDate,
    setEndTime,
  } = useMeetingDraftContext() ?? {};

  const { mutate: createMeeting, isPending: isMeetingCreating } =
    useCreateMeetingQuery({ meetingRoutes });

  const meetingEditMatch = useMatch(routes.meetings.meetingId().edit.url('/*'));

  const { mutate: saveEditedMeeting, isPending: isMeetingSaving } =
    useUpdateMeetingQuery({
      onSuccessCallback: () => {
        if (meetingRoutes) {
          if (meetingEditMatch) {
            navigate(routes.meetings.url());
          } else {
            navigate(meetingRoutes.details.meeting_created.url());
          }
        }
      },
      meetingId: meetingDraft?.meetingId,
    });

  const endTime = useMemo(() => {
    if (!meetingDraft || !meetingDraft.howLong) {
      return null;
    }
    return meetingDraft.when.add(meetingDraft.howLong);
  }, [meetingDraft?.when, meetingDraft?.howLong]);

  const meetingActions = useMemo(() => {
    if (!meetingDraft) {
      return {
        title: '',
        buttonText: '',
        onSave: noop,
      };
    }

    switch (meetingDraft.draftKind) {
      case 'new-meeting':
      case 'clone-meeting':
        return {
          title: 'Schedule Meeting',
          buttonText: 'Schedule',
          onSave: () => {
            createMeeting(meetingDraft);
          },
        };

      case 'edit-meeting':
        return {
          title: 'Edit Meeting',
          buttonText: 'Save Changes',
          onSave: () => saveEditedMeeting(meetingDraft),
        };
    }
  }, [createMeeting, meetingDraft, saveEditedMeeting]);

  if (!meetingDraft) {
    return <Navigate to={routes.meetings.url()} replace={true} />;
  }

  return (
    <>
      <Display
        documentTitle="new meeting details"
        animationVariant="card-effect"
      >
        <Navbar
          title={meetingActions.title}
          left={<CancelNavigationButton />}
          classes={{
            children: classes.navbarChildren,
          }}
          withBorderBottom
          partialLoading={isMeetingCreating || isMeetingSaving}
        >
          <NavbarInput
            placeholder="Meeting Title"
            value={meetingDraft.name}
            onChange={changeName}
          />
        </Navbar>

        <ContentSlot>
          <div className={classes.content}>
            <div>
              <TimeSelector
                label="Meeting Start"
                time={meetingDraft.when.utc()}
                onChange={(newTime) => {
                  if (newTime) {
                    setDate?.(newTime);
                  }
                }}
              />

              <TimeSelector
                label="Meeting End"
                time={endTime}
                minTime={meetingDraft.when.utc()}
                onChange={setEndTime}
              />
            </div>

            <SlatReminder
              duration={meetingDraft.remindBefore}
              onClick={() => {
                if (meetingRoutes) {
                  navigate(meetingRoutes.details.reminder.url());
                }
              }}
            />

            <MemberList
              members={meetingDraft.invitedMembers}
              mode="edit"
              onRemoveMemberClick={removeMember}
            />
          </div>
        </ContentSlot>

        {meetingDraft && (
          <SlotPortal slotName="footer">
            <div className={classes.footerNext}>
              <Button
                variant="contained"
                color="primary"
                onClick={meetingActions.onSave}
                sx={{ width: '100%' }}
              >
                {meetingActions.buttonText}
              </Button>
            </div>
          </SlotPortal>
        )}
      </Display>

      {children}
    </>
  );
};
