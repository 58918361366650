import {
  type CSSProperties,
  memo,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDocumentTitle } from 'usehooks-ts';
import { ReservedSlot, SlotProvider } from '../../organisms/slots';
import { DisplayContext, type DisplayContextType } from './DisplayContext.ts';
import classes from './Display.module.scss';

const ANIMATION_TIME_MILLISECONDS = 200;

type AnimationVariant = 'card-effect';

// todo: для экрана с аватаром пользователя можно сделать эффект, что при клике по нему (переход на экран фоток)
//  карточка с информацией под ним уедет вниз, а сам аватар как бы расшириться на весь экран

type Props = {
  animationVariant?: AnimationVariant;
  children: ReactNode;
  documentTitle: string;
};

const memoizedComponent = memo(Display);
export { memoizedComponent as Display };

function Display(props: Props) {
  const { children, animationVariant, documentTitle } = props;

  useDocumentTitle(documentTitle);
  const animation = useMemo(
    () => createAnimation(animationVariant),
    [animationVariant]
  );

  const [style, setStyle] = useState(() => animation.initial());
  const context = useMemo<DisplayContextType>(
    () => ({
      onClose: (callback) => {
        setStyle(animation.onClose());
        setTimeout(callback, ANIMATION_TIME_MILLISECONDS);
      },
    }),
    [animation]
  );

  useEffect(() => {
    setStyle(animation.onOpen());

    return () => {
      setStyle(animation.onClose());
    };
  }, [animation]);

  return (
    <DisplayContext.Provider value={context}>
      <SlotProvider>
        <div className={classes.root} style={style}>
          <ReservedSlot className={classes.navbar} slotName="navbar" />

          <div className={classes.contentContainer}>
            <ReservedSlot className={classes.content} slotName="content" />
          </div>

          <ReservedSlot className={classes.footer} slotName="footer" />
        </div>

        {children}
      </SlotProvider>
    </DisplayContext.Provider>
  );
}

interface Animation {
  initial: () => CSSProperties;
  onOpen: () => CSSProperties;
  onClose: () => CSSProperties;
}

function createAnimation(animationVariant?: AnimationVariant): Animation {
  switch (animationVariant) {
    case 'card-effect': {
      function makeStyle(css: CSSProperties): CSSProperties {
        return {
          ...css,
          ['--animation-time' as keyof CSSProperties]: `${ANIMATION_TIME_MILLISECONDS}ms`,
        };
      }

      return {
        initial: () => makeStyle({ right: '-100%' }),
        onOpen: () => makeStyle({ right: 0 }),
        onClose: () => makeStyle({ right: '-100%' }),
      };
    }

    default:
      return {
        initial: () => ({}),
        onOpen: () => ({}),
        onClose: () => ({}),
      };
  }
}
