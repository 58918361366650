import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const ProfileFilledIcon = (props: Props) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6096 12C15.4669 12 17.712 9.8 17.712 7C17.712 4.2 15.4669 2 12.6096 2C9.75234 2 7.50732 4.2 7.50732 7C7.50732 9.8 9.75234 12 12.6096 12ZM22.1 20.8C20.7734 15.7 15.4669 12.5 10.2626 13.8C6.79299 14.7 4.03773 17.3 3.11931 20.8C3.01727 21.3 3.32341 21.9 3.93569 22C4.03773 22 4.13978 22 4.13978 22H21.0795C21.6918 22 22.1 21.6 22.1 21C22.1 20.9 22.1 20.8 22.1 20.8Z"
        fill={color}
      />
    </svg>
  );
};

export type { Props as ProfileFilledIconProps };
export { ProfileFilledIcon };
