import { type FC, type PropsWithChildren, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useBlockedUsersQuery } from '~/entities/block-list/index.ts';
import {
  type ColleagueProfile,
  useUserById,
} from '~/entities/colleague/index.ts';
import { MeetingDraftContextProvider } from '~/entities/meeting/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { Photo } from '~/shared/atoms/Photo/index.ts';
import { Button } from '~/shared/molecules/Button/index.ts';
import { useParseInt } from '~/shared/numbers/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { FooterTabs } from '~/shared/templates/FooterTabs.tsx';
import { OptionsIconButton } from '~/shared/templates/IconButtons/OptionsIconButton.tsx';
import { Display, Navbar, useDisplayLoader } from '~/shared/ui/index.ts';
import { ProfileMainInfo } from '~/widgets/profile/index.ts';
import classes from '~/widgets/profile/ui/CurrentProfileInformation.module.scss';
import { ColleagueProfileOptions } from './ColleagueProfileOptions.tsx';

export const ContactProfileDisplay: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const navigate = useNavigate();
  const { userId: stringUserId } = useParams<{ userId: string | undefined }>();
  const userId = useParseInt(stringUserId);

  const { data: colleague, isLoading: colleagueIsLoading } =
    useUserById(userId);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useDisplayLoader(colleagueIsLoading);

  const { data: blockedUsers, isLoading } = useBlockedUsersQuery();

  const blockedUserIdsSet = useMemo(
    () =>
      new Set<ColleagueProfile['userId'] | undefined>(
        blockedUsers?.map((user) => user.userId)
      ),
    [blockedUsers]
  );
  const isUserBlocked = blockedUserIdsSet.has(colleague?.userId);

  return (
    <>
      <MeetingDraftContextProvider
        parentRoute={routes.contacts.userId(stringUserId).new_meeting}
      >
        <Display
          documentTitle={`${colleague?.name} profile`}
          animationVariant="card-effect"
        >
          <Navbar
            title={!colleague ? '' : colleague.firstName}
            left={<BackIconNavigationButton />}
            right={
              <OptionsIconButton
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              />
            }
            partialLoading={isLoading}
          />

          <ContentSlot className={classes.root}>
            <Photo
              avatarUrl={colleague?.avatar.url}
              onClick={() =>
                navigate(routes.contacts.userId(stringUserId).avatars.url())
              }
            />

            <ProfileMainInfo
              userId={userId}
              userRoutes={routes.contacts.userId(stringUserId)}
            >
              {!isUserBlocked && (
                <div className={classes.inviteControls}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigate(routes.chat.userId(stringUserId).url());
                    }}
                  >
                    Chat
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!stringUserId}
                    onClick={() => {
                      if (stringUserId) {
                        navigate(
                          routes.contacts.userId(stringUserId).new_meeting.url({
                            colleagueIds: [stringUserId],
                          })
                        );
                      }
                    }}
                  >
                    Invite
                  </Button>
                </div>
              )}
            </ProfileMainInfo>

            <ColleagueProfileOptions
              anchorEl={anchorEl}
              onClose={() => {
                setAnchorEl(null);
              }}
              userId={userId!}
            />
          </ContentSlot>

          <FooterTabs />
        </Display>

        {children}
      </MeetingDraftContextProvider>
    </>
  );
};
