import { type CommonIconProps } from './CommonProps';

type Props = Omit<CommonIconProps, 'color'> & {
  stroke?: string;
  fill?: string;
};

const NotFoundIcon = (props: Props) => {
  const { height = 97, stroke = '#ADB5BD', fill = '#E9ECEF', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5776_7231)">
        <path
          d="M87.0871 75.0749C87.0871 55.9729 96.9619 42.8554 96.9619 33.651C96.9619 24.4466 91.6814 5.11539 49.4235 5.11539C7.16569 5.11539 0.961914 31.1213 0.961914 41.2453C0.961914 88.6537 87.0871 108.904 87.0871 75.0749Z"
          fill={fill}
        />
        <path
          d="M81.833 40.5C79.6277 40.5 77.833 38.7053 77.833 36.5C77.833 34.2947 79.6277 32.5 81.833 32.5C84.0384 32.5 85.833 34.2947 85.833 36.5C85.833 38.7053 84.0384 40.5 81.833 40.5ZM81.833 35.1667C81.097 35.1667 80.4997 35.7667 80.4997 36.5C80.4997 37.2333 81.097 37.8333 81.833 37.8333C82.569 37.8333 83.1664 37.2333 83.1664 36.5C83.1664 35.7667 82.569 35.1667 81.833 35.1667Z"
          fill={stroke}
        />
        <path
          d="M49.833 61.8333C49.833 55.892 54.1636 50.9907 59.833 50.036V23.8333C59.833 20.8867 57.4463 18.5 54.4996 18.5H23.1663C20.2197 18.5 17.833 20.8867 17.833 23.8333V65.1667C17.833 68.1133 20.2197 70.5 23.1663 70.5H53.5663C51.2756 68.3133 49.833 65.2493 49.833 61.8333Z"
          fill="white"
        />
        <path
          d="M23.833 65.1667V23.8333C23.833 20.8867 26.2197 18.5 29.1663 18.5H23.1663C20.2197 18.5 17.833 20.8867 17.833 23.8333V65.1667C17.833 68.1133 20.2197 70.5 23.1663 70.5H29.1663C26.2197 70.5 23.833 68.1133 23.833 65.1667Z"
          fill={fill}
        />
        <path
          d="M61.8333 75.1667C54.4813 75.1667 48.5 69.1853 48.5 61.8333C48.5 54.4813 54.4813 48.5 61.8333 48.5C69.1853 48.5 75.1667 54.4813 75.1667 61.8333C75.1667 69.1853 69.1853 75.1667 61.8333 75.1667ZM61.8333 52.5C56.6867 52.5 52.5 56.6867 52.5 61.8333C52.5 66.98 56.6867 71.1667 61.8333 71.1667C66.98 71.1667 71.1667 66.98 71.1667 61.8333C71.1667 56.6867 66.98 52.5 61.8333 52.5Z"
          fill={stroke}
        />
        <path
          d="M71.2473 68.4193L68.4189 71.2478L77.0854 79.9139L79.9138 77.0854L71.2473 68.4193Z"
          fill={stroke}
        />
        <path
          d="M44.58 72.5H23.8333C19.788 72.5 16.5 69.212 16.5 65.1667V23.8333C16.5 19.788 19.788 16.5 23.8333 16.5H54.5C58.5453 16.5 61.8333 19.788 61.8333 23.8333V42.6067H57.8333V23.8333C57.8333 21.996 56.3373 20.5 54.5 20.5H23.8333C21.996 20.5 20.5 21.996 20.5 23.8333V65.1667C20.5 67.004 21.996 68.5 23.8333 68.5H44.58V72.5Z"
          fill={stroke}
        />
        <path
          d="M51.8333 37.8333H26.5V41.8333H51.8333V37.8333Z"
          fill={stroke}
        />
        <path d="M41.1667 48.5H26.5V52.5H41.1667V48.5Z" fill={stroke} />
        <path d="M38.5 27.1667H26.5V31.1667H38.5V27.1667Z" fill={stroke} />
      </g>
      <defs>
        <clipPath id="clip0_5776_7231">
          <rect
            width="96"
            height="96"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export type { Props as NotFoundIconProps };
export { NotFoundIcon };
