import { type TouchEvent, useCallback, useRef, useState } from 'react';

export const useCardTouch = () => {
  const startPositionRef = useRef({ x: 0 });
  const [translateX, setTranslateX] = useState(0);

  const onTouchStart = useCallback((event: TouchEvent) => {
    const { clientX } = event.touches[0];
    startPositionRef.current.x = clientX;
  }, []);

  const onTouchMove = useCallback((event: TouchEvent) => {
    const { clientX } = event.touches[0];
    const diffX = clientX - startPositionRef.current.x;
    setTranslateX(translateX + diffX);
  }, []);

  const reset = useCallback(() => {
    startPositionRef.current.x = 0;
    setTranslateX(0);
  }, []);

  return {
    onTouchStart,
    onTouchMove,
    reset,

    translateX,
  };
};
