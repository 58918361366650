import { Divider, Typography } from '@mui/material';
import { Section } from '~/pages/profile/Section';
import { ContentCollapser } from '~/shared/molecules/ContentCollapser';
import classes from './About.module.scss';

type Props = {
  bio: string | undefined;
};

const lineHeight = 20; // px
const maxLines = 7;

export const About = (props: Props) => {
  const { bio } = props;

  if (!bio) {
    return null;
  }

  return (
    <>
      <Divider />

      <Section>
        <Typography variant="h3" className={classes.title}>
          About Information
        </Typography>

        <div className={classes.content}>
          <ContentCollapser lineHeight={lineHeight} maxLines={maxLines}>
            {({ forwardRef, toggle }) => (
              <Typography ref={forwardRef} variant="body1" onClick={toggle}>
                {bio}
              </Typography>
            )}
          </ContentCollapser>
        </div>
      </Section>
    </>
  );
};
