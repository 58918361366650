import { ApiBase } from '../ApiBase.ts';
import { type InterestFakeApi } from './InterestFakeApi.ts';
import { type InterestRealApi } from './InterestRealApi.ts';

export class InterestApi extends ApiBase {
  constructor(
    private realApi: InterestRealApi,
    private fakeApi: InterestFakeApi
  ) {
    super();
  }

  createInterest: InterestRealApi['createInterest'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.createInterest(...args);
    }
    return this.realApi.createInterest(...args);
  };

  searchInterests: InterestRealApi['searchInterests'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.searchInterests(...args);
    }
    return this.realApi.searchInterests(...args);
  };

  searchInterestsById: InterestRealApi['searchInterestsById'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.searchInterestsById(...args);
    }
    return this.realApi.searchInterestsById(...args);
  };
}
