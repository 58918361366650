import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { type FC, useMemo } from 'react';
import { IconTwoColumnRow } from '~/shared/ui';
import { type Profile } from '../model/Profile.ts';

dayjs.extend(relativeTime);

type Props = {
  workExperienceSince: Profile['workExperienceSince'] | undefined;
  isLoading?: boolean;
};

export const WorkExperienceTwoColumnRow: FC<Props> = (props) => {
  const { workExperienceSince, isLoading } = props;

  const timeDiff = useMemo(
    () => workExperienceSince?.fromNow(true),
    [workExperienceSince]
  );

  if (timeDiff == null || isLoading) {
    return null;
  }

  return (
    <IconTwoColumnRow
      icon="clockFilled"
      label="Total work experience:"
      content={timeDiff}
      isLoading={isLoading}
    />
  );
};
