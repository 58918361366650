import { faker } from '@faker-js/faker';
import { type FakeApiDatabase } from '../../fakeApiDatabase/index.ts';
import { ApiError } from '../ApiError.ts';
import { ApiResponseStatus } from '../ApiResponseStatus.ts';
import type { TagDto } from '../index.ts';
import { type DepartmentRealApi } from './DepartmentRealApi.ts';

export const DEPARTMENTS_PAGE_SIZE = 25;

export class DepartmentFakeApi {
  constructor(readonly db: FakeApiDatabase) {}

  createDepartment: DepartmentRealApi['createDepartment'] = async (
    departmentName
  ) => {
    const departments = await this.db.department.getAll();
    const existedDepartment = departments.find(
      ({ name }) =>
        name.toLocaleLowerCase() === departmentName.toLocaleLowerCase()
    );

    if (existedDepartment) {
      throw new ApiError({
        status: ApiResponseStatus.BadRequest,
        error: 'department with such name already exist',
      });
    }

    const newDepartment: TagDto = {
      id: faker.number.int(),
      name: departmentName,
      userByCount: 0,
    };
    await this.db.department.insert(newDepartment.id, newDepartment);
    return {
      createdTag: newDepartment,
    };
  };

  searchDepartments: DepartmentRealApi['searchDepartments'] = async (
    search
  ) => {
    const { departmentName, page, pageSize } = search;
    const departments = await this.db.department.getAll();

    const lowerDepartmentName = departmentName?.toLocaleLowerCase();

    const normalizedSize = pageSize ?? DEPARTMENTS_PAGE_SIZE;
    const startIndex = (page ?? 0) * normalizedSize;
    const endIndex = startIndex + normalizedSize;
    const filtered = departments.filter((department) => {
      if (
        lowerDepartmentName &&
        !department.name.toLocaleLowerCase().includes(lowerDepartmentName)
      ) {
        return false;
      }
      return true;
    });
    const batch = filtered.slice(startIndex, endIndex);

    return {
      totalCount: filtered.length,
      items: batch,
    };
  };

  searchDepartmentsById: DepartmentRealApi['searchDepartmentsById'] = async (
    departmentIds
  ) => {
    const departments = await this.db.department.getAll();

    const set = new Set(departmentIds);
    const filtered = departments.filter((department) => set.has(department.id));

    return {
      totalCount: filtered.length,
      items: filtered,
    };
  };
}
