import { type RouteObject } from 'react-router-dom';
import { routes } from '~/shared/routing/index.ts';
import { PetsDisplay } from './ui/PetsDisplay.tsx';

export const petsRouteObject: RouteObject[] = [
  {
    path: routes.pets.relativeUrl(),
    children: [
      {
        index: true,
        element: <PetsDisplay />,
      },
    ],
  },
];
