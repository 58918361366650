import { Typography } from '@mui/material';
import { TimeField, type TimeFieldProps } from '@mui/x-date-pickers/TimeField';
import dayjs, { type Dayjs } from 'dayjs';
import { useCallback, useRef } from 'react';
import { Slat } from '~/shared/molecules/Slat/index.ts';
import { noop } from '../../../utils/index.ts';

type Props = Pick<TimeFieldProps<Dayjs>, 'minTime'> & {
  label: string;
  time: Dayjs | null;
  onChange?: (newTime: Dayjs | null) => void;
  disabled?: boolean;
};

export const TimeSelector = (props: Props) => {
  const { label, time, onChange, minTime, disabled } = props;

  const newTimeRef = useRef(time);

  const handleChange = useCallback(
    (newTime: Dayjs | null) => {
      newTimeRef.current = newTime;

      if (newTime && minTime) {
        if (newTime.isBefore(minTime)) {
          newTimeRef.current = dayjs(minTime)
            .hour(newTime.hour())
            .second(newTime.second())
            .millisecond(newTime.millisecond());
        }
      }

      onChange?.(newTimeRef.current);
    },
    [minTime, onChange]
  );

  return (
    <TimeField
      disabled={disabled}
      shouldDisableTime={(timeValue, clockType) => {
        if (!minTime) {
          return false;
        }

        if (clockType === 'hours') {
          const shouldDisable = timeValue.hour() < minTime.hour();
          return shouldDisable;
        }

        if (!newTimeRef.current) {
          return false;
        }

        if (clockType === 'minutes') {
          const timeForMinutes = newTimeRef.current.minute(timeValue.minute());
          const shouldDisable = timeForMinutes.isBefore(minTime);
          return shouldDisable;
        }

        const timeForSeconds = newTimeRef.current.second(timeValue.second());
        const shouldDisable = timeForSeconds.isBefore(minTime);
        return shouldDisable;
      }}
      value={time}
      onChange={handleChange}
      ampm={false}
      disableIgnoringDatePartForTimeValidation={true}
      slots={{
        textField: ({ onClick }) => (
          <Slat
            onClick={onClick ?? noop}
            iconProps={{
              variant: 'clocks',
              color: 'primary',
            }}
            rightChildren={
              <Typography>{time?.format('H:mm') ?? '--:--'}</Typography>
            }
          >
            <Typography>{label}</Typography>
          </Slat>
        ),
      }}
    />
  );
};
