import { createStore } from '~/shared/store/index.ts';

export const useFakeApiConfig = createStore(
  {
    useFakeApi: false,
  },
  'dev-config'
);

export const useFakeApi = () => useFakeApiConfig((config) => config.useFakeApi);

export const toggleUseMirageApi = () => {
  useFakeApiConfig.setState((prevState) => ({
    useFakeApi: !prevState.useFakeApi,
  }));

  // todo: use real or fake api on method access with Proxy ? to avoid page reload
  window.location.reload();
};
