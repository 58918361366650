/**
 * it filters undefined keys from query key to prevent cases, when query cannot be invalidated because of them
 * @example
 * queryKeys(['load data', { someId: 2 }]) // ['load data', { someId: 2 }]
 *
 * @example
 * queryKeys(['load data', { someId: undefined }]) // ['load data']
 *
 * @example
 * queryKeys(['load data', { someId: undefined, prop: 'c', anotherId: null }]) // ['load data', { prop: 'c' }]
 * */
export function queryKeys(...keys: unknown[]) {
  const definedKeys = [] as unknown[];

  for (const key of keys) {
    if (key == null) {
      continue;
    }

    if (typeof key === 'string' || typeof key === 'number') {
      definedKeys.push(key);
      continue;
    }

    if (Array.isArray(key)) {
      throw new Error('Dayton: not implemented query keys for array key');
    }

    if (typeof key === 'object') {
      const objectKey: Record<string, unknown> = {};

      for (const [propertyName, propertyValue] of Object.entries(key)) {
        if (propertyValue != null) {
          objectKey[propertyName] = propertyValue;
        }
      }

      if (Object.keys(objectKey).length > 0) {
        definedKeys.push(objectKey);
      }
      continue;
    }

    throw new Error(
      `Dayton: not implemented handling of query key type: ${key}`
    );
  }

  return definedKeys;
}
