import { Mapper } from '@tomas-light/mapper-js';
import { useMemo, useState } from 'react';
import {
  ContactProfile,
  ContactsList,
  useContactsQueryByName,
} from '~/entities/contact/index.ts';
import {
  MeetingMember,
  useMeetingDraftContext,
} from '~/entities/meeting/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { HorizontalUserList } from '~/shared/components/index.ts';
import { NavbarInput } from '~/shared/molecules/NavbarInput/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { Navbar } from '~/shared/ui/index.ts';
import classes from './MeetingAddMembersDisplay.module.scss';

export const MeetingAddMembersDisplay = () => {
  const { meetingDraft, inviteMember, removeMember } =
    useMeetingDraftContext() ?? {};

  const selectedMembers = useMemo<Set<MeetingMember['userId']>>(() => {
    if (!meetingDraft) {
      return new Set();
    }
    return new Set(meetingDraft.invitedMembers.map((member) => member.userId));
  }, [meetingDraft]);

  const [searchedContactName, setSearchedContactName] = useState('');

  const { data: contacts, isLoading: contactsAreLoading } =
    useContactsQueryByName(searchedContactName);

  const membersToInvite = useMemo(() => {
    return contacts?.map((contact) =>
      Mapper.map(ContactProfile, MeetingMember, contact)
    );
  }, [contacts]);

  if (!meetingDraft) {
    return null;
  }

  return (
    <>
      <Navbar
        title="Add"
        left={<BackIconNavigationButton />}
        classes={{
          children: classes.navbar,
        }}
        withBorderBottom
        partialLoading={contactsAreLoading}
      >
        <NavbarInput
          placeholder="Search"
          icon={<Icon variant="search" />}
          value={searchedContactName}
          onChange={setSearchedContactName}
        />

        <HorizontalUserList
          users={meetingDraft.invitedMembers}
          onRemove={removeMember}
        />
      </Navbar>

      <ContentSlot>
        <ContactsList
          interactible
          contacts={membersToInvite}
          selectedContacts={selectedMembers}
          onClick={(member) => {
            const checked = selectedMembers.has(member.userId);
            if (checked) {
              removeMember?.(member);
            } else {
              inviteMember?.(member);
            }
          }}
        />
      </ContentSlot>
    </>
  );
};
