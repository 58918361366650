import type { DialogDto } from '../DialogDto.ts';
import type { IncomeMessageDto } from '../IncomeMessageDto.ts';
import type { IncomeMessageInGroupDto } from '../IncomeMessageInGroupDto.ts';
import type { MeetingDto } from '../meeting/MeetingDto.ts';
import type { MessageDto } from '../MessageDto.ts';
import type { MessageInGroupStatusNotificationDto } from '../MessageInGroupStatusNotificationDto.ts';
import type { MessageSentNotificationDto } from '../MessageSentNotificationDto.ts';
import type { MessageStatusNotificationDto } from '../MessageStatusNotificationDto.ts';
import type { NewGroupChatNotificationDto } from '../NewGroupChatNotificationDto.ts';
import type { PaginatedRequestDto } from '../PaginatedRequestDto.ts';
import type { PaginationDto } from '../PaginationDto.ts';
import type { UserForCarouselDto } from '../profile/UserForCarouselDto.ts';
import type { Search } from '../Search.ts';

export abstract class ChatSignal {
  abstract start(authorizationToken: string): Promise<void>;
  abstract stop(): Promise<void>;

  // ChatMethodNamesEnum.sendMessage
  abstract sendMessage(dto: {
    interlocutorId: number;
    message: string;
    guid: string;
  }): Promise<void>;

  // ChatMethodNamesEnum.sendMessageToGroup
  abstract sendMessageToGroup(dto: {
    groupId: number;
    message: string;
  }): Promise<void>;

  // ChatMethodNamesEnum.getDialogs
  abstract getDialogs(
    paginationDto: Search<'searchString'>
  ): Promise<PaginationDto<DialogDto>>;

  // ChatMethodNamesEnum.getDialogById
  abstract getDialogById(
    dialogId: DialogDto['dialogId']
  ): Promise<DialogDto | null>;

  // ChatMethodNamesEnum.getDialogByUserId
  abstract getDialogByUserId(
    userId: UserForCarouselDto['userId']
  ): Promise<DialogDto | null>;

  // ChatMethodNamesEnum.getDialogByGroupId
  abstract getDialogByGroupId(
    groupId: DialogDto['dialogId']
  ): Promise<DialogDto | null>;

  // ChatMethodNamesEnum.getDialogByMeetingId
  abstract getDialogByMeetingId(
    meetingId: MeetingDto['meetingId']
  ): Promise<DialogDto | null>;

  // ChatMethodNamesEnum.getDialogMessages
  abstract getDialogMessages(
    dto: Omit<PaginatedRequestDto, 'page'> & {
      dialogId: number;
      /** message before we want to load other messages. so if we have messages [5,6,7,8] and you pass message id 5, we wil load messages [1,2,3,4]*/
      earliestMessageId: number | null;
    }
  ): Promise<PaginationDto<MessageDto>>;

  // ChatMethodNamesEnum.notifyThatMessageWasReceived
  abstract notifyThatMessageWasReceived(dto: {
    messageId: number;
    interlocutorId: number;
  }): Promise<void>;

  // ChatMethodNamesEnum.notifyThatMessageWasViewed
  abstract notifyThatMessageWasViewed(dto: {
    messageId: number;
    interlocutorId: number;
  }): Promise<void>;

  // ChatMethodNamesEnum.notifyThatMessageInGroupWasViewed
  abstract notifyThatMessageInGroupWasViewed(dto: {
    messageId: number;
    messageAuthorId: number;
    groupId: number;
  }): Promise<void>;

  // ChatMethodNamesEnum.createGroupChat
  abstract createGroupChat(dto: {
    groupName: string;
    memberIds: number[];
  }): Promise<void>;

  abstract onNewMessage(callback: (dto: IncomeMessageDto) => void): void;
  abstract onNewGroupMessage(
    callback: (dto: IncomeMessageInGroupDto) => void
  ): void;
  abstract onUpdateMessageStatus(
    callback: (dto: MessageStatusNotificationDto) => void
  ): void;
  abstract onUpdateMessageStatusInGroup(
    callback: (dto: MessageInGroupStatusNotificationDto) => void
  ): void;
  abstract onNewGroupChatCreated(
    callback: (dto: NewGroupChatNotificationDto) => void
  ): void;
  abstract onMessageWasSent(
    callback: (dto: MessageSentNotificationDto) => void
  ): void;
}
