import { FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import MUICheckbox, { type CheckboxProps } from '@mui/material/Checkbox';
import { type ForwardedRef, forwardRef, type ReactElement } from 'react';

type Props = CheckboxProps & {
  label: ReactElement | string;
  isError?: boolean;
  helpText?: string;
  onChangeValue?: (checked: boolean) => void;
};

const component = forwardRef(Checkbox);
export { component as Checkbox };

function Checkbox(props: Props, ref: ForwardedRef<HTMLInputElement>) {
  const {
    label,
    isError,
    helpText,
    onChange,
    onChangeValue,
    ...checkboxProps
  } = props;

  return (
    <FormControl error={isError}>
      <FormControlLabel
        control={
          <MUICheckbox
            inputRef={ref}
            onChange={(event, checked) => {
              onChange?.(event, checked);
              onChangeValue?.(checked);
            }}
            {...checkboxProps}
          />
        }
        label={label}
      />
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
}
