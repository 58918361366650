import type { NewMatchNotificationDto } from '../NewMatchNotificationDto.ts';

export abstract class MatchSignal {
  abstract start(authorizationToken: string): Promise<void>;
  abstract stop(): Promise<void>;

  abstract notifyThatMatchNotificationWasReceived(dto: {
    matchId: number;
  }): Promise<void>;

  abstract onNewMatch(callback: (dto: NewMatchNotificationDto) => void): void;
}
