import { createStore } from '~/shared/store';

type AuthConfig = {
  authorizationToken: string | null;
};

const useAuthConfig = createStore<AuthConfig>(
  {
    authorizationToken: null,
  },
  'auth-config'
);

export const useAuthorizationToken = () =>
  useAuthConfig((config) => config.authorizationToken);

export const setAuthorizationTokenToConfig = (authorizationToken: string) =>
  useAuthConfig.setState(() => ({
    authorizationToken,
  }));

export const removeAuthorizationTokenFromConfig = () =>
  useAuthConfig.setState(() => ({
    authorizationToken: null,
  }));
