import { RealApiBase } from '../RealApiBase.ts';
import { apiRoutes, type Search, type TagDto } from '../index.ts';
import type { PaginationDto } from '../PaginationDto.ts';

export class InterestRealApi extends RealApiBase {
  createInterest = (interestName: string) => {
    return this.post<{ createdTag: TagDto }>(
      apiRoutes.tags.interest.create.url(),
      {
        name: interestName,
      }
    );
  };

  searchInterests = (search: Search<'interestName'>) => {
    return this.post<PaginationDto<TagDto>>(
      apiRoutes.tags.interest.search.url(),
      {
        query: search.interestName,
        page: search.page,
        pageSize: search.pageSize,
      }
    );
  };

  searchInterestsById = (interestIds: number[]) => {
    return this.post<PaginationDto<TagDto>>(
      apiRoutes.tags.interest.search_by_ids.url(),
      {
        tagIds: interestIds,
      }
    );
  };
}
