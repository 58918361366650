import { MapFunction, Mapper } from '@tomas-light/mapper-js';
import { Profile } from '~/entities/profile/crossExports.ts';
import { UserForCarouselDto } from '~/shared/api/index.ts';
import { ColleagueProfile } from './ColleagueProfile.ts';

export const colleaguesMappingProfile = [
  new MapFunction(
    UserForCarouselDto,
    ColleagueProfile,
    mapUserForCarouselDtoToColleagueProfile
  ),
  new MapFunction(ColleagueProfile, ColleagueProfile, mapProfileLikeToProfile),
];

function mapUserForCarouselDtoToColleagueProfile(
  dto: UserForCarouselDto
): ColleagueProfile {
  const profile = Mapper.map(UserForCarouselDto, Profile, dto);

  return new ColleagueProfile({
    ...profile,
    liked: false,
    disliked: false,
  });
}

function mapProfileLikeToProfile(
  profileLike: ColleagueProfile
): ColleagueProfile {
  return new ColleagueProfile({
    ...profileLike,
    liked: false,
    disliked: false,
  });
}
