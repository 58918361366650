import { Typography } from '@mui/material';
import { type FC, type PropsWithChildren, useMemo } from 'react';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { BackIconNavigationButton } from '~/features/navigation/index.ts';
import { Icon } from '~/shared/atoms/icons/index.ts';
import { ContentSlot } from '~/shared/organisms/slots/index.ts';
import { Navbar, useNavbarLoader } from '~/shared/ui/index.ts';
import classes from './LevelPage.module.scss';

export const LevelPage: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const { data: currentUser, isLoading: isCurrentUserLoading } =
    useCurrentUser();

  useNavbarLoader(isCurrentUserLoading);

  const swipeLevel = useMemo(() => {
    if (!currentUser?.levelableSkills.length) {
      return;
    }

    return currentUser.levelableSkills.find(
      (level) => level.name === 'swipes-count-power'
    );
  }, [currentUser?.levelableSkills]);

  const coinsLevel = useMemo(() => {
    if (!currentUser?.levelableSkills.length) {
      return;
    }

    return currentUser.levelableSkills.find(
      (level) => level.name === 'receive-coin-after-each-swipe'
    );
  }, [currentUser?.levelableSkills]);

  return (
    <>
      <Navbar
        title="Level"
        left={<BackIconNavigationButton />}
        withBorderBottom
      />

      <ContentSlot className={classes.root}>
        {swipeLevel && (
          <div className={classes.level}>
            <Icon variant="swipe" />
            <Typography>Swipe level: {swipeLevel.level}</Typography>
          </div>
        )}

        {coinsLevel && (
          <div className={classes.level}>
            <Icon variant="coin" />
            <Typography>Coins level: {coinsLevel.level}</Typography>
          </div>
        )}

        {!swipeLevel && !coinsLevel && !isCurrentUserLoading && (
          <Typography>There is no level information</Typography>
        )}
      </ContentSlot>

      {children}
    </>
  );
};
