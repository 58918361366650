import { type CommonIconProps } from '~/shared/atoms/icons/CommonProps';
import { useThemeColor } from '~/shared/useThemeColor';

const ChevronLeftIcon = (props: CommonIconProps) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  const themeColor = useThemeColor(color);

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31169 12.8358L14.2338 18.6866C14.6494 19.1045 15.2727 19.1045 15.6883 18.6866C16.1039 18.2687 16.1039 17.6418 15.6883 17.2239L10.5974 12L15.6883 6.77612C16.1039 6.35821 16.1039 5.73134 15.6883 5.31343C15.4805 5.10448 15.2727 5 14.961 5C14.6494 5 14.4416 5.10448 14.2338 5.31343L8.31169 11.1642C7.8961 11.6866 7.8961 12.3134 8.31169 12.8358C8.31169 12.7313 8.31169 12.7313 8.31169 12.8358Z"
        fill={themeColor}
      />
    </svg>
  );
};

export { ChevronLeftIcon };
