import { RealApiBase } from '../RealApiBase.ts';
import { apiRoutes } from '../apiRoutes.ts';

export class TelegramRealApi extends RealApiBase {
  login = (telegramHash: string) => {
    return this.post<{
      token: string;
      // tonLink: string;
    }>(apiRoutes.telegram.telegram_entry.url(), {
      token: telegramHash,
    });
  };

  /** It returns link to redirect to TON.
   * User should confirm connecting to its TON wallet */
  connectTonWallet = () => {
    return this.post<string>(apiRoutes.telegram.connect_tg_wallet.url());
  };

  isTonWalletConnected = () => {
    return this.post<boolean>(apiRoutes.telegram.is_tg_wallet_connected.url());
  };
}
