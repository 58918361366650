import { Delete, Restore } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import { type IdAndName } from '~/commonModels/IdAndName';
import classes from './TagListView.module.scss';

export interface TagWithErrors extends IdAndName {
  modified?: boolean;
  removable?: boolean;
}

type Props<Tag extends TagWithErrors> = {
  list: undefined | Tag[];
  removed: Set<Tag['id']>;
  added: Set<Tag['id']>;
  onRestore?: (tagId: Tag['id']) => void;
  onRemove?: (tagId: Tag['id']) => void;
};

export function TagListView<Tag extends TagWithErrors>(props: Props<Tag>) {
  const { list, removed, added, onRemove, onRestore } = props;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {list?.map((tag) => (
          <Tooltip key={tag.id} title={tag.name}>
            <Chip
              label={tag.name}
              variant="outlined"
              onDelete={() =>
                removed.has(tag.id) ? onRestore?.(tag.id) : onRemove?.(tag.id)
              }
              deleteIcon={
                removed.has(tag.id) ? (
                  <Restore onClick={() => onRestore?.(tag.id)} />
                ) : (
                  <Delete onClick={() => onRemove?.(tag.id)} />
                )
              }
              className={clsx({
                [classes.removed]: removed.has(tag.id),
                [classes.added]: added.has(tag.id),
              })}
            />
          </Tooltip>
        ))}
      </div>
    </div>
  );
}

export { type Props as TagListViewProps };
