import { createLoaderStore, type Loader } from '../../createLoaderStore.ts';

const {
  useLoaderStore: useDisplayLoaderStore,
  useActiveLoader: useActiveDisplayLoader,
  addLoader: addDisplayLoader,
  removeLoader: removeDisplayLoader,
} = createLoaderStore();

export {
  useDisplayLoaderStore,
  useActiveDisplayLoader,
  addDisplayLoader,
  removeDisplayLoader,
  type Loader,
};
