import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { useCurrentUser } from '~/entities/profile/crossExports.ts';
import { DialogDto } from '~/shared/api/DialogDto.ts';
import { ChatSignal, queryKeys } from '~/shared/api/index.ts';
import { time } from '../../../utils/index.ts';
import { PrivateChat } from '../model/PrivateChat.ts';
import { useLoadChatImageUrls } from './useLoadChatImageUrls.ts';

export const chatByUserIdQueryKey = (userId?: PrivateChat['interlocutorId']) =>
  queryKeys('load private chat with user', { userId });

export function useChatByUserIdQuery(userId?: PrivateChat['interlocutorId']) {
  const chatSignal = useDi(ChatSignal);
  const loadChatImageUrls = useLoadChatImageUrls();

  const { data: myProfile } = useCurrentUser();

  return useQuery({
    enabled: userId != null && myProfile != null,
    queryKey: chatByUserIdQueryKey(userId),
    queryFn: async () => {
      if (!myProfile) {
        throw new Error('my profile was not loaded');
      }
      if (!userId) {
        throw new Error('there is no user id');
      }

      const dialogDto = await chatSignal.getDialogByUserId(userId);
      if (!dialogDto) {
        throw new Error('chat not found');
      }

      const privateChat = Mapper.map(DialogDto, PrivateChat, dialogDto);
      if (privateChat.chatId === myProfile.userId) {
        privateChat.name = 'Избранное';
      }

      await loadChatImageUrls([privateChat]);

      return privateChat;
    },
    staleTime: time(5, 'minutes'),
  });
}
