import { ListItemButton, Paper } from '@mui/material';
import clsx from 'clsx';
import {
  memo,
  type MouseEvent as ReactMouseEvent,
  type ReactNode,
} from 'react';
import { Icon, type IconProps, type IconVariant } from '~/shared/atoms/icons';
import classes from './Slat.module.scss';

type Props<Variant extends IconVariant> = {
  variant?: 'default' | 'nested-slat';
  iconProps: IconProps<Variant>;
  children: ReactNode;
  rightChildren?: ReactNode;
  onClick?: (event: ReactMouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
};

function Slat<Variant extends IconVariant>(props: Props<Variant>) {
  const {
    variant = 'default',
    iconProps,
    children,
    rightChildren,
    ...rest
  } = props;

  return (
    <Paper variant="outlined" square>
      <ListItemButton
        className={classes.root}
        {...rest}
        sx={{
          padding: '16px 24px',
          display: 'grid',
          ...(variant === 'nested-slat'
            ? {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              }
            : {}),
        }}
      >
        <div className={classes.left}>
          <Icon
            {...iconProps}
            className={clsx(classes.icon, iconProps.className)}
          />
          {children}
        </div>

        {rightChildren && <div className={classes.right}>{rightChildren}</div>}
      </ListItemButton>
    </Paper>
  );
}

const memoizedComponent = memo(Slat, (prevProps, nextProps) => {
  if (
    prevProps.children !== nextProps.children ||
    prevProps.rightChildren !== nextProps.rightChildren ||
    prevProps.onClick !== nextProps.onClick
  ) {
    return false;
  }

  if (
    Object.keys(prevProps.iconProps).length ===
    Object.keys(nextProps.iconProps).length
  ) {
    for (const [key, value] of Object.entries(prevProps)) {
      if (value !== nextProps[key as keyof typeof nextProps]) {
        return false;
      }
    }
  }

  return true;
}) as typeof Slat;

export { memoizedComponent as Slat };
export type { Props as SlatProps };
