import dayjs, { type Dayjs } from 'dayjs';
import durationPlugin, { type Duration } from 'dayjs/plugin/duration';
import { type MeetingMember } from './MeetingMember.ts';

dayjs.extend(durationPlugin);

export abstract class Meeting {
  abstract meetingId: number;
  abstract acceptedMembers: MeetingMember[];
  abstract name: string;
  abstract description?: string;

  abstract when: Dayjs;
  abstract howLong: Duration | null;
  abstract remindBefore: Duration | null;

  abstract meetingOwner: MeetingMember;
  abstract invitedMembers: MeetingMember[];
}
