import { Typography } from '@mui/material';
import clsx from 'clsx';
import classes from './TextOrChip.module.scss';

type Props = TextProps & {
  isHighLighted: boolean;
};

export const TextOrChip = (props: Props) => {
  const { isHighLighted, ...textProps } = props;

  if (isHighLighted) {
    return <Chip {...textProps} />;
  }
  return <Text {...textProps} />;
};

const Chip = (props: TextProps) => {
  return <Text {...props} className={classes.chip} />;
};

type TextProps = {
  text: string;
  className?: string;
  addComma?: boolean;
};

const Text = (props: TextProps) => {
  const { text, className, addComma = false } = props;
  return (
    <>
      <Typography
        variant="body1"
        component="span"
        className={clsx(classes.text, className)}
      >
        {text}
      </Typography>
      {addComma && ', '}
    </>
  );
};
