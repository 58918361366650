import { MapFunction } from '@tomas-light/mapper-js';
import { TagDto } from '~/shared/api/TagDto.ts';
import { Interest } from './Interest.ts';

export const interestMappingProfile = [
  //
  new MapFunction(TagDto, Interest, mapTagDtoToInterest),
];
function mapTagDtoToInterest(tag: TagDto): Interest {
  return {
    id: tag.id,
    name: tag.name,
    linkedCount: tag.userByCount,
  };
}
