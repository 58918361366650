import type { ModelImage } from '../../profile/model/ModelImage.ts';
import { type InvitationStatus } from './InvitationStatus.ts';

export abstract class MeetingMember {
  abstract userId: number;
  abstract firstName: string;
  abstract lastName: string;
  abstract avatar: ModelImage;
  abstract status: InvitationStatus;
  abstract jobPositionId: number | null;
}
