import { Typography } from '@mui/material';
import classes from './NameAndEmail.module.scss';

type Props = {
  name: string;
  email: string;
};

const NameAndEmail = (props: Props) => {
  const { name, email } = props;

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.name}>
        {name}
      </Typography>
      <Typography variant="body1" className={classes.email}>
        {email}
      </Typography>
    </div>
  );
};

export { NameAndEmail };
