import clsx from 'clsx';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '~/shared/molecules/Button/index.ts';
import { routes } from '~/shared/routing/index.ts';
import { StyledAccount } from './StyledAccount.tsx';
import classes from './SwitchButton.module.scss';

type Props = {
  className?: string;
};

export const LoginSwitchButton: FC<Props> = (props) => {
  const { className } = props;

  const navigate = useNavigate();

  return (
    <div className={clsx(classes.appInfo, className)}>
      <div className={classes.toggler}>
        <StyledAccount variant="body1">У вас нет аккаунта?</StyledAccount>

        <Button
          variant="text"
          color="primary"
          onClick={() => {
            navigate(routes.auth.registration.url());
          }}
        >
          Register
        </Button>
      </div>
    </div>
  );
};
