import { type FC, useState } from 'react';
import { type Meeting } from '~/entities/meeting/index.ts';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { notify } from '~/shared/notification/index.ts';
import { DestructionDialog } from '~/shared/templates/DestructionDialog/index.ts';
import { FooterButton } from '~/shared/templates/FooterButton/index.ts';
import { useNavbarLoader } from '~/shared/ui/index.ts';
import { useOnBack } from '../../navigation/index.ts';
import { useCancelMeetingMutation } from '../api/useCancelMeetingMutation.ts';

type Props = {
  meeting: Meeting | undefined;
};

export const CancelMeetingFooterButton: FC<Props> = (props) => {
  const { meeting } = props;

  const [showDestructionDialog, setShowDestructionDialog] = useState(false);

  const onBack = useOnBack({});

  const { data: profile, isLoading: profileIsLoading } = useCurrentUser();

  const { mutate: cancelMeeting, isPending: isMeetingCancelling } =
    useCancelMeetingMutation({
      onSuccessCallback: onBack,
    });

  useNavbarLoader(profileIsLoading || isMeetingCancelling);

  return (
    <>
      <FooterButton
        color="error"
        onClick={() => {
          setShowDestructionDialog(true);
        }}
      >
        Cancel Meeting
      </FooterButton>

      <DestructionDialog
        open={showDestructionDialog}
        title="Are you sure?"
        cancelButtonText="Changed my mind"
        confirmButtonText="Yes, Cancel"
        onCancelOrClose={() => {
          setShowDestructionDialog(false);
        }}
        onConfirm={() => {
          if (meeting) {
            if (profile?.userId !== meeting.meetingOwner.userId) {
              notify({
                message: 'Только владелец встречи может её отменять',
                variant: 'warning',
              });
            } else {
              cancelMeeting(meeting.meetingId);
            }
          }
        }}
      >
        {!meeting && 'You are about to cancel the meeting'}
        {meeting &&
          `You are about to cancel the meeting "{meeting.name}", which has ${meeting.acceptedMembers.length + 1} participant(s).`}
      </DestructionDialog>
    </>
  );
};
