import { MonitorHeartOutlined } from '@mui/icons-material';
import MuiIconButton, { type IconButtonProps } from '@mui/material/IconButton';
import { type FC } from 'react';
import { useApiDevToolsConfig } from '../config/useApiDevToolsConfig.ts';

type Props = Pick<IconButtonProps, 'className' | 'disabled'>;

export const ApiDevToolsButton: FC<Props> = (props) => {
  return (
    <MuiIconButton
      aria-label="API control center"
      title="API control center"
      size="large"
      color="primary"
      onClick={() => {
        useApiDevToolsConfig.setState((prev) => ({
          visible: !prev.visible,
        }));
      }}
      {...props}
    >
      <MonitorHeartOutlined />
    </MuiIconButton>
  );
};
