import { type DeveloperRealApi } from './DeveloperRealApi.ts';

export class DeveloperFakeApi {
  deleteMatches: DeveloperRealApi['deleteMatches'] = async () => {
    throw new Error('deleteMatches not implemented');
  };

  deleteMessagesBetweenMeAndUser: DeveloperRealApi['deleteMessagesBetweenMeAndUser'] =
    async (userId) => {
      throw new Error('deleteMessagesBetweenMeAndUser not implemented');
    };
}
