import { createStore } from '../store/index.ts';

export type Loader = {
  id: string;
  loading?: boolean;
};

export function createLoaderStore() {
  const useLoaderStore = createStore({
    loaders: new Map<string, Loader>(),
  });

  const useActiveLoader = () =>
    useLoaderStore((store) =>
      Array.from(store.loaders.values()).find((loader) => loader.loading)
    );

  const addLoader = (loader: Loader) =>
    useLoaderStore.setState(({ loaders }) => {
      const mutatedLoaders = new Map(loaders);
      mutatedLoaders.set(loader.id, loader);

      return {
        loaders: mutatedLoaders,
      };
    });

  const removeLoader = (loaderId: Loader['id']) =>
    useLoaderStore.setState(({ loaders }) => {
      const mutatedLoaders = new Map(loaders);
      mutatedLoaders.delete(loaderId);

      return {
        loaders: mutatedLoaders,
      };
    });

  return {
    useLoaderStore,
    useActiveLoader,
    addLoader,
    removeLoader,
  };
}
