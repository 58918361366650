import { type MessageType } from './MessageType.ts';
import { SentMessage } from './SentMessage.ts';

export class Message extends SentMessage {
  readonly type: MessageType = 'message';

  inOutType?: 'incoming' | 'outcoming';

  messageId: number;

  constructor(message: Omit<Message, 'type'>) {
    super(message);
    this.inOutType = message.inOutType;
    this.messageId = message.messageId;
  }
}
