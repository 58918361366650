import dayjs, { type Dayjs } from 'dayjs';
import durationPlugin, { type Duration } from 'dayjs/plugin/duration';
import { distinct } from '../../../utils/index.ts';
import { type OmitMethods, type PartialExcept } from '../../../utils/types.ts';
import { type MeetingMember } from './MeetingMember.ts';

dayjs.extend(durationPlugin);

export type MeetingDraftKind = 'new-meeting' | 'clone-meeting' | 'edit-meeting';

export class MeetingDraft {
  draftKind: MeetingDraftKind;

  meetingId?: number;
  name: string;
  when: Dayjs;
  howLong: Duration | null;
  remindBefore: Duration | null;
  meetingOwner: MeetingMember;
  invitedMembers: MeetingMember[];
  acceptedMembers: MeetingMember[];

  constructor(
    draft: PartialExcept<
      OmitMethods<MeetingDraft>,
      'draftKind' | 'meetingOwner'
    >
  ) {
    this.draftKind = draft.draftKind;
    this.meetingId = draft.meetingId ?? undefined;
    this.name = draft.name ?? '';
    this.when = draft.when ?? dayjs();
    this.howLong = draft.howLong ?? dayjs.duration(1, 'hour');
    this.remindBefore = draft.remindBefore ?? null;
    this.meetingOwner = draft.meetingOwner;
    this.invitedMembers = draft.invitedMembers ?? [];
    this.acceptedMembers = draft.acceptedMembers ?? [];
  }

  setDate(date: Dayjs) {
    return new MeetingDraft({
      ...this,
      when: date,
    });
  }

  setName(name: string) {
    return new MeetingDraft({
      ...this,
      name: name,
    });
  }

  setEndTime(endTime: Dayjs | null) {
    let howLong: MeetingDraft['howLong'] = null;

    if (endTime) {
      howLong = dayjs.duration(endTime.diff(this.when));
    }

    return new MeetingDraft({
      ...this,
      howLong: howLong,
    });
  }

  setReminder(reminder: MeetingDraft['remindBefore']) {
    return new MeetingDraft({
      ...this,
      remindBefore: reminder,
    });
  }

  addInvitedMember(newMember: MeetingMember) {
    const meetingMembers = distinct(
      this.invitedMembers.concat(newMember),
      (member) => member.userId
    );

    return new MeetingDraft({
      ...this,
      invitedMembers: meetingMembers,
    });
  }

  removeInvitedMember(member: MeetingMember) {
    if (this.invitedMembers.length <= 1) {
      return this;
    }

    return new MeetingDraft({
      ...this,
      invitedMembers: this.invitedMembers.filter(
        (meetingMember) => meetingMember.userId !== member.userId
      ),
    });
  }
}
