import { IconButton, type OutlinedInputProps } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import {
  type ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Icon } from '../../atoms/icons/index.ts';
import { StyledTextField } from './StyledTextField';

type Props = Pick<
  OutlinedInputProps,
  | 'placeholder'
  | 'onChange'
  | 'value'
  | 'endAdornment'
  | 'type'
  | 'disabled'
  | 'onClick'
  | 'onKeyDown'
  | 'multiline'
  | 'rows'
  | 'fullWidth'
  | 'name'
> & {
  onChangeValue?: (newValue: string) => void;
  isError?: boolean;
  helpText?: string;
  label?: string;
  clearable?: boolean;
};
export type { Props as TextFieldProps };

const component = forwardRef(TextField);
export { component as TextField };

function TextField(props: Props, ref: ForwardedRef<HTMLInputElement>) {
  const {
    onChange,
    onChangeValue,
    isError,
    helpText,
    label,
    disabled,
    value,
    multiline,
    rows,
    fullWidth,
    clearable,
    endAdornment,
    ...rest
  } = props;

  const [, rerender] = useState({});

  useEffect(() => {
    // react-hook-form sets initial values via refs, and react doesn't call rerender
    // so value appears in text field, but clear icon is not visible
    setTimeout(() => {
      rerender({});
    });
  }, []);

  const inputRef = useRef<HTMLInputElement>();
  const hasValue = Boolean(value) || Boolean(inputRef.current?.value);

  const showClearButton = !disabled && hasValue;

  return (
    <StyledTextField
      inputRef={(input) => {
        if (typeof ref === 'function') {
          ref(input);
        } else if (ref) {
          ref.current = input;
        }
        inputRef.current = input;
      }}
      fullWidth={fullWidth}
      InputProps={{
        endAdornment: showClearButton ? (
          <>
            {endAdornment}
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  if (inputRef.current) {
                    inputRef.current.value = '';
                    // без принудительного ререндера компонент не скроет иконку очистки поля
                    rerender({});
                  }
                  onChangeValue?.('');
                }}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
                edge="end"
              >
                <Icon variant="cross" />
              </IconButton>
            </InputAdornment>
          </>
        ) : (
          endAdornment
        ),
        ...rest,
      }}
      label={label}
      value={value}
      onChange={(event) => {
        onChange?.(event);
        onChangeValue?.(event.target.value);
        // без принудительного ререндера при использовании с react-hook-forms компонент при маунте не видит, что у него уже есть значение
        // (как например на экране логина, когда браузер сам авто-заполняет форму)
        // и тогда у нас не показываются иконки очистки поля до первого ререндера... Какое-то говно на стыке MUI и RHF
        rerender({});
      }}
      variant="outlined"
      error={isError}
      disabled={disabled}
      helperText={helpText}
      multiline={multiline}
      rows={rows}
    />
  );
}
