import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useRef, useState } from 'react';
import { useCurrentUser } from '~/entities/profile/index.ts';
import {
  loadSkillsQueryKeys,
  type Skill,
  SkillsInfiniteList,
} from '~/entities/skill/index.ts';
import {
  CancelNavigationButton,
  useOnBack,
} from '~/features/navigation/index.ts';
import { useProfileSkillsEditContext } from '~/features/profile/index.ts';
import { useCreateSkill } from '~/features/skill/index.ts';
import { type TagDto } from '~/shared/api/index.ts';
import { Icon } from '~/shared/atoms/icons';
import { Button } from '~/shared/molecules/Button';
import { NavbarInput } from '~/shared/molecules/NavbarInput';
import { ContentSlot, SlotPortal } from '~/shared/organisms/slots';
import {
  ConfirmButton,
  Display,
  Navbar,
  type PageOffsetInfiniteScrollRef,
} from '~/shared/ui/index.ts';
import classes from './AddProfileSkillDisplay.module.scss';

const MAX_SKILL_NAME_LENGTH = 30;

export const AddProfileSkillDisplay = () => {
  const queryClient = useQueryClient();

  const { data: profile } = useCurrentUser();
  const { mutate: createSkill, isPending: skillIsCreating } = useCreateSkill();
  const { selectedSkillIds, addSkill, deleteSkill } =
    useProfileSkillsEditContext() ?? {};

  const [searchedSkillName, setSearchedSkillName] = useState<Skill['name']>('');

  const scrollRef = useRef<PageOffsetInfiniteScrollRef<TagDto>>(null);
  const onBack = useOnBack({});

  const addOrCreate = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    let skill: TagDto | undefined;

    const loadedSkills = scrollRef.current.getAllRows();

    if (searchedSkillName) {
      skill = loadedSkills.at(0);
    } else {
      skill = loadedSkills.find(
        (_skill) =>
          _skill.name.toLowerCase() === searchedSkillName.toLowerCase()
      );
    }

    if (skill) {
      addSkill?.(skill.id);
      onBack();
    } else {
      createSkill(searchedSkillName, {
        onSuccess: async ({ createdTag }) => {
          await queryClient.invalidateQueries({
            queryKey: loadSkillsQueryKeys(),
          });
          addSkill?.(createdTag.id);
          onBack();
        },
      });
    }
  }, [addSkill, createSkill, onBack, queryClient, searchedSkillName]);

  const doesUserEnterSkillName = Boolean(searchedSkillName);

  return (
    <Display documentTitle="skills" animationVariant="card-effect">
      <Navbar
        title="Add Skills"
        left={<CancelNavigationButton />}
        right={
          <ConfirmButton
            onClick={doesUserEnterSkillName ? addOrCreate : undefined}
          >
            Add
          </ConfirmButton>
        }
        classes={{
          children: classes.navbarChildren,
        }}
        partialLoading={skillIsCreating}
        withBorderBottom
      >
        <NavbarInput
          placeholder="Skill Name"
          icon={<Icon variant="search" />}
          value={searchedSkillName}
          onChange={(newSkillName) => {
            if (newSkillName.length <= MAX_SKILL_NAME_LENGTH) {
              setSearchedSkillName(newSkillName);
            }
          }}
        />
      </Navbar>

      <ContentSlot>
        <SkillsInfiniteList
          ref={scrollRef}
          searchedSkillName={searchedSkillName}
          selectedSkillIds={selectedSkillIds}
          onRowClick={(skill) => {
            if (selectedSkillIds?.has(skill.id)) {
              deleteSkill?.(skill.id);
            } else {
              addSkill?.(skill.id);
            }
          }}
        />
      </ContentSlot>

      <SlotPortal slotName="footer">
        <div className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon variant="check" />}
            onClick={onBack}
            className={classes.button}
            disabled={!selectedSkillIds?.size && !profile?.skillIds.length}
          >
            Confirm Selection
          </Button>
        </div>
      </SlotPortal>
    </Display>
  );
};
