import clsx from 'clsx';
import { memo } from 'react';
import { HorizontalUser, type UserInfo } from './HorizontalUser';
import classes from './HorizontalUserList.module.scss';

type UserInfoWithId = UserInfo & {
  userId: number;
};

type Props<User extends UserInfoWithId> = {
  users: undefined | User[];
  onRemove?: (user: User) => void;
  className?: string;
};

function HorizontalUserList<User extends UserInfoWithId>(props: Props<User>) {
  const { users, onRemove, className } = props;

  return (
    <div className={clsx(classes.root, className)}>
      {users?.map((user) => (
        <HorizontalUser key={user.userId} user={user} onRemove={onRemove} />
      ))}
    </div>
  );
}

const memoizedComponent = memo(HorizontalUserList) as typeof HorizontalUserList;
export { memoizedComponent as HorizontalUserList };
