import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { SkillApi, TagDto } from '~/shared/api/index.ts';
import { time } from '~/utils/index.ts';
import { Skill } from '../model/Skill.ts';

export function useLoadSkillsById(
  skillIds: Skill['id'][] | undefined,
  options?: {
    enabled: boolean;
  }
) {
  const { enabled = true } = options ?? {};

  const skillsApi = useDi(SkillApi);

  return useQuery({
    queryKey: ['load skills by ids', skillIds],
    queryFn: () => skillsApi.searchSkillsById(skillIds ?? []),
    enabled: skillIds != null && skillIds.length > 0 && enabled,
    staleTime: time(5, 'minutes'),
    select: mapResponse,
  });
}

const mapResponse = (dto: Awaited<ReturnType<SkillApi['searchSkillsById']>>) =>
  dto.items.map((dtoItem) => Mapper.map(TagDto, Skill, dtoItem));
