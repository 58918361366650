import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDi } from 'cheap-di-react';
import { invalidateCurrentUserQuery } from '~/entities/profile/index.ts';
import { MatcherApi } from '~/shared/api/index.ts';
import { notify } from '~/shared/notification/index.ts';
import { viewUser } from '../model/useColleaguesViewStore.ts';

export function useLikeColleague() {
  const queryClient = useQueryClient();
  const matcherApi = useDi(MatcherApi);

  return useMutation({
    mutationKey: ['like colleague'],
    mutationFn: matcherApi.like,
    onError: (error) => {
      notify({
        message: `Cant like colleague: ${error.message}`,
        variant: 'error',
      });
    },
    onSuccess: (_, userId) => {
      viewUser(userId);
      invalidateCurrentUserQuery(queryClient);
    },
  });
}
