import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { InterestApi, TagDto } from '~/shared/api/index.ts';
import { time } from '~/utils/index.ts';
import { Interest } from '../model/Interest.ts';

export function useLoadInterestsById(
  interestIds: Interest['id'][] | undefined,
  options?: {
    enabled: boolean;
  }
) {
  const { enabled = true } = options ?? {};

  const interestApi = useDi(InterestApi);

  return useQuery({
    queryKey: ['load interests by ids', interestIds],
    queryFn: () => interestApi.searchInterestsById(interestIds ?? []),
    enabled: interestIds != null && interestIds.length > 0 && enabled,
    staleTime: time(5, 'minutes'),
    select: mapResponse,
  });
}

const mapResponse = (
  dto: Awaited<ReturnType<InterestApi['searchInterestsById']>>
) => dto.items.map((dtoItem) => Mapper.map(TagDto, Interest, dtoItem));
