import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const NetworkFilledIcon = (props: Props) => {
  const { height = 16, color = 'currentColor', ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3636 9.90909H13.0909V8C13.0909 7.61818 12.8364 7.36364 12.4545 7.36364H8.63636V6.09091H9.90909C10.2909 6.09091 10.5455 5.83636 10.5455 5.45455V1.63636C10.5455 1.25455 10.2909 1 9.90909 1H6.09091C5.70909 1 5.45455 1.25455 5.45455 1.63636V5.45455C5.45455 5.83636 5.70909 6.09091 6.09091 6.09091H7.36364V7.36364H3.54545C3.16364 7.36364 2.90909 7.61818 2.90909 8V9.90909H1.63636C1.25455 9.90909 1 10.1636 1 10.5455V14.3636C1 14.7455 1.25455 15 1.63636 15H5.45455C5.83636 15 6.09091 14.7455 6.09091 14.3636V10.5455C6.09091 10.1636 5.83636 9.90909 5.45455 9.90909H4.18182V8.63636H11.8182V9.90909H10.5455C10.1636 9.90909 9.90909 10.1636 9.90909 10.5455V14.3636C9.90909 14.7455 10.1636 15 10.5455 15H14.3636C14.7455 15 15 14.7455 15 14.3636V10.5455C15 10.1636 14.7455 9.90909 14.3636 9.90909Z"
        fill={color}
      />
    </svg>
  );
};

export type { Props as NetworkFilledIconProps };
export { NetworkFilledIcon };
