import { type FC } from 'react';
import { useRegisterUserMutation } from '~/features/auth/api/useRegisterUserMutation.ts';
import { useRegistrationContext } from '~/features/auth/index.ts';
import { Button } from '~/shared/molecules/Button/index.ts';
import { CredentialsStep } from './CredentialsStep.tsx';
import { InformationStep } from './InformationStep.tsx';

export const RegistrationForm: FC = () => {
  const { registrationStep } = useRegistrationContext() ?? {};

  const { mutate: registerUser, isPending: isUserRegistering } =
    useRegisterUserMutation();

  const { email, password, confirmPassword } = useRegistrationContext() ?? {};

  if (registrationStep == null) {
    return null;
  }

  return (
    <div>
      {registrationStep === 'credentials' ? (
        <CredentialsStep />
      ) : (
        <InformationStep
          onSubmit={(data) => {
            if (
              email != null &&
              password != null &&
              confirmPassword != null &&
              data.firstName != null &&
              data.lastName
            ) {
              registerUser({
                email,
                password,
                confirmPassword,
                firstName: data.firstName,
                lastName: data.lastName,
              });
            }
          }}
          SubmitButton={
            <Button
              variant="contained"
              color="primary"
              disabled={isUserRegistering}
              type="submit"
            >
              Sign Up
            </Button>
          }
        />
      )}
    </div>
  );
};
