import { createNiceWebRoutes } from 'nice-web-routes';

export const routes = createNiceWebRoutes({
  // websocket
  hubs: {
    chat: {},
  },
  auth: {
    login: {},
    registration: {},
  },
  // colleagues with mutual match
  contacts: {
    new_meeting: {
      details: {
        member: {},
        reminder: {},
        meeting_created: {},
      },
    },
    userId: () => ({
      avatars: {},
      interests: {},
      skills: {},

      new_meeting: {
        details: {
          member: {},
          reminder: {},
          meeting_created: {},
        },
      },
    }),
  },
  // unknown colleagues
  colleagues: {
    userId: () => ({
      avatars: {},
      interests: {},
      skills: {},
    }),
    filters: {},
  },
  profile: {
    first_login: {},
    avatars: {},
    settings: {
      blockList: {},
      edit: {
        personal: {},
        career: {
          department: {},
          position: {},
        },
        interests: {
          new: {},
        },
        skills: {
          new: {},
        },
      },
      wallet: {},
      level: {},
    },
    interests: {},
    skills: {},
  },
  pets: {},
  quests: {
    questId: () => ({}),
  },
  fake_ton_wallet_connect: {},
  chat: {
    userId: () => ({}),
    new: {},
  },
  meetings: {
    meetingId: () => ({
      edit: {
        details: {
          member: {},
          reminder: {},
          meeting_created: {},
        },
      },
    }),
    new: {
      details: {
        member: {},
        reminder: {},
        meeting_created: {},
      },
    },
  },
  // some playground
  sandbox: {},
});
