import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { type ReactNode } from 'react';
import { Button } from '~/shared/molecules/Button';

type Props = {
  open: boolean;
  onConfirm: () => void;
  onCancelOrClose: () => void;

  title: string;
  children: ReactNode;

  confirmButtonText: string;
  cancelButtonText: string;
};

export const DestructionDialog = (props: Props) => {
  const {
    open,
    onConfirm,
    onCancelOrClose,
    title,
    children,
    confirmButtonText,
    cancelButtonText,
  } = props;

  return (
    <Dialog open={open} onClose={onCancelOrClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onCancelOrClose}>
          {cancelButtonText}
        </Button>

        <Button variant="text" color="error" onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
