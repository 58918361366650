import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { type Meeting } from '~/entities/meeting/index.ts';
import { useCurrentUser } from '~/entities/profile/index.ts';
import {
  AcceptMeetingFooterButton,
  CancelMeetingFooterButton,
  DeclineMeetingFooterButton,
  LeaveMeetingFooterButton,
} from '~/features/meeting/index.ts';
import { SlotPortal } from '~/shared/organisms/slots/index.ts';
import { useNavbarLoader } from '~/shared/ui/index.ts';

type Props = {
  isOwner: boolean;
  meeting: Meeting | undefined;
};

export function ViewMeetingDisplayFooter(props: Props) {
  const { isOwner, meeting } = props;

  const { data: profile, isLoading: profileIsLoading } = useCurrentUser();

  useNavbarLoader(profileIsLoading);

  const hasParticipated =
    profile &&
    !isOwner &&
    meeting?.acceptedMembers.some((member) => member.userId === profile.userId);

  const hasInvited =
    profile &&
    !isOwner &&
    !hasParticipated &&
    meeting?.invitedMembers.some((member) => member.userId === profile.userId);

  if (isOwner) {
    return (
      <SlotPortal slotName="footer">
        <FooterMessage>You are the meeting owner</FooterMessage>
        <CancelMeetingFooterButton meeting={meeting} />
      </SlotPortal>
    );
  }

  if (hasParticipated) {
    return (
      <SlotPortal slotName="footer">
        <FooterMessage> You are participating in the meeting</FooterMessage>
        <LeaveMeetingFooterButton meeting={meeting} />
      </SlotPortal>
    );
  }

  if (hasInvited) {
    return (
      <SlotPortal slotName="footer">
        <FooterMessage>You are invited</FooterMessage>

        <DeclineMeetingFooterButton meeting={meeting} />
        <AcceptMeetingFooterButton meeting={meeting} />
      </SlotPortal>
    );
  }

  return null;
}

const FooterMessage = styled(Typography)({
  '&.MuiTypography-root': {
    padding: '12px 24px 0',
    textAlign: 'center',
  },
});
