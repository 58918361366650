import { useQuery } from '@tanstack/react-query';
import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { JobPositionApi, TagDto } from '~/shared/api/index.ts';
import { time } from '~/utils/index.ts';
import { JobPosition } from '../model/JobPosition.ts';

export const loadLoadJobPositionsQueryKeys = (
  jobPositionName?: JobPosition['name']
) => ['load job position', { jobPositionName }];

export function useLoadJobPositions(jobPositionName?: JobPosition['name']) {
  const jobPositionApi = useDi(JobPositionApi);

  return useQuery({
    queryKey: loadLoadJobPositionsQueryKeys(jobPositionName),
    queryFn: () => jobPositionApi.searchJobPositions({ jobPositionName }),
    staleTime: time(5, 'minutes'),
    select: mapResponse,
  });
}

const mapResponse = (
  dto: Awaited<ReturnType<JobPositionApi['searchJobPositions']>>
) => ({
  totalCount: dto.totalCount,
  departments: dto.items.map((dtoItem) =>
    Mapper.map(TagDto, JobPosition, dtoItem)
  ),
});
