import { FormField } from '../form/FormField.tsx';
import {
  TextField,
  type TextFieldProps,
} from '../organisms/TextField/index.ts';

type Props = Omit<TextFieldProps, 'endAdornment'>;

export const EmailField = (props: Props) => (
  <FormField<{
    email: string | undefined;
  }>
    name="email"
  >
    {({ register, errors }) => (
      <TextField
        placeholder="Email"
        clearable
        isError={errors?.email != null}
        helpText={errors?.email?.message}
        {...props}
        {...register(props)}
      />
    )}
  </FormField>
);
