import { type ForwardedRef, forwardRef } from 'react';
import { type CommonIconProps } from './CommonProps';

type Props = CommonIconProps;

const UsersIcon = (props: Props, ref: ForwardedRef<SVGSVGElement>) => {
  const { height = 24, color = 'currentColor', ...rest } = props;

  return (
    <svg
      ref={ref}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.46271 11.5C11.6813 11.5 13.4966 9.7 13.4966 7.5C13.4966 5.3 11.6813 3.5 9.46271 3.5C7.24409 3.5 5.42885 5.3 5.42885 7.5C5.42885 9.7 7.24409 11.5 9.46271 11.5ZM14.0008 10.6C15.7152 12 18.2364 11.7 19.6482 10C21.0601 8.3 20.7575 5.8 19.0431 4.4C18.3372 3.8 17.4296 3.5 16.522 3.5C15.6144 3.5 14.7067 3.8 14.0008 4.4C15.7152 5.8 16.0177 8.2 14.6059 10C14.505 10.2 14.2025 10.4 14.0008 10.6ZM23.9846 19.4C23.3795 15.7 20.2533 13 16.522 13C15.6144 13 14.8076 13.1 14.0008 13.4C16.6228 14.3 18.5389 16.6 18.9423 19.3C19.0431 19.8 18.6398 20.4 18.0347 20.4H17.9338H22.9762C23.5812 20.4 23.9846 20 23.9846 19.4C23.9846 19.5 23.9846 19.4 23.9846 19.4ZM9.46271 13C5.73139 13 2.5043 15.7 2.00006 19.4C1.89922 19.9 2.3026 20.5 2.90768 20.5H3.00853H15.9169C16.522 20.5 16.9254 20.1 16.9254 19.5V19.4C16.4211 15.7 13.194 13 9.46271 13Z"
        fill={color}
      />
    </svg>
  );
};

const componentWithRef = forwardRef(UsersIcon);
export { componentWithRef as UsersIcon, type Props as UsersIconProps };
