import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoadDepartmentById } from '~/entities/department/index.ts';
import { useIsPublicVersion } from '~/entities/device/index.ts';
import { useCurrentUser } from '~/entities/profile/index.ts';
import { Icon } from '~/shared/atoms/icons';
import { TextField } from '~/shared/organisms/TextField';
import { routes } from '~/shared/routing/index.ts';
import { useProfileCareerEditContext } from '../model/ProfileCareerEditContext.tsx';

export const EditUserCompanyField: FC = () => {
  const { selectedDepartmentId, setSelectedDepartmentId } =
    useProfileCareerEditContext() ?? {};

  const navigate = useNavigate();
  const isPublicVersion = useIsPublicVersion();

  const { data: selectedDepartment } =
    useLoadDepartmentById(selectedDepartmentId);
  const { data: profile } = useCurrentUser();
  const { data: savedDepartment } = useLoadDepartmentById(
    profile?.departmentId
  );

  const department = selectedDepartment ?? savedDepartment;

  return (
    <TextField
      label={isPublicVersion ? 'Company' : 'Department'}
      value={department?.name ?? ''}
      // isError={Boolean(departmentError)}
      // helpText={departmentError} // saving error
      onClick={() =>
        navigate(routes.profile.settings.edit.career.department.url())
      }
      endAdornment={
        selectedDepartmentId != null && (
          <InputAdornment position="end">
            <IconButton
              onClick={(event) => {
                setSelectedDepartmentId?.(null);
                event.stopPropagation(); // do not open department list on clear action
              }}
              edge="end"
            >
              <Icon variant="cross" />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};
