import {
  DateCalendar,
  type DateCalendarProps,
} from '@mui/x-date-pickers/DateCalendar';
import clsx from 'clsx';
import dayjs, { type Dayjs } from 'dayjs';
import { useMemo } from 'react';
import {
  CalendarEventDay,
  type CalendarEventDayProps,
} from './CalendarEventDay';
import classes from './Calendar.module.scss';

export type CalendarProps = DateCalendarProps<Dayjs> &
  Pick<CalendarEventDayProps, 'events'>;

export const Calendar = (props: CalendarProps) => {
  const { events, ...calendarProps } = props;

  const maxDate = useMemo(() => {
    const now = dayjs();
    return now.add(2, 'month');
  }, []);

  return (
    <DateCalendar
      {...calendarProps}
      classes={{
        root: clsx(classes.root, props.classes?.root),
      }}
      slotProps={{
        day: {
          events,
        } as Partial<CalendarEventDayProps>,
      }}
      slots={{
        day: CalendarEventDay,
      }}
      maxDate={maxDate}
    />
  );
};
