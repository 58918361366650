import { useEffect, useMemo } from 'react';
import { guid } from '~/utils/index.ts';
import {
  addNavbarLoader,
  removeNavbarLoader,
} from './model/useNavbarLoaderStore.ts';

export function useNavbarLoader(loading: boolean) {
  const loaderId = useMemo(() => guid(), []);

  useEffect(() => {
    addNavbarLoader({
      id: loaderId,
      loading,
    });

    return () => {
      removeNavbarLoader(loaderId);
    };
  }, [loaderId, loading]);
}
