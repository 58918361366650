import { type Constructor, type Container } from 'cheap-di';
import type { ConfigurationOptions } from '../ConfigurationOptions.ts';
import { createApiLogger } from '../createApiLogger.ts';
import { MeetingApi } from './MeetingApi.ts';
import { MeetingSignal } from './MeetingSignal.ts';

export async function configureMeetingApi(
  container: Container,
  options: ConfigurationOptions
) {
  container
    .registerImplementation(MeetingApi)
    .asSingleton()
    .enrich(createApiLogger('meeting'));

  await configureMeetingSignal(container, options);
}

export async function configureMeetingSignal(
  container: Container,
  options: ConfigurationOptions
) {
  let signal: {
    implementation: Constructor<MeetingSignal>;
    name: string;
  };

  if (options.isMirageApi) {
    const { MeetingFakeSignal } = await import('./MeetingFakeSignal.ts');
    signal = {
      implementation: MeetingFakeSignal,
      name: 'MeetingFakeSignal',
    };
  } else {
    const { MeetingRealSignal } = await import('./MeetingRealSignal.ts');
    signal = {
      implementation: MeetingRealSignal,
      name: 'MeetingRealSignal',
    };
  }

  container
    .registerImplementation(signal.implementation)
    .as(MeetingSignal)
    .enrich(createApiLogger(signal.name));
}
