import { Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import {
  type CSSProperties,
  type ForwardedRef,
  forwardRef,
  useEffect,
} from 'react';
import { useMessageViewedMutation } from '../api/useMessageViewedMutation.ts';
import { Message } from '../model/Message.ts';
import classes from './IncomingMessage.module.scss';

type Props = {
  message: Message | null | undefined;
  loading?: boolean;
  className?: string;
  style?: CSSProperties;
};

const component = forwardRef(IncomingMessage);
export { component as IncomingMessage };

function IncomingMessage(props: Props, ref: ForwardedRef<HTMLDivElement>) {
  const { className, message, style, loading, ...rest } = props;

  const { mutate: messageWasViewed } = useMessageViewedMutation();

  useEffect(() => {
    if (!(message instanceof Message)) {
      debugger;
    }

    if (message instanceof Message && message.status !== 'viewed') {
      messageWasViewed(message);
    }
  }, [message, messageWasViewed]);

  return (
    <div
      ref={ref}
      className={clsx(classes.row, className)}
      style={style}
      {...rest}
    >
      {(loading || !message) && (
        <Skeleton
          className={clsx(classes.message, classes.skeleton)}
          variant="rectangular"
          height={40}
          width={120}
        />
      )}

      {!loading && message && (
        <div className={classes.message}>
          <StyledText variant="body1">{message.content}</StyledText>

          <div className={classes.info}>
            <Typography
              variant="caption"
              component="span"
              className={classes.time}
            >
              {message.date.format('HH:mm')}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
}

const StyledText = styled(Typography)({
  '&.MuiTypography-root': {
    lineHeight: '20px',
    whiteSpace: 'pre-wrap',
  },
});
