import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, IconButton, ListItem, ListItemText } from '@mui/material';
import { type FC, type ReactElement, type ReactNode, useState } from 'react';

type Props = {
  className?: string;
  shortInfo: ReactElement;
  children: ReactNode;
};

const CollapsedItem: FC<Props> = (props) => {
  const { className, shortInfo, children } = props;

  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className={className}>
      <ListItem
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="toggle-view"
            title="Toggle view"
            onClick={() => setCollapsed((state) => !state)}
          >
            {collapsed ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        }
      >
        <ListItemText primary={shortInfo} />
      </ListItem>

      <Collapse in={!collapsed}>{children}</Collapse>
    </div>
  );
};

export { CollapsedItem };
export type { Props as CollapsedItemProps };
