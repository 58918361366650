import { type CommonIconProps } from './CommonProps';

type Props = Omit<CommonIconProps, 'color'>;

const CalendarCheckIcon = (props: Props) => {
  const { height = 97, ...rest } = props;

  return (
    <svg
      {...rest}
      width={height}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5664_13541)">
        <path
          d="M18.598 10.3751C39.7321 10.3751 54.2451 0.5 64.4287 0.5C74.6123 0.5 96 5.78066 96 48.0397C96 90.2988 67.2275 96.5 56.0264 96.5C3.57468 96.5028 -18.8305 10.3751 18.598 10.3751Z"
          fill="#F1F3F5"
        />
        <path
          d="M67.8333 29.1667V34.5H18.5V29.1667C18.5 26.2333 20.9 23.8333 23.8333 23.8333H62.5C65.4333 23.8333 67.8333 26.2333 67.8333 29.1667Z"
          fill="#B1CEF9"
        />
        <path
          d="M67.8333 34.5L67.8307 59.8333C67.8307 62.7773 65.4413 65.1667 62.4973 65.1667H23.8333C20.9 65.1667 18.5 62.7667 18.5 59.8333V34.5H67.8333Z"
          fill="#F3F3F1"
        />
        <path
          d="M29.8333 23.8333H23.8333C20.9 23.8333 18.5 26.2333 18.5 29.1667V34.5H24.5V29.1667C24.5 26.2333 26.9 23.8333 29.8333 23.8333Z"
          fill="#5295F0"
        />
        <path
          d="M24.5 59.8333V34.5H18.5V59.8333C18.5 62.7667 20.9 65.1667 23.8333 65.1667H29.8333C26.9 65.1667 24.5 62.7667 24.5 59.8333Z"
          fill="#DEE2E6"
        />
        <path
          d="M63.1668 78.5C71.6352 78.5 78.5002 71.635 78.5002 63.1667C78.5002 54.6983 71.6352 47.8333 63.1668 47.8333C54.6985 47.8333 47.8335 54.6983 47.8335 63.1667C47.8335 71.635 54.6985 78.5 63.1668 78.5Z"
          fill="#B1CEF9"
        />
        <path
          d="M53.8335 63.1667C53.8335 55.7267 59.1348 49.5267 66.1668 48.132C65.1962 47.94 64.1935 47.8333 63.1668 47.8333C54.6975 47.8333 47.8335 54.6973 47.8335 63.1667C47.8335 71.636 54.6975 78.5 63.1668 78.5C64.1935 78.5 65.1962 78.396 66.1668 78.2013C59.1348 76.8067 53.8335 70.6067 53.8335 63.1667Z"
          fill="#5295F0"
        />
        <path
          d="M60.5 70.5C59.972 70.5 59.46 70.2893 59.0867 69.9133L54.42 65.2467L57.2493 62.4173L60.3987 65.564L67.66 57.188L70.6813 59.8067L62.0147 69.8067C61.652 70.2253 61.1293 70.476 60.5747 70.4947C60.5453 70.5 60.5213 70.5 60.5 70.5ZM39.1667 67.1667H23.8333C19.788 67.1667 16.5 63.8787 16.5 59.8333V29.1667C16.5 25.1213 19.788 21.8333 23.8333 21.8333H62.5C66.5427 21.8333 69.8333 25.1213 69.8333 29.1667V39.8333H65.8333V29.1667C65.8333 27.3293 64.3373 25.8333 62.5 25.8333H23.8333C21.996 25.8333 20.5 27.3293 20.5 29.1667V59.8333C20.5 61.6707 21.996 63.1667 23.8333 63.1667H39.1667V67.1667Z"
          fill="#343A40"
        />
        <path
          d="M18.5 32.5H67.8333V36.5H18.5V32.5ZM27.1667 18.5H31.1667V29.1667H27.1667V18.5ZM55.1667 18.5H59.1667V29.1667H55.1667V18.5ZM63.1667 80.5C53.6093 80.5 45.8333 72.724 45.8333 63.1667C45.8333 53.6093 53.6093 45.8333 63.1667 45.8333C72.724 45.8333 80.5 53.6093 80.5 63.1667C80.5 72.724 72.724 80.5 63.1667 80.5ZM63.1667 49.8333C55.8147 49.8333 49.8333 55.8147 49.8333 63.1667C49.8333 70.5187 55.8147 76.5 63.1667 76.5C70.5187 76.5 76.5 70.5187 76.5 63.1667C76.5 55.8147 70.5187 49.8333 63.1667 49.8333Z"
          fill="#343A40"
        />
      </g>

      <defs>
        <clipPath id="clip0_5664_13541">
          <rect
            width="96"
            height="96"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export type { Props as CalendarCheckIconProps };
export { CalendarCheckIcon };
