import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { useCallback } from 'react';
import { useCurrentUser } from '~/entities/profile/crossExports.ts';
import { DialogDto } from '~/shared/api/DialogDto.ts';
import { ChatSignal, queryKeys } from '~/shared/api/index.ts';
import type { PaginationDto } from '~/shared/api/PaginationDto.ts';
import { Chat } from '../model/Chat.ts';
import { PrivateChat } from '../model/PrivateChat.ts';
import { CHATS_PAGE_SIZE } from '../ui/chatsPageSize.ts';
import { useLoadChatImageUrls } from './useLoadChatImageUrls.ts';

export const infiniteChatsListQueryKeys = (chatName?: Chat['name']) =>
  queryKeys('load chats', 'infinitely', { chatName });

export function useInfiniteChatsListRequest(chatName?: Chat['name']) {
  const chatSignal = useDi(ChatSignal);
  const loadChatImageUrls = useLoadChatImageUrls();

  const { data: myProfile } = useCurrentUser();

  return useCallback(
    async (offset: number): Promise<PaginationDto<Chat>> => {
      const { items: dtos, totalCount } = await chatSignal.getDialogs({
        pageSize: CHATS_PAGE_SIZE,
        page: offset,
        searchString: chatName,
      });

      const chats = dtos.map((dto) => Mapper.map(DialogDto, Chat, dto));

      for (const chat of chats) {
        if (chat instanceof PrivateChat) {
          if (chat.interlocutorId === myProfile?.userId) {
            chat.name = 'Избранное';
          }
        }
      }

      await loadChatImageUrls(chats);

      return {
        items: chats,
        totalCount: totalCount,
      };
    },
    [chatSignal, chatName, loadChatImageUrls, myProfile?.userId]
  );
}
