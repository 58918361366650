import type dayjs from 'dayjs';
import { type FC } from 'react';
import { DateField } from '~/shared/organisms/fields/index.ts';

type Props = {
  workExperienceSince: dayjs.Dayjs | null;
  onChange: (joinedCompanyDate: Props['workExperienceSince']) => void;
};

export const EditCareerStartDateField: FC<Props> = (props) => {
  const { workExperienceSince, onChange } = props;

  return (
    <DateField
      label="Career Start Date"
      value={workExperienceSince?.utc()}
      onChange={onChange}
      disableFuture
      openTo="year"
      views={['year', 'month']}
    />
  );
};
