import type { MeetingStatusEnum } from '../../enums/MeetingStatusEnum.ts';
import { type MeetingMemberDto } from './MeetingMemberDto.ts';

export abstract class MeetingDto {
  abstract meetingId: number;
  abstract name: string;
  abstract startAt: string; // iso
  abstract members: MeetingMemberDto[];
  abstract icon?: string;
  abstract reminderInMinutes: number | null;
  abstract howLong: number | null; // in minutes
  abstract description?: string;
  abstract meetingStatus: MeetingStatusEnum;
}
