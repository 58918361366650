import * as yup from 'yup';
import {
  oneDigitRegexp,
  oneLowercaseLetterRegexp,
  onePasswordSpecialCharacterRegexp,
  oneUppercaseLetterRegexp,
} from './regulareExpressions.ts';

const passwordMinLength = 8;

export class LoginUserCredentials {
  email: string | undefined;
  password: string | undefined;

  static validationSchema = yup.object({
    email: yup.string().email().required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .transform((password) => password.trim())
      .min(
        passwordMinLength,
        `Password length must be at least ${passwordMinLength} characters.`
      )
      .matches(
        oneLowercaseLetterRegexp,
        'Password must contain at least one lowercase letter'
      )
      .matches(
        oneUppercaseLetterRegexp,
        'Password must contain at least one uppercase letter'
      )
      .matches(oneDigitRegexp, 'Password must contain at least one digit')
      .matches(
        onePasswordSpecialCharacterRegexp,
        'Password must contain at least one special character'
      ),
  });
}
