import { type Constructor, type Container } from 'cheap-di';
import type { ConfigurationOptions } from '../ConfigurationOptions.ts';
import { createApiLogger } from '../createApiLogger.ts';
import { ChatSignal } from './ChatSignal.ts';

export async function configureChatApi(
  container: Container,
  options: ConfigurationOptions
) {
  let api: {
    implementation: Constructor<ChatSignal>;
    name: string;
  };

  if (options.isMirageApi) {
    const { ChatFakeSignal } = await import('./ChatFakeSignal.ts');
    api = {
      implementation: ChatFakeSignal,
      name: 'ChatFakeSignal',
    };
  } else {
    const { ChatRealSignal } = await import('./ChatRealSignal.ts');
    api = {
      implementation: ChatRealSignal,
      name: 'ChatRealSignal',
    };
  }

  container
    .registerImplementation(api.implementation)
    .as(ChatSignal)
    .enrich(createApiLogger(api.name));
}
