import { Divider, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { type Quest as QuestModel } from '~/entities/quest/index.ts';
import { ShortInfo } from './ShortInfo';
import { Tasks } from './Tasks';
import classes from './Quest.module.scss';

type Props = {
  quest: QuestModel;
};

export const Quest = (props: Props) => {
  const { quest } = props;

  const dateBoundaries = useMemo(() => {
    const start = formatDate(quest.startAt);
    const end = formatDate(quest.endAt);
    return `${start} - ${end}`;
  }, [quest, quest.endAt]);

  return (
    <div className={classes.root}>
      <div className={classes.shortInfoContainer}>
        <ShortInfo label="Сроки проведения">{dateBoundaries}</ShortInfo>
      </div>

      <Divider />

      <div>
        <Typography
          variant="h6"
          className={classes.taskTitle}
          sx={{
            fontSize: 18,
            lineHeight: '24px',
          }}
        >
          Список задач - {quest.taskCounts.total}
        </Typography>

        <Tasks questId={quest.id} tasks={quest.tasks} />
      </div>
    </div>
  );
};

function formatDate(date: Date) {
  return dayjs(date).format('DD.MM.YYYY');
}
