import { Mapper } from '@tomas-light/mapper-js';
import { useDi } from 'cheap-di-react';
import { type FC, type PropsWithChildren, useEffect } from 'react';
import { useAuthorizationToken } from '~/entities/auth/index.ts';
import { Message } from '~/entities/chat/index.ts';
import { ChatMessagesController } from '~/pages/chat/redux/index.ts';
import { IncomeMessageDto } from '~/shared/api/IncomeMessageDto.ts';
import { ChatSignal } from '~/shared/api/index.ts';
import { useMessageReceivedMutation } from '../api/useMessageReceivedMutation.ts';

export const ChatReactionsProvider: FC<PropsWithChildren> = ({ children }) => {
  useConfigureReactionsOnWebsocketMessages();
  return children;
};

function useConfigureReactionsOnWebsocketMessages() {
  const chatSignal = useDi(ChatSignal);
  const authorizationToken = useAuthorizationToken();

  const { mutate: notifyMessagesReceived } = useMessageReceivedMutation();

  useEffect(() => {
    if (!authorizationToken) {
      return;
    }

    (async () => {
      await chatSignal.start(authorizationToken);

      chatSignal.onNewMessage((incomeMessageDto) => {
        const newMessage = Mapper.map(
          IncomeMessageDto,
          Message,
          incomeMessageDto
        );
        newMessage.inOutType = 'incoming';

        notifyMessagesReceived(newMessage);
      });

      chatSignal.onMessageWasSent((messageSentNotificationDto) => {
        // todo: do we need anything here ?
        //  mark message wit hone mark icon ?
      });
      chatSignal.onUpdateMessageStatus((dto) => {
        ChatMessagesController.onUpdateMessageStatus({
          messageNotification: dto,
        });
      });
    })();

    return () => {
      chatSignal.stop();
    };
  }, [authorizationToken, chatSignal, notifyMessagesReceived]);
}
