import { yupResolver } from '@hookform/resolvers/yup';
import {
  type ForwardedRef,
  forwardRef,
  type PropsWithChildren,
  type ReactElement,
  type RefAttributes,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import {
  type DefaultValues,
  type Resolver,
  type SubmitHandler,
  useForm,
} from 'react-hook-form';
import { type ObjectSchema } from 'yup';
import { FormContext, type FormContextType } from './FormContext.ts';

export type FormProviderRef = {
  triggerSubmit: VoidFunction;
};

type Props<FormValues extends object> = PropsWithChildren<{
  className?: string;
  initialValues?: DefaultValues<FormValues>;
  validationSchema: ObjectSchema<FormValues>;
  onValidSubmit?: SubmitHandler<FormValues>;
}>;

const component = forwardRef(FormProvider) as <FormValues extends object>(
  props: Props<FormValues> & RefAttributes<FormProviderRef>
) => ReactElement;
export { component as FormProvider };

function FormProvider<FormValues extends object>(
  props: Props<FormValues>,
  ref: ForwardedRef<FormProviderRef>
) {
  const {
    className,
    children,
    initialValues,
    validationSchema,
    onValidSubmit,
  } = props;

  const usedForm = useForm({
    resolver: yupResolver(validationSchema, {
      abortEarly: false,
    }) as unknown as Resolver<FormValues>,
    defaultValues: initialValues,
    reValidateMode: 'onChange',
  });

  const { handleSubmit, formState } = usedForm;

  const onValidSubmitRef = useRef(onValidSubmit);

  const context = useMemo<FormContextType<FormValues>>(
    () => ({
      form: usedForm,
      setOnValidSubmit: (handler) => {
        onValidSubmitRef.current = handler;
      },
    }),
    [usedForm, formState]
  );

  const onSubmit = useMemo(
    () => handleSubmit((...args) => onValidSubmitRef.current?.(...args)),
    [handleSubmit]
  );

  useImperativeHandle(
    ref,
    () => ({
      triggerSubmit: () => {
        onSubmit();
      },
    }),
    [onSubmit]
  );

  return (
    <FormContext.Provider value={context}>
      <form
        className={className}
        onSubmit={(formEvent) => {
          // prevents html submit (with page reload)
          formEvent.preventDefault();

          onSubmit(formEvent);
        }}
      >
        {children}
      </form>
    </FormContext.Provider>
  );
}
