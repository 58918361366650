import { type FC } from 'react';
import { useUnblockUserMutation } from '~/features/block-list/index.ts';
import { useNavbarLoader , NotFoundView } from '~/shared/ui/index.ts';
import { useBlockedUsersQuery } from '../api/useBlockedUsersQuery.ts';
import type { BlockedUser } from '../model/BlockedUser.ts';
import { BlockedUserRow } from './BlockedUserRow.tsx';

type Props = {
  searchedName?: BlockedUser['name'];
};

export const BlockedUsers: FC<Props> = (props) => {
  const { searchedName } = props;

  const { data: blockedUsers, isLoading } = useBlockedUsersQuery(searchedName);
  const { mutate: unblockUserById, isPending: isUserUnblocking } =
    useUnblockUserMutation();

  useNavbarLoader(isLoading || isUserUnblocking);

  if (!blockedUsers?.length) {
    return <NotFoundView>Block list is empty</NotFoundView>;
  }

  return (
    <div>
      {blockedUsers?.map((user) => (
        <BlockedUserRow
          key={user.userId}
          user={user}
          onUnblockClick={() => {
            if (user) {
              unblockUserById(user.userId);
            }
          }}
        />
      ))}
    </div>
  );
};
