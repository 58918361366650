import { RealApiBase } from '../RealApiBase.ts';
import { apiRoutes, type Search, type TagDto } from '../index.ts';
import type { PaginationDto } from '../PaginationDto.ts';

export class JobPositionRealApi extends RealApiBase {
  createJobPosition = (jobPositionName: string) => {
    return this.post<{ createdTag: TagDto }>(
      apiRoutes.tags.job_position.create.url(),
      {
        name: jobPositionName,
      }
    );
  };

  searchJobPositions = (search: Search<'jobPositionName'>) => {
    return this.post<PaginationDto<TagDto>>(
      apiRoutes.tags.job_position.search.url(),
      {
        query: search.jobPositionName,
        page: search.page,
        pageSize: search.pageSize,
      }
    );
  };

  searchJobPositionsById = (jobPositionIds: number[]) => {
    return this.post<PaginationDto<TagDto>>(
      apiRoutes.tags.job_position.search_by_ids.url(),
      {
        tagIds: jobPositionIds,
      }
    );
  };
}
