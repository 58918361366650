import { RealApiBase } from '../RealApiBase.ts';
import { apiRoutes } from '../apiRoutes.ts';
import type { UserForCarouselDto } from '../profile/UserForCarouselDto.ts';

export class DeveloperRealApi extends RealApiBase {
  deleteMatches = async () => {
    return this.get<{}>(apiRoutes.developer.delete_all_queue_likes.url());
  };

  deleteMessagesBetweenMeAndUser = async (
    userId: UserForCarouselDto['userId']
  ) => {
    return this.post<{}>(
      apiRoutes.developer.dev_delete_messages_between.url(),
      {
        userId,
      }
    );
  };
}
