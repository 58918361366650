import { ApiBase } from '../ApiBase.ts';
import { type MatcherFakeApi } from './MatcherFakeApi.ts';
import { type MatcherRealApi } from './MatcherRealApi.ts';

export class MatcherApi extends ApiBase {
  constructor(
    private realApi: MatcherRealApi,
    private fakeApi: MatcherFakeApi
  ) {
    super();
  }

  getUsersForCarousel: MatcherRealApi['getUsersForCarousel'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.getUsersForCarousel(...args);
    }
    return this.realApi.getUsersForCarousel(...args);
  };

  like: MatcherRealApi['like'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.like(...args);
    }
    return this.realApi.like(...args);
  };

  dislike: MatcherRealApi['dislike'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.dislike(...args);
    }
    return this.realApi.dislike(...args);
  };

  getMatches: MatcherRealApi['getMatches'] = (...args) => {
    if (this.isFakeApi) {
      return this.fakeApi.getMatches(...args);
    }
    return this.realApi.getMatches(...args);
  };

  getFakeMutualMatches = async () => {
    return this.fakeApi.getMutualMatches();
  };
}
