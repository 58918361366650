import { type FakeApiDatabase } from '../../fakeApiDatabase/index.ts';
import { findCurrentUser } from '../findCurrentUser.ts';
import type { MyUserDto } from '../profile/MyUserDto.ts';
import type { UserForCarouselDto } from '../profile/UserForCarouselDto.ts';
import { COLLEAGUES_PAGE_SIZE } from './COLLEAGUES_PAGE_SIZE.ts';
import { CONTACTS_PAGE_SIZE } from './CONTACTS_PAGE_SIZE.ts';
import { type MatcherRealApi } from './MatcherRealApi.ts';
import { type ViewedUserStatusEntity } from './ViewedUserEntity.ts';

export class MatcherFakeApi {
  private readonly findCurrentUser: () => ReturnType<typeof findCurrentUser>;

  constructor(readonly db: FakeApiDatabase) {
    this.findCurrentUser = findCurrentUser.bind(null, db);
  }

  getUsersForCarousel: MatcherRealApi['getUsersForCarousel'] = async (dto) => {
    const {
      filters: { interestIds, skillIds, departmentIds, jobPositionIds },
      page,
      pageSize,
    } = dto;

    const currentUser = await this.findCurrentUser();

    const users = await this.db.user.getAll();
    const matches = await this.db.match.get(currentUser.userId);
    const viewedUserIds = new Set(
      matches?.viewed.map((viewedUser) => viewedUser.userId)
    );

    const filters = {
      departments:
        departmentIds.length > 0 ? new Set<number | null>(departmentIds) : null,
      jobPositions:
        jobPositionIds.length > 0
          ? new Set<number | null>(jobPositionIds)
          : null,
    };

    const filtered = users.filter((user) => {
      if (user.userId === currentUser.userId) {
        return false;
      }
      if (viewedUserIds?.has(user.userId)) {
        return false;
      }

      if (interestIds.length > 0) {
        const set = new Set(user.interests);
        if (!interestIds.some((id) => set.has(id))) {
          return false;
        }
      }

      if (skillIds.length > 0) {
        const set = new Set(user.skills);
        if (!skillIds.some((id) => set.has(id))) {
          return false;
        }
      }

      if (filters.departments) {
        if (!filters.departments.has(user.department)) {
          return false;
        }
      }

      if (filters.jobPositions) {
        if (!filters.jobPositions.has(user.jobPosition)) {
          return false;
        }
      }

      return true;
    });

    const normalizedSize = pageSize ?? COLLEAGUES_PAGE_SIZE;
    const startIndex = (page ?? 0) * normalizedSize;
    const endIndex = startIndex + normalizedSize;
    const batch = filtered.slice(startIndex, endIndex);

    return {
      totalCount: filtered.length,
      usersToMatch: batch,
    };
  };

  like: MatcherRealApi['like'] = async (userId) => {
    return this.setMatchStatus(userId, 'like');
  };

  dislike: MatcherRealApi['dislike'] = async (userId) => {
    return this.setMatchStatus(userId, 'dislike');
  };

  private async setMatchStatus(
    userId: UserForCarouselDto['userId'],
    status: ViewedUserStatusEntity
  ) {
    const currentUser = await this.findCurrentUser();

    let myMatches = await this.db.match.get(currentUser.userId);
    if (!myMatches) {
      await this.db.match.insert(currentUser.userId, {
        userId,
        viewed: [
          {
            userId,
            status: status,
          },
        ],
      });
      return {};
    }

    let viewedUser = myMatches.viewed.find(
      (viewedUser) => viewedUser.userId === userId
    );
    if (!viewedUser) {
      viewedUser = {
        userId,
        status: status,
      };
      myMatches.viewed.push(viewedUser);
    } else {
      viewedUser.status = status;
    }

    await this.db.match.update(currentUser.userId, myMatches);

    return {};
  }

  getMatches: MatcherRealApi['getMatches'] = async (dto) => {
    const currentUser = await this.findCurrentUser();

    const matchEntities = await this.db.match.getAll();
    const userMatches = matchEntities.find(
      (match) => match.userId === currentUser.userId
    );
    if (!userMatches) {
      return {
        items: [],
        totalItemsCount: 0,
      };
    }

    const likedUserIds = userMatches.viewed
      .filter((viewedUser) => viewedUser.status === 'like')
      .map((viewedUser) => viewedUser.userId);
    const likedUserIdsSet = new Set(likedUserIds);
    /** matches of users that were liked by current user */
    const likedUserMatches = matchEntities.filter((match) =>
      likedUserIdsSet.has(match.userId)
    );
    const counterLikedUserMatches = likedUserMatches.map((match) => ({
      userId: match.userId,
      status: match.viewed.find(
        (viewedUser) => viewedUser.userId === currentUser.userId
      )?.status,
    }));
    const mutualMatch = counterLikedUserMatches.filter(
      (match) => match.status === 'like'
    );
    const matchedUserIds = new Set(mutualMatch.map((match) => match.userId));

    const { userName, page, pageSize } = dto;

    const users = await this.db.user.getAll();
    let filteredUsers = users.filter((user) => matchedUserIds.has(user.userId));

    if (userName) {
      const lowerCasedName = userName.toLowerCase();
      filteredUsers = filteredUsers.filter((user) =>
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(lowerCasedName)
      );
    }

    const normalizedSize = pageSize ?? CONTACTS_PAGE_SIZE;
    const startIndex = (page ?? 0) * normalizedSize;
    const endIndex = startIndex + normalizedSize;
    const batch = filteredUsers.slice(startIndex, endIndex);

    return {
      items: batch,
      totalItemsCount: filteredUsers.length,
    };
  };

  getMutualMatches = async () => {
    const userReactions = await this.db.match.getAll();

    type LikedUsersSet = Set<MyUserDto['userId']>;

    const map = new Map<MyUserDto['userId'], LikedUsersSet>();

    for (const userReaction of userReactions) {
      const likedReactions = userReaction.viewed.filter(
        (reaction) => reaction.status === 'like'
      );

      map.set(
        userReaction.userId,
        new Set(likedReactions.map((reaction) => reaction.userId))
      );
    }

    const mutualLikeIds: Array<
      readonly [MyUserDto['userId'], MyUserDto['userId']]
    > = [];
    const uniqueSet = new Set<string>();

    map.forEach((likedUsersSet, currentUserId) => {
      likedUsersSet.forEach((likedUserId) => {
        const backLikesSet = map.get(likedUserId);
        if (!backLikesSet?.has(currentUserId)) {
          return;
        }

        const ids = [currentUserId, likedUserId] as const;
        const compositeKey_1 = ids.join(', ');
        const compositeKey_2 = ids.toReversed().join(', ');

        if (!uniqueSet.has(compositeKey_1) && !uniqueSet.has(compositeKey_2)) {
          mutualLikeIds.push(ids);
          uniqueSet.add(compositeKey_1);
        }
      });
    });

    const allUsers = await this.db.user.getAll();
    const usersMap = allUsers.reduce((_map, user) => {
      return _map.set(user.userId, {
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        userAvatarPhotoFileName: user.userAvatarPhotoFileName,
        department: user.department,
        jobPosition: user.jobPosition,
        aboutMe: user.aboutMe,
        timezoneId: user.timezoneId,
        workInCompanyStartDate: user.workInCompanyStartDate,
        careerStartDate: user.careerStartDate,
        skills: user.skills,
        interests: user.interests,
        profilePhotoNames: user.profilePhotoNames,
        sensitiveInformation: user.sensitiveInformation,
        wasSaved: user.wasSaved,
        tonWallets: Object.entries(user.connectedWallets).reduce(
          (wallets, [walletName, walletDto]) => {
            wallets[walletName as 'telegram-wallet'] = {
              isAlive: true,
              address: walletDto.id,
            };
            return wallets;
          },
          {} as MyUserDto['tonWallets']
        ),
        coins: user.coins,
        levelableSkills: user.levelableSkills,
      });
    }, new Map<MyUserDto['userId'], MyUserDto>());

    const mutualLikes: Array<[MyUserDto, MyUserDto]> = [];
    for (const [userId_1, userId_2] of mutualLikeIds) {
      mutualLikes.push([usersMap.get(userId_1)!, usersMap.get(userId_2)!]);
    }

    return mutualLikes;
  };
}
